import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

function AssetGraph({ events, graphCount, assetId, assetType }) {
  const [graphData, setGraphData] = useState({
    options: {
      chart: {
        id: assetId,
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
    },
    series: [
      {
        name: "view(s)",
        data: [],
      },
    ],
  });
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    setTimeout(() => setDisplay(true), 1);
  }, []);

  useEffect(() => {
    if (events && events.length) {
      const graphDataRef = graphData;
      let keys = [...Array(Number(graphCount)).keys()];
      const seriesData = [];
      if (assetType === "document") {
        keys.shift();
        keys.push(keys.length + 1);
      } else {
        graphDataRef.series[0].name = "pause(s)";
      }

      keys.map((item, index) => {
        let interactions = [];
        if (assetType === "video") {
          interactions = events.filter(
            (event) =>
              Math.floor(event.video_point) === item &&
              event.event_name === "video_pause"
          );
          keys[index] = `${item}s`;
        } else {
          interactions = events.filter(
            (event) =>
              event.document_page === item &&
              (event.event_name === "view_start" ||
                event.event_name === "document_page_change")
          );
          keys[index] = `Page ${item}`;
        }
        seriesData.push(interactions.length);
        return true;
      });

      graphDataRef.options.xaxis.categories = keys;
      graphDataRef.series[0].data = seriesData;
      setGraphData(() => graphDataRef);
    }
  }, [events, graphCount]); //eslint-disable-line

  if (graphData.options.xaxis.categories.length === 0) {
    return null;
  }

  if (!display) {
    return <></>;
  }

  return (
    <div className="px-2 py-1 mt-1 bg-[whitesmoke] rounded">
      <Chart
        options={graphData.options}
        series={graphData.series}
        type="area"
        width="200"
        height="30"
      />
    </div>
  );
}

export default AssetGraph;
