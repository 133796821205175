import React from "react";

import Account from "../../Settings/Account";
import "./Organization.css";

const Organization = ({ slide }) => {
  return (
    <div className="organization-show-wrap">
      <div className="organization-show">
        <div className="">
          <Account slide={slide} />
        </div>
      </div>
    </div>
  );
};

export default Organization;
