import React from "react";

import { PageWrapper } from "../../components";
import ComposeChild from "./components/ComposeChild";

import "./compose.css";

const Compose = () => {
  return (
    <PageWrapper>
      <ComposeChild />
    </PageWrapper>
  );
};

export default Compose;
