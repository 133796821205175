import React from 'react';

import { BsTrash } from 'react-icons/bs';

const Promote = () => {
  return (
    <div className="bg-white rounded-xl md:m-4 md:p-14 p-2 w-full flex flex-col">
      <div className="font-bold text-uberark-orange text-xl">
        {' '}
        Promote Settings
      </div>

      <div className="flex justify-between mt-10 my-4">
        <div className="flex flex-col text-[#757575] text-xs">
          <span>Promoted By: Jon Deo</span>
          <span>Promoted On: 14 Apr 2022</span>
        </div>
        <div className="flex items-end">
          <button className="flex items-center gap-2 text-[#757575] text-base">
            <BsTrash />
            <span>Delete</span>
          </button>
        </div>
      </div>

      <div className="w-full h-full flex items-center justify-center">
        <div className="w-[500px]">
          <img
            src="https://images.unsplash.com/photo-1562059392-096320bccc7e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fG9jZWFufGVufDB8MnwwfHw%3D&auto=format&fit=crop&w=800&q=60"
            alt=""
            className="object-cover h-[500px] w-[500px] rounded-2xl"
            style={{ border: '0.24px solid #000080' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Promote;
