import React, { Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";
import Dcalender from "../../components/commons/Charts/Dcalender";
import Parameter from "../../components/commons/Charts/Parameter";
import PiCharts from "../../components/commons/Charts/PiCharts";
import Line from "../../components/commons/Charts/Line";
import RecentUpload from "../../components/commons/Charts/RecentUpload";

import "react-circular-progressbar/dist/styles.css";
import { BiCog } from "react-icons/bi";

const Dashboard = () => {
  return (
    <div
      className="mt-6 md:mt-0 relative top-20 md:top-0"
      style={{
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
        width: "-webkit-fill-available",
      }}
    >
      <div className="flex justify-end">
        <Menu as="div" className="relative inline-block text-left mr-3">
          <div>
            <Menu.Button className="inline-flex justify-center w-full rounded-full  hover:bg-light-gray p-2 focus:outline-none">
              <BiCog
                size={25}
                className="flex text-uberark-blue hover:text-uberark-orange"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10">
              <div className="py-1">
                <label className="flex gap-2 hover:text-uberark-orange px-4 py-2 text-sm flex-row items-center">
                  <input type="checkbox" className="checked:bg-#00ff00" />
                  Weekly Report
                </label>
                <label className="flex gap-2 hover:text-uberark-orange px-4 py-2 text-sm flex-row items-center">
                  <input type="checkbox" className="checked:bg-#00ff00" />
                  Files recently uploaded
                </label>
                <label className="flex gap-2 hover:text-uberark-orange px-4 py-2 text-sm flex-row items-center">
                  <input type="checkbox" className="checked:bg-#00ff00" />
                  Upcoming schedule
                </label>
                <label className="flex gap-2 hover:text-uberark-orange px-4 py-2 text-sm flex-row items-center">
                  <input type="checkbox" className="checked:bg-#00ff00" />
                  Visit insights
                </label>
              </div>
              <div className="px-4 py-2 cursor-pointer">
                Reset Dashboard Layouts
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <div className="">
        <div className="grid lg:grid-flow-col grid-flow-row">
          <div className="mt-5 grid grid-flow-row gap-4 pl-1rem">
            <div className="grid md:grid-flow-col grid-flow-row gap-4">
              <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-32 w-full rounded-xl p-2 hover:shadow-xl">
                <Parameter
                  data={{
                    per: 66,
                    label: "Parameter 1",
                    value: "17,068.99",
                  }}
                  theme="orange"
                />
              </div>

              <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-32 w-full rounded-xl  p-2 m-2 hover:shadow-xl">
                <Parameter
                  data={{
                    per: 55,
                    label: "Parameter 2",
                    value: "17,068.99",
                  }}
                  theme="blue"
                />
              </div>
            </div>

            <div className="mx-2 w-full bg-white rounded-xl flex flex-wrap flex-col lg:flex-nowrap mb-6 hover:shadow-xl">
              <span className="flex font-semibold text-black text-xl p-2 relative">
                Monthly report
              </span>
              <div className="h-96">
                <Line />
              </div>
            </div>

            <div className="flex-col w-full mx-2 p-2 mb-2 bg-white rounded-xl flex flex-wrap lg:flex-nowrap hover:shadow-xl">
              <span className="flex  font-semibold text-black text-xl p-2 relative">
                Files recently uploaded
              </span>
              <RecentUpload />
            </div>
          </div>
          <div className="flex flex-col h-full w-full items-center prl-1rem gap-4">
            <div className="flex w-full">
              <Dcalender />
            </div>

            <div className="bg-white flex flex-col w-full rounded-xl mt-6">
              <div className="flex overflow-x-scroll flex-col justify-center hover:shadow-xl w-full">
                <span className="flex font-semibold text-black text-xl p-2 mb-8 relative">
                  Visit insights
                </span>
                <PiCharts />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
