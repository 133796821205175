import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  BsFingerprint,
  BsFillEyeFill,
  BsFillArrowDownCircleFill,
  BsClockFill,
  BsPersonFillLock,
  BsActivity,
} from "react-icons/bs";

import { handleGetReadableTimeRange } from "../../../app/utilities/helpers";
import { conversationsActions } from "../actions";
import avatarPlaceholder from "../../../assets/images/avatar-placeholder.png";
import DigitalPrint from "./DigitalPrint";

const Recipient = ({
  conversationData,
  contactData,
  handleActivitySlideToggle,
}) => {
  const dispatch = useDispatch();
  const [sessionShow, setSessionShow] = useState(false);

  const onChangeAccess = () => {
    return dispatch(
      conversationsActions.handleConversationsAccess({
        contact_id: contactData.id,
        email_id: conversationData.id,
      })
    );
  };

  const onSessionHideShow = () => {
    setSessionShow(!sessionShow);
  };

  return (
    <>
      <div className="flex flex-col flex-wrap w-full p-4 gap-4">
        <div className="flex md:flex-row flex-col flex-wrap items-center justify-between">
          <div className="flex flex-col items-center p-2 gap-4">
            <div className="flex flex-row items-center gap-2">
              <div className="flex">
                <img
                  src={avatarPlaceholder}
                  alt="avatar"
                  className="w-8 h-8 rounded-full shadow-xl"
                  loading="lazy"
                />
              </div>
              <div className="flex flex-col">
                <span
                  className={`${
                    contactData.anonymous
                      ? "text-slate-600"
                      : "text-uberark-blue"
                  } text-base  font-semibold`}
                >
                  {contactData.email}
                </span>
                <div className="flex items-center divide-x divide-slate-500">
                  {contactData?.sessions && contactData?.sessions.length > 0 ? (
                    <div
                      className="flex justify-center items-center pr-2 cursor-pointer text-xs text-uberark-orange"
                      onClick={() => onSessionHideShow()}
                    >
                      <BsFingerprint className="mr-1" /> Digital Footprint&nbsp;
                    </div>
                  ) : null}
                  {!contactData?.anonymous ? (
                    <span
                      className="text-uberark-orange text-xs cursor-pointer flex items-center pl-2"
                      onMouseDown={() => onChangeAccess()}
                      id={contactData.id}
                    >
                      <BsPersonFillLock className="mr-1" />{" "}
                      {contactData.access ? " Remove Access" : "Grant Access"}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center h-full">
            {contactData?.access ? (
              <>
                <div className="divide-x text-xs divide-slate-500 flex justify-end text-black">
                  <div className="flex justify-center items-center px-2">
                    <BsFillEyeFill className="text-slate-600" />
                    <div className="text-xs ml-1 text-slate-500">
                      {contactData?.statistics?.views_count
                        ? contactData?.statistics?.views_count
                        : 0}
                    </div>
                  </div>
                  <div className="flex justify-center items-center px-2">
                    <BsFillArrowDownCircleFill className="text-slate-600" />
                    <div className="text-xs ml-1 text-slate-500">
                      {contactData?.statistics?.download_count
                        ? contactData?.statistics?.download_count
                        : 0}
                    </div>
                  </div>
                  <div className="flex justify-center items-center px-2">
                    <BsClockFill className="text-slate-600" />
                    <div className="text-xs ml-1 text-slate-500">
                      {contactData?.statistics?.time_spent
                        ? handleGetReadableTimeRange(
                            contactData?.statistics?.time_spent
                          )
                        : "0"}
                    </div>
                  </div>
                  <div className="flex justify-center items-center px-2">
                    <div
                      className="text-xs ml-1 text-slate-600 cursor-pointer flex text-uberark-orange"
                      onClick={() =>
                        handleActivitySlideToggle(contactData, "recipient")
                      }
                    >
                      <BsActivity className="mr-1" />
                      Timeline
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>

        {sessionShow && (
          <DigitalPrint
            hideSession={onSessionHideShow}
            sessionDatas={contactData?.sessions}
          />
        )}
      </div>
    </>
  );
};

export default Recipient;
