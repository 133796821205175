import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  HtmlEditor,
  Inject,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  Link,
} from "@syncfusion/ej2-react-richtexteditor";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { FiEye, FiEyeOff, FiEdit } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import { BsFillPersonFill, BsFillEnvelopeFill } from "react-icons/bs";
import LoadingSpinner from "../../components/commons/LoadingSpinner";
import avatarPlaceholder from "../../assets/images/avatar-placeholder.png";
import { toast } from "react-toastify";

import { authActions } from "../Auth/actions";
import { settingsActions } from "./actions";
import { RichTextEditorToolbar } from "../../app/utilities/helpers";

const Profile = () => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.auth);
  const {
    loading: loadingEsign,
    eSign,
    eSignCreation,
    eSignDeleteConfirmation,
    eSignDltloading,
  } = useSelector((state) => state.settings);
  const [eye, setEye] = useState(false);
  const [confirmEye, setConfirmEye] = useState(false);
  const [initialValues, setInitialValues] = useState({
    password: "",
    username: "",
    avatar: "",
    confirmPassword: "",
  });
  const [initialEmailSignValues, setInitialEmailSignValues] = useState({
    signature: eSign && eSign.length ? eSign[0]["signature"] : "",
  });
  const [noData, setNoData] = useState(true);
  const supportedFileExt = ["image/jpeg", "image/jpg", "image/png"];

  useEffect(() => {
    setInitialValues({
      username: user?.username || "",
      avatar: user.avatar || "",
      password: "",
      confirmPassword: "",
    });
  }, [user]);

  useEffect(() => {
    if (user && user.organization) {
      if ((!eSign || eSign.length === 0) && noData) {
        setNoData(false);
      }
      setInitialEmailSignValues({
        signature: eSign && eSign.length ? eSign[0]["signature"] : "",
      });
    }
  }, [dispatch, eSign, noData, user, user.organization]);

  useEffect(() => {
    if (
      user &&
      user.organization &&
      (eSignCreation || eSignDeleteConfirmation)
    ) {
      dispatch(settingsActions.handleESignListsFetch(`email/signatures`));
    }
  }, [dispatch, eSignCreation, eSignDeleteConfirmation, user]);

  const FormSchema = Yup.object().shape({
    username: Yup.string().required("Please enter username"),
    password: Yup.string()
      .min(8, "Password is too short - should be 8 chars minimum.")
      .max(16, "Use max of 16 characters")
      .matches(
        /[^a-zA-Z0-9]/,
        "Password should have atleast 1 special character"
      )
      .matches(/[A-Z]/, "Password should have atleast 1 Upper case character")
      .matches(/[a-z]/, "Password should have atleast 1 Lower case character")
      .matches(/[0-9]/, "Password should have atleast 1 Number character"),
    confirmPassword: Yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
  });

  const ESFormSchema = Yup.object().shape({
    signature: Yup.string().required("Please enter Email signature"),
  });

  const handleSubmit = (data) => {
    const payload = data;
    const trimmedPassword = payload.password.trim();
    const trimmedConfrimPassword = payload.confirmPassword.trim();
    if (!payload.password) {
      delete payload.password;
    }
    if (typeof payload.avatar === "string" && payload.avatar.includes("http")) {
      delete payload.avatar;
    }

    if (trimmedPassword) {
      if (trimmedConfrimPassword) {
        if (
          trimmedPassword.length &&
          trimmedConfrimPassword.length &&
          trimmedPassword === trimmedConfrimPassword
        ) {
          return dispatch(authActions.handleUpdateUser(payload));
        } else {
          return;
        }
      } else {
        return;
      }
    }
    return dispatch(authActions.handleUpdateUser(payload));
  };

  const togglePasswordVisible = () => setEye((prevState) => !prevState);
  const toggleConfirmPasswordVisible = () =>
    setConfirmEye((prevState) => !prevState);

  const handleESSubmit = (data) => {
    return eSign.length
      ? dispatch(settingsActions.handleESignUpdate(data, eSign["0"]["id"]))
      : dispatch(settingsActions.handleESignCreation(data));
  };

  const onEsignDelete = () => {
    dispatch(settingsActions.handleESignDelete(eSign["0"]["id"]));
  };

  return (
    <div
      className={`flex flex-col ${
        window.innerWidth < 768 ? "w-full" : "w-9/12"
      }`}
    >
      <div className="bg-white rounded-xl lg:m-4 lg:p-14 p-2 md:p-4 h-full">
        <div className="font-bold text-uberark-orange text-xl"> Profile</div>

        <div className="flex lg:flex-row flex-col justify-between w-full">
          <div className="flex flex-col gap-4 text-uberark-blue w-full">
            <div className="py-3 w-full grid">
              <div className="floating-form flex flex-col gap-2 w-full">
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={FormSchema}
                  enableReinitialize
                  validator={() => ({})}
                >
                  {({ errors, touched, values, setFieldValue }) => (
                    <Form className="w-full">
                      <div className="mb-4 flex justify-center w-full">
                        <label
                          className={`w-[166px] h-[166px] rounded-full relative bg-transparent flex flex-row items-center justify-center hover:bg-white drop-shadow-xl`}
                          style={{ border: "0.24px solid #000080" }}
                        >
                          <img
                            className="w-full h-full cursor-pointer opacity-80 hover:scale-10 ease-in-out duration-300 rounded-full object-contain"
                            style={{
                              filter: "brightness(0.5)",
                            }}
                            src={
                              !values.avatar
                                ? avatarPlaceholder
                                : typeof values.avatar !== "string"
                                ? URL.createObjectURL(values.avatar)
                                : values.avatar
                            }
                            alt="cover"
                          />

                          <span
                            className="rounded bg-white text-sm flex items-center text-slate-600 p-2 bottom-[65px]"
                            style={{ position: "absolute" }}
                          >
                            {values.avatar ? (
                              <>
                                <FiEdit className="hover:scale-105 ease-in-out duration-300 mr-1" />{" "}
                                Edit Picture
                              </>
                            ) : (
                              <>
                                <AiOutlinePlus className="hover:scale-105 ease-in-out duration-300 mr-1" />{" "}
                                Add Picture
                              </>
                            )}
                          </span>

                          <input
                            className="floating-input h-14 pl-2 text-lg text-uberark-blue hidden"
                            name="avatar"
                            id="avatar"
                            type="file"
                            onChange={(event) => {
                              if (
                                supportedFileExt.includes(
                                  event.currentTarget.files[0]["type"]
                                )
                              ) {
                                setFieldValue(
                                  "avatar",
                                  event.currentTarget.files[0]
                                );
                              } else {
                                const selectedFile =
                                  event.currentTarget.files[0];
                                const extension =
                                  selectedFile.name.split(".")[1];
                                toast.error(
                                  `.${extension} file format is not supported!`
                                );
                              }
                            }}
                          />
                        </label>
                        {errors.avatar && touched.avatar && (
                          <div
                            className="text-red-600 pb-2"
                            style={{ right: "-120%", position: "absolute" }}
                          >
                            {errors.avatar}
                          </div>
                        )}
                      </div>

                      <div className="grid grid-cols-2 gap-2 w-full relative">
                        <div className="w-full">
                          <div className="font-semibold">Username: </div>
                          <div className="mb-3 relative w-full">
                            <Field
                              className="floating-input pl-2 text-lg text-uberark-blue"
                              name="username"
                              id="username"
                              type="text"
                              placeholder={user.username}
                            />
                            {errors.username && touched.username && (
                              <div className="text-red-600">
                                {errors.username}
                              </div>
                            )}
                            <span className="absolute top-[10px] right-5">
                              <BsFillPersonFill
                                size={20}
                                className="text-[#666666]"
                              />
                            </span>
                          </div>
                        </div>

                        <div className="w-full">
                          <div className="font-semibold">Email: </div>
                          <div className="mb-3 relative w-full">
                            <Field
                              className="floating-input pl-2 text-lg text-uberark-blue"
                              name="email"
                              id="email"
                              type="text"
                              placeholder={user.email}
                              disabled
                            />
                            <span className="absolute top-[10px] right-5">
                              <BsFillEnvelopeFill
                                size={20}
                                className="text-[#666666]"
                              />
                            </span>
                          </div>
                        </div>

                        <div className="relative w-full">
                          <span className="font-semibold">
                            Password:&nbsp;{" "}
                          </span>
                          <div className="pb-3 relative w-full">
                            <Field
                              className="floating-input pl-2 text-lg text-uberark-blue"
                              name="password"
                              id="password"
                              type={eye ? "text" : "password"}
                              placeholder="**********"
                            />
                            {errors.password && touched.password && (
                              <div className="text-red-600">
                                {errors.password}
                              </div>
                            )}
                            <div
                              className="absolute top-[10px] right-5"
                              onMouseDown={() => togglePasswordVisible()}
                            >
                              {eye ? (
                                <FiEyeOff
                                  size={20}
                                  className="text-[#666666]"
                                />
                              ) : (
                                <FiEye size={20} className="text-[#666666]" />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="relative w-full">
                          <span className="font-semibold">
                            Confirm Password:&nbsp;{" "}
                          </span>
                          <div className="pb-3 relative w-full">
                            <Field
                              className="floating-input pl-2 text-lg text-uberark-blue"
                              name="confirmPassword"
                              id="confirmPassword"
                              type={confirmEye ? "text" : "password"}
                              placeholder="**********"
                            />
                            {errors.confirmPassword &&
                              touched.confirmPassword && (
                                <div className="text-red-600">
                                  {errors.confirmPassword}
                                </div>
                              )}
                            <div
                              className="absolute top-[10px] right-5"
                              onMouseDown={() => toggleConfirmPasswordVisible()}
                            >
                              {confirmEye ? (
                                <FiEyeOff
                                  size={20}
                                  className="text-[#666666]"
                                />
                              ) : (
                                <FiEye size={20} className="text-[#666666]" />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="flex gap-4">
                          <button
                            type="submit"
                            className=":px-2 bg-[#000080] text-white hover:drop-shadow-lg font-bold py-2 px-6 hover:scale-105 ease-in-out duration-200 rounded-2xl"
                            disabled={loading}
                          >
                            {loading ? <LoadingSpinner /> : "Save"}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl lg:m-4 lg:p-14 p-2 md:p-4 h-full w-full">
        <div className="flex flex-col mb-4">
          <span className="text-uberark-blue font-semibold py-4">
            Email Signature
          </span>
          <div className="bg-white rounded-3xl">
            <Formik
              initialValues={initialEmailSignValues}
              onSubmit={handleESSubmit}
              validationSchema={ESFormSchema}
              enableReinitialize
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form>
                  <RichTextEditorComponent
                    change={(event) => setFieldValue("signature", event.value)}
                    value={values.signature}
                    toolbarSettings={RichTextEditorToolbar}
                    placeholder="Enter Email signature..."
                  >
                    <Inject
                      services={[HtmlEditor, Link, Toolbar, QuickToolbar]}
                    />
                  </RichTextEditorComponent>
                  {errors.signature && touched.signature && (
                    <div className="text-red-600 pb-2">{errors.signature}</div>
                  )}
                  <div className="flex flex-row text-uberark-blue gap-4 p-2 text-lg">
                    <button
                      className=":px-2 bg-[#000080] hover:bg-white text-white hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-6 hover:scale-105 ease-in-out duration-200 rounded-2xl"
                      type="submit"
                      disabled={loadingEsign}
                    >
                      {loadingEsign ? (
                        <LoadingSpinner />
                      ) : eSign && eSign.length ? (
                        "Edit"
                      ) : (
                        "Save"
                      )}
                    </button>
                    {eSign && eSign.length ? (
                      <button
                        className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-6 hover:scale-105 ease-in-out duration-200 sm:bg-transparent sm:text-uberark-blue rounded-2xl"
                        disabled={eSignDltloading}
                        style={{ border: "0.24px solid #000080" }}
                        type="button"
                        onClick={() => onEsignDelete()}
                      >
                        {eSignDltloading ? <LoadingSpinner /> : "Delete"}
                      </button>
                    ) : null}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
