import React, { Fragment } from "react";
import { NavLink, Outlet } from "react-router-dom";

import { Menu, Transition } from "@headlessui/react";

import { BsChevronDown } from "react-icons/bs";

const UserProfile = () => {
  const activeLink =
    "flex items-center gap-2 p-5 rounded-lg text-base text-uberark-orange text-md  font-semibold";
  const normalLink =
    "flex items-center gap-2 p-5 rounded-lg text-base text-[#000080] dark:text-gray-200 dark:hover:text-black hover:text-red-600  font-semibold";
  const nLink =
    "bg-white lg:w-64 w-52 h-20 rounded-2xl flex justify-center text-[#000080] items-center font-semibold hover:text-uberark-orange";
  const aLink =
    "bg-white lg:w-64 w-52 h-20 p-5 rounded-2xl flex justify-center text-uberark-orange items-center font-semibold";

  return (
    <div className="mt-24 md:mt-0">
      <div className="md:mt-8 md:mx-20 mx-6 flex flex-row justify-between">
        <div className="flex flex-col justify-items-end">
          <span className="text-uberark-orange font-extrabold text-xl">
            Settings
          </span>
          <span className="text-[#757575] text-base">step up, set up!</span>
        </div>
      </div>
      <div className="md:hidden flex justify-end px-4">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-500 hover:text-[#000080] hover:bg-gray-50 focus:outline-none">
              Options
              <BsChevronDown
                className="-mr-1 ml-2 h-5 w-5"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-fit-content rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-50">
              <div className="py-1">
                <Menu.Item>
                  <NavLink
                    to="/settings/profile"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <div className="grid grid-flow-col gap-2 justify-start items-center">
                      <span>Profile</span>
                    </div>
                  </NavLink>
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  <NavLink
                    to="/settings/users"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <div className="grid grid-flow-col gap-2 justify-start items-center">
                      <span>User Management</span>
                    </div>
                  </NavLink>
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  <NavLink
                    to="/settings/account"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <div className="grid grid-flow-col gap-2 justify-start items-center">
                      <span>Account Management</span>
                    </div>
                  </NavLink>
                </Menu.Item>
              </div>
              {/*
                <div className="py-1">
                  <Menu.Item>
                    <NavLink
                      to="/settings/promote"
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <div className="grid grid-flow-col gap-2 justify-start items-center">
                        <span>Promote Settings</span>
                      </div>
                    </NavLink>
                  </Menu.Item>
                </div>
                */}
              <div className="py-1">
                <Menu.Item>
                  <NavLink
                    to="/settings/integrations"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <div className="grid grid-flow-col gap-2 justify-start items-center">
                      <span> Integrations</span>
                    </div>
                  </NavLink>
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  <NavLink
                    to="/settings/email-templates"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <div className="grid grid-flow-col gap-2 justify-start items-center">
                      <span>Email Templates</span>
                    </div>
                  </NavLink>
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  <NavLink
                    to="/settings/nda-templates"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <div className="grid grid-flow-col gap-2 justify-start items-center">
                      <span>NDA Templates</span>
                    </div>
                  </NavLink>
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  <NavLink
                    to="/settings/privacy-control"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <div className="grid grid-flow-col gap-2 justify-start items-center">
                      <span>Privacy Policy Control</span>
                    </div>
                  </NavLink>
                </Menu.Item>
              </div>
              {/*
                <div className="py-1">
                  <Menu.Item>
                    <NavLink
                      to="/settings/privacy-policy"
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <div className="grid grid-flow-col gap-2 justify-start items-center">
                        <span>Privacy Policy</span>
                      </div>
                    </NavLink>
                  </Menu.Item>
                </div>
              */}
              <div className="py-1">
                <Menu.Item>
                  <NavLink
                    to="/settings/terms"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <div className="grid grid-flow-col gap-2 justify-start items-center">
                      <span>Terms & Conditions</span>
                    </div>
                  </NavLink>
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  <NavLink
                    to="/settings/notifications"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <div className="grid grid-flow-col gap-2 justify-start items-center">
                      <span>Notification Settings</span>
                    </div>
                  </NavLink>
                </Menu.Item>
              </div>
              {/*
                <div className="py-1">
                  <Menu.Item>
                    <NavLink
                      to="/settings/calender"
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      <div className="grid grid-flow-col gap-2 justify-start items-center">
                        <span>Calender</span>
                      </div>
                    </NavLink>
                  </Menu.Item>
                </div>
              */}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <div className="rounded-3xl bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] m-4 p-2 shadow flex md:py-8 py-4">
        <div className="flex flex-row w-full">
          <div className="hidden md:flex flex-col gap-4 p-4">
            <NavLink
              to="/settings/profile"
              className={({ isActive }) => (isActive ? aLink : nLink)}
            >
              Profile
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? aLink : nLink)}
              to="/settings/users"
            >
              User Management
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? aLink : nLink)}
              to="/settings/account"
            >
              Account Management
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? aLink : nLink)}
              to="/settings/notifications"
            >
              Notifications
            </NavLink>
            {/*             
              <NavLink
                className={({ isActive }) => (isActive ? aLink : nLink)}
                to="/settings/promote"
              >
                Promote Settings
              </NavLink>
              */}
            <NavLink
              className={({ isActive }) => (isActive ? aLink : nLink)}
              to="/settings/integrations"
            >
              Integrations
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? aLink : nLink)}
              to="/settings/email-templates"
            >
              Email Templates
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? aLink : nLink)}
              to="/settings/nda-templates"
            >
              NDA Templates
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? aLink : nLink)}
              to="/settings/privacy-control"
            >
              Privacy Policy Control
            </NavLink>
            {/*   <NavLink
                className={({ isActive }) => (isActive ? aLink : nLink)}
                to="/settings/privacy-policy"
              >
                Privacy Policy
              </NavLink>
            */}
            <NavLink
              className={({ isActive }) => (isActive ? aLink : nLink)}
              to="/settings/terms"
            >
              Terms and Conditions
            </NavLink>
            {/*
              <NavLink
                className={({ isActive }) => (isActive ? aLink : nLink)}
                to="/settings/calender"
              >
                Calender
              </NavLink>
            */}
          </div>

          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
