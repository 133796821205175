import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "../../components/commons/LoadingSpinner";
import {
  HtmlEditor,
  Inject,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  Link,
} from "@syncfusion/ej2-react-richtexteditor";
import { toast } from "react-toastify";

import { FiEdit } from "react-icons/fi";
import { BsXLg, BsFillEyeFill, BsLayoutTextWindow } from "react-icons/bs";
import "./collection.css";

import { collectionActions } from "./actions";
import { RichTextEditorToolbar } from "../../app/utilities/helpers";
import Templates from "./components/Templates";

const CreateNewCollection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { folder } = useParams();
  const { loading, collectionTemplate: templates } = useSelector(
    (state) => state.collection
  );
  const { organization } = useSelector((state) => state.auth.user);
  const [fileType, setFileType] = useState("");
  const [preview, setPreview] = useState(false);
  const [collectionTemplate, setCollectionTemplate] = useState(false);
  const [collectionShowTemplate, setCollectionShowTemplate] = useState(false);
  const [collectionSelectedTemplate, setCollectionSelectedTemplate] = useState(
    {}
  );

  const supportedLogoFileExt = ["image/jpeg", "image/jpg", "image/png"];
  const supportedBannerFileExt = [...supportedLogoFileExt, "video/mp4"];
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    banner: "",
    logo: "",
  });

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    description: Yup.string().nullable(),
    banner: collectionTemplate
      ? Yup.mixed()
      : Yup.mixed()
          .nullable()
          .test("fileType", "Unsupported Banner File Format", (value) =>
            value ? supportedBannerFileExt.includes(value?.type) : true
          ),
    logo: collectionTemplate
      ? Yup.mixed()
      : Yup.mixed()
          .nullable()
          .test("fileType", "Unsupported Logo File Format", (value) =>
            value ? supportedLogoFileExt.includes(value?.type) : true
          ),
  });

  useEffect(() => {
    dispatch(collectionActions.handleGetCollectionTemplate(1, "True"));
  }, [dispatch]);

  const onSubmitCallback = (id) => {
    navigate(`/collection/${id}`);
  };

  const handleCreateCollection = (data) => {
    if (organization) {
      const payload = {
        ...data,
      };

      if (folder) {
        payload.folder = folder;
      }
      if (typeof data.banner === "string") {
        delete payload.banner;
      }
      if (typeof data.logo === "string") {
        delete payload.logo;
      }
      if (
        collectionTemplate &&
        collectionSelectedTemplate &&
        Object.keys(collectionSelectedTemplate).length
      ) {
        return dispatch(
          collectionActions.handleCreateCollection(
            payload,
            onSubmitCallback,
            collectionSelectedTemplate.id
          )
        );
      } else {
        return dispatch(
          collectionActions.handleCreateCollection(payload, onSubmitCallback)
        );
      }
    }

    toast.error("You need to be in an organization to create");
  };

  const onSelectedTemplate = (t) => {
    setCollectionSelectedTemplate(t);
    setInitialValues({
      name: t.name,
      description: t.description,
      banner: t.banner,
      logo: t.logo,
    });
    setCollectionShowTemplate(false);
  };

  return (
    <div className="relative">
      <div className="absolute sm:top-0 top-20 w-full h-full mb-5">
        <div className="md:mt-4 md:mx-20 mx-4 mt-4 grid grid-cols-1 md:grid-cols-2 justify-between bg-transparent">
          <div className="flex flex-col justify-items-end">
            <span className="text-[#FE4502] font-extrabold text-lg">
              Collections
            </span>

            <span className="text-[#757575] text-base">
              Create new collection.
            </span>
          </div>
          <div className="grid grid-flow-col items-center justify-between md:justify-end py-4 pt-4 md:p-0 text-slate-600">
            <button
              onClick={() => navigate("/collections")}
              className="inline-block font-medium text-xl leading-tight hover:shadow-md hover:bg-light-gray shadow-gray-900 focus:bg-white focus:shadow-xl focus:outline-none focus:ring-0 active:shadow-lg hover:scale-105 ease-in-out duration-300 p-3 bg-white w-10 h-10 flex justify-center items-center"
              style={{ borderRadius: "100%", border: "0.24px solid #000080" }}
            >
              <BsXLg className="text-[#FE4502] font-bold" />
            </button>
          </div>
        </div>

        <div className="rounded-3xl bg-white m-4 shadow grid">
          <div className="flex flex-col w-full">
            <div className="rounded-3xl bg-white flex flex-row justify-between flex-wrap gap-4 text-gray-600">
              <div className="w-full">
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={handleCreateCollection}
                  validationSchema={FormSchema}
                  className="w-full"
                >
                  {({ errors, touched, setFieldValue, values }) => (
                    <Form>
                      <div
                        className="relative bg-white min-h-[370px] rounded-3xl object-contain flex flex-wrap text-gray-600 w-full"
                        style={{
                          backgroundImage: !values.banner["type"]
                            ? `url(${values.banner})`
                            : values.banner &&
                              supportedBannerFileExt.includes(
                                values.banner["type"]
                              )
                            ? `url(${URL.createObjectURL(values.banner)})`
                            : "",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      >
                        {values.banner && fileType.includes("video/mp4") ? (
                          <video
                            className="absolute w-full h-full rounded-2xl"
                            style={{ objectFit: "fill" }}
                            controls={false}
                            loop
                            muted
                            autoPlay
                          >
                            <source
                              src={URL.createObjectURL(values.banner)}
                              type="video/mp4"
                            />
                            <source
                              src={URL.createObjectURL(values.banner)}
                              type="video/ogg"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : !values.banner["type"] &&
                          values.banner.includes(".mp4") ? (
                          <video
                            className="absolute w-full h-full rounded-2xl"
                            style={{ objectFit: "fill" }}
                            controls={false}
                            loop
                            muted
                            autoPlay
                          >
                            <source src={values.banner} type="video/mp4" />
                            <source src={values.banner} type="video/ogg" />
                            Your browser does not support the video tag.
                          </video>
                        ) : null}

                        <div
                          className="absolute right-10 top-5 flex"
                          style={{ zIndex: "100" }}
                        >
                          {!preview ? (
                            <>
                              <label className="bg-transparent flex flex-row items-center justify-center rounded hover:bg-white drop-shadow-xl mr-1">
                                <span
                                  className="rounded border px-2 flex items-center"
                                  style={{ backgroundColor: "white" }}
                                >
                                  <FiEdit className="hover:scale-105 ease-in-out duration-300" />
                                  Add Logo
                                </span>
                                <input
                                  className="floating-input object-contain h-14 pl-2 text-lg text-uberark-blue hidden"
                                  name="logo"
                                  id="logo"
                                  type="file"
                                  onChange={(event) => {
                                    if (
                                      supportedLogoFileExt.includes(
                                        event.currentTarget.files[0]["type"]
                                      )
                                    ) {
                                      setFileType(
                                        event.currentTarget.files[0]["type"]
                                      );
                                      setFieldValue(
                                        "logo",
                                        event.currentTarget.files[0]
                                      );
                                    } else {
                                      toast.error("The Logo should be image!");
                                    }
                                  }}
                                />
                              </label>

                              <label className="bg-transparent flex flex-row items-center justify-center rounded hover:bg-white drop-shadow-xl">
                                <span
                                  className="rounded border px-2 flex items-center"
                                  style={{ backgroundColor: "white" }}
                                >
                                  <FiEdit className="hover:scale-105 ease-in-out duration-300" />
                                  Add Banner
                                </span>
                                <input
                                  className="floating-input h-14 pl-2 text-lg text-uberark-blue hidden"
                                  name="banner"
                                  id="banner"
                                  type="file"
                                  onChange={(event) => {
                                    if (
                                      supportedBannerFileExt.includes(
                                        event.currentTarget.files[0]["type"]
                                      )
                                    ) {
                                      setFileType(
                                        event.currentTarget.files[0]["type"]
                                      );
                                      setFieldValue(
                                        "banner",
                                        event.currentTarget.files[0]
                                      );
                                    } else {
                                      toast.error(
                                        "The banner should be image or video!"
                                      );
                                    }
                                  }}
                                />
                              </label>
                            </>
                          ) : null}

                          {templates && templates.length > 0 && (
                            <button
                              className={`${
                                collectionTemplate ? "text-[#FE4502]" : ""
                              } ml-2 border px-2 bg-white flex flex-row items-center justify-center rounded hover:bg-white drop-shadow-xl mr-1`}
                              type="button"
                              onClick={() => {
                                setCollectionTemplate(!collectionTemplate);
                                setCollectionShowTemplate(
                                  !collectionShowTemplate
                                );
                              }}
                            >
                              <BsLayoutTextWindow className="hover:scale-105 ease-in-out duration-300" />{" "}
                              &nbsp;Template
                            </button>
                          )}

                          <button
                            className={`${
                              preview ? "text-[#FE4502]" : ""
                            } ml-2 border px-2 bg-white flex flex-row items-center justify-center rounded hover:bg-white drop-shadow-xl mr-1`}
                            type="button"
                            onClick={() => setPreview(!preview)}
                          >
                            <BsFillEyeFill className="hover:scale-105 ease-in-out duration-300" />
                          </button>
                        </div>

                        <div
                          className="relative p-8 pt-2 justify-between w-full relative bg-[#fafafd4f] rounded-3xl"
                          style={{
                            background:
                              "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QgdBy0F1sAe4QAAACFJREFUCNdj/P//vy8DBNgzMDAwMDGgARaYDAMDw0GsKgD/0gSTo/gcFwAAAABJRU5ErkJggg==) repeat hsla(0,0%,100%,.4)",
                          }}
                        >
                          <div className="w-full">
                            <div className="flex flex-col h-[40px] w-[80px] w-80px">
                              {supportedLogoFileExt.includes(
                                values.logo["type"]
                              ) ? (
                                <img
                                  src={URL.createObjectURL(values.logo)}
                                  alt="logo"
                                  className="object-contain w-full h-full rounded"
                                />
                              ) : null}
                              {!values.logo["type"] && values.logo.length ? (
                                <img
                                  src={values.logo}
                                  alt="logo"
                                  className="object-contain w-full h-full rounded edhu"
                                />
                              ) : null}
                            </div>
                          </div>
                          {errors.banner && touched.banner && (
                            <div className="text-red-600 pb-2 font-normal text-sm flex w-full">
                              {errors.banner}
                            </div>
                          )}
                          {errors.logo && touched.logo && (
                            <div className="text-red-600 pb-2 font-normal text-sm flex w-full">
                              {errors.logo}
                            </div>
                          )}

                          <div className="flex flex-col items-center md:flex-row">
                            <div className="flex sm:flex-row flex-col pt-2 gap-4 min-h-[166px] md:w-3/4 w-full pr-2">
                              <div className="flex flex-col gap-2">
                                <span className="text-[#FE4502] font-bold text-2xl text-left mb-2">
                                  {!preview ? (
                                    <Field
                                      placeholder="Enter collection name"
                                      className="p-2 outline-none input-transparent"
                                      name="name"
                                      id="name"
                                    />
                                  ) : (
                                    <>{values.name}</>
                                  )}

                                  {errors.name && touched.name && (
                                    <div className="text-red-600 pb-2 font-normal text-sm">
                                      {errors.name}
                                    </div>
                                  )}
                                </span>

                                {!preview ? (
                                  <RichTextEditorComponent
                                    change={(event) => {
                                      setFieldValue("description", event.value);
                                    }}
                                    value={values.description}
                                    toolbarSettings={RichTextEditorToolbar}
                                    className="richtext-border-remove"
                                    placeholder="Enter collection description..."
                                  >
                                    <Inject
                                      services={[
                                        HtmlEditor,
                                        Toolbar,
                                        Link,
                                        QuickToolbar,
                                      ]}
                                    />
                                  </RichTextEditorComponent>
                                ) : (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: values.description,
                                    }}
                                  />
                                )}

                                {errors.description && touched.description && (
                                  <div className="text-red-600 pb-2 font-normal text-sm">
                                    {errors.description}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="flex flex-col flex-wrap h-full md:w-1/4 w-full"></div>
                          </div>
                        </div>

                        <div className="flex flex-row text-uberark-blue gap-4 text-lg mb-3 absolute bottom-2 right-5">
                          <button
                            className=":px-2 bg-[#000080] text-white  hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl "
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? <LoadingSpinner /> : "Save"}
                          </button>
                          <button
                            className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
                            onClick={() => navigate("/collections")}
                            style={{ border: "0.24px solid #000080" }}
                            type="button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                {collectionShowTemplate && (
                  <Templates
                    onClose={setCollectionShowTemplate}
                    visible={collectionShowTemplate}
                    onSelectedTemplate={onSelectedTemplate}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewCollection;
