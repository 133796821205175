import React, { useEffect, useState } from "react";

import {
  BsFillEyeFill,
  BsFillArrowDownCircleFill,
  BsClockFill,
  BsActivity,
} from "react-icons/bs";
import AssetCard from "../../commons/Asset/components/AssetCard";
import AssetGraph from "./AssetGraph";

import {
  handleGetReadableTimeRange,
  textTruncate,
} from "../../../app/utilities/helpers";

const Asset = ({ asset, handleActivitySlideToggle }) => {
  const [events, setEvents] = useState([]);
  const [assetType, setAssetType] = useState();

  useEffect(() => {
    if (asset) {
      const imageExtensions = ["jpeg", "jpg", "png"];
      const videoExtensions = ["mp4"];
      const iframeCompatibleDocuments = [
        "pdf",
        "ppt",
        "pptx",
        "doc",
        "docx",
        "xls",
        "xlxs",
      ];
      iframeCompatibleDocuments.includes(
        asset.name.split(".").at(-1).toLowerCase()
      ) && setAssetType("document");
      videoExtensions.includes(asset.name.split(".").at(-1).toLowerCase()) &&
        setAssetType("video");
      imageExtensions.includes(asset.name.split(".").at(-1).toLowerCase()) &&
        setAssetType("image");
    }

    if (asset.activity && asset.activity.length) {
      const allEvents = [];
      asset.activity.forEach((activity) => {
        if (activity.events && activity.events.length) {
          allEvents.push(...activity.events);
        }
      });

      setEvents(allEvents);
    }
  }, [asset]);

  return (
    <div key={asset.id} className="flex flex-col w-full p-4 gap-4">
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="flex flex-col items-center p-2 gap-4">
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex relative">
              <AssetCard
                asset={asset}
                size="asset__small"
                className="md:w-20 md:h-20 w-11 h-11 conversation-asset"
              />
            </div>

            <div className="flex flex-col">
              <span className="text-[#000000] w-[200px] truncate font-normal pl-2">
                {textTruncate(asset.name, 17)}
              </span>
              {/* {assetType === "video" || assetType === "document" ? (
                <>
                  {asset?.statistics?.last_viewed && (
                    <div className="flex items-center px-2 text-[10px] text-[#757575] font-normal italic">
                      Last Viewed
                      <div className="text-[10px] text-[#757575] font-normal ml-1 italic">
                        {asset.statistics.last_viewed}
                      </div>
                    </div>
                  )}
                </>
              ) : null} */}
              {asset?.statistics?.last_viewed && (
                <div className="flex items-center px-2 text-[10px] text-[#757575] font-normal italic">
                  Last Viewed
                  <div className="text-[10px] text-[#757575] font-normal ml-1 italic">
                    {asset.statistics.last_viewed}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-end items-center text-xs text-slate-600">
          <div className="divide-x divide-slate-500 flex text-xs justify-end">
            <div className="flex justify-center items-center px-2 mt-2">
              <BsFillEyeFill className="text-slate-600" />
              <div className="text-xs ml-1 text-slate-500">
                {asset.statistics ? asset.statistics.views_count : 0}
              </div>
            </div>
            <div className="flex justify-center items-center px-2 mt-2">
              <BsFillArrowDownCircleFill className="text-slate-600" />
              <div className="text-xs ml-1 text-slate-500">
                {asset.statistics ? asset.statistics.download_count : 0}
              </div>
            </div>
            <div className="flex justify-center items-center px-2 mt-2">
              <BsClockFill className="text-slate-600" />
              <div className="text-xs ml-1 text-slate-500">
                {asset?.statistics?.time_spent
                  ? handleGetReadableTimeRange(asset?.statistics?.time_spent)
                  : "0"}
              </div>
            </div>
            {handleActivitySlideToggle && (
              <div className="flex justify-center items-center px-2 mt-2">
                <div
                  className="text-xs ml-1 text-uberark-orange cursor-pointer flex"
                  onClick={() => handleActivitySlideToggle(asset, "asset")}
                >
                  <BsActivity className="mr-1" /> Timeline
                </div>
              </div>
            )}
          </div>
          {assetType === "video" || assetType === "document" ? (
            <div className="flex justify-end w-full">
              <AssetGraph
                events={events}
                assetId={asset.id}
                graphCount={Math.ceil(
                  asset["video_duration"] || asset["page_count"]
                )}
                assetType={assetType}
              />
            </div>
          ) : null}
          {/* {assetType === "video" || assetType === "document" ? (
            <div className="flex justify-end w-full">
              <AssetGraph
                events={events}
                assetId={asset.id}
                graphCount={Math.ceil(
                  asset["video_duration"] || asset["page_count"]
                )}
                assetType={assetType}
              />
            </div>
          ) : (
            <div
              className="flex justify-end w-full"
              style={{ alignItems: "baseline", marginTop: 10 }}
            >
              {asset?.statistics?.last_viewed && (
                <div className="flex items-center px-2 text-[10px] text-[#757575] font-normal italic">
                  Last Viewed
                  <div className="text-[10px] text-[#757575] font-normal ml-1 italic">
                    {asset.statistics.last_viewed}
                  </div>
                </div>
              )}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Asset;
