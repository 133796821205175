import {
  handleResponse,
  axiosInstance,
  fileRequestConfig,
} from "../../../app/utilities/helpers";

const handleAccountManagementCreation = (data) =>
  axiosInstance
    .post("organizations", data, fileRequestConfig)
    .then(handleResponse);

const handleAccountManagementUpdate = (data, orgId) =>
  axiosInstance
    .put(`organizations/${orgId}`, data, fileRequestConfig)
    .then(handleResponse);

const handleAccountManagementFetch = (orgId) =>
  axiosInstance.get(`organizations/${orgId}`).then(handleResponse);

const handleSendUserInvite = (data) =>
  axiosInstance.post("invite", data).then(handleResponse);

const handleUserListsFetch = (queryUrl) =>
  axiosInstance.get(`users/organization/${queryUrl}`).then(handleResponse);

const handleDeleteUser = (userId) =>
  axiosInstance.delete(`user/${userId}`).then(handleResponse);

const handleNDAListsFetch = (queryUrl) =>
  axiosInstance.get(`${queryUrl}`).then(handleResponse);

const handleNDACreation = (data) =>
  axiosInstance.post("ndas", data).then(handleResponse);

const handleNDAUpdate = (data, id) =>
  axiosInstance.put(`ndas/${id}`, data).then(handleResponse);

const handleNDADelete = (id) =>
  axiosInstance.delete(`ndas/${id}`).then(handleResponse);

const handleETemplateListsFetch = (queryUrl) =>
  axiosInstance.get(`${queryUrl}`).then(handleResponse);

const handleETemplateCreation = (data) =>
  axiosInstance.post("email/templates", data).then(handleResponse);

const handleETemplateUpdate = (data, id) =>
  axiosInstance.put(`email/templates/${id}`, data).then(handleResponse);

const handleETemplateDelete = (id) =>
  axiosInstance.delete(`email/templates/${id}`).then(handleResponse);

const handleTACListsFetch = (queryUrl) =>
  axiosInstance.get(`${queryUrl}`).then(handleResponse);

const handleTACCreation = (data) =>
  axiosInstance.post("termsconditions", data).then(handleResponse);

const handleTACUpdate = (data, id) =>
  axiosInstance.put(`termsconditions/${id}`, data).then(handleResponse);

const handleTACDelete = (id) =>
  axiosInstance.delete(`termsconditions/${id}`).then(handleResponse);

const handleESignListsFetch = (queryUrl) =>
  axiosInstance.get(`${queryUrl}`).then(handleResponse);

const handleESignCreation = (data) =>
  axiosInstance
    .post("email/signatures", data, fileRequestConfig)
    .then(handleResponse);

const handleESignUpdate = (data, id) =>
  axiosInstance
    .put(`email/signatures/${id}`, data, fileRequestConfig)
    .then(handleResponse);

const handleESignDelete = (id) =>
  axiosInstance.delete(`email/signatures/${id}`).then(handleResponse);

const handleNotificationListsFetch = (queryUrl) =>
  axiosInstance.get(`${queryUrl}`).then(handleResponse);

const handleNotificationUpdate = (data, id) =>
  axiosInstance
    .put(`inappnotifications/unread/${id}`, data)
    .then(handleResponse);

const handleGetNotificationSettings = () =>
  axiosInstance.get(`notifications?user=true`).then(handleResponse);

const handleEditNotificationSettings = (data) =>
  axiosInstance.put(`notifications`, data).then(handleResponse);

const handleCustomFieldCreate = (data) =>
  axiosInstance.post("customfields", data).then(handleResponse);

const handleCustomFieldUpdate = (data, id) =>
  axiosInstance.put(`customfields/${id}`, data).then(handleResponse);

const handleCustomFieldDelete = (id) =>
  axiosInstance.delete(`customfields/${id}`).then(handleResponse);

const handlePrivacyPolicyCreate = (data) =>
  axiosInstance.post("privacy", data).then(handleResponse);

const handlePrivacyPolicyUpdate = (data, id) =>
  axiosInstance.put(`privacy/${id}`, data).then(handleResponse);

const handlePrivacyPolicyDelete = (id) =>
  axiosInstance.delete(`privacy/${id}`).then(handleResponse);

const handleShareGet = () => axiosInstance.get("share").then(handleResponse);

const handleShareCreate = (data) =>
  axiosInstance.post("share", data).then(handleResponse);

const handleShareUpdate = (data, id) =>
  axiosInstance.put(`share/${id}`, data).then(handleResponse);

const handleGmailAuth = (data) =>
  axiosInstance.post("integrations", data).then(handleResponse);

export const settingsService = {
  handleAccountManagementCreation,
  handleAccountManagementUpdate,
  handleAccountManagementFetch,
  handleSendUserInvite,
  handleUserListsFetch,
  handleDeleteUser,
  handleNDAListsFetch,
  handleNDACreation,
  handleNDAUpdate,
  handleNDADelete,
  handleETemplateListsFetch,
  handleETemplateCreation,
  handleETemplateUpdate,
  handleETemplateDelete,
  handleTACListsFetch,
  handleTACCreation,
  handleTACUpdate,
  handleTACDelete,
  handleESignListsFetch,
  handleESignCreation,
  handleESignUpdate,
  handleESignDelete,
  handleNotificationListsFetch,
  handleNotificationUpdate,
  handleGetNotificationSettings,
  handleEditNotificationSettings,
  handleCustomFieldCreate,
  handleCustomFieldUpdate,
  handleCustomFieldDelete,
  handlePrivacyPolicyCreate,
  handlePrivacyPolicyUpdate,
  handlePrivacyPolicyDelete,
  handleShareGet,
  handleShareCreate,
  handleShareUpdate,
  handleGmailAuth,
};
