import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Switch } from "@headlessui/react";
import LoadingSpinner from "../../../components/commons/LoadingSpinner";
import { settingsActions } from "../actions";

const Share = () => {
  const dispatch = useDispatch();
  const { share, loading } = useSelector((state) => state.settings);

  const [statusData, setStatusData] = useState({
    read_only: share.id ? share?.read_only : true,
    email_gate: share.id ? share?.email_gate : false,
    share: share.id ? share?.share : true,
    download: share.id ? share?.download : true,
  });

  useEffect(() => {
    dispatch(settingsActions.handleShareGet());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusToggle = (status) =>
    setStatusData((prev) => ({ ...prev, [status]: !statusData[status] }));

  const handleSubmit = () =>
    share.id
      ? dispatch(settingsActions.handleShareUpdate(statusData, share.id))
      : dispatch(settingsActions.handleShareCreate(statusData));

  return (
    <div className="bg-white rounded-xl md:m-4 md:p-14 p-2 w-full flex flex-col">
      <div className="font-bold text-uberark-orange text-xl my-4 space-between-flex">
        <span>Share Settings</span>
      </div>
      <div className="pl-2">
        <div className="flex justify-between items-center w-1/2 py-2">
          <div>
            Read Only
            <div className="text-xs italic">
              *This applies to PPTs, Docs, Google Slides and Google Docs.
            </div>
          </div>{" "}
          <Switch
            checked={statusData["read_only"]}
            onChange={() => statusToggle("read_only")}
            className={`${
              statusData["read_only"]
                ? " bg-uberark-blue"
                : " bg-uberark-orange"
            }
             relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="sr-only">Read Only</span>
            <span
              aria-hidden="true"
              className={`${
                statusData["read_only"] ? "translate-x-5" : "translate-x-0"
              }
               pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>{" "}
        </div>

        <div className="flex justify-between items-center w-1/2 py-2">
          <div>Enable Email Gating</div>{" "}
          <Switch
            checked={statusData["email_gate"]}
            onChange={() => statusToggle("email_gate")}
            className={`${
              statusData["email_gate"]
                ? " bg-uberark-blue"
                : " bg-uberark-orange"
            }
              relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${
                statusData["email_gate"] ? "translate-x-5" : "translate-x-0"
              }
                pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>{" "}
        </div>

        <div className="flex justify-between items-center w-1/2 py-2">
          <div>Allow recipient to Reshare</div>{" "}
          <Switch
            checked={statusData["share"]}
            onChange={() => statusToggle("share")}
            className={`${
              statusData["share"] ? " bg-uberark-blue" : " bg-uberark-orange"
            }
              relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${
                statusData["share"] ? "translate-x-5" : "translate-x-0"
              }
                pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>{" "}
        </div>

        <div className="flex justify-between items-center w-1/2 py-2">
          <div>Allow recipient to Download</div>{" "}
          <Switch
            checked={statusData["download"]}
            onChange={() => statusToggle("download")}
            className={`${
              statusData["download"] ? " bg-uberark-blue" : " bg-uberark-orange"
            }
              relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${
                statusData["download"] ? "translate-x-5" : "translate-x-0"
              }
                pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>{" "}
        </div>
      </div>
      <div className="flex lg:justify-end flex-row text-uberark-blue gap-4 text-lg mb-3 mt-3">
        <button
          className=":px-2 bg-[#000080] text-white hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl "
          type="submit"
          disabled={loading}
          onClick={() => handleSubmit()}
        >
          {loading ? <LoadingSpinner /> : "Save"}
        </button>
      </div>
    </div>
  );
};

export default Share;
