import React from "react";

const PopUpDelete = ({
  visible,
  onClose,
  itemName,
  deleteFn,
  actionName,
  confirmingTxt,
}) => {
  const handleOnClose = (e) => {
    if (e.target.id === "deleteContainer") onClose();
  };

  if (!visible) return null;

  return (
    <div
      id="deleteContainer"
      onClick={handleOnClose}
      className="fixed inset-0 bg-gray-50 bg-opacity-30 backdrop-blur-sm text-black grid items-center justify-center"
      style={{ zIndex: "100" }}
    >
      <div className="bg-white flex flex-col justify-between items-center text-center h-96 w-96 p-12 rounded-2xl">
        <span className="font-bold text-xl text-[#000080]">Confirm Action</span>
        <div className="flex flex-col gap-4">
          <span className="text-[#FE4502] text-base font-bold">
            {confirmingTxt ? confirmingTxt : "Deleting"} {itemName}
          </span>
          <span className="font-semibold text-sm text-[#000080]">
            Are you sure you would like to {actionName ? actionName : "Delete"}{" "}
            this?
          </span>
        </div>

        <div className="flex gap-4">
          <button
            onClick={() => deleteFn()}
            className="bg-[#000080] hover:bg-white hover:text-[#FE4502] text-white drop-shadow-lg font-bold py-2 px-4 rounded-3xl"
          >
            {actionName ? actionName : "Delete"}
          </button>

          <button
            onClick={() => onClose()}
            className="text-uberark-blue hover:text-[#000080] hover:shadow-lg border-1 border-uberark-blue font-bold py-2 px-4 rounded-3xl"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUpDelete;
