import * as types from "../actions/actionTypes";

const initialState = {
  user: {},
  loading: false,
  accountConfirmed: false,
  message: "",
  errors: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GOOGLE_AUTH_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
      };
    case types.GOOGLE_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.data,
      };
    case types.GOOGLE_AUTH_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };

    case types.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.data,
        loading: false,
      };
    case types.LOGIN_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };

    case types.SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
      };
    case types.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.data.message,
      };
    case types.SIGNUP_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        message: "",
      };

    case types.CONFIRM_REQUEST:
      return {
        ...state,
        loading: true,
        accountConfirmed: false,
      };
    case types.CONFIRM_SUCCESS:
      return {
        ...state,
        accountConfirmed: true,
        loading: false,
      };
    case types.CONFIRM_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        accountConfirmed: false,
      };

    case types.GET_LOGGED_IN_USER_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
      };
    case types.GET_LOGGED_IN_USER_SUCCESS:
      return {
        ...state,
        user: action.data,
        loading: false,
      };
    case types.GET_LOGGED_IN_USER_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };

    case types.EDIT_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
      };
    case types.EDIT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.data.user,
      };
    case types.EDIT_USER_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };

    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
      };
    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };

    case types.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
      };
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };

    default:
      return state;
  }
};

export default authReducer;
