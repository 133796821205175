import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BsFolder, BsXCircleFill } from "react-icons/bs";
import { RiEditCircleFill } from "react-icons/ri";
import AssetCard from "../../commons/Asset/components/AssetCard";

import { streamActions } from "../actions";
import DeleteConversation from "../../Conversations/components/DeleteConversation";

const FileCard = (props) => {
  const {
    assetsAndGroupData,
    deleteToggle,
    setDeleteToggle,
    editGroup,
    setSelectedGroup,
    handleOpenGroup,
    onAssetClick,
    fileHover,
    cardLoader,
  } = props;
  const { dltConfirm } = useSelector((state) => state.stream);
  const dispatch = useDispatch();
  const [dltPopup, setDltPopup] = useState(false);
  const [dltID, setDltID] = useState(null);
  const [dltType, setDltType] = useState(null);
  const [selectedDltItem, setSelectedDltItem] = useState(null);

  const handleAssetsAndGroupSelect = (id, type, item) => {
    setSelectedDltItem(item);
    setDltID(id);
    setDltType(type);
    setDltPopup(true);
  };

  const handleAssetClick = (asset) => {
    if (onAssetClick) {
      onAssetClick(asset);
    }
  };

  const handleOnClose = () => {
    setDltPopup(false);
  };

  const onConfirmDlt = () => {
    if (dltID !== null) {
      if (dltType === "group") {
        // delete group
        setDeleteToggle(false);
        return dispatch(streamActions.handleDeleteGroup(dltID));
      }
      setDeleteToggle(false);
      return dispatch(streamActions.handleDeleteAsset(dltID));
    }
  };

  useEffect(() => {
    if (dltConfirm) {
      setDltPopup(false);
    }
  }, [dltConfirm]);

  return (
    <>
      {assetsAndGroupData && assetsAndGroupData.length
        ? assetsAndGroupData.map((item, index) => (
            <div key={index} className={`${!cardLoader ? "file-loader" : ""}`}>
              {item.file || item.url ? (
                <>
                  {deleteToggle && (
                    <div
                      className="file-card__delete animate-pulse"
                      style={{ marginBottom: "-20px", marginLeft: "3px" }}
                    >
                      <BsXCircleFill
                        onMouseDown={() =>
                          handleAssetsAndGroupSelect(item.id, "asset", item)
                        }
                      />
                    </div>
                  )}
                  <div
                    onMouseDown={(e) => {
                      e.preventDefault();
                      if (e.target !== e.currentTarget) return;
                      handleAssetClick(item);
                    }}
                    className={
                      fileHover
                        ? "file_img_wrap mx-2 mt-2"
                        : "pdf-viewer mx-2 mt-2"
                    }
                  >
                    {item.name.includes(".pdf") && fileHover ? (
                      <p
                        onMouseDown={(e) => {
                          e.preventDefault();
                          if (e.target !== e.currentTarget) return;
                          handleAssetClick(item);
                        }}
                      ></p>
                    ) : null}
                    <AssetCard
                      asset={item}
                      withName={true}
                      size="asset__medium"
                      handleAssetClick={(e) => {
                        // e.preventDefault();
                        if (e.target !== e.currentTarget) return;
                        handleAssetClick(item);
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  {item.group_tree_index !== "0" && (
                    <>
                      {deleteToggle && (
                        <div className="file-card__delete animate-pulse">
                          <BsXCircleFill
                            onMouseDown={() =>
                              handleAssetsAndGroupSelect(item.id, "group", item)
                            }
                          />
                        </div>
                      )}
                      {editGroup && (
                        <div className="file-card__delete animate-pulse">
                          <RiEditCircleFill
                            className="hover:scale-105 ease-in-out duration-300"
                            onMouseDown={() => setSelectedGroup(() => item.id)}
                          />
                        </div>
                      )}
                      <div
                        className="p-1 m-2 file-unsupported"
                        onMouseDown={() => handleOpenGroup(item.id)}
                      >
                        <BsFolder className="text-[#000080] drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]" />
                      </div>
                      <div className="file-card__name truncate">
                        {item.name}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          ))
        : null}
      <DeleteConversation
        visible={dltPopup}
        dltTitle={dltType}
        onClose={handleOnClose}
        onDeleteEvent={onConfirmDlt}
        deleteDetails={selectedDltItem}
        dltLoader={dltConfirm}
      />
    </>
  );
};

export default FileCard;
