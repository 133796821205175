import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { BsChevronDown, BsChevronUp, BsXLg } from "react-icons/bs";
import { Switch } from "@headlessui/react";
import LoadingSpinner from "../../components/commons/LoadingSpinner";
import EditLink from "./components/EditLink";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { collectionActions } from "./actions";
import { settingsActions } from "../Settings/actions";
import { toast } from "react-toastify";

const Links = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id: collectionId } = useParams();
  const { loading, generatedLink, previewCollection } = useSelector(
    (state) => state.collection
  );
  const { share } = useSelector((state) => state.settings);
  const { nda: ndas } = useSelector((state) => state.settings);
  const [statusData, setStatusData] = useState({
    read_only: share.id ? share?.read_only : true,
    email_gate: share.id ? share?.email_gate : false,
    share: share.id ? share?.share : true,
    download: share.id ? share?.download : true,
  });
  const [advancedSettingToggle, setAdvancedSettingToggle] = useState(false);
  const [linkTypeAnonymous, setLinkTypeAnonymous] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [expiryRequired, setExpiryRequired] = useState(false);
  const [ndaRequired, setNdaRequired] = useState(false);
  const [createAnother, setCreateAnother] = useState(false);
  const [collectionData, setCollectionData] = useState({});
  const [manageLink, setManageLink] = useState();
  console.log({ share });

  useEffect(() => {
    dispatch(settingsActions.handleNDAListsFetch("ndas"));
    dispatch(settingsActions.handleShareGet());
    dispatch(collectionActions.handleClearLink());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (previewCollection) {
      setCollectionData(previewCollection);
    }
  }, [previewCollection]);

  const initialValues = {
    email: "",
    password: "",
    expiry_date: "",
    nda: "",
  };

  const FormSchema = Yup.object().shape({
    email: linkTypeAnonymous
      ? null
      : Yup.string().email("Invalid email").required("Please enter you Email"),
    password: passwordRequired
      ? Yup.string()
          .required("Please enter password")
          .min(8, "Password is too short - should be 8 chars minimum.")
          .max(16, "Use max of 16 characters")
          .matches(
            /[^a-zA-Z0-9]/,
            "Password should have atleast 1 special character"
          )
          .matches(
            /[A-Z]/,
            "Password should have atleast 1 Upper case character"
          )
          .matches(
            /[a-z]/,
            "Password should have atleast 1 Lower case character"
          )
          .matches(/[0-9]/, "Password should have atleast 1 Number character")
      : null,
    expiry_date: expiryRequired
      ? Yup.date().required("Please enter expiry date")
      : null,
    nda: ndaRequired ? Yup.string().required("Please select NDA") : null,
  });

  const handleCreateLink = (data) => {
    const payload = statusData;
    if (data.email) {
      payload.contact = data.email;
    }
    if (data.password) {
      payload.password = data.password;
    }
    if (data.expiry_date) {
      payload.expiry_date = data.expiry_date;
    }
    if (data.nda) {
      payload.nda = data.nda;
    }
    if (linkTypeAnonymous) {
      delete payload.contact;
      payload.email_gate = true;
    }
    if (!passwordRequired) {
      delete payload.password;
    }

    payload.collection = collectionData.id;
    dispatch(collectionActions.handleCreateLink(payload));
    setCreateAnother(false);
  };

  const statusToggle = (status) =>
    setStatusData((prev) => ({ ...prev, [status]: !statusData[status] }));

  const handleDeleteLink = (id) =>
    dispatch(collectionActions.handleDeleteLink(id, collectionId));

  const handleManageLink = (linkId) => {
    if (linkId === manageLink) {
      return setManageLink();
    }
    setManageLink(linkId);
  };

  return (
    <div className="relative">
      <div className="absolute sm:top-0 top-20 w-full h-full mb-5">
        {/*------------------------Head----------------------------------------*/}
        <div className="md:mt-4 md:mx-20 mx-4 mt-4 grid grid-cols-1 md:grid-cols-2 justify-between bg-transparent">
          <div className="flex flex-col justify-items-end">
            <span className="text-[#FE4502] font-extrabold text-lg">Links</span>
            <span className="text-[#757575] text-base">
              Create and manage links.
            </span>
          </div>
          <div className="grid grid-flow-col items-center justify-between md:justify-end py-4 pt-4 md:p-0 text-slate-600">
            <button
              onClick={() =>
                navigate(
                  `/collection/${window.location.pathname.split("/").pop()}`
                )
              }
              className="inline-block font-medium text-xl leading-tight hover:shadow-md hover:bg-light-gray shadow-gray-900 focus:bg-white focus:shadow-xl focus:outline-none focus:ring-0 active:shadow-lg hover:scale-105 ease-in-out duration-300 p-3 bg-white w-10 h-10 flex justify-center items-center"
              style={{ borderRadius: "100%", border: "0.24px solid #000080" }}
            >
              <BsXLg className="text-[#FE4502] font-bold" />
            </button>
          </div>
        </div>

        {/*------------------------Form----------------------------------------*/}
        <div className="rounded-3xl bg-white m-4 p-6 shadow grid text-slate-600">
          <div className="flex flex-col">
            <div className="flex flex-col items-left">
              {generatedLink && !createAnother ? (
                <div>
                  <div className="flex justify-between font-semibold mt-3 mb-2">
                    <div>Here's your link</div>
                  </div>
                  <div className="my-4 flex flex-wrap">
                    <div>
                      {process.env.REACT_APP_LINK_APP_BASE_URL}
                      {generatedLink.id}
                    </div>
                    <div
                      className="text-uberark-orange ml-2"
                      onMouseDown={() => {
                        toast.success("Link copied");
                        navigator.clipboard.writeText(
                          `${
                            process.env.REACT_APP_LINK_APP_BASE_URL +
                            generatedLink.id
                          }`
                        );
                      }}
                    >
                      COPY LINK
                    </div>
                  </div>
                  <button
                    className="text-[#FE4502] py-2 px-2 mt-2"
                    onClick={() => setCreateAnother(true)}
                  >
                    Create New Link
                  </button>
                </div>
              ) : (
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={handleCreateLink}
                  validationSchema={FormSchema}
                >
                  {({
                    errors,
                    touched,
                    setFieldValue,
                    values,
                    handleBlur,
                    handleChange,
                  }) => (
                    <Form>
                      <div className="p-4 gap-4 text-gray-600">
                        <div className="flex justify-between items-center w-1/2 pl-2 py-2">
                          <div>Anonymous Link</div>{" "}
                          <Switch
                            checked={linkTypeAnonymous}
                            onChange={() =>
                              setLinkTypeAnonymous(!linkTypeAnonymous)
                            }
                            className={`${
                              linkTypeAnonymous
                                ? " bg-uberark-blue"
                                : " bg-uberark-orange"
                            }
                          relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                          >
                            <span className="sr-only">Use setting</span>
                            <span
                              aria-hidden="true"
                              className={`${
                                linkTypeAnonymous
                                  ? "translate-x-5"
                                  : "translate-x-0"
                              }
                            pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                            />
                          </Switch>{" "}
                        </div>
                        {!linkTypeAnonymous && (
                          <>
                            <div className="flex items-center">
                              <Field
                                placeholder="Enter recipient email here"
                                className="p-2 outline-none"
                                name="email"
                                id="email"
                              />
                            </div>
                            {errors.email && touched.email && (
                              <div className="text-red-600 pb-2">
                                {errors.email}
                              </div>
                            )}
                          </>
                        )}

                        <div
                          className="pl-2 pt-6 flex items-center font-semibold"
                          onMouseDown={() =>
                            setAdvancedSettingToggle(!advancedSettingToggle)
                          }
                        >
                          Advanced Settings{" "}
                          {advancedSettingToggle ? (
                            <BsChevronDown className="pl-2 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]" />
                          ) : (
                            <BsChevronUp className="pl-2 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]" />
                          )}
                        </div>
                        {advancedSettingToggle && (
                          <div className="pl-2">
                            <div className="flex justify-between items-center w-1/2 py-2">
                              <div>
                                Read Only
                                <div className="text-xs italic">
                                  *This applies to PPTs, Docs, Google Slides and
                                  Google Docs.
                                </div>
                              </div>{" "}
                              <Switch
                                checked={statusData["read_only"]}
                                onChange={() => statusToggle("read_only")}
                                className={`${
                                  statusData["read_only"]
                                    ? " bg-uberark-blue"
                                    : " bg-uberark-orange"
                                }
                            relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className={`${
                                    statusData["read_only"]
                                      ? "translate-x-5"
                                      : "translate-x-0"
                                  }
                            pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                />
                              </Switch>{" "}
                            </div>

                            <div className="flex justify-between items-center w-1/2 py-2">
                              <div>Enable Email Gating</div>{" "}
                              <Switch
                                checked={statusData["email_gate"]}
                                onChange={() => statusToggle("email_gate")}
                                className={`${
                                  statusData["email_gate"]
                                    ? " bg-uberark-blue"
                                    : " bg-uberark-orange"
                                }
                            relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className={`${
                                    statusData["email_gate"]
                                      ? "translate-x-5"
                                      : "translate-x-0"
                                  }
                            pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                />
                              </Switch>{" "}
                            </div>

                            <div className="flex justify-between items-center w-1/2 py-2">
                              <div>Allow recipient to Reshare</div>{" "}
                              <Switch
                                checked={statusData["share"]}
                                onChange={() => statusToggle("share")}
                                className={`${
                                  statusData["share"]
                                    ? " bg-uberark-blue"
                                    : " bg-uberark-orange"
                                }
                            relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className={`${
                                    statusData["share"]
                                      ? "translate-x-5"
                                      : "translate-x-0"
                                  }
                            pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                />
                              </Switch>{" "}
                            </div>

                            <div className="flex justify-between items-center w-1/2 py-2">
                              <div>Allow recipient to Download</div>{" "}
                              <Switch
                                checked={statusData["download"]}
                                onChange={() => statusToggle("download")}
                                className={`${
                                  statusData["download"]
                                    ? " bg-uberark-blue"
                                    : " bg-uberark-orange"
                                }
                            relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className={`${
                                    statusData["download"]
                                      ? "translate-x-5"
                                      : "translate-x-0"
                                  }
                            pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                />
                              </Switch>{" "}
                            </div>

                            <div className="flex justify-between items-center w-1/2 py-2">
                              <div>Set a Password</div>{" "}
                              <Switch
                                checked={passwordRequired}
                                onChange={() =>
                                  setPasswordRequired(!passwordRequired)
                                }
                                className={`${
                                  passwordRequired
                                    ? " bg-uberark-blue"
                                    : " bg-uberark-orange"
                                }
                            relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className={`${
                                    passwordRequired
                                      ? "translate-x-5"
                                      : "translate-x-0"
                                  }
                              pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                />
                              </Switch>{" "}
                            </div>
                            {passwordRequired && (
                              <>
                                <div className="flex items-center">
                                  <Field
                                    placeholder="Enter Password"
                                    className="p-2 outline-none"
                                    name="password"
                                    id="password"
                                    type="password"
                                  />
                                </div>
                                {errors.password && touched.password && (
                                  <div className="text-red-600 pb-2">
                                    {errors.password}
                                  </div>
                                )}
                              </>
                            )}

                            <div className="flex justify-between items-center w-1/2 py-2">
                              <div>Set expiry date & time</div>{" "}
                              <Switch
                                checked={expiryRequired}
                                onChange={() =>
                                  setExpiryRequired(!expiryRequired)
                                }
                                className={`${
                                  expiryRequired
                                    ? " bg-uberark-blue"
                                    : " bg-uberark-orange"
                                }
                            relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className={`${
                                    expiryRequired
                                      ? "translate-x-5"
                                      : "translate-x-0"
                                  }
                              pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                />
                              </Switch>{" "}
                            </div>
                            {expiryRequired && (
                              <>
                                <div className="flex items-center">
                                  <Field
                                    className="p-2 outline-none"
                                    name="expiry_date"
                                    id="expiry_date"
                                    type="datetime-local"
                                  />
                                </div>
                                {errors.expiry_date && touched.expiry_date && (
                                  <div className="text-red-600 pb-2">
                                    {errors.expiry_date}
                                  </div>
                                )}
                              </>
                            )}

                            <div className="flex justify-between items-center w-1/2 py-2">
                              <div>
                                Enable NDA
                                {(!ndas || ndas.length === 0) && (
                                  <div className="text-xs text-[#ff0000] italic">
                                    *You need to create an NDAs to enable NDA.{" "}
                                    <div
                                      className="underline font-semibold"
                                      onMouseDown={() =>
                                        navigate(`/UserProfile/NDA`)
                                      }
                                    >
                                      Create NDA
                                    </div>
                                  </div>
                                )}
                              </div>{" "}
                              <Switch
                                checked={ndaRequired}
                                onChange={() =>
                                  ndas &&
                                  ndas.length > 0 &&
                                  setNdaRequired(!ndaRequired)
                                }
                                className={`${
                                  ndaRequired
                                    ? " bg-uberark-blue"
                                    : " bg-uberark-orange"
                                }
                            relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className={`${
                                    ndaRequired
                                      ? "translate-x-5"
                                      : "translate-x-0"
                                  }
                              pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                />
                              </Switch>{" "}
                            </div>
                            {ndaRequired && (
                              <>
                                <div className="flex items-center">
                                  <select
                                    name="nda"
                                    value={values.nda}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{ display: "block" }}
                                  >
                                    <option value="" label="Select an NDA">
                                      Select an NDA{" "}
                                    </option>
                                    {ndas &&
                                      ndas.map((nda, index) => (
                                        <option
                                          value={nda.id}
                                          label={nda.title}
                                        >
                                          {" "}
                                          {nda.title}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                {errors.nda && touched.nda && (
                                  <div className="text-red-600 pb-2">
                                    {errors.nda}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        )}

                        <div className="flex flex-row items-center text-uberark-blue gap-4 pt-5 text-lg">
                          <button
                            className=":px-2 bg-[#000080] text-white hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? <LoadingSpinner /> : "Submit"}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>

        {/*------------------------Table----------------------------------------*/}
        <div className="rounded-3xl bg-white m-4 p-6 shadow grid text-slate-600">
          <div className="flex flex-col">
            <div className="flex flex-col items-left">
              <div className="flex justify-between font-semibold mt-3 mb-2">
                <div>Manage your links</div>
              </div>

              <div>
                {collectionData.link &&
                  collectionData.link.length > 0 &&
                  collectionData.link.map((link, index) => (
                    <div className="py-2" key={index}>
                      <div className="flex justify-between">
                        <div className="flex">
                          {index + 1}.{" "}
                          {link.contact_data &&
                          link.contact_data.length &&
                          !link.email_gate ? (
                            <div className="flex items-center">
                              <span className="text-[#FE4502]">To: </span>
                              <div className="divide-x text-sm divide-slate-500">
                                {link.contact_data.map((item, index) => (
                                  <span key={index} className="px-1">
                                    {item.email}
                                  </span>
                                ))}
                              </div>
                            </div>
                          ) : (
                            <>Anonymous Link</>
                          )}
                        </div>
                        <div className="flex">
                          <div
                            className="px-1 font-semibold"
                            onMouseDown={() => {
                              toast.success("Link copied");
                              navigator.clipboard.writeText(
                                `${
                                  process.env.REACT_APP_LINK_APP_BASE_URL +
                                  link.id
                                }`
                              );
                            }}
                          >
                            {loading ? <LoadingSpinner /> : "COPY"}
                          </div>
                          <div
                            className="px-1 font-semibold text-[#ff0000]"
                            onMouseDown={() => handleDeleteLink(link.id)}
                          >
                            {loading ? <LoadingSpinner /> : "DELETE"}
                          </div>
                          <div
                            className="px-1 font-semibold text-uberark-blue"
                            onMouseDown={() => handleManageLink(link.id)}
                          >
                            {loading ? <LoadingSpinner /> : "EDIT"}
                          </div>
                        </div>
                      </div>
                      {manageLink === link.id && (
                        <div>
                          <EditLink link={link} collectionId={collectionId} />
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Links;
