import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { HiOutlineUpload } from "react-icons/hi";
import {
  BsTrash,
  BsFillPersonFill,
  BsFillEnvelopeFill,
  BsFillBuildingsFill,
  BsTelephoneFill,
  BsTabletFill,
  BsFacebook,
  BsTwitter,
  BsLinkedin,
  BsInstagram,
  BsFillCloudUploadFill,
  BsInputCursor,
} from "react-icons/bs";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { LoadingSpinner, PopUpDelete } from "../../../../components";
import { toast } from "react-toastify";
import FormBulkUpload from "./FormBulkUpload";

import {
  validUrlRegex2,
  phoneRegExp,
  imageExtensions,
} from "../../../../app/utilities/helpers";
import { contactActions } from "../../actions";

const ContactsForm = ({ activeContact, handleCloseForm }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.contacts);
  const [update, setUpdate] = useState(false);
  const [deleteContactModal, setDeleteContactModal] = useState(false);
  const [bulkUpload, setBulkUpload] = useState(false);

  const [initialValues, setInitialValues] = useState({
    picture: "",
    name: "",
    title: "",
    email: "",
    phone_number: "",
    company: "",
    work_phone_number: "",
    facebook_url: "",
    linkedin_url: "",
    twitter_url: "",
    instagram_url: "",
  });

  useEffect(() => {
    if (activeContact && activeContact.email) {
      setUpdate(true);
      setInitialValues({
        picture: activeContact?.picture || null,
        name: activeContact.name || null,
        title: activeContact.title || null,
        email: activeContact.email || null,
        phone_number: activeContact.phone_number || null,
        company: activeContact.company || null,
        work_phone_number: activeContact.work_phone_number || null,
        facebook_url: activeContact.facebook_url || null,
        linkedin_url: activeContact.linkedin_url || null,
        twitter_url: activeContact.twitter_url || null,
        instagram_url: activeContact.instagram_url || null,
      });
    } else {
      setUpdate(false);
      setInitialValues({
        picture: "",
        name: "",
        title: "",
        email: "",
        phone_number: "",
        company: "",
        work_phone_number: "",
        facebook_url: "",
        linkedin_url: "",
        twitter_url: "",
        instagram_url: "",
      });
    }
  }, [activeContact]);

  const FormSchema = Yup.object().shape({
    picture: Yup.mixed().nullable(),
    name: Yup.string().required("Please enter contact name"),
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter correct email"),
    title: Yup.string().nullable(),
    company: Yup.string().nullable(),
    work_phone_number: Yup.string()
      .nullable()
      .matches(phoneRegExp, "Work Phone number is not valid")
      .min(10, "Work Phone number should be 10 digit long")
      .max(10, "Work Phone number should be 10 digit long"),
    phone_number: Yup.string()
      .nullable()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone number should be 10 digit long")
      .max(10, "Phone number should be 10 digit long"),
    facebook_url: Yup.string()
      .matches(validUrlRegex2, "Enter correct url!")
      .nullable(),
    linkedin_url: Yup.string()
      .matches(validUrlRegex2, "Enter correct url!")
      .nullable(),
    twitter_url: Yup.string()
      .matches(validUrlRegex2, "Enter correct url!")
      .nullable(),
    instagram_url: Yup.string()
      .matches(validUrlRegex2, "Enter correct url!")
      .nullable(),
  });

  const handleSubmit = (data) => {
    let payload = new FormData();
    Object.keys(data).map((item) => {
      if (item === "picture" && data[item] && typeof data[item] === "object") {
        payload.append("picture", data.picture);
      } else if (item === "email") {
        data[item] && payload.append([item], data[item]);
      } else {
        data[item] && payload.append([item], data[item].trim());
      }
      return true;
    });

    if (update) {
      dispatch(
        contactActions.handleContactUpdate(payload, activeContact.id, () => {
          handleCloseForm(true);
        })
      );
    } else {
      return dispatch(
        contactActions.handleContactCreation(payload, handleCloseForm)
      );
    }
  };

  const handleDelete = () =>
    dispatch(
      contactActions.handleContactDelete(activeContact.id, () => {
        handleCloseForm();
        toggleDeleteContactModal();
      })
    );

  const toggleDeleteContactModal = () =>
    setDeleteContactModal(!deleteContactModal);

  const toggleBulkUpload = () => setBulkUpload(!bulkUpload);

  return (
    <div className={`p-4 grid w-full`}>
      {update ? (
        <div
          className="grid justify-center px-2 absolute"
          style={{ top: 25, right: 5 }}
        >
          <button
            className="grid grid-flow-col items-center gap-1 hover:text-[#000080]"
            onClick={() => toggleDeleteContactModal()}
          >
            <BsTrash />
            <span className="hidden md:block">Delete</span>
          </button>
        </div>
      ) : (
        <div className="flex justify-between items-center">
          <div className="font-bold text-xl text-[#000080] ml-2">
            Add Contact
          </div>
          <button
            onClick={() => toggleBulkUpload()}
            className="inline-block font-medium text-base text-[#000080] leading-tight hover:shadow-md hover:bg-light-gray shadow-gray-900 focus:bg-white focus:shadow-xl focus:outline-none focus:ring-0 active:shadow-lg hover:scale-105 ease-in-out duration-300 bg-white flex justify-center items-center rounded mr-2"
            style={{ border: "0.24px solid #000080", padding: 8 }}
          >
            {bulkUpload ? (
              <BsInputCursor className="font-bold" />
            ) : (
              <BsFillCloudUploadFill className="font-bold" />
            )}{" "}
            <span style={{ fontSize: "0.9rem", paddingLeft: 5 }}>
              {bulkUpload ? "Use Form" : "Bulk Upload"} instead?
            </span>
          </button>
        </div>
      )}

      {bulkUpload ? (
        <FormBulkUpload toggleBulkUpload={toggleBulkUpload} />
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={FormSchema}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form>
              <div className="p-2 flex justify-between">
                <span className="text-base text-uberark-orange font-bold">
                  General Information
                </span>
              </div>

              <div className="mb-4 flex flex-col justify-center gap-2 p-2">
                <span className="text-uberark-blue">Profile Photo</span>
                <div>
                  <label className="w-20 h-20 rounded-full flex flex-col relative items-center justify-center bg-[#EEF3FF]">
                    {values.picture ? (
                      <img
                        className="w-full h-full cursor-pointer opacity-80 hover:scale-10 ease-in-out duration-300 rounded"
                        style={{
                          filter: "brightness(0.5)",
                          borderRadius: "50%",
                        }}
                        src={
                          typeof values.picture !== "string"
                            ? URL.createObjectURL(values.picture)
                            : values.picture
                        }
                        alt="cover"
                        loading="lazy"
                      />
                    ) : (
                      <HiOutlineUpload
                        size={30}
                        className="text-uberark-orange"
                      />
                    )}
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={(event) => {
                        if (
                          imageExtensions.includes(
                            event.currentTarget.files[0]["type"]
                          )
                        ) {
                          setFieldValue(
                            "picture",
                            event.currentTarget.files[0]
                          );
                        } else {
                          const selectedFile = event.currentTarget.files[0];
                          const extension = selectedFile.name.split(".")[1];
                          toast.error(
                            `.${extension} file format is not supported!`
                          );
                        }
                      }}
                    />
                  </label>
                </div>
              </div>

              <div>
                <div className="mb-6">
                  <div className="grid grid-cols-2 gap-2">
                    <div className="mb-1">
                      <label className="font-medium text-uberark-blue">
                        Name
                        <span className="text-red-500 mr-0">*</span>:
                      </label>
                      <div className="relative">
                        <Field
                          type="text"
                          placeholder="Name"
                          name="name"
                          className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                        />
                        {errors.name && touched.name && (
                          <div className="text-red-600 absolute">
                            {errors.name}
                          </div>
                        )}
                        <span className="absolute top-[10px] right-2">
                          <BsFillPersonFill
                            size={20}
                            className="text-[#666666]"
                          />
                        </span>
                      </div>
                    </div>

                    <div className="mb-1">
                      <label className="font-medium text-uberark-blue">
                        <span>Email</span>{" "}
                        <span className="text-red-500 ml-1">*</span>:
                      </label>
                      <div className="relative">
                        <Field
                          type="text"
                          placeholder="E-mail "
                          name="email"
                          className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                        />
                        {errors.email && touched.email && (
                          <div className="text-red-600 absolute">
                            {errors.email}
                          </div>
                        )}
                        <span className="absolute top-[10px] right-2">
                          <BsFillEnvelopeFill
                            size={20}
                            className="text-[#666666]"
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-2">
                    <div className="mb-1">
                      <label className="font-medium text-uberark-blue">
                        Mobile:
                      </label>
                      <div className="relative">
                        <Field
                          type="tel"
                          placeholder="Phone number"
                          name="phone_number"
                          className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                        />
                        {errors.phone_number && touched.phone_number && (
                          <div className="text-red-600">
                            {errors.phone_number}
                          </div>
                        )}
                        <span className="absolute top-[10px] right-2">
                          <BsTabletFill size={20} className="text-[#666666]" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="p-2 flex justify-between mt-2">
                    <span className="text-base text-uberark-orange font-bold">
                      Work Information
                    </span>
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="mb-1">
                      <label className="font-medium text-uberark-blue">
                        Company:
                      </label>
                      <div className="relative">
                        <Field
                          type="text"
                          placeholder="Company"
                          name="company"
                          className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                        />
                        <span className="absolute top-[10px] right-2">
                          <BsFillBuildingsFill
                            size={20}
                            className="text-[#666666]"
                          />
                        </span>
                      </div>
                    </div>
                    <div className="mb-1">
                      <label className="font-medium text-uberark-blue">
                        Title:
                      </label>
                      <div className="relative">
                        <Field
                          type="text"
                          placeholder="Designation"
                          name="title"
                          className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                        />
                        <span className="absolute top-[10px] right-2">
                          <BsFillEnvelopeFill
                            size={20}
                            className="text-[#666666]"
                          />
                        </span>
                      </div>
                    </div>
                    <div className="mb-1">
                      <label className="font-medium text-uberark-blue">
                        <span>Work</span>
                        <span>Phone:</span>
                      </label>
                      <div className="relative">
                        <Field
                          type="tel"
                          pattern="[1-9]{1}[0-9]{9}"
                          placeholder="Work Phone Number"
                          name="work_phone_number"
                          className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                        />
                        {errors.work_phone_number &&
                          touched.work_phone_number && (
                            <div className="text-red-600">
                              {errors.work_phone_number}
                            </div>
                          )}
                        <span className="absolute top-[10px] right-2">
                          <BsTelephoneFill
                            size={20}
                            className="text-[#666666]"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <span className="grid font-bold text-uberark-orange  lg:mb-4 mb-6 p-2">
                  Social Profile
                </span>
                <div className="grid lg:grid-rows-2 sm:grid-rows-2 grid-flow-row lg:grid-flow-col sm:grid-flow-col lg:gap-4 gap-6 lg:mb-4 mb-6">
                  <div className="mb-1">
                    <label className="font-medium text-uberark-blue">
                      <span className="pr-1">Facebook</span>
                      <span>url:</span>
                    </label>
                    <div className="relative">
                      <Field
                        type="text"
                        placeholder="Facebook url"
                        name="facebook_url"
                        className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                      />
                      <span className="absolute top-[10px] right-2">
                        <BsFacebook size={20} className="text-[#666666]" />
                      </span>
                    </div>
                  </div>

                  <div className="mb-1">
                    <label className="font-medium text-uberark-blue">
                      <span className="pr-1">Linkedin</span>
                      <span>url:</span>
                    </label>
                    <div className="relative">
                      <Field
                        type="text"
                        placeholder="Linkedin url"
                        name="linkedin_url"
                        className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                      />
                      <span className="absolute top-[10px] right-2">
                        <BsLinkedin size={20} className="text-[#666666]" />
                      </span>
                    </div>
                  </div>
                  <div className="mb-1">
                    <label className="font-medium text-uberark-blue">
                      <span className="pr-1">Twitter</span>
                      <span>url:</span>
                    </label>
                    <div className="relative">
                      <Field
                        type="text"
                        placeholder="Twitter url"
                        name="twitter_url"
                        className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                      />
                      <span className="absolute top-[10px] right-2">
                        <BsTwitter size={20} className="text-[#666666]" />
                      </span>
                    </div>
                  </div>
                  <div className="mb-1">
                    <label className="font-medium text-uberark-blue">
                      <span className="pr-1">Instagram</span>
                      <span>url:</span>
                    </label>
                    <div className="relative">
                      <Field
                        type="text"
                        placeholder="Instagram url"
                        name="instagram_url"
                        className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                      />
                      <span className="absolute top-[10px] right-2">
                        <BsInstagram size={20} className="text-[#666666]" />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row justify-center text-uberark-blue gap-4 text-lg lg:justify-end">
                  <button
                    type="submit"
                    disabled={loading}
                    className="leading-tight bg-[#000080] hover:bg-white text-white hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 focus:shadow-xl focus:outline-none focus:ring-0 active:shadow-lg hover:scale-105 ease-in-out duration-300 rounded-2xl"
                  >
                    {loading ? <LoadingSpinner /> : "Save"}
                  </button>
                  {!update && (
                    <button
                      className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
                      style={{ border: "0.24px solid #000080" }}
                      type="button"
                      onClick={() => handleCloseForm()}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}

      <PopUpDelete
        visible={deleteContactModal}
        onClose={toggleDeleteContactModal}
        itemName={activeContact?.email || ""}
        deleteFn={handleDelete}
        actionName={"Delete"}
      />
    </div>
  );
};

export default ContactsForm;
