import React from 'react';

const PageWrapper = ({ children }) => (
  <div className="relative">
    <div className="absolute sm:top-0 top-20 w-full h-full mb-5">
      {children}
    </div>
  </div>
)

export default PageWrapper;
