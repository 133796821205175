import * as types from '../actions/actionTypes'

const initialState = {
  loading: false,
  message: '',
  errors: {},
  contacts: [],
  contactsCreation: false,
  contactsDeleteConfirmation: false,
  distribution: [],
  distributionCreation: false,
  distributionDeleteConfirmation: false,
  totalContactPage: 0,
  contactPaginationUpdate: false,
  totalDistributionPages: 1
}

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CONTACT_LISTS_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: '',
        contactsCreation: false,
        contactsDeleteConfirmation: false,
        contactPaginationUpdate:false
      }
    case types.FETCH_CONTACT_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts: action?.data?.results ? action?.data?.results : action.data,
        contactsCreation: false,
        contactsDeleteConfirmation: false,
        totalContactPage: action.data.count ? `${Math.ceil(action.data.count / 10)}` : 1,
        contactPaginationUpdate: true
      }
    case types.FETCH_CONTACT_LISTS_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        contactsCreation: false,
        contactsDeleteConfirmation: false,
        contactPaginationUpdate: false
      }
    case types.CREATE_CONTACT_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: '',
        contactsCreation: false
      }
    case types.CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contactsCreation: true
      }
    case types.CREATE_CONTACT_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        contactsCreation: false
      }
    case types.DELETE_CONTACT_REQUEST:
      return {
        ...state,
        contactsDeleteConfirmation: false,
        errors: {},
        message: '',
        loading: true
      }
    case types.DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contactsDeleteConfirmation: true,
        loading: false
      }
    case types.DELETE_CONTACT_FAILED:
      return {
        ...state,
        errors: action.error,
        contactsDeleteConfirmation: false,
        loading: false
      }
    case types.FETCH_DISTRIBUTION_LISTS_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: '',
        distributionCreation: false,
        distributionDeleteConfirmation: false
      }
    case types.FETCH_DISTRIBUTION_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        distribution: action.data.results,
        distributionCreation: false,
        distributionDeleteConfirmation: false,
        totalDistributionPages: action.data.count ? `${Math.ceil(action.data.count / 10)}` : 1
      }
    case types.FETCH_DISTRIBUTION_LISTS_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        distributionCreation: false,
        distributionDeleteConfirmation: false
      }
    case types.CREATE_DISTRIBUTION_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: '',
        distributionCreation: false
      }
    case types.CREATE_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        distributionCreation: true
      }
    case types.CREATE_DISTRIBUTION_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        distributionCreation: false
      }
    case types.DELETE_DISTRIBUTION_REQUEST:
      return {
        ...state,
        distributionDeleteConfirmation: false,
        errors: {},
        message: '',
        loading: true
      }
    case types.DELETE_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        distributionDeleteConfirmation: true,
        loading: false
      }
    case types.DELETE_DISTRIBUTION_FAILED:
      return {
        ...state,
        errors: action.error,
        distributionDeleteConfirmation: false,
        loading: false
      }
    case types.ADD_CONTACT_DISTRIBUTION_REQUEST:
      return {
        ...state,
        errors: {},
        message: '',
        loading: true,
        distributionCreation: false
      }
    case types.ADD_CONTACT_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        distributionCreation: true
      }
    case types.ADD_CONTACT_DISTRIBUTION_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        distributionCreation: false
      }
    case types.DELETE_CONTACT_DISTRIBUTION_REQUEST:
      return {
        ...state,
        errors: {},
        message: '',
        loading: true,
        distributionCreation: false
      }
    case types.DELETE_CONTACT_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        distributionCreation: true
      }
    case types.DELETE_CONTACT_DISTRIBUTION_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        distributionCreation: false
      }

    default:
      return state
  }
}

export default contactReducer
