import * as types from "./actionTypes";
import {
  request,
  success,
  failure,
  redirect,
  handleUserResponse,
} from "../../../app/utilities/helpers/";
import { authService } from "./_service";

import { toast } from "react-toastify";

const handleSignup = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.SIGNUP_REQUEST));
    authService.handleSignup(data).then(
      (res) => {
        toast.success(res.user.message);
        dispatch(success(res, types.SIGNUP_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.SIGNUP_FAILED));
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else if (
          error?.response?.data?.errors?.error &&
          error?.response?.data?.errors?.error.length > 0
        ) {
          toast.error(error?.response?.data?.errors?.error[0]);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleConfirm = (token) => {
  return (dispatch) => {
    dispatch(request(null, types.CONFIRM_REQUEST));
    authService.handleConfirm(token).then(
      (res) => {
        toast.success(res.message);
        dispatch(success(true, types.CONFIRM_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.CONFIRM_FAILED));
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else if (
          error?.response?.data?.errors?.error &&
          error?.response?.data?.errors?.error.length > 0
        ) {
          toast.error(error?.response?.data?.errors?.error[0]);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleLogin = (data, rememberMe) => {
  return (dispatch) => {
    dispatch(request(null, types.LOGIN_REQUEST));
    authService.handleLogin(data).then(
      (res) => {
        toast.success("Login successful");
        dispatch(success(res.user, types.LOGIN_SUCCESS));
        handleUserResponse(res.user.token, res.user.refresh_token);
        setTimeout(function () {
          // redirect("/hub");
        }, 300);
      },
      (error) => {
        if (error?.response?.data?.errors?.error?.length) {
          toast.error(error?.response?.data?.errors?.error[0]);
          dispatch(
            failure(error?.response?.data?.errors?.error, types.LOGIN_FAILED)
          );
        } else if (
          error?.response?.data?.errors?.error &&
          error?.response?.data?.errors?.error.length > 0
        ) {
          toast.error(error?.response?.data?.errors?.error[0]);
        } else {
          toast.error("Something went wrong. It's us not you");
          dispatch(
            failure("Something went wrong. It's us not you", types.LOGIN_FAILED)
          );
        }
      }
    );
  };
};

const handleGetLoggedInUser = () => {
  return (dispatch) => {
    dispatch(request(null, types.GET_LOGGED_IN_USER_REQUEST));
    authService.handleGetLoggedInUser().then(
      (res) => {
        dispatch(success(res.user, types.GET_LOGGED_IN_USER_SUCCESS));
      },
      (error) => {
        if (error?.response?.data?.errors?.error?.length) {
          toast.error(error?.response?.data?.errors?.error[0]);
          dispatch(
            failure(
              error?.response?.data?.errors?.error,
              types.GET_LOGGED_IN_USER_FAILED
            )
          );
        } else {
          toast.error("Something went wrong. It's us not you");
          dispatch(
            failure(
              "Something went wrong. It's us not you",
              types.GET_LOGGED_IN_USER_FAILED
            )
          );
        }
      }
    );
  };
};

const handleLogout = () => {
  return (dispatch) => {
    redirect("/login/1");
  };
};

const handleForgotPassword = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.FORGOT_PASSWORD_REQUEST));
    authService.handleForgotPassword(data).then(
      (response) => {
        dispatch(success(response, types.FORGOT_PASSWORD_SUCCESS));
        toast.success("An email has been sent to you to reset your password");
      },
      (error) => {
        dispatch(failure(error, types.FORGOT_PASSWORD_FAILED));
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else if (
          error?.response?.data?.errors?.error &&
          error?.response?.data?.errors?.error.length > 0
        ) {
          toast.error(error?.response?.data?.errors?.error[0]);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleResetPassword = (payload) => {
  return (dispatch) => {
    dispatch(request(null, types.RESET_PASSWORD_REQUEST));
    authService.handleResetPassword(payload.token, payload.password).then(
      (res) => {
        toast.success(res.message);
        dispatch(success(true, types.RESET_PASSWORD_SUCCESS));
        // Redirect to Login
        setTimeout(() => {
          redirect("/login");
        }, 3000);
      },
      (error) => {
        dispatch(failure(error, types.RESET_PASSWORD_FAILED));
        if (error?.response?.message) {
          toast.error(error?.response?.message);
        } else if (
          error?.response?.data?.errors?.error &&
          error?.response?.data?.errors?.error.length > 0
        ) {
          toast.error(error?.response?.data?.errors?.error[0]);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleGoogleAuthSignup = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.GOOGLE_AUTH_REQUEST));
    authService.handleGoogleAuthSignup(data).then(
      (res) => {
        toast.success("Successful");
        // update user state in auth store
        dispatch(success(res, types.LOGIN_SUCCESS));
        handleUserResponse(res.token, res.refresh_token);
        redirect("/hub");
      },
      (error) => {
        dispatch(failure(error, types.GOOGLE_AUTH_FAILED));
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else if (
          error?.response?.data?.errors?.error &&
          error?.response?.data?.errors?.error.length > 0
        ) {
          toast.error(error?.response?.data?.errors?.error[0]);
        } else {
          toast.error("Something went wrong. It's us, not you");
        }
      }
    );
  };
};

const handleGoogleAuthLogin = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.GOOGLE_AUTH_REQUEST));
    authService.handleGoogleAuthLogin(data).then(
      (res) => {
        toast.success("Login successful");
        // update user state in auth store
        dispatch(success(res, types.GOOGLE_AUTH_SUCCESS));
        handleUserResponse(res.token, res.refresh_token);
        redirect("/hub");
      },
      (error) => {
        dispatch(failure(error, types.GOOGLE_AUTH_FAILED));
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else if (
          error?.response?.data?.errors?.error &&
          error?.response?.data?.errors?.error.length > 0
        ) {
          toast.error(error?.response?.data?.errors?.error[0]);
        } else {
          toast.error("Something went wrong. It's us, not you");
        }
      }
    );
  };
};

const handleUpdateUser = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.EDIT_USER_PROFILE_REQUEST));
    authService.handleUpdateUser(data).then(
      (res) => {
        toast.success("Update successful");
        dispatch(success(res, types.EDIT_USER_PROFILE_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.EDIT_USER_PROFILE_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.EDIT_USER_PROFILE_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else if (
          error?.response?.data?.errors?.error &&
          error?.response?.data?.errors?.error.length > 0
        ) {
          toast.error(error?.response?.data?.errors?.error[0]);
        } else {
          toast.error("Something went wrong. It's us, not you");
        }
      }
    );
  };
};

export const authActions = {
  handleSignup,
  handleConfirm,
  handleLogin,
  handleGetLoggedInUser,
  handleLogout,
  handleForgotPassword,
  handleResetPassword,
  handleGoogleAuthSignup,
  handleGoogleAuthLogin,
  handleUpdateUser,
};
