import React, { useState, useEffect } from 'react';

import Chart from 'react-apexcharts';

const PiCharts = () => {
  const [state, setState] = useState();

  useEffect(() => {
    setState({
      options: {
        colors: ['#FF4101', '#000080'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },

        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
        },
      },
      series: [
        {
          name: 'Views',
          data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 103, 56, 99],
        },
        {
          name: 'Downloads',
          data: [30, 45, 25, 55, 29, 30, 90, 101, 95, 25, 100, 125],
        },
      ],
    })
  }, [])

  return (
    <div>
      {state?.series && (
        <Chart
          series={state?.series}
          options={state?.options}
          type="bar"
          width={360}
          height={335}
          // overflow-x-scroll=" overflow-x: scroll"
        />
      )}
    </div>
  );
};

export default PiCharts;
