import React, { useEffect, useState, Fragment } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import ReactPaginate from "react-paginate";
import { Menu, Transition } from "@headlessui/react";
import AssetCard from "../commons/Asset/components/AssetCard";
import { LoadingSpinner, PageWrapper, Slider } from "../../components";
import ComposeChild from "../Compose/components/ComposeChild";
import ConversationPreview from "./components/ConversationPreview";
import DeleteConversation from "./components/DeleteConversation";
import Activity from "./components/Activity";
import Recipient from "./components/Recipient";
import Asset from "./components/Asset";
import SideBar from "./components/SideBar";
import {
  BsChevronDown,
  BsFillEyeFill,
  BsFillSendPlusFill,
  BsArrowClockwise,
  BsTrashFill,
  BsFillFilterCircleFill,
  BsSortUp,
  BsSortDownAlt,
} from "react-icons/bs";
import { BiConversation } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
import { FiGitMerge } from "react-icons/fi";
import { FiInfo } from "react-icons/fi";

import { conversationsActions } from "./actions";
import {
  dateCalculation,
  handleGetReadableTimeRange,
} from "../../app/utilities/helpers";
import no_content from "../../assets/images/no_content.svg";
import "./Conversation.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Conversations = () => {
  let { id: conversationIdParam } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, conversations, previewConversation, totalConversationPage } =
    useSelector((state) => state.conversations);

  const [sortType] = useState(null);
  const [conversationPageNumber, setConversationPageNumber] = useState(1);

  const [conversationId, setConversationId] = useState();
  const [activeConversation, setActiveConversation] = useState([]);
  const [recipients, setRecipients] = useState([]);

  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [activityContext, setActivityContext] = useState(null);
  const [previewConversationShow, setPreviewConversationShow] = useState(false);
  const [showResendConversation, setShowResendConversation] = useState(false);
  const [showRecipient, setShowRecipient] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortValues, setSortValues] = useState({
    created_at: "",
    last_activity: "",
    time_spent: "",
  });
  const [recipientSortValues, setRecipientSortValues] = useState({
    most_active: "",
    last_activity: "",
  });

  const handleDeletePopupClose = () => setShowDeletePopUp(false);

  const changePage = ({ selected }) => {
    setCurrentPage(selected);
    setConversationPageNumber(selected + 1);
    const queryUrl = `page=${selected + 1}${
      sortType ? `&order_by=${sortType}` : ""
    }`;
    handleGetConversationsShallow(queryUrl);
  };

  useEffect(() => {
    const queryUrl = `page=${conversationPageNumber}${
      sortType ? `&order_by=${sortType}` : ""
    }`;
    handleGetConversationsShallow(queryUrl);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (conversations && conversations.length) {
      if (conversationIdParam) {
        setConversationId(conversationIdParam);
      } else {
        setConversationId(conversations[0].id);
      }
    }
  }, [conversationIdParam, conversations]);

  useEffect(() => {
    if (conversationId) {
      dispatch(
        conversationsActions.handleConversationsPreviewFetch(
          `compose/send/${conversationId}`
        )
      );
    }
  }, [conversationId, dispatch]);

  useEffect(() => {
    if (previewConversation && previewConversation.id) {
      setActiveConversation([previewConversation]);
      const knownRecipientsEmails = [
        ...previewConversation.to_email,
        ...previewConversation.bcc,
        ...previewConversation.cc,
      ];
      const knownRecipients = [
        ...previewConversation.to_email_data,
        ...previewConversation.bcc_data,
        ...previewConversation.cc_data,
      ];
      const allRecipients = [];
      previewConversation.contacts.map((item) => {
        if (!knownRecipientsEmails.includes(item.email)) {
          allRecipients.push({ ...item, anonymous: true, access: true });
        } else {
          allRecipients.push({ ...item, anonymous: false, access: true });
        }
        return true;
      });
      const allRecipientsEmails = [];
      allRecipients.map((item) => {
        return allRecipientsEmails.push(item.email);
      });
      knownRecipients.map((item) => {
        if (!allRecipientsEmails.includes(item.email)) {
          allRecipients.push({ ...item, anonymous: false, access: false });
        }
        return true;
      });
      setRecipients(allRecipients);
    }
  }, [previewConversation]);

  // sort recipients
  useEffect(() => {
    let recipientsRef = recipients;
    if (recipientSortValues.most_active) {
      recipientsRef = recipientsRef.sort(
        (a, b) =>
          recipientSortValues.most_active === "asc"
            ? a.activity_count - b.activity_count // MOST_ACTIVE: ascending order
            : b.activity_count - a.activity_count // MOST_ACTIVE: descending order
      );
    }

    if (recipientSortValues.last_activity) {
      recipientsRef = recipientsRef.sort(
        (a, b) =>
          recipientSortValues.last_activity === "asc"
            ? new Date(a.last_active) - new Date(b.last_active) // LAST_ACTIVITY: ascending order
            : new Date(b.last_active) - new Date(a.last_active) // LAST_ACTIVITY: descending order
      );
    }
    setRecipients(recipientsRef);
  }, [recipientSortValues, recipients]);

  useEffect(() => {
    if (!showRecipient) {
      setShowRecipient(true);
    }
  }, [showRecipient]);

  const handleGetConversations = () =>
    dispatch(
      conversationsActions.handleConversationsPreviewFetch(
        `compose/send/${conversationId}`
      )
    );

  const handleGetConversationsShallow = (queryUrl) =>
    dispatch(
      conversationsActions.handleConversationsListsFetch(
        `compose/send-shallow?${queryUrl}`
      )
    );

  const handleConversationDelete = () => {
    dispatch(
      conversationsActions.handleConversationsDelete(activeConversation[0].id)
    );
    setTimeout(function () {
      handleDeletePopupClose();
    }, 500);
  };

  const handlePreviewConversationToggle = () => {
    setPreviewConversationShow(!previewConversationShow);
  };

  const handleResendConversationToggle = () =>
    setShowResendConversation(!showResendConversation);

  const handleActivitySlideToggle = (activity, context) => {
    if (activity && context) {
      setActivityContext({
        data: activity,
        context: context,
      });
    } else {
      setActivityContext(null);
    }
  };

  return (
    <PageWrapper>
      <div className="mt-1 lg:mx-20 flex flex-row items-center justify-between bg-transparent">
        <div className="flex flex-col ml-6 lg:ml-0">
          <span className="text-[#FE4502] font-extrabold text-lg">
            Conversations
          </span>
          <span className="text-[#757575] text-base">
            Track User Engagement
          </span>
        </div>

        <div className="flex justify-end p-4">
          {!previewConversationShow ? (
            <>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex justify-center w-full rounded-md  px-4 py-2 bg-transparent text-sm text-gray-500 hover:text-[#000080] hover:bg-gray-50 focus:outline-none">
                    <span className="text-uberark-blue">Sort By</span>
                    <BsChevronDown
                      className="-mr-1 ml-2 h-5 w-5 text-uberark-orange chevron"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-[1]">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <>
                            <div
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-[#000080]"
                                  : "text-gray-500",
                                "flex items-center px-4 py-2 text-sm"
                              )}
                              onClick={() => {
                                setSortValues((prev) => ({
                                  ...prev,
                                  created_at:
                                    sortValues.created_at === "asc"
                                      ? "desc"
                                      : "asc",
                                  last_activity: "",
                                  time_spent: "",
                                }));
                                handleGetConversationsShallow(
                                  `order_by=${
                                    sortValues.created_at === "asc"
                                      ? "-created_at"
                                      : "created_at"
                                  }`
                                );
                              }}
                            >
                              Created Date
                              {sortValues.created_at === "asc" && (
                                <BsSortUp className="ml-1" />
                              )}
                              {sortValues.created_at === "desc" && (
                                <BsSortDownAlt className="ml-1" />
                              )}
                            </div>

                            <div
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-[#000080]"
                                  : "text-gray-500",
                                "flex items-center px-4 py-2 text-sm"
                              )}
                              onClick={() => {
                                setSortValues((prev) => ({
                                  ...prev,
                                  last_activity:
                                    sortValues.last_activity === "asc"
                                      ? "desc"
                                      : "asc",
                                  created_at: "",
                                  time_spent: "",
                                }));
                                handleGetConversationsShallow(
                                  `order_by=${
                                    sortValues.last_activity === "asc"
                                      ? "-last_activity"
                                      : "last_activity"
                                  }`
                                );
                              }}
                            >
                              Last activity
                              {sortValues.last_activity === "asc" && (
                                <BsSortUp className="ml-1" />
                              )}
                              {sortValues.last_activity === "desc" && (
                                <BsSortDownAlt className="ml-1" />
                              )}
                            </div>

                            <div
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-[#000080]"
                                  : "text-gray-500",
                                "flex items-center px-4 py-2 text-sm"
                              )}
                              onClick={() => {
                                setSortValues((prev) => ({
                                  ...prev,
                                  time_spent:
                                    sortValues.time_spent === "asc"
                                      ? "desc"
                                      : "asc",
                                  created_at: "",
                                  last_activity: "",
                                }));
                                handleGetConversationsShallow(
                                  `order_by=${
                                    sortValues.time_spent === "asc"
                                      ? "-time_spent"
                                      : "time_spent"
                                  }`
                                );
                              }}
                            >
                              Time spent
                              {sortValues.time_spent === "asc" && (
                                <BsSortUp className="ml-1" />
                              )}
                              {sortValues.time_spent === "desc" && (
                                <BsSortDownAlt className="ml-1" />
                              )}
                            </div>
                          </>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>

              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex justify-center items-center w-full rounded-md  px-4 py-2 bg-transparent text-sm text-gray-500 hover:text-[#000080] hover:bg-gray-50 focus:outline-none">
                    <BsFillFilterCircleFill className="mr-1 text-uberark-blue" />
                    <span className="text-uberark-blue">Filter By</span>
                    <BsChevronDown
                      className="-mr-1 ml-2 h-5 w-5 text-uberark-orange chevron"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max pr-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-[1]">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <>
                            <div
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-[#000080]"
                                  : "text-gray-500",
                                "block px-4 py-2 text-sm"
                              )}
                              onClick={() => {
                                handleGetConversationsShallow("");
                              }}
                            >
                              All Viewers
                            </div>
                            <div
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-[#000080]"
                                  : "text-gray-500",
                                "block px-4 py-2 text-sm"
                              )}
                              onClick={() => {
                                handleGetConversationsShallow(
                                  "anonymous=False"
                                );
                              }}
                            >
                              Known viewers
                            </div>
                            <div
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-[#000080]"
                                  : "text-gray-500",
                                "block px-4 py-2 text-sm"
                              )}
                              onClick={() => {
                                handleGetConversationsShallow("anonymous=True");
                              }}
                            >
                              Anonymous viewers
                            </div>
                          </>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </>
          ) : null}
        </div>
      </div>

      {!previewConversationShow ? (
        <div
          className={`rounded-3xl bg-white mx-4 py-6 shadow justify-items-center min-h-[75vh] ${
            conversations && conversations.length
              ? ""
              : "grid gap-4 grid-cols-1"
          }`}
        >
          {conversations && conversations.length ? (
            <>
              <div className="conversation-layout-wrap">
                {/* sidebar */}
                <SideBar
                  conversations={conversations}
                  conversationId={conversationId}
                  setConversationId={setConversationId}
                />

                {/* selected conversation */}
                <div className="conversarion-list-preview bg-white">
                  {activeConversation &&
                  activeConversation.length &&
                  !loading ? (
                    activeConversation.map((conversation) => (
                      <div className="relative" key={conversation.id}>
                        <div className="convContain w-full">
                          {/* ----------------------------------head --------------------------- */}
                          <div className="flex flex-col gap-4">
                            <div className="flex md:flex-row flex-col gap-4 justify-between items-center px-6">
                              {/* ---------------------------head-left--------------------- */}
                              <div className="flex flex-row items-center gap-2 w-1/2">
                                <div className="flex">
                                  <div className="md:w-20 bg-white flex justify-center items-center md:h-20 w-12 h-12 rounded imgShadow">
                                    {conversation.assets &&
                                    conversation.assets.length ? (
                                      <AssetCard
                                        asset={conversation.asset}
                                        size="asset__small"
                                        className="md:w-20 md:h-20 w-11 h-11 conversation-asset"
                                      />
                                    ) : (
                                      <BiConversation className="md:w-12  md:h-12 w-11 h-11" />
                                    )}
                                  </div>
                                </div>
                                <div className="flex flex-col md:p-3 h-full justify-center">
                                  <div className="flex flex-col">
                                    {" "}
                                    <span className="text-xs text-[#757575] font-normal">
                                      {conversation.contacts &&
                                      conversation.contacts.length &&
                                      conversation.to_email.length ? (
                                        <>
                                          {conversation.to_email[0]}
                                          {conversation.contacts.length > 1 ? (
                                            <span className="text-uberark-orange ml-1 font-semibold">
                                              +{" "}
                                              {conversation.contacts.length - 1}
                                            </span>
                                          ) : null}
                                        </>
                                      ) : (
                                        "Anonymous Conversation"
                                      )}
                                    </span>
                                    <span className="text-base text-uberark-blue font-semibold w-[200px] truncate">
                                      {conversation.subject}
                                    </span>
                                  </div>

                                  <div>
                                    <span className="text-[10px] text-[#757575] font-normal">
                                      Shared{" "}
                                      {dateCalculation(
                                        new Date(conversation.created_at),
                                        new Date()
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              {/* ---------------------------head-right--------------------- */}
                              <div className="flex flex-col items-center pt-3 w-1/2">
                                <div className="divide-x text-sm divide-slate-500 flex">
                                  <div
                                    className="grid justify-center px-2"
                                    onClick={() =>
                                      handlePreviewConversationToggle()
                                    }
                                  >
                                    <button className="grid grid-flow-col items-center gap-1 hover:text-[#000080]">
                                      <BsFillEyeFill />
                                      <span className="hidden md:block">
                                        Preview
                                      </span>
                                    </button>
                                  </div>
                                  {conversationId &&
                                  !conversation.parent_email ? (
                                    <div
                                      className="grid justify-center px-2"
                                      onClick={() =>
                                        handleResendConversationToggle()
                                      }
                                    >
                                      <button className="grid grid-flow-col items-center gap-1 hover:text-[#000080]">
                                        <BsFillSendPlusFill />
                                        <span className="hidden md:block">
                                          Resend
                                        </span>
                                      </button>
                                    </div>
                                  ) : null}

                                  <div className="grid justify-center px-2">
                                    <button
                                      className="grid grid-flow-col items-center gap-1 hover:text-[#000080]"
                                      onClick={() => setShowDeletePopUp(true)}
                                    >
                                      <BsTrashFill />
                                      <span className="hidden md:block">
                                        Delete
                                      </span>
                                    </button>
                                  </div>
                                  <div className="grid justify-center px-2">
                                    <button
                                      className="grid grid-flow-col items-center gap-1 hover:text-[#000080]"
                                      onClick={() => handleGetConversations()}
                                    >
                                      <BsArrowClockwise />
                                      <span className="hidden md:block">
                                        Refresh
                                      </span>
                                    </button>
                                  </div>
                                  {conversationId &&
                                  conversation.parent_email ? (
                                    <div className="grid justify-center px-2">
                                      <button
                                        className="grid grid-flow-col items-center gap-1 hover:text-[#000080] border rounded drop-shadow-lg flex items-center px-2 cursor-pointer hover:text-uberark-orange text-uberark-orange border-color-blue"
                                        onClick={() =>
                                          navigate(
                                            "/conversations/" +
                                              conversation.parent_email
                                          )
                                        }
                                      >
                                        <FiGitMerge />
                                        <span className="hidden md:block"></span>
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                                <span>Here's How We Are Doing</span>
                                <div className="divide-x text-[10px] text-[#757575] font-normal divide-slate-500 flex">
                                  <div className="grid justify-center px-1">
                                    {" "}
                                    Open Rate:{" "}
                                    {conversation.statistics &&
                                    conversation.statistics.open_rate
                                      ? conversation.statistics.open_rate
                                      : 0}
                                    %
                                  </div>
                                  <div className="grid justify-center px-1">
                                    Click Rate:{" "}
                                    {conversation.statistics &&
                                    conversation.statistics.click_rate
                                      ? conversation.statistics.click_rate
                                      : 0}
                                    %{" "}
                                  </div>
                                  <div className="grid justify-center px-1">
                                    Time spent:{" "}
                                    {conversation.statistics &&
                                    conversation?.statistics?.time_spent
                                      ? handleGetReadableTimeRange(
                                          conversation.statistics.time_spent
                                        )
                                      : 0}
                                  </div>
                                </div>
                                {/* {conversation?.statistics ? (<LastActivity statistics={conversation?.statistics} />) : null} */}
                              </div>
                            </div>
                          </div>

                          {/* ----------------------------------Recipients------------------------- */}
                          {recipients?.length > 0 ? (
                            <>
                              <div className="flex flex-row w-full justify-between items-center border-b-1 border-uberark-blue pt-4 pb-1 px-10">
                                <div>Recipients ({recipients?.length})</div>
                                {recipients?.length > 1 ? (
                                  <div className="flex flex-col justify-center h-full pr-4 gap-4">
                                    <div className="divide-x text-xs divide-slate-500 flex justify-end text-black">
                                      <div
                                        className="flex justify-center items-center px-2 cursor-pointer"
                                        onClick={() => {
                                          setRecipientSortValues((prev) => ({
                                            ...prev,
                                            most_active:
                                              recipientSortValues.most_active ===
                                              "asc"
                                                ? "desc"
                                                : "asc",
                                            last_activity: "",
                                          }));
                                          setShowRecipient(false);
                                        }}
                                      >
                                        <div
                                          className={`text-xs ml-1  flex items-center ${
                                            recipientSortValues.most_active
                                              ? "text-uberark-blue"
                                              : ""
                                          }`}
                                        >
                                          Most Active
                                          {recipientSortValues.most_active ===
                                            "asc" && (
                                            <BsSortUp className="ml-1" />
                                          )}
                                          {recipientSortValues.most_active ===
                                            "desc" && (
                                            <BsSortDownAlt className="ml-1" />
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        className="flex justify-center items-center px-2 cursor-pointer"
                                        onClick={() => {
                                          setRecipientSortValues((prev) => ({
                                            ...prev,
                                            last_activity:
                                              recipientSortValues.last_activity ===
                                              "asc"
                                                ? "desc"
                                                : "asc",
                                            most_active: "",
                                          }));
                                          setShowRecipient(false);
                                        }}
                                      >
                                        <div
                                          className={`text-xs ml-1 flex items-center ${
                                            recipientSortValues.last_activity
                                              ? "text-uberark-blue"
                                              : ""
                                          }`}
                                        >
                                          Last Activity
                                          {recipientSortValues.last_activity ===
                                            "asc" && (
                                            <BsSortUp className="ml-1" />
                                          )}
                                          {recipientSortValues.last_activity ===
                                            "desc" && (
                                            <BsSortDownAlt className="ml-1" />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              <div
                                className={`${
                                  recipients?.length > 10
                                    ? "overflow-y-auto h-72"
                                    : ""
                                }`}
                              >
                                {showRecipient && recipients.length
                                  ? recipients.map((contact, i) => (
                                      <Recipient
                                        key={contact.id}
                                        conversationData={conversation}
                                        handleActivitySlideToggle={
                                          handleActivitySlideToggle
                                        }
                                        contactData={contact}
                                      />
                                    ))
                                  : null}
                              </div>
                            </>
                          ) : null}

                          {/* --------------------------------------Assets------------------------- */}
                          <div className="flex flex-row w-full justify-between border-b-1 border-uberark-blue pt-4 pb-1 px-10">
                            <span>
                              Assets (
                              {conversation?.assets
                                ? conversation?.assets.length
                                : 0}
                              )
                            </span>
                          </div>
                          {conversation?.assets && conversation.assets.length
                            ? conversation.assets.map((asset) => (
                                <Asset
                                  key={asset.id}
                                  asset={asset}
                                  handleActivitySlideToggle={
                                    handleActivitySlideToggle
                                  }
                                />
                              ))
                            : null}
                        </div>
                      </div>
                    ))
                  ) : loading ? (
                    <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10 mt-20">
                      <div className="w-full flex justify-center">
                        <LoadingSpinner colorClassName="border-[#000080]" />
                      </div>
                    </div>
                  ) : (
                    <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10">
                      <div className="w-full flex justify-center">
                        <img
                          src={no_content}
                          alt="nothing to show illustration"
                          style={{ height: "60px" }}
                          loading="lazy"
                        />
                      </div>
                      <div className="text-center relative p-5 text-[#757575] text-base flex items-center">
                        Nothing to show.{" "}
                        <FiInfo className="text-uberark-orange ml-1" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {totalConversationPage > 1 ? (
                <div className="flex items-start">
                  <div className="p-4">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      pageCount={totalConversationPage}
                      onPageChange={changePage}
                      forcePage={currentPage}
                      containerClassName={"paginationBttns"}
                      previousLinkClassName={"previousBttn"}
                      nextLinkClassName={"nextBttn"}
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                    />
                  </div>
                </div>
              ) : null}
            </>
          ) : loading ? (
            <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10 mt-[70px]">
              <div className="w-full flex justify-center">
                <LoadingSpinner colorClassName="border-[#000080]" />
              </div>
            </div>
          ) : (
            <div className="h-[155px] w-full flex flex-col justify-center items-center mt-[70px]">
              <div className="w-full flex justify-center">
                <img
                  src={no_content}
                  alt="nothing to show illustration"
                  style={{ height: "60px" }}
                  loading="lazy"
                />
              </div>
              <div className="text-center relative p-5 text-[#757575] text-base flex items-center">
                Nothing to show. <FiInfo className="text-uberark-orange ml-1" />
              </div>
              <Link
                className="flex items-center justify-center py-1 px-5 cursor-pointer opacity-80 hover:scale-105 ease-in-out duration-300 border rounded"
                alt={`/compose`}
                to={`/compose`}
              >
                <AiOutlinePlus className="text-[#FE4502] mr-1" /> Compose
              </Link>
            </div>
          )}
        </div>
      ) : (
        <div
          className={`rounded-3xl bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] mx-4 p-6 shadow justify-items-center`}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="convContain md:h-[577px] h-[450px] w-full overflow-y-scroll scroll scroll-smooth scrollbar-hide"
          >
            <div className="w-full relative">
              <ConversationPreview
                onShareCollectionClose={setPreviewConversationShow}
                onResendConversation={() => {
                  handleResendConversationToggle();
                  const queryUrl = `page=${conversationPageNumber}${
                    sortType ? `&order_by=${sortType}` : ""
                  }`;
                  handleGetConversationsShallow(queryUrl);
                }}
                collection={activeConversation[0]}
              />
            </div>
          </div>
        </div>
      )}

      {/* Pop ups, modals, sliders ---------------------------------------------------------------------------------------------------------------- */}
      {showResendConversation ? (
        <div
          onMouseDown={() => {
            handleResendConversationToggle();
            const queryUrl = `page=${conversationPageNumber}${
              sortType ? `&order_by=${sortType}` : ""
            }`;
            handleGetConversationsShallow(queryUrl);
          }}
          className="pop_up_container fixed inset-0 bg-gray-50 bg-opacity-30 backdrop-blur-sm text-black h-full"
          style={{ zIndex: "100", overflowY: "scroll" }}
        >
          <div onMouseDown={(e) => e.stopPropagation()} className="h-full flex">
            <div className="bg-white pl-5 pt-5 md:w-4/6 w-full relative">
              <ComposeChild
                onShareCollectionClose={() => {
                  handleResendConversationToggle();
                  const queryUrl = `page=${conversationPageNumber}${
                    sortType ? `&order_by=${sortType}` : ""
                  }`;
                  handleGetConversationsShallow(queryUrl);
                }}
                collection={activeConversation[0]}
                position={"resend"}
              />
            </div>
          </div>
        </div>
      ) : null}

      {activityContext && activityContext?.data ? (
        <Slider
          float="right"
          title="Timeline"
          visible={activityContext.data ? true : false}
          onClose={handleActivitySlideToggle}
        >
          <Activity
            activityContext={activityContext}
            closeActivity={handleActivitySlideToggle}
          />
        </Slider>
      ) : null}

      <DeleteConversation
        onClose={handleDeletePopupClose}
        onDeleteEvent={handleConversationDelete}
        visible={showDeletePopUp}
        dltLoader={loading}
      />
    </PageWrapper>
  );
};

export default Conversations;
