import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LoadingSpinner, Slider } from "../";
import AssetCard from "../../pages/commons/Asset/components/AssetCard";
import ReactPaginate from "react-paginate";
import { BsDot, BsEnvelopeFill } from "react-icons/bs";
import { BiCheckDouble, BiCheck } from "react-icons/bi";
import { FiInfo } from "react-icons/fi";

import { settingsActions } from "../../pages/Settings/actions";
import {
  dateCalculation,
  notificationsCategories,
} from "../../app/utilities/helpers";
import no_content from "../../assets/images/no_content.svg";

const Notification = ({ visible, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notifications, loading, notificationsUpdate } = useSelector(
    (state) => state.settings
  );
  const { user } = useSelector((state) => state.auth);
  const [noData, setNoData] = useState(true);
  const [notifyFilter, setNotifyFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if ((noData || notificationsUpdate) && user.organization) {
      let url =
        notifyFilter === "unread"
          ? "inappnotifications/unread"
          : notifyFilter === "read"
          ? "inappnotifications/read"
          : "inappnotifications";
      dispatch(settingsActions.handleNotificationListsFetch(url));
      setNoData(false);
      setCurrentPage(0);
    }
  }, [
    dispatch,
    notifications,
    notificationsUpdate,
    noData,
    notifyFilter,
    user,
  ]);

  const onReadNotification = (item) => {
    let payload = {
      id: item.id,
      is_read: true,
    };
    dispatch(settingsActions.handleNotificationUpdate(payload, item.id));

    if (
      item.collection_data &&
      item.collection_data.id &&
      !item.collection_data.deleted
    ) {
      setTimeout(function () {
        navigate("/collection/" + item.collection_data.id);
      }, 500);
      onClose();
    }

    if (
      item.content_data &&
      item.content_data.id &&
      !item.content_data.deleted
    ) {
      setTimeout(function () {
        navigate("/stream/" + item.content_data.id);
      }, 500);
      onClose();
    }

    if (
      item.composed_email_data &&
      item.composed_email_data.id &&
      !item.composed_email_data.deleted
    ) {
      setTimeout(function () {
        navigate("/conversations/" + item.composed_email_data.id);
      }, 500);
      onClose();
    }
  };

  const handleFilterNodification = (t) => {
    setNotifyFilter(t);
    setNoData(true);
  };

  const changePage = ({ selected }) => {
    setCurrentPage(selected);
    if (user.organization) {
      let url =
        notifyFilter === "unread"
          ? "inappnotifications/unread"
          : notifyFilter === "read"
          ? "inappnotifications/read"
          : "inappnotifications";
      dispatch(
        settingsActions.handleNotificationListsFetch(
          `${url}?page=${selected + 1}`
        )
      );
    }
  };

  const paginationPageCount = (totalItems) => Math.ceil(totalItems / 10);

  const groupedNotifications = notificationsCategories(notifications.results);

  return (
    <Slider
      direction="right"
      title="Notifications"
      visible={visible}
      onClose={onClose}
    >
      <div className="flex flex-wrap text-sm divide-x text-slate-600 divide-slate-500 w-full px-3">
        <div
          className={`px-2 flex items-center cursor-pointer ${
            notifyFilter === "all" ? " text-uberark-blue" : ""
          }`}
          onClick={() => handleFilterNodification("all")}
        >
          <BsEnvelopeFill className="mr-1" />
          All
        </div>
        <div
          className={`px-2 flex items-center cursor-pointer ${
            notifyFilter === "unread" ? " text-uberark-blue" : ""
          }`}
          onClick={() => handleFilterNodification("unread")}
        >
          <BiCheck className="mr-1" />
          Unread
        </div>
        <div
          className={`px-2 flex items-center cursor-pointer ${
            notifyFilter === "read" ? " text-uberark-blue" : ""
          }`}
          onClick={() => handleFilterNodification("read")}
        >
          <BiCheckDouble className="mr-1" />
          Read
        </div>
      </div>

      <hr className="mt-2 mb-0" />
      {/* body goes here */}
      <div className="notification-scroll h-[80vh] scroll-smooth">
        {loading ? (
          <div className="w-full h-40 flex justify-center items-center mt-20">
            <LoadingSpinner colorClassName="border-[#000080]" />
          </div>
        ) : (
          <>
            {notifications &&
            notifications.results &&
            notifications.results.length ? (
              Object.keys(groupedNotifications).map((groupTitle) => (
                <div key={groupTitle}>
                  <h2
                    className={`${
                      groupTitle ? "p-3 font-medium text-uberark-blue" : ""
                    }`}
                  >
                    {groupTitle}
                  </h2>
                  {groupedNotifications[groupTitle].map((item) => (
                    <div
                      key={item.id}
                      className={`${
                        !item.is_read ? "unread-noditification" : ""
                      } flex flex-wrap relative`}
                      onClick={() => onReadNotification(item)}
                    >
                      <div className="flex justify-between notify-wrap cursor-pointer relative w-full p-3 border pb-5 hover:scale-102 ease-in-out duration-300">
                        {item.collection_data && item.collection_data.id && (
                          <div className="h-[40px] mr-2 w-[40px]">
                            <AssetCard
                              asset={{
                                file: item.collection_data.banner,
                                name: item.collection_data.banner.includes(
                                  ".mp4"
                                )
                                  ? "file.mp4"
                                  : "file.jpg",
                              }}
                              size="asset__x-small"
                              className="rounded-full"
                            />
                          </div>
                        )}
                        {item.content_data && item.content_data.id && (
                          <div className="h-[40px] mr-2 w-[40px]">
                            <AssetCard
                              asset={{
                                file: item.content_data.cover_image,
                                name: item.content_data.cover_image.includes(
                                  ".mp4"
                                )
                                  ? "file.mp4"
                                  : "file.jpg",
                              }}
                              size="asset__x-small"
                              className="rounded-full"
                            />
                          </div>
                        )}
                        {item.composed_email_data &&
                          item.composed_email_data.id && (
                            <div className="h-[40px] mr-2 w-[40px]">
                              <AssetCard
                                asset={{
                                  file: item.composed_email_data.asset.thumbnail
                                    ? item.composed_email_data.asset.thumbnail
                                    : item.composed_email_data.asset.file,
                                  name: item.composed_email_data.asset.name,
                                }}
                                size="asset__x-small"
                                className="rounded-full"
                              />
                            </div>
                          )}
                        <div className={`w-full flex flex-col relative`}>
                          <p
                            className={`text-slate-600 text-sm`}
                            dangerouslySetInnerHTML={{
                              __html: item.notification_message,
                            }}
                          ></p>
                          <div className="text-slate-600 flex mt-1">
                            <div className="text-slate-600 text-xs flex">
                              {dateCalculation(
                                new Date(item.created_at),
                                new Date()
                              )}
                            </div>
                            <div
                              className={`flex items-center ml-1 cursor-pointer`}
                            >
                              {item.collection_data &&
                                item.collection_data.id && (
                                  <>
                                    <BsDot className="text-base" />
                                    <div className="text-xs">Collection</div>
                                  </>
                                )}
                              {item.composed_email_data &&
                                item.composed_email_data.id && (
                                  <>
                                    <BsDot className="text-base" />
                                    <div className="text-xs">Conversation</div>
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <div className="h-[155px] w-full mt-20 flex flex-col justify-center items-center">
                <div className="w-full flex justify-center">
                  <img
                    src={no_content}
                    alt="nothing to show illustration"
                    style={{ height: "60px" }}
                  />
                </div>
                <div className="text-center relative p-5 text-[#757575] text-base flex items-center">
                  Nothing to show{" "}
                  <FiInfo className="text-uberark-orange ml-1" />
                </div>
              </div>
            )}

            {paginationPageCount(notifications.count) > 1 ? (
              <div className="py-4">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={paginationPageCount(notifications.count)}
                  onPageChange={changePage}
                  marginPagesDisplayed={0}
                  pageRangeDisplayed={5}
                  forcePage={currentPage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            ) : null}
          </>
        )}

        {/* <div className="mt-5">
          <Button
            color="black"
            // bgColor={currentColor}
            text="See all notifications"
            borderRadius="10px"
            width="full"
          />
        </div> */}
      </div>
    </Slider>
  );
};

export default Notification;
