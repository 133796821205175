import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { settingsActions } from "./actions";
import {
  HtmlEditor,
  Inject,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  Link,
} from "@syncfusion/ej2-react-richtexteditor";
// import { BsTrash, BsGear, BsXCircle } from 'react-icons/bs';
import { BsTrash } from "react-icons/bs";
import { RichTextEditorToolbar } from "../../app/utilities/helpers";
import LoadingSpinner from "../../components/commons/LoadingSpinner";
import { toast } from "react-toastify";
import PrivacyControl from "./PrivacyControl";

const Terms = () => {
  const dispatch = useDispatch();
  const { organization } = useSelector((state) => state.auth.user);
  const { tac, loading, tacCreation, tacDeleteConfirmation } = useSelector(
    (state) => state.settings
  );
  const [tacValue, setTACValue] = useState(
    tac && tac["0"] ? tac["0"]["term"] : ""
  );
  const [tacEditor, setTACEditor] = useState(false);
  const [noData, setNoData] = useState(false);
  // const [showSettings, setShowSettings] = useState(false)
  const showSettings = false;

  useEffect(() => {
    if (
      ((!tac || tac.length === 0) && !noData) ||
      tacCreation ||
      tacDeleteConfirmation
    ) {
      if (organization && organization !== "None") {
        dispatch(
          settingsActions.handleTACListsFetch(
            `termsconditions?organization=${organization}`
          )
        );
        setNoData(true);
        setTACEditor(false);
      }
    }
  }, [dispatch, organization, tac, tacCreation, tacDeleteConfirmation, noData]);

  const onTAC = () => {
    if (!tacValue) {
      return toast.error(`Please Enter Terms and Conditions`);
    }

    let payload = {
      term: tacValue,
    };
    if (!tac || tac.length === 0) {
      dispatch(settingsActions.handleTACCreation(payload));
    } else {
      dispatch(settingsActions.handleTACUpdate(payload, tac["0"]["id"]));
    }
  };

  const onTACDelete = () => {
    if (tac || tac.length) {
      setTACValue("");
      setTACEditor(!tacEditor);
      return dispatch(settingsActions.handleTACDelete(tac["0"]["id"]));
    }
  };

  return (
    <div className="md:m-4 w-full">
      <div className="md:p-14 p-2 bg-white rounded-xl w-full lg:h-[700px] h-[400px]">
        <div
          className={`bg-white flex ${!showSettings ? "gap-6" : ""} flex-col`}
        >
          <div className="font-bold text-uberark-orange text-xl my-4 space-between-flex">
            <span>Terms And Condition</span>
            <div className="flex">
              {/* {!showSettings ?<button
                type="button"
                onClick={() => setShowSettings(true)}
                style={{ "color": "#008" }}
                className="relative text-base rounded-full p-3 hover:bg-light-gray flex items-center"
              >
                <BsGear />&nbsp;Settings
              </button>:<button
                type="button"
                onClick={() => setShowSettings(false)}
                style={{ "color": "#008" }}
                className="relative text-base rounded-full p-3 hover:bg-light-gray flex items-center"
              >
                <BsXCircle />&nbsp;Cancel
              </button>} */}
              {tacValue ? (
                <button
                  type="button"
                  onClick={() => onTACDelete()}
                  style={{ color: "#008" }}
                  className="relative text-base rounded-full p-3 hover:bg-light-gray flex items-center"
                >
                  <BsTrash />
                  &nbsp;Delete
                </button>
              ) : null}
            </div>
          </div>
          {!showSettings ? (
            <>
              <div
                className="rounded-2xl relative w-full h-[400px]"
                style={{
                  border: "1px solid #000080",
                  overflow: "scroll",
                  height: window.innerWidth < 768 ? "14rem" : "none",
                }}
              >
                <div className="rounded-2xl overflow-y-auto absolute w-full">
                  <RichTextEditorComponent
                    change={(event) => {
                      setTACValue(event.value);
                    }}
                    value={tac && tac["0"] ? tac["0"]["term"] : tacValue}
                    toolbarSettings={RichTextEditorToolbar}
                    className="e-control e-richtexteditor e-lib e-rte-toolbar-enabled e-rte-tb-expand richtext-border-remove"
                    placeholder="Enter Terms and Conditions..."
                  >
                    <Inject
                      services={[HtmlEditor, Link, Toolbar, QuickToolbar]}
                    />
                  </RichTextEditorComponent>
                </div>
              </div>
              <div className="flex lg:justify-end flex-row text-uberark-blue gap-4 text-lg mb-3 mt-3">
                <button
                  className=":px-2 bg-[#000080] text-white hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl "
                  type="submit"
                  disabled={loading}
                  onClick={() => onTAC()}
                >
                  {loading ? <LoadingSpinner /> : "Save"}
                </button>
              </div>
            </>
          ) : (
            <PrivacyControl removeClass={true} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Terms;
