import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useGoogleLogin } from "@react-oauth/google";

import Logocombo from "../../assets/images/logos/logo-lg.svg";
import avatar from "../../assets/images/avatar.jpg";
import Divider from "../../assets/images/Divider.svg";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";
import { IoMail } from "react-icons/io5";
import { BsFillPersonFill } from "react-icons/bs";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { authActions } from "./actions";
import { success } from "../../app/utilities/helpers/";
import LoadingSpinner from "../../components/commons/LoadingSpinner";
import "./LoginPage.css";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { logout } = useParams();
  const { loading, user } = useSelector((state) => state.auth);
  const [eye, setEye] = useState(false);
  const [confirmEye, setConfirmEye] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [formShow, setFormShow] = useState(true);
  const [initialValues, setInitialValues] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (logout) {
      dispatch(success(undefined, "LOGOUT_SUCCESS"));
      setTimeout(function () {
        navigate("/login");
      }, 100);
    } else if (Object.keys(user).length > 0) {
      navigate("/hub");
    }
  }, [user, navigate]); // eslint-disable-line

  const FormSchema = Yup.object().shape({
    username: isSignup
      ? Yup.string().required("Please enter your username")
      : null,
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter you Email"),
    password: Yup.string()
      .required("Please enter password")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .max(16, "Use max of 16 characters")
      .matches(
        /[^a-zA-Z0-9]/,
        "Password should have atleast 1 special character"
      )
      .matches(/[A-Z]/, "Password should have atleast 1 Upper case character")
      .matches(/[a-z]/, "Password should have atleast 1 Lower case character")
      .matches(/[0-9]/, "Password should have atleast 1 Number character"),
    confirmPassword: isSignup
      ? Yup.string()
          .required("Please enter confirm password")
          .when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              "Both password need to be the same"
            ),
          })
      : null,
  });

  const handleSubmit = (e) => {
    const trimmedPassword = e.password.trim();
    const trimmedUsername = e.username.trim();
    const trimmedConfirmPassword = e.confirmPassword.trim();
    const payload = {
      user: {
        email: e.email,
        password: trimmedPassword,
      },
    };
    if (isSignup) {
      if (
        trimmedPassword.length &&
        trimmedConfirmPassword.length &&
        trimmedPassword === trimmedConfirmPassword
      ) {
        payload.user.username = trimmedUsername;
        return dispatch(authActions.handleSignup(payload));
      }
    }
    return dispatch(authActions.handleLogin(payload));
  };

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        if (isSignup) {
          dispatch(
            authActions.handleGoogleAuthSignup({
              token: response.access_token,
            })
          );
        } else {
          dispatch(
            authActions.handleGoogleAuthLogin({
              token: response.access_token,
              code: response.code,
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    },
    // flow: "auth-code",
  });

  const togglePasswordVisible = () => setEye((prevState) => !prevState);
  const toggleConfirmPasswordVisible = () =>
    setConfirmEye((prevState) => !prevState);

  const toSignupPage = () => {
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setFormShow(false);
  };

  useEffect(() => {
    if (!formShow) {
      setInitialValues({
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
      });
      setFormShow(true);
    }
  }, [formShow]);

  return (
    <div
      className="grid md:grid-cols-2 grid-cols-1  fixed inset-0 w-full bg-white overflow-y-scroll p-4"
      style={{ margin: "0px!important" }}
    >
      <div className="grid grid-cols-1 px-4">
        <div className="login_logo">
          <div className="mb-4">
            <img src={Logocombo} alt="Logocombo.svg" className="" />
          </div>

          <div className="px-6 py-3 w-full grid">
            <span className="text-4xl font-bold text-uberark-blue mb-8">
              {isSignup ? "Sign Up" : "Login"}
            </span>

            <div>
              <div className="floating-form flex flex-col gap-2">
                {formShow ? (
                  <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={FormSchema}
                  >
                    {({ errors, touched, setFieldValue, values }) => (
                      <Form>
                        <div className={isSignup ? "floating-label" : "hidden"}>
                          <Field
                            className="floating-input h-14 pl-2 text-lg text-uberark-blue"
                            name="username"
                            id="username"
                            type="text"
                          />
                          {errors.username && touched.username && (
                            <div className="text-red-600">
                              {errors.username}
                            </div>
                          )}
                          <span className="absolute top-[18px] right-5">
                            <BsFillPersonFill
                              size={20}
                              className="text-[#666666]"
                            />
                          </span>
                          <label className="loginLable">
                            Username <span className="text-red-500">*</span>
                          </label>
                        </div>

                        <div className="floating-label">
                          <Field
                            className="floating-input h-14 pl-2 text-lg text-uberark-blue"
                            name="email"
                            id="email"
                            type="email"
                          />
                          {errors.email && touched.email && (
                            <div className="text-red-600">{errors.email}</div>
                          )}
                          <span className="absolute top-[18px] right-5">
                            <IoMail size={20} className="text-[#666666]" />
                          </span>
                          <label className="loginLable">
                            E-mail <span className="text-red-500">*</span>
                          </label>
                        </div>

                        <div className="floating-label">
                          <Field
                            className="floating-input h-14 pl-2 text-lg text-uberark-blue"
                            name="password"
                            id="password"
                            type={eye ? "text" : "password"}
                          />
                          {errors.password && touched.password && (
                            <div className="text-red-600">
                              {errors.password}
                            </div>
                          )}
                          <button
                            className="absolute top-[18px] right-5"
                            onClick={togglePasswordVisible}
                            type="button"
                          >
                            {eye ? (
                              <FiEyeOff size={20} className="text-[#666666]" />
                            ) : (
                              <FiEye size={20} className="text-[#666666]" />
                            )}
                          </button>
                          <label className="loginLable">
                            Password <span className="text-red-500">*</span>
                          </label>
                        </div>
                        <div className={isSignup ? "floating-label" : "hidden"}>
                          <Field
                            className="floating-input h-14 pl-2 text-lg text-uberark-blue"
                            name="confirmPassword"
                            id="confirmPassword"
                            type={confirmEye ? "text" : "password"}
                          />
                          {errors.confirmPassword &&
                            touched.confirmPassword && (
                              <div className="text-red-600">
                                {errors.confirmPassword}
                              </div>
                            )}
                          <button
                            className="absolute top-[18px] right-5"
                            onClick={toggleConfirmPasswordVisible}
                            type="button"
                          >
                            {confirmEye ? (
                              <FiEyeOff size={20} className="text-[#666666]" />
                            ) : (
                              <FiEye size={20} className="text-[#666666]" />
                            )}
                          </button>
                          <label className="loginLable">
                            Confirm Password{" "}
                            <span className="text-red-500">*</span>
                          </label>
                        </div>

                        {!isSignup && (
                          <div className="flex justify-end items-center w-full text-uberark-blue mb-4">
                            <Link to="/forgotpassword">Forgot Password</Link>
                          </div>
                        )}

                        <button
                          type="submit"
                          className="text-white h-16 bg-uberark-blue w-full rounded"
                          disabled={loading}
                        >
                          {loading ? (
                            <LoadingSpinner />
                          ) : isSignup ? (
                            "Sign Up"
                          ) : (
                            "Login"
                          )}
                        </button>
                      </Form>
                    )}
                  </Formik>
                ) : null}
              </div>
            </div>

            <button
              onClick={toSignupPage}
              className="text-uberark-blue text-lg font-medium mt-4"
            >
              {" "}
              {isSignup
                ? "Already have an account? Sign In "
                : "Don't have an account? Sign Up"}
            </button>

            <div>
              <img src={Divider} alt="Divider.svg" className="w-full " />
              <button className="GloginBtn" onClick={login}>
                <FcGoogle size={20} />
                <span className="GloginTxt">
                  {isSignup ? "Signup" : "Login"} with Google
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 greeting w-full">
        <div className="greetingArea flex flex-col gap-6">
          <span
            className="text-[#1a3447] md:text-6xl text-5xl font-bold"
            component="div"
          >
            Welcome to <br></br>Uberark
          </span>
          <span className="text-[#1A3447B2] font-medium text-base">
            Engage your customer, while working from anywhere! Collaborate
            smartly, track results.
          </span>

          <div className="flex flex-col md:flex-row gap-2">
            <div>
              <div className="mt-3 flex -space-x-2 overflow-hidden">
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={avatar}
                  alt="{avatar}"
                />
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={avatar}
                  alt="{avatar}"
                />
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={avatar}
                  alt="{avatar}"
                />
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={avatar}
                  alt="{avatar}"
                />
              </div>
              <div className="mt-3 text-sm font-medium">
                <a
                  href="##"
                  className="text-uberark-blue font-medium text-base"
                >
                  3k+ agents have joined us, now it’s your turn!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
