import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Logocombo from "../../assets/images/logos/logo-lg.svg";
import avatar from "../../assets/images/avatar.jpg";
import { IoMail } from "react-icons/io5";
import { authActions } from "./actions";
import LoadingSpinner from "../../components/commons/LoadingSpinner";
import "./LoginPage.css";

function ForgotPassword() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const FormSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter you Email"),
  });

  const handleSubmit = (e) => {
    const trimmedEmail = e.email.trim();
    return dispatch(authActions.handleForgotPassword({ email: trimmedEmail }));
  };

  return (
    <div
      className="grid md:grid-cols-2 grid-cols-1 fixed inset-0 w-full bg-white overflow-y-scroll p-4"
      style={{ margin: "0px!important" }}
    >
      <div className="grid grid-cols-1 px-4">
        <div className="login_logo">
          <div className="mb-4">
            <img src={Logocombo} alt="Logocombo.svg" className="" />
          </div>
          <div className="px-6 py-3 w-full grid">
            <span className="text-4xl font-bold text-uberark-blue mb-8">
              Forgot Password
            </span>
            <div>
              <div className="floating-form flex flex-col gap-2">
                <Formik
                  initialValues={{ email: "" }}
                  onSubmit={handleSubmit}
                  validationSchema={FormSchema}
                >
                  {({ errors, touched, setFieldValue, values }) => (
                    <Form>
                      <div className="floating-label">
                        <Field
                          className="floating-input h-14 pl-2 text-lg text-uberark-blue"
                          name="email"
                          id="email"
                          type="email"
                        />
                        {errors.email && touched.email && (
                          <div className="text-red-600">{errors.email}</div>
                        )}
                        <span className="absolute top-[18px] right-5">
                          <IoMail size={20} className="text-[#666666]" />
                        </span>
                        <label className="loginLable">
                          E-mail <span className="text-red-500">*</span>
                        </label>
                      </div>

                      <button
                        type="submit"
                        className="text-white h-16 bg-uberark-blue w-full rounded"
                        disabled={loading}
                      >
                        {loading ? <LoadingSpinner /> : "Send Code"}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="noacc text-lg mt-2">
              Already have an account?
              <Link to="/login" className="text-uberark-blue">
                {" "}
                Log In
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 greeting w-full">
        <div className="greetingArea flex flex-col gap-6">
          <span
            className="text-[#1a3447] md:text-6xl text-5xl font-bold"
            component="div"
          >
            Welcome to <br></br>Uberark
          </span>
          <span className="text-[#1A3447B2] font-medium text-base">
            Engage your customer, while working from anywhere! Collaborate
            smartly, track results.
          </span>

          <div className="flex flex-col md:flex-row gap-2">
            <div>
              <div className="mt-3 flex -space-x-2 overflow-hidden">
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={avatar}
                  alt="{avatar}"
                />
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={avatar}
                  alt="{avatar}"
                />
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={avatar}
                  alt="{avatar}"
                />
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={avatar}
                  alt="{avatar}"
                />
              </div>
              <div className="mt-3 text-sm font-medium">
                <a
                  href="##"
                  className="text-uberark-blue font-medium text-base"
                >
                  3k+ agents have joined us, now it’s your turn!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
