import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Logocombo from "../../assets/images/logos/logo-lg.svg";
import avatar from "../../assets/images/avatar.jpg";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { authActions } from "./actions";
import LoadingSpinner from "../../components/commons/LoadingSpinner";
import "./LoginPage.css";

function ResetPassword() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const [eye1, setEye1] = useState(false);
  const [eye2, setEye2] = useState(false);

  const FormSchema = Yup.object().shape({
    password: Yup.string()
      .required("Please enter password")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .max(16, "Use max of 16 characters")
      .matches(
        /[^a-zA-Z0-9]/,
        "Password should have atleast 1 special character"
      )
      .matches(/[A-Z]/, "Password should have atleast 1 Upper case character")
      .matches(/[a-z]/, "Password should have atleast 1 Lower case character")
      .matches(/[0-9]/, "Password should have atleast 1 Number character")
      .required("Please enter your Password"),
    repeatPassword: Yup.string()
      .required("Please enter password")
      .min(8, "Use 8 or more characters")
      .max(16, "Use max of 16 characters")
      .required("Please confirm Password")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const handleSubmit = (e) => {
    const url = window.location.href;
    const token =
      url.split("/").at(-2) + "/" + url.substr(url.lastIndexOf("/") + 1);
    const trimmedPassword = e.password.trim();
    const payload = {
      password: { password: trimmedPassword },
      token,
    };
    return dispatch(authActions.handleResetPassword(payload));
  };

  return (
    <div
      className="grid md:grid-cols-2 grid-cols-1  fixed inset-0 w-full bg-white overflow-y-scroll p-4"
      style={{ margin: "0px!important" }}
    >
      <div className="grid grid-cols-1 px-4">
        <div className="login_logo">
          <div className="mb-4">
            <img src={Logocombo} alt="Logocombo.svg" className="" />
          </div>
          <div className="h-4/6 flex items-center">
            <div className="px-6 py-3 w-full grid">
              <span className="text-4xl font-bold text-uberark-blue mb-8">
                Reset Password
              </span>

              <div>
                <div className="floating-form flex flex-col gap-2">
                  <Formik
                    initialValues={{ password: "", repeatPassword: "" }}
                    onSubmit={handleSubmit}
                    validationSchema={FormSchema}
                  >
                    {({ errors, touched, setFieldValue, values }) => (
                      <Form>
                        <div className="floating-label">
                          <Field
                            className="floating-input h-14 pl-2 text-lg text-uberark-blue"
                            name="password"
                            id="password"
                            type={eye1 ? "text" : "password"}
                          />
                          {errors.password && touched.password && (
                            <div className="text-red-600">
                              {errors.password}
                            </div>
                          )}
                          <button
                            className="absolute top-[18px] right-5"
                            onClick={() => setEye1(!eye1)}
                            type="button"
                          >
                            {eye1 ? (
                              <FiEyeOff size={20} className="text-[#666666]" />
                            ) : (
                              <FiEye size={20} className="text-[#666666]" />
                            )}
                          </button>
                          <label className="loginLable">
                            Password<span className="text-red-500">*</span>
                          </label>
                        </div>

                        <div className="floating-label">
                          <Field
                            className="floating-input h-14 pl-2 text-lg text-uberark-blue"
                            name="repeatPassword"
                            id="repeatPassword"
                            type={eye2 ? "text" : "password"}
                          />
                          {errors.repeatPassword && touched.repeatPassword && (
                            <div className="text-red-600">
                              {errors.repeatPassword}
                            </div>
                          )}
                          <button
                            className="absolute top-[18px] right-5"
                            onClick={() => setEye2(!eye2)}
                            type="button"
                          >
                            {eye2 ? (
                              <FiEyeOff size={20} className="text-[#666666]" />
                            ) : (
                              <FiEye size={20} className="text-[#666666]" />
                            )}
                          </button>
                          <label className="loginLable">
                            Confirm Password
                            <span className="text-red-500">*</span>
                          </label>
                        </div>

                        <button
                          type="submit"
                          className="text-white h-16 bg-uberark-blue w-full rounded"
                          disabled={loading}
                        >
                          {loading ? <LoadingSpinner /> : "Reset password"}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 greeting w-full">
        <div className="greetingArea flex flex-col gap-6">
          <span
            className="text-[#1a3447] md:text-6xl text-5xl font-bold"
            component="div"
          >
            Welcome to <br></br>Uberark
          </span>
          <span className="text-[#1A3447B2] font-medium text-base">
            Engage your customer, while working from anywhere! Collaborate
            smartly, track results.
          </span>

          <div className="flex flex-col md:flex-row gap-2">
            <div>
              <div className="mt-3 flex -space-x-2 overflow-hidden">
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={avatar}
                  alt="{avatar}"
                />
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={avatar}
                  alt="{avatar}"
                />
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={avatar}
                  alt="{avatar}"
                />
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={avatar}
                  alt="{avatar}"
                />
              </div>
              <div className="mt-3 text-sm font-medium">
                <a
                  href="##"
                  className="text-uberark-blue font-medium text-base"
                >
                  3k+ agents have joined us, now it’s your turn!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
