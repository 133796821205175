import React, { useState, useEffect, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { PopUpDelete, Slider } from "../../components";
import GroupAdd from "./components/GroupAdd";
import GroupEdit from "./components/GroupEdit";
import FileTable from "./components/FileTable.jsx";
import FileCard from "./components/FileCard.jsx";
import AssetAdd from "../commons/Asset/components/AssetAdd";
import StreamEdit from "./StreamEdit";
import { Menu, Transition } from "@headlessui/react";
import {
  BsChevronLeft,
  BsPlus,
  BsFillGrid1X2Fill,
  BsTable,
  BsThreeDots,
} from "react-icons/bs";
import {
  RiDeleteBinLine,
  RiAddCircleFill,
  RiEditCircleFill,
  RiCloseCircleFill,
} from "react-icons/ri";
import { FaCut } from "react-icons/fa";
import { FiInfo } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";

import { streamActions } from "./actions";
import { collectionActions } from "../Collections/actions";
import avatarPlaceholder from "../../assets/images/avatar-placeholder.png";
import no_content from "../../assets/images/no_content.svg";
import "./stream.css";

const Stream = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id: streamId } = useParams();
  const { previewStream, streamUpdate, showStream } = useSelector(
    (state) => state.stream
  );
  const { user } = useSelector((state) => state.auth);

  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [streamData, setStreamData] = useState({});
  const [assetsAndGroupData, setAssetsAndGroupData] = useState([]);
  const [allStreamAssetsCount, setAllStreamAssetsCount] = useState(0);
  const [addGroup, setAddGroup] = useState(false);
  const [editGroup, setEditGroup] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState();
  const [openGroup, setOpenGroup] = useState({});
  const [addAsset, setAddAsset] = useState(false);
  const [toggleGridView, setToggleGridView] = useState(false);
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [followUnfollow, setFollowUnfollow] = useState(false);
  const [currentGroupId, setCurrentGroupId] = useState(
    previewStream && previewStream.groupId ? previewStream.groupId : null
  );
  const [collaboratorPermission, setCollaboratorPermission] = useState(
    user && user.organization_admin ? user.organization_admin : false
  );
  const [moveAsset, setMoveAsset] = useState(false);
  const [editStream, setEditStream] = useState(false);
  const [showTableStream, setTableStream] = useState(showStream);
  const [streamPreviewBg, setStreamPreviewBg] = useState("");

  useEffect(() => {
    if (user.organization) {
      // get stream and collections
      dispatch(collectionActions.handleGetAllCollection(user.organization));
      dispatch(streamActions.handleGetAllStream(user.organization));
      // get active stream
      dispatch(streamActions.handleGetStreamPreview(streamId, currentGroupId));
    }
  }, [user.organization, streamId, dispatch, currentGroupId]);

  // get stream data
  useEffect(() => {
    if (previewStream && Object.keys(previewStream).length && showStream) {
      setTableStream(false);
      // filter stream by id
      const openStream = previewStream;
      setStreamPreviewBg(previewStream.cover_image);
      setStreamData((prevState) => openStream);
      if (openStream.group && openStream.group.length) {
        let cgi =
          previewStream && previewStream.groupId
            ? previewStream.groupId
            : currentGroupId;
        if (cgi) {
          let groupData = openStream?.group.filter(
            (item) => item.id === cgi
          )[0];
          if (groupData !== undefined) {
            setOpenGroup((prevState) => groupData);
          } else {
            setOpenGroup((prevState) => openStream?.group[0]);
          }
        } else {
          setOpenGroup((prevState) => openStream?.group[0]);
        }
      }
      if (openStream?.created_by.id === user.id) {
        if (!user.organization_admin) {
          setCollaboratorPermission(true);
        }
      } else {
        if (openStream.collaborators && openStream.collaborators.length > 0) {
          let d = openStream.collaborators?.filter(
            (item) => item.user_id === user.id
          );
          if (!user.organization_admin) {
            setCollaboratorPermission(d.length ? true : false);
          }
        } else {
          if (!user.organization_admin) {
            setCollaboratorPermission(false);
          }
        }
      }
    }
  }, [previewStream, currentGroupId, showStream]); // eslint-disable-line

  useEffect(() => {
    if (streamUpdate) {
      setTimeout(() => {
        dispatch(streamActions.handleGetStreamPreview(streamId));
      }, 1000);
    }
  }, [streamUpdate, streamId, dispatch]);

  // get all assets in groups
  useEffect(() => {
    if (streamData?.group && openGroup) {
      // get child groups in this openGroup
      const groups = streamData?.group.filter(
        (item) => item.parent_group === openGroup.id && !item.deleted
      );

      // get total stream asset count
      let assetsNumber = 0;
      streamData?.group.map(
        (item) =>
          (assetsNumber =
            assetsNumber + item.asset.filter((asset) => !asset.deleted).length)
      );
      setAllStreamAssetsCount(assetsNumber);

      // create asset & groups array for files card/table data
      let assetsAndGroupsCombined = [];
      if (
        openGroup &&
        openGroup.asset &&
        openGroup.asset.length &&
        groups.length
      ) {
        assetsAndGroupsCombined = [...openGroup.asset, ...groups];
      } else {
        if (openGroup && openGroup.asset && openGroup.asset.length) {
          assetsAndGroupsCombined = [...openGroup.asset];
        }
        if (groups.length) {
          assetsAndGroupsCombined = [...groups];
        }
      }
      // sort groups and assets by created_at
      const sortedAssetsAndGroupsCombined = assetsAndGroupsCombined.sort(
        function (a, b) {
          let date1 = new Date(a.created_at).getTime();
          let date2 = new Date(b.created_at).getTime();
          if (date1 < date2) return 1;
          if (date1 > date2) return -1;
          return 0;
        }
      );

      const filteredAssetsAndGroupsCombined = [];
      sortedAssetsAndGroupsCombined.map((grouporasset) => {
        if (grouporasset.asset) {
          if (!grouporasset.deleted) {
            const groupItem = grouporasset;
            groupItem["asset"] = grouporasset.asset.filter(
              (asset) => !asset.deleted
            );
            filteredAssetsAndGroupsCombined.push(groupItem);
          }
        } else {
          if (!grouporasset.deleted) {
            filteredAssetsAndGroupsCombined.push(grouporasset);
          }
        }

        return true;
      });

      setAssetsAndGroupData(() => filteredAssetsAndGroupsCombined);
      setTimeout(() => {
        setTableStream(true);
      }, 1000);
    }
  }, [streamData, openGroup]);

  const handleGetAssetsAndGroups = (group) => {
    const groups = streamData?.group.filter(
      (item) => item.parent_group === group.id && !item.deleted
    );
    // create asset & groups array for files card/table data
    const assetsAndGroupsCombined = [...group.asset, ...groups];

    // sort groups and assets by created_at
    const sortedAssetsAndGroupsCombined = assetsAndGroupsCombined.sort(
      function (a, b) {
        let date1 = new Date(a.created_at).getTime();
        let date2 = new Date(b.created_at).getTime();
        if (date1 < date2) return 1;
        if (date1 > date2) return -1;
        return 0;
      }
    );

    const filteredAssetsAndGroupsCombined = [];
    sortedAssetsAndGroupsCombined.map((grouporasset) => {
      if (grouporasset.asset) {
        if (!grouporasset.deleted) {
          const groupItem = grouporasset;
          groupItem["asset"] = grouporasset.asset.filter(
            (asset) => !asset.deleted
          );
          filteredAssetsAndGroupsCombined.push(groupItem);
        }
      } else {
        if (!grouporasset.deleted) {
          filteredAssetsAndGroupsCombined.push(grouporasset);
        }
      }

      return true;
    });

    return filteredAssetsAndGroupsCombined;
  };

  const handleOnClose = () => setShowDeletePopUp(false);

  const onSubmitCallback = () => {
    navigate(`/streams`);
  };

  const handleDeleteStream = () => {
    return dispatch(
      streamActions.handleDeleteStream(streamId, onSubmitCallback)
    );
  };

  const handleToggleGridView = () => {
    setToggleGridView(() => !toggleGridView);
  };

  const handleOpenGroup = (groupId) => {
    const streamDataRef = streamData;
    const updatedOpenGroup = streamDataRef.group.filter(
      (item) => item.id === groupId
    )[0];
    setOpenGroup((prevState) => updatedOpenGroup);
  };

  const handleGroupEditClose = () => {
    setEditGroup(false);
    setSelectedGroup();
  };

  const handleAddAssetToggle = () => {
    setAddAsset(() => !addAsset);
  };

  const handleCreateAsset = (data) => {
    const payload = {
      ...data,
      group: openGroup.id,
      streamId: streamId,
    };
    setCurrentGroupId(openGroup.id);
    dispatch(streamActions.handleCreateAsset(payload, handleAddAssetToggle));
  };

  const handleAssetCopySubmit = (selectedStream, selectedCollection) => {
    const payload = [];
    if (selectedStream && selectedStream.length) {
      selectedStream.map((item) => {
        payload.push({
          id: item.id,
          group: openGroup.id,
          stream: streamId,
        });
        return true;
      });
    }
    if (selectedCollection && selectedCollection.length) {
      selectedCollection.map((item) => {
        payload.push({
          id: item.id,
          group: openGroup.id,
          stream: streamId,
        });
        return true;
      });
    }

    dispatch(streamActions.handleCopyAsset(payload, handleAddAssetToggle));

    setTimeout(function () {
      handleAddAssetToggle();
    }, 500);
  };

  const handleFollowUnFollow = () => {
    setFollowUnfollow(!followUnfollow);
    return dispatch(
      streamActions.handleFollowUnfollowStream({ stream_id: streamId })
    );
  };

  let followBtnText = "Follow";
  if (streamData?.followers !== undefined) {
    for (let follower of streamData.followers) {
      if (follower.id === user.id) {
        followBtnText = "Unfollow";
      } else {
        followBtnText = "Follow";
      }
    }
  }

  const handleOnAssetClick = (asset) => navigate(`/asset/${asset.id}`);

  const handleCollaborate = () => {
    navigate(`/collaborators`, {
      state: {
        streamId: streamId,
      },
    });
  };

  const handleMoveAssetToggle = () => {
    setMoveAsset(() => !moveAsset);
  };

  const handleMoveAssetSubmit = (selectedStream, selectedCollection) => {
    const payload = [];
    if (selectedStream && selectedStream.length) {
      selectedStream.map((item) => {
        payload.push({
          id: item.id,
          group: openGroup.id,
        });
        return true;
      });
    }

    if (selectedCollection && selectedCollection.length) {
      selectedCollection.map((item) => {
        payload.push({
          id: item.id,
          group: openGroup.id,
        });
        return true;
      });
    }

    dispatch(
      streamActions.handleMoveAsset(payload, handleMoveAssetToggle, streamId)
    );

    setTimeout(() => {
      handleMoveAssetToggle();
    }, 500);
  };

  const handleCloseAddGroupPopup = () => setAddGroup(false);

  const nLink =
    "flex items-center justify-center px-2 text-base py-0 text-[#000080] dark:text-gray-200 dark:hover:text-black hover:text-red-600 block text-sm";
  const aLink =
    "flex items-center justify-center px-2 text-base py-0 text-uberark-orange dark:text-gray-200 dark:hover:text-black hover:text-red-600 text-sm";

  return (
    <div className="relative">
      <div className="absolute sm:top-0 top-20 w-full h-full mb-5">
        {/* Head */}
        <div className="mt-5 sm:mt-15 mx-10 lg:mx-20 grid grid-flow-col justify-between bg-transparent">
          <div className="grid grid-flow-col justify-items-end items-center justify-center gap-4">
            <button
              onClick={() => navigate("/hub")}
              className="inline-block font-medium text-lg leading-tight hover:bg-white hover:shadow-lg focus:bg-white bg-[#ecf2ff] focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg hover:scale-105 ease-in-out duration-300 rounded-full p-3 "
              style={{ border: "0.24px solid #000080" }}
            >
              <BsChevronLeft className="text-[#FE4502] drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] " />
            </button>

            <button
              className="mr-4 text-gray-600  hover:text-[#000080] "
              onClick={() => navigate("/hub")}
            >
              Back
            </button>
          </div>

          {collaboratorPermission ? (
            <div className="grid grid-flow-col items-center justify-evenly divide-x divide-slate-500">
              <button
                className={editStream ? aLink : nLink}
                onClick={() => setEditStream(!editStream)}
              >
                <RiEditCircleFill className="hover:scale-105 ease-in-out duration-300 mr-1" />
                Edit Stream
              </button>

              <button
                className={nLink}
                onClick={() => setShowDeletePopUp(true)}
              >
                <RiDeleteBinLine className="hover:scale-105 ease-in-out duration-300 mr-1" />
                <span>Delete Stream</span>
              </button>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex justify-center rounded-full border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 ml-2">
                    <BsThreeDots />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-3">
                      <Menu.Item>
                        <div className="grid grid-flow-col gap-2 items-center justify-between px-5 py-2">
                          <button
                            className="relative flex items-center justify-end gap-1 hover:text-[#000080]"
                            onClick={() => handleCollaborate()}
                          >
                            <HiUserGroup
                              className="mx-1 text-base"
                              style={{
                                background: "#FE4502",
                                borderRadius: "5px",
                                padding: "3px",
                                color: "white",
                              }}
                            />
                            <span>Collaborate</span>
                          </button>
                          <span className="relative right-4">
                            {streamData && streamData.collaborators
                              ? streamData.collaborators.length
                              : 0}
                          </span>
                        </div>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          ) : null}
        </div>

        {/* stream ----------------------------------------------------------------------------------- */}
        {editStream ? (
          <StreamEdit
            imported={true}
            handleClose={() => {
              dispatch(
                streamActions.handleGetStreamPreview(streamId, currentGroupId)
              );
              setEditStream(false);
            }}
          />
        ) : (
          <div
            className={`relative rounded-3xl min-h-[370px] bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] m-4 shadow flex flex-wrap text-gray-600`}
            style={{
              backgroundImage:
                streamData &&
                streamData.cover_image_thumbnail &&
                String(streamData.cover_image_thumbnail).length
                  ? `url(${streamData.cover_image_thumbnail})`
                  : streamData.cover_image &&
                    String(streamData.cover_image).length
                  ? `url(${streamData.cover_image})`
                  : `url(${streamPreviewBg})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            {streamData &&
            streamData?.cover_image &&
            streamData?.cover_image.includes(".mp4") ? (
              <video
                className="absolute w-full h-full rounded-3xl"
                style={{ objectFit: "fill" }}
                controls={false}
                loop
                muted
                autoPlay
              >
                <source src={streamData?.cover_image} type="video/mp4" />
                <source src={streamData?.cover_image} type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            ) : null}

            <div
              className="flex flex-col items-center lg:flex-row md:justify-between w-full bg-[#fafafd4f] p-4 rounded-3xl"
              style={{
                background:
                  "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QgdBy0F1sAe4QAAACFJREFUCNdj/P//vy8DBNgzMDAwMDGgARaYDAMDw0GsKgD/0gSTo/gcFwAAAABJRU5ErkJggg==) repeat hsla(0,0%,100%,.4)",
                zIndex: 1,
              }}
            >
              <div className="flex sm:flex-row flex-col items-center gap-2">
                <div className="flex flex-col p-4 w-80 md:w-[550px] min-h-[166px] gap-2">
                  <div className="text-[#FE4502] font-bold text-2xl text-left">
                    {streamData?.title}
                  </div>
                  <div
                    className="e-stream"
                    dangerouslySetInnerHTML={{
                      __html: streamData?.description,
                    }}
                  />
                  <div>
                    <button
                      className="bg-[#000080] flex font-bold py-2 px-4 text-white hover:bg-white hover:text-[#000080] hover:drop-shadow-lg hover:scale-105 ease-in-out duration-200 rounded-2xl"
                      onClick={() => handleFollowUnFollow()}
                    >
                      {followBtnText}
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex gap-4 justify-center flex-wrap">
                <div className="flex flex-col items-center justify-center">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="flex flex-col items-center justify-center">
                        <span className="text-[#000080] font-bold">
                          {streamData?.followers_count}
                        </span>
                        Followers
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            <div className="flex flex-col m-1.5 max-h-[300px] overflow-scroll pl-2 pt-3">
                              {streamData?.followers !== undefined &&
                                streamData?.followers.map((follower, index) => (
                                  <div
                                    className="pb-2 flex item-center"
                                    key={index}
                                  >
                                    <img
                                      className="h-[25px] w-[25px] rounded-full mr-2"
                                      src={
                                        follower?.avatar
                                          ? follower.avatar
                                          : avatarPlaceholder
                                      }
                                      alt="stream follower"
                                      loading="lazy"
                                    />
                                    <div className="truncate">
                                      {follower?.username}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <span className="text-[#000080] font-bold">
                    {streamData?.views_count}
                  </span>
                  <span>Views</span>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <span className="text-[#000080] font-bold">
                    {streamData?.downloads_count}
                  </span>
                  <span>Downloads</span>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* files/assets head------------------------------------------------------------------------------------- */}
        <div className="text-black mt-5 mx-10 lg:mx-20 flex justify-between">
          <div className="flex font-semibold">
            {openGroup?.group_tree_index &&
              openGroup.group_tree_index !== "0" && (
                <>
                  <button
                    className={nLink}
                    onClick={() => handleOpenGroup(openGroup?.parent_group)}
                  >
                    {" "}
                    <BsChevronLeft />
                    Back{" "}
                  </button>
                  <div className="text-[#FE4502] pr-1 max-w-[200px] truncate">
                    {openGroup.name.toUpperCase()}
                  </div>
                </>
              )}
          </div>

          <div className="grid grid-flow-col justify-between">
            <div className="grid grid-flow-col justify-evenly cursor-pointer">
              <div
                className="grid grid-flow-col gap-4 cursor-pointer items-center justify-center ml-4 text-slate-600"
                onMouseDown={() => handleToggleGridView()}
              >
                <BsFillGrid1X2Fill
                  className={`
                    ${
                      !toggleGridView
                        ? "text-uberark-orange "
                        : "text-[#000080] "
                    }
                    font-semibold text-base dark:text-gray-200 dark:hover:text-black hover:text-red-600`}
                />
                <BsTable
                  className={`
                    ${
                      toggleGridView
                        ? "text-uberark-orange "
                        : "text-[#000080] "
                    }
                    font-semibold text-base dark:text-gray-200 dark:hover:text-black hover:text-red-600`}
                />
              </div>
            </div>
          </div>
        </div>

        {/* files/assets body -------------------------------------------------------------------------------------*/}
        {toggleGridView ? (
          <div className="rounded-3xl bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] m-4 p-4 shadow flex flex-wrap text-gray-600 pb-10">
            <div className="text-black mt-5 mx-5 lg:mx-5 w-full">
              <div className="grid grid-flow-col justify-between">
                <div className="grid grid-flow-col max-w-sm justify-center items-center gap-2 divide-x-2 text-slate-600 divide-slate-500 cursor-pointer">
                  <div className="grid justify-center hover:text-[#000080] drop-shadow-lg text-sm">
                    {streamData?.group && streamData?.group.length - 1} Group(s){" "}
                  </div>
                  <div className="grid justify-center hover:text-[#000080] px-2 drop-shadow-lg text-sm">
                    {allStreamAssetsCount} Asset(s){" "}
                  </div>
                </div>

                <div className="grid grid-flow-col justify-evenly cursor-pointer">
                  {collaboratorPermission ? (
                    <div className="rid grid-flow-col justify-center items-center px-2 divide-x divide-slate-500 text-base tableLinks">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="inline-flex justify-center items-center rounded border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 ml-2">
                            <BsThreeDots className="mr-1" />
                            Actions
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-3">
                              <Menu.Item>
                                <button
                                  className={`${
                                    addGroup ? aLink : nLink
                                  } hover:text-[#000080] drop-shadow-lg flex items-center px-5 py-2 text-slate-600`}
                                  onClick={() => {
                                    setAddGroup(true);
                                    setDeleteToggle(false);
                                    setAddAsset(false);
                                    setEditGroup(false);
                                    setMoveAsset(false);
                                  }}
                                >
                                  <RiAddCircleFill
                                    className="ml-2 mr-2 text-base"
                                    style={{
                                      background: "#FE4502",
                                      borderRadius: "5px",
                                      padding: "3px",
                                      color: "white",
                                    }}
                                  />
                                  Add Group{" "}
                                </button>
                              </Menu.Item>
                              <hr className="my-2" />
                              <Menu.Item>
                                <button
                                  className={`${
                                    addAsset ? aLink : nLink
                                  } hover:text-[#000080] drop-shadow-lg flex items-center px-5 py-2 text-slate-600`}
                                  onClick={() => {
                                    setAddAsset(!addAsset);
                                    setDeleteToggle(false);
                                    setEditGroup(false);
                                    setAddGroup(false);
                                    setCurrentGroupId(openGroup.id);
                                  }}
                                >
                                  <RiAddCircleFill
                                    className="ml-2 mr-2 text-base"
                                    style={{
                                      background: "#FE4502",
                                      borderRadius: "5px",
                                      padding: "3px",
                                      color: "white",
                                    }}
                                  />
                                  Add Asset{" "}
                                </button>
                              </Menu.Item>
                              <Menu.Item>
                                <button
                                  className={`${nLink} hover:text-[#000080] drop-shadow-lg flex items-center px-5 py-2 text-slate-600`}
                                  onClick={() => {
                                    setMoveAsset(!moveAsset);
                                    setAddAsset(false);
                                    setDeleteToggle(false);
                                    setEditGroup(false);
                                    setAddGroup(false);
                                  }}
                                >
                                  <FaCut
                                    className="ml-2 mr-2 text-base"
                                    style={{
                                      background: "#FE4502",
                                      borderRadius: "5px",
                                      padding: "3px",
                                      color: "white",
                                    }}
                                  />
                                  Move Asset{" "}
                                </button>
                              </Menu.Item>
                              <hr className=" my-2" />
                              <Menu.Item>
                                <button
                                  className={
                                    editGroup
                                      ? aLink + " px-5 py-2"
                                      : `${nLink} hover:text-[#000080] drop-shadow-lg flex items-center px-5 py-2 text-slate-600`
                                  }
                                  onClick={() => {
                                    setEditGroup(!editGroup);
                                    setDeleteToggle(false);
                                    setAddAsset(false);
                                    setAddGroup(false);
                                    setMoveAsset(false);
                                  }}
                                >
                                  <RiEditCircleFill
                                    className="ml-2 mr-2 text-base"
                                    style={{
                                      background: "#FE4502",
                                      borderRadius: "5px",
                                      padding: "3px",
                                      color: "white",
                                    }}
                                  />
                                  Rename{" "}
                                </button>
                              </Menu.Item>
                              <Menu.Item>
                                <button
                                  className={
                                    deleteToggle
                                      ? aLink + " px-5 py-2"
                                      : `${nLink} hover:text-[#000080] drop-shadow-lg flex items-center px-5 py-2 text-slate-600`
                                  }
                                  onClick={() => {
                                    setDeleteToggle(!deleteToggle);
                                    setEditGroup(false);
                                    setAddAsset(false);
                                    setAddGroup(false);
                                    setCurrentGroupId(openGroup.id);
                                    setMoveAsset(false);
                                  }}
                                >
                                  <RiCloseCircleFill
                                    className="ml-2 mr-2 text-base"
                                    style={{
                                      background: "#FE4502",
                                      borderRadius: "5px",
                                      padding: "3px",
                                      color: "white",
                                    }}
                                  />
                                  Delete{" "}
                                </button>
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <FileTable
              assetsAndGroupData={assetsAndGroupData}
              deleteToggle={deleteToggle}
              setDeleteToggle={setDeleteToggle}
              editGroup={editGroup}
              setEditGroup={setEditGroup}
              setSelectedGroup={setSelectedGroup}
              handleOpenGroup={handleOpenGroup}
              onAssetClick={handleOnAssetClick}
              collaboratorPermission={collaboratorPermission}
              handleGetAssetsAndGroups={handleGetAssetsAndGroups}
            />
          </div>
        ) : (
          <>
            {/* assets  ----------------------------------------------------------------------------- */}
            <div className="rounded-3xl bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] m-4 p-4 shadow flex flex-wrap text-gray-600 pb-10">
              <div className="text-black mt-5 mx-5 lg:mx-5 w-full">
                <div className="grid grid-flow-col justify-between">
                  <div className="grid grid-flow-col max-w-sm justify-center items-center gap-2 divide-x-2 text-slate-600 divide-slate-500">
                    <div className="grid justify-center hover:text-[#000080] drop-shadow-lg text-sm">
                      {streamData?.group && streamData?.group.length - 1}{" "}
                      Group(s){" "}
                    </div>
                    <div className="grid justify-center hover:text-[#000080] px-2 drop-shadow-lg text-sm">
                      {allStreamAssetsCount} Asset(s){" "}
                    </div>
                  </div>

                  <div className="grid grid-flow-col justify-evenly cursor-pointer">
                    {collaboratorPermission ? (
                      <div className="rid grid-flow-col justify-center items-center px-2 divide-x divide-slate-500 text-base tableLinks">
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="inline-flex justify-center items-center rounded border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 ml-2">
                              <BsThreeDots className="mr-1" />
                              Actions
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-3">
                                <Menu.Item>
                                  <button
                                    className={`${
                                      addGroup ? aLink : nLink
                                    } hover:text-[#000080] drop-shadow-lg flex items-center px-5 py-2 text-slate-600`}
                                    onClick={() => {
                                      setAddGroup(true);
                                      setDeleteToggle(false);
                                      setAddAsset(false);
                                      setEditGroup(false);
                                      setMoveAsset(false);
                                    }}
                                  >
                                    <RiAddCircleFill
                                      className="ml-2 mr-2 text-base"
                                      style={{
                                        background: "#FE4502",
                                        borderRadius: "5px",
                                        padding: "3px",
                                        color: "white",
                                      }}
                                    />
                                    Add Group{" "}
                                  </button>
                                </Menu.Item>
                                <hr className="my-2" />
                                <Menu.Item>
                                  <button
                                    className={`${
                                      addAsset ? aLink : nLink
                                    } hover:text-[#000080] drop-shadow-lg flex items-center px-5 py-2 text-slate-600`}
                                    onClick={() => {
                                      setAddAsset(!addAsset);
                                      setDeleteToggle(false);
                                      setEditGroup(false);
                                      setAddGroup(false);
                                      setCurrentGroupId(openGroup.id);
                                    }}
                                  >
                                    <RiAddCircleFill
                                      className="ml-2 mr-2 text-base"
                                      style={{
                                        background: "#FE4502",
                                        borderRadius: "5px",
                                        padding: "3px",
                                        color: "white",
                                      }}
                                    />
                                    Add Asset{" "}
                                  </button>
                                </Menu.Item>
                                <Menu.Item>
                                  <button
                                    className={`${nLink} hover:text-[#000080] drop-shadow-lg flex items-center px-5 py-2 text-slate-600`}
                                    onClick={() => {
                                      setMoveAsset(!moveAsset);
                                      setAddAsset(false);
                                      setDeleteToggle(false);
                                      setEditGroup(false);
                                      setAddGroup(false);
                                    }}
                                  >
                                    <FaCut
                                      className="ml-2 mr-2 text-base"
                                      style={{
                                        background: "#FE4502",
                                        borderRadius: "5px",
                                        padding: "3px",
                                        color: "white",
                                      }}
                                    />
                                    Move Asset{" "}
                                  </button>
                                </Menu.Item>
                                <hr className=" my-2" />
                                <Menu.Item>
                                  <button
                                    className={
                                      editGroup
                                        ? aLink + " px-5 py-2"
                                        : `${nLink} hover:text-[#000080] drop-shadow-lg flex items-center px-5 py-2 text-slate-600`
                                    }
                                    onClick={() => {
                                      setEditGroup(!editGroup);
                                      setDeleteToggle(false);
                                      setAddAsset(false);
                                      setAddGroup(false);
                                      setMoveAsset(false);
                                    }}
                                  >
                                    <RiEditCircleFill
                                      className="ml-2 mr-2 text-base"
                                      style={{
                                        background: "#FE4502",
                                        borderRadius: "5px",
                                        padding: "3px",
                                        color: "white",
                                      }}
                                    />
                                    Rename{" "}
                                  </button>
                                </Menu.Item>
                                <Menu.Item>
                                  <button
                                    className={
                                      deleteToggle
                                        ? aLink + " px-5 py-2"
                                        : `${nLink} hover:text-[#000080] drop-shadow-lg flex items-center px-5 py-2 text-slate-600`
                                    }
                                    onClick={() => {
                                      setDeleteToggle(!deleteToggle);
                                      setEditGroup(false);
                                      setAddAsset(false);
                                      setAddGroup(false);
                                      setCurrentGroupId(openGroup.id);
                                      setMoveAsset(false);
                                    }}
                                  >
                                    <RiCloseCircleFill
                                      className="ml-2 mr-2 text-base"
                                      style={{
                                        background: "#FE4502",
                                        borderRadius: "5px",
                                        padding: "3px",
                                        color: "white",
                                      }}
                                    />
                                    Delete{" "}
                                  </button>
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="file-card__container flex-wrap w-full">
                {assetsAndGroupData && assetsAndGroupData.length === 0 ? (
                  <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10 mt-5">
                    <div className="w-full flex justify-center">
                      <img
                        src={no_content}
                        alt="nothing to show illustration"
                        style={{ height: "60px" }}
                        loading="lazy"
                      />
                    </div>
                    <div className="text-center relative p-5 text-[#757575] text-base flex items-center">
                      Nothing to show{" "}
                      <FiInfo className="text-uberark-orange ml-1" />
                    </div>

                    <button
                      className="flex items-center justify-center py-1 px-5 cursor-pointer opacity-80 hover:scale-105 ease-in-out duration-300 border rounded"
                      onClick={() => {
                        setAddAsset(!addAsset);
                        setDeleteToggle(false);
                        setEditGroup(false);
                        setAddGroup(false);
                        setCurrentGroupId(openGroup.id);
                      }}
                    >
                      {" "}
                      <BsPlus />
                      Add Asset{" "}
                    </button>
                  </div>
                ) : (
                  <>
                    <div
                      className="mt-2 w-155px h-[155px] md:h-[155px] md:w-[155px]
                inline-grid items-center justify-center p-2 cursor-pointer
                opacity-80 hover:scale-105 ease-in-out duration-300
                rounded-[20px]"
                      style={{ border: "0.25px solid #000080" }}
                      onMouseDown={() => {
                        setAddAsset(!addAsset);
                        setDeleteToggle(false);
                        setEditGroup(false);
                        setAddGroup(false);
                        setCurrentGroupId(openGroup.id);
                      }}
                    >
                      <button
                        onClick={() => {
                          setAddAsset(!addAsset);
                          setDeleteToggle(false);
                          setEditGroup(false);
                          setAddGroup(false);
                          setCurrentGroupId(openGroup.id);
                        }}
                      >
                        <AiOutlinePlus size={70} className="text-[#FE4502]" />
                      </button>
                    </div>
                    <FileCard
                      assetsAndGroupData={assetsAndGroupData}
                      deleteToggle={deleteToggle}
                      setDeleteToggle={setDeleteToggle}
                      editGroup={editGroup}
                      setSelectedGroup={setSelectedGroup}
                      handleOpenGroup={handleOpenGroup}
                      onAssetClick={handleOnAssetClick}
                      fileHover={true}
                      cardLoader={showTableStream}
                    />
                  </>
                )}
              </div>
            </div>
          </>
        )}

        <Slider
          float="left"
          title=""
          visible={addAsset}
          onClose={handleAddAssetToggle}
          className="w-[50%]"
        >
          <AssetAdd
            handleModalClose={handleAddAssetToggle}
            handleAssetCopySubmit={handleAssetCopySubmit}
            handleAssetCreateSubmit={handleCreateAsset}
            openGroup={openGroup}
            stream={{
              id: streamId,
              groupId: openGroup?.id || null,
            }}
          />
        </Slider>

        <Slider
          float="left"
          title=""
          visible={moveAsset}
          onClose={handleMoveAssetToggle}
          className="w-[50%]"
        >
          <AssetAdd
            handleModalClose={handleMoveAssetToggle}
            handleMoveAssetSubmit={handleMoveAssetSubmit}
            handleMoveAssetClick={handleMoveAssetToggle}
            openGroup={openGroup}
          />
        </Slider>

        <PopUpDelete
          onClose={handleOnClose}
          visible={showDeletePopUp}
          itemName={streamData?.title}
          deleteFn={handleDeleteStream}
        />

        <GroupAdd
          groupId={openGroup?.id}
          streamId={streamId}
          handleCloseAddGroup={handleCloseAddGroupPopup}
          show={addGroup}
          treeIndex={
            openGroup && openGroup.group_tree_index
              ? parseInt(openGroup.group_tree_index) + 1
              : 1
          }
        />

        {editGroup && selectedGroup && (
          <GroupEdit
            group={
              streamData?.group.filter((item) => item.id === selectedGroup)[0]
            }
            setSelectedGroup={setSelectedGroup}
            show={editGroup}
            handleGroupEditClose={handleGroupEditClose}
          />
        )}
      </div>
    </div>
  );
};

export default Stream;
