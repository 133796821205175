import {
  handleResponse,
  axiosInstance,
  fileRequestConfig,
} from "../../../app/utilities/helpers";

const handleGetCollection = (queryUrl) =>
  axiosInstance.get(`${queryUrl}`).then(handleResponse);

const handleCreateCollection = (payload, templateId) => {
  // Initialize the form data
  const data = new FormData();
  if (payload.logo) {
    data.append("logo", payload.logo, payload.logo.name);
  }
  if (payload.banner) {
    data.append("banner", payload.banner, payload.banner.name);
  }
  data.append("name", payload.name);
  data.append("description", payload.description || "");

  payload.folder && data.append("folder", payload.folder);

  return axiosInstance
    .post(
      `${templateId ? `collections?template=${templateId}` : `collections`}`,
      data,
      fileRequestConfig
    )
    .then(handleResponse);
};

const handleEditCollection = (payload) => {
  // Initialize the form data
  const data = new FormData();
  if ("logo" in payload) {
    data.append("logo", payload.logo, payload.logo.name);
  }
  if ("banner" in payload) {
    data.append("banner", payload.banner, payload.banner.name);
  }
  if ("name" in payload) {
    data.append("name", payload.name);
  }
  if ("description" in payload) {
    data.append("description", payload.description);
  }
  if ("live_status" in payload) {
    data.append("live_status", payload.live_status);
  }
  if ("template_status" in payload) {
    data.append("template_status", payload.template_status);
  }
  if ("internal_use_only_status" in payload) {
    data.append("internal_use_only_status", payload.internal_use_only_status);
  }
  if ("promote" in payload) {
    data.append("promote", payload.promote);
  }

  return axiosInstance
    .put(`collections/${payload.id}`, data, fileRequestConfig)
    .then(handleResponse);
};

const handleDeleteCollection = (collectionId) =>
  axiosInstance.delete(`collections/${collectionId}`).then(handleResponse);

const handleCreateSection = (data) =>
  axiosInstance.post("sections", data).then(handleResponse);

const handleEditSection = (data) =>
  axiosInstance.put(`sections/${data.id}`, data).then(handleResponse);

const handleDeleteSection = (sectionId) =>
  axiosInstance.delete(`sections/${sectionId}`).then(handleResponse);

const handleCreateLink = (data) =>
  axiosInstance.post("links", data).then(handleResponse);

const handleDeleteLink = (linkId) =>
  axiosInstance.delete(`links/${linkId}`).then(handleResponse);

const handleEditLink = (data) =>
  axiosInstance.put(`links/${data.id}`, data).then(handleResponse);

const handleCreateFolder = (data) =>
  axiosInstance.post("folders", data).then(handleResponse);

const handleGetFolder = () => axiosInstance.get(`folders`).then(handleResponse);

const handleDeleteFolder = (folderId) =>
  axiosInstance.delete(`folders/${folderId}`).then(handleResponse);

const handleEditFolder = (data) =>
  axiosInstance.put(`folders/${data.id}`, data).then(handleResponse);

const handleAddCollaborators = async (payload) => {
  const res = await axiosInstance.post(`collectioncollaborators`, payload);
  return handleResponse(res);
};

const handleDeleteCollaborator = (collaboratorId) =>
  axiosInstance
    .delete(`collectioncollaborators/${collaboratorId}`)
    .then(handleResponse);

const handleCloneCollection = (id) =>
  axiosInstance.get(`collections/clone/${id}`).then(handleResponse);

const handleCreateUrl = (data) =>
  axiosInstance.post("asset", data).then(handleResponse);

export const collectionService = {
  handleGetCollection,
  handleCreateCollection,
  handleEditCollection,
  handleDeleteCollection,
  handleCreateSection,
  handleEditSection,
  handleDeleteSection,
  handleCreateLink,
  handleDeleteLink,
  handleEditLink,
  handleCreateFolder,
  handleGetFolder,
  handleDeleteFolder,
  handleEditFolder,
  handleAddCollaborators,
  handleDeleteCollaborator,
  handleCloneCollection,
  handleCreateUrl,
};
