import * as types from '../actions/actionTypes'

const initialState = {
  loading: false,
  collection: [],
  folders: [],
  generatedLink: '',
  message: '',
  errors: {},
  cloneCollection: false,
  deleteCollection: false,
  dltSection: false,
  dltFolder: false,
  collectionTree: [],
  totalCollectionPage: 1,
  previewCollection: {},
  showCollection: true,
  collectionUpdate: true,
  collectionTemplate: [],
  totalCollectionTemplatePage: 1
}

const collectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_COLLECTION_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
        errors: {},
        message: '',
      }
    case types.CREATE_COLLECTION_SUCCESS:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
      }
    case types.CREATE_COLLECTION_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        errors: action.error,
        loading: false,
      }

    case types.EDIT_COLLECTION_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
        errors: {},
        message: '',
      }
    case types.EDIT_COLLECTION_SUCCESS:
      // const collectionRef = state.collection
      // const objIndex = collectionRef.findIndex((obj => obj.id === action.data.id));
      // collectionRef[objIndex] = action.data
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
        collection: action.data
      }
    case types.EDIT_COLLECTION_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        errors: action.error,
        loading: false,
      }

    case types.DELETE_COLLECTION_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
        errors: {},
        message: '',
        deleteCollection: true
      }
    case types.DELETE_COLLECTION_SUCCESS:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
        collection: state.collection.filter((obj) => obj.id !== action.data),
        deleteCollection: false
      }
    case types.DELETE_COLLECTION_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        errors: action.error,
        loading: false,
        deleteCollection: false
      }

    case types.GET_COLLECTION_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
        errors: {},
        message: '',
        showCollection: false,
        collectionTree: []
      }
    case types.GET_COLLECTION_SUCCESS:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
        collection: action.data.results,
        showCollection: true,
        collectionTree: [],
        totalCollectionPage: action.data.count ? `${Math.ceil(action.data.count / 10)}` : 1
      }
    case types.GET_COLLECTION_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        errors: action.error,
        loading: false,
      }

    case types.CREATE_LINK_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
        generatedLink: '',
        errors: {},
        message: '',
      }
    case types.CREATE_LINK_SUCCESS:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
        generatedLink: action.data,
      }
    case types.CREATE_LINK_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        errors: action.error,
        generatedLink: '',
        loading: false,
      }

    case types.EDIT_LINK_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
      }
    case types.EDIT_LINK_SUCCESS:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
      }
    case types.EDIT_LINK_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
      }

    case types.DELETE_LINK_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
      }
    case types.DELETE_LINK_SUCCESS:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
      }
    case types.DELETE_LINK_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
      }

    case types.ADD_COLLABORATOR_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
        errors: {},
        message: '',
        collectionUpdate: false
      }
    case types.ADD_COLLABORATOR_SUCCESS:
      // const collaborator = state.collection
      // collaborator.push(action.data)
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
        collectionUpdate: true
        // collection: collaborator
      }
    case types.ADD_COLLABORATOR_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        errors: action.error,
        loading: false,
      }

    case types.DELETE_COLLABORATOR_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
        errors: {},
        message: '',
        collectionUpdate: false
      }
    case types.DELETE_COLLABORATOR_SUCCESS:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
        collectionUpdate: true
      }
    case types.DELETE_COLLABORATOR_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        errors: action.error,
        loading: false,
      }




    case types.CREATE_FOLDER_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
        errors: {},
        message: '',
      }
    case types.CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
      }
    case types.CREATE_FOLDER_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        errors: action.error,
        loading: false,
      }

    case types.GET_FOLDER_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
        errors: {},
        message: '',
      }
    case types.GET_FOLDER_SUCCESS:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
        folders: action.data
      }
    case types.GET_FOLDER_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        errors: action.error,
        loading: false,
      }

    case types.EDIT_FOLDER_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
        errors: {},
        message: '',
      }
    case types.EDIT_FOLDER_SUCCESS:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
      }
    case types.EDIT_FOLDER_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        errors: action.error,
        loading: false,
      }

    case types.DELETE_FOLDER_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
        errors: {},
        message: '',
      }
    case types.DELETE_FOLDER_SUCCESS:
      return {
        ...state,
        dltFolder: true,
        dltSection: false,
        loading: false,
      }
    case types.DELETE_FOLDER_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        errors: action.error,
        loading: false,
      }
    case types.CLONE_COLLECTION_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
        cloneCollection: false,
        errors: {},
        message: '',
      }
    case types.CLONE_COLLECTION_SUCCESS:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
        cloneCollection: true
      }
    case types.CLONE_COLLECTION_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        errors: action.error,
        loading: false,
        cloneCollection: false
      }
    case types.DELETE_SECTION_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
        errors: {},
        message: '',
        collectionUpdate: false
      }
    case types.DELETE_SECTION_SUCCESS:
      return {
        ...state,
        dltFolder: false,
        dltSection: true,
        loading: false,
        collectionUpdate: true
      }
    case types.DELETE_SECTION_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        errors: action.error,
        loading: false,
        collectionUpdate: false
      }
    case types.GET_ALL_COLLECTION_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
        errors: {},
        message: ''
      }
    case types.GET_ALL_COLLECTION_SUCCESS:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
        collectionTree: action.data
      }
    case types.GET_ALL_COLLECTION_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        errors: action.error,
        loading: false,
      }
    case types.PREVIEW_COLLECTION_REQUEST:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: true,
        errors: {},
        message: '',
        previewCollection: {},
        showCollection: false,
        collectionUpdate: false,
        sectionId: null
      }
    case types.PREVIEW_COLLECTION_SUCCESS:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        loading: false,
        previewCollection: action.data,
        showCollection: true,
        collectionUpdate: false,
        sectionId: action.data.sectionId
      }
    case types.PREVIEW_COLLECTION_FAILED:
      return {
        ...state,
        dltFolder: false,
        dltSection: false,
        errors: action.error,
        loading: false,
        collectionUpdate: false
      }
      case types.GET_COLLECTION_TEMPLATE_REQUEST:
        return {
          ...state,
          loading: true,
          errors: {},
          message: '',
          collectionTree: [],
          totalCollectionTemplatePage: 1
        }
      case types.GET_COLLECTION_TEMPLATE_SUCCESS:
        return {
          ...state,
          loading: false,
          collectionTemplate: action.data.results,
          totalCollectionTemplatePage: action.data.count ? `${Math.ceil(action.data.count / 10)}` : 1
        }
      case types.GET_COLLECTION_TEMPLATE_FAILED:
        return {
          ...state,
          errors: action.error,
          loading: false,
        }

    default:
      return state
  }
}

export default collectionReducer
