import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// import { AiOutlinePlus } from "react-icons/ai";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { FiXCircle } from "react-icons/fi";
import {
  HtmlEditor,
  Inject,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  Link,
} from "@syncfusion/ej2-react-richtexteditor";
import AssetAdd from "../../commons/Asset/components/AssetAdd";
import AssetCard from "../../commons/Asset/components/AssetCard";
import { LoadingSpinner, Slider } from "../../../components";
import EmailTemplates from "../../Settings/EmailTemplates";
import { RichTextEditorToolbar } from "../../../app/utilities/helpers";
import { Combobox } from "@headlessui/react";
import { Switch } from "@headlessui/react";

import { collectionActions } from "../../Collections/actions";
import { streamActions } from "../../Streams/actions";
import { composeActions } from "./../actions";
import { contactActions } from "../../Contacts/actions";
import { settingsActions } from "../../Settings/actions";
import "../compose.css";

const ComposeChild = ({ collection, onShareCollectionClose, position }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.compose);
  const { addedStream } = useSelector((state) => state.stream);
  const { contacts, distribution } = useSelector((state) => state.contacts);
  const { eSign, nda: ndas, share } = useSelector((state) => state.settings);

  const [to, setTo] = useState(
    collection && collection.to_email ? collection.to_email : []
  );
  const [toEmail, setToEmail] = useState("");
  const [ccAndbcc, setccAndbcc] = useState(
    collection && collection.cc ? collection.cc : []
  );
  const [bcc, setBcc] = useState(
    collection && collection.bcc ? collection.bcc : []
  );
  const [ccEmail, setCCEmail] = useState("");
  const [bccEmail, setBCCEmail] = useState("");
  const [subject, setSubject] = useState(
    collection && collection.subject ? collection.subject : ""
  );
  const [message, setMessage] = useState(
    collection && collection.message
      ? collection.message
      : "<div>Hello there,</div><br><div>Here's a collection of assets exclusively personalised for you! Hope you find this material useful. I'd love to hear what you think about them. Please feel free to get in touch at any time.<br></div>"
  );
  const [emailError, setEmailError] = useState("");
  const [formError, setFormError] = useState("");
  const [addAssetToggle, setAddAssetToggle] = useState(false);
  const [selectedStreamAsset, setSelectedStreamAsset] = useState([]);
  const [selectedCollectionAsset, setSelectedCollectionAsset] = useState([]);
  const [addedStreamAsset, setAddedStreamAsset] = useState([]);
  const [addedStreamShow, setAddedStreamShow] = useState(false);
  const [templateToggle, setTemplateToggle] = useState(false);
  const [ccToggle, setCcToggle] = useState(false);
  const [bccToggle, setBccToggle] = useState(false);

  const [toUpdate, setToUpdate] = useState(true);
  const [CCUpdate, setCCUpdate] = useState(true);
  const [BCCUpdate, setBCCUpdate] = useState(true);
  const [query, setQuery] = useState("");
  const [statusData, setStatusData] = useState({
    read_only: share.id ? share?.read_only : true,
    email_gate: share.id ? share?.email_gate : false,
    share: share.id ? share?.share : true,
    download: share.id ? share?.download : true,
  });
  const [advancedSettingToggle, setAdvancedSettingToggle] = useState(false);
  const [noNDAData, setNoNDAData] = useState(true);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [expiryRequired, setExpiryRequired] = useState(false);
  const [ndaRequired, setNdaRequired] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [expiryValue, setExpiryValue] = useState("");
  const [ndaValue, setNdaValue] = useState("");

  useEffect(() => {
    if (collection) {
      setTo(collection.to_email ? collection.to_email : []);
      setccAndbcc(collection.cc ? collection.cc : []);
      setBcc(collection.bcc ? collection.bcc : []);
      setSubject(collection.subject ? collection.subject : "");
      setMessage(
        collection.message
          ? collection.message
          : "<div>Hello there,</div><br><div>Here's a collection of assets exclusively personalized for you! Hope you find this material useful. I'd love to hear what you think about them. Please feel free to get in touch at any time.<br></div>"
      );
    }
  }, [collection]);

  const formSubmit = (e) => {
    e.preventDefault();
    if (to.length && subject.length) {
      let attaments = [];
      if (collection && collection.asset) {
        attaments = [
          ...collection.asset,
          ...selectedStreamAsset,
          ...selectedCollectionAsset,
          ...addedStreamAsset,
        ];
      } else if (collection && collection.assets) {
        attaments = [
          ...collection.assets,
          ...selectedStreamAsset,
          ...selectedCollectionAsset,
          ...addedStreamAsset,
        ];
      } else {
        attaments = [
          ...selectedStreamAsset,
          ...selectedCollectionAsset,
          ...addedStreamAsset,
        ];
      }
      let attamentIds = [];
      if (attaments.length) {
        attamentIds = attaments.map((attach) => {
          return attach.id;
        });
      }

      if (attaments.length === 0) {
        setFormError("Attach a file before sending.");
        return;
      }

      let toEmails = [];
      to.forEach((e) => {
        if (e.contacts) {
          e.contacts.forEach((ce) => {
            if (!findDuplicate(ce.email, toEmails)) {
              toEmails.push(ce.email);
            }
          });
        } else if (e.email) {
          if (!findDuplicate(e.email, toEmails)) {
            toEmails.push(e.email);
          }
        } else {
          if (!findDuplicate(e, toEmails)) {
            toEmails.push(e);
          }
        }
      });

      let ccEmails = [];
      ccAndbcc.forEach((e) => {
        if (e.contacts) {
          e.contacts.forEach((ce) => {
            if (!findDuplicate(ce.email, ccEmails)) {
              ccEmails.push(ce.email);
            }
          });
        } else if (e.email) {
          if (!findDuplicate(e.email, ccEmails)) {
            ccEmails.push(e.email);
          }
        } else {
          if (!findDuplicate(e, ccEmails)) {
            ccEmails.push(e);
          }
        }
      });

      let bccEmails = [];
      bcc.forEach((e) => {
        if (e.contacts) {
          e.contacts.forEach((ce) => {
            if (!findDuplicate(ce.email, bccEmails)) {
              bccEmails.push(ce.email);
            }
          });
        } else if (e.email) {
          if (!findDuplicate(e.email, bccEmails)) {
            bccEmails.push(e.email);
          }
        } else {
          if (!findDuplicate(e, bccEmails)) {
            bccEmails.push(e);
          }
        }
      });

      let payload = {
        subject: subject.trim(),
        message: message,
        files: attamentIds,
        to_email: to.length ? toEmails : [],
        cc: ccAndbcc.length ? ccEmails : [],
        bcc: bcc.length ? bccEmails : [],
        ...statusData,
      };

      if (passwordRequired) {
        payload.password = passwordValue;
      }
      if (expiryRequired) {
        payload.expiry_date = expiryValue;
      }
      if (ndaRequired) {
        payload.nda = ndaValue;
      }
      if (!passwordRequired) {
        delete payload.password;
      }
      if (!expiryRequired) {
        delete payload.expiry_date;
      }
      if (!ndaRequired) {
        delete payload.nda;
      }

      if (eSign && eSign.length) {
        payload["signature"] = onEmailSignPayload();
      }

      if (collection && collection.id && position !== "resend") {
        payload["collection"] = collection.id;
      }
      if (
        collection &&
        collection.assets &&
        collection.assets.length &&
        position === "resend"
      ) {
        payload["collection"] = collection.assets[0].collection;
      }
      return dispatch(
        composeActions.handleCompose(payload, onShareCollectionClose)
      );
    } else {
      if (!to.length) {
        setFormError("Enter TO email address");
      } else if (to.length && !subject.length) {
        setFormError("Enter subject");
      }
    }
  };

  const findDuplicate = (email, upd) => {
    let isDuplicate = false;
    upd.forEach((em) => {
      if (em.email) {
        if (em.email === email) {
          isDuplicate = true;
        }
      } else if (em === email) {
        isDuplicate = true;
      }
    });
    return isDuplicate;
  };

  const onInputChange = (name, e) => {
    let value = e.name ? e.name : e.target ? e.target.value : e.value;
    setFormError("");
    setEmailError("");
    switch (name) {
      case "to":
        setToEmail(value);
        break;
      case "cc":
        setCCEmail(value);
        break;
      case "bcc":
        setBCCEmail(value);
        break;
      case "subject":
        setSubject(value);
        break;
      case "message":
        setMessage(value);
        break;

      default:
        break;
    }
  };

  const onEmailChange = (name, e) => {
    let value = e.name ? e.name : e.target ? e.target.value : e.value;
    setEmailError("");
    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      (e.which === 13 ||
        e.which === 9 ||
        (e.type === "blur" && !filteredContact.length) ||
        e.name) &&
      value.length
    ) {
      if (name === "to") {
        if (e && !e.name) {
          if (emailRegex.test(value)) {
            let toEmail = to;
            if (!findDuplicate(value, toEmail)) {
              toEmail.push(value);
            }
            setTo(toEmail);
            setToEmail("");
            setToUpdate(false);
          } else {
            setEmailError("Invalid Email!");
            return;
          }
        } else {
          let toEmail = to;
          if (e.email) {
            if (!findDuplicate(e.email, toEmail)) {
              toEmail.push(e);
            }
          } else {
            toEmail.push(e);
          }
          setTo(toEmail);
          setToEmail("");
          setToUpdate(false);
        }
      } else if (name === "bcc") {
        if (e && !e.name) {
          if (emailRegex.test(value)) {
            let ccAndbccEmail = bcc;
            if (!findDuplicate(value, ccAndbccEmail)) {
              ccAndbccEmail.push(value);
            }
            setBcc(ccAndbccEmail);
            setBCCEmail("");
            setBCCEmail(false);
          } else {
            setEmailError("Invalid Email!");
            return;
          }
        } else {
          let ccAndbccEmail = bcc;
          if (e.email) {
            if (!findDuplicate(e.email, ccAndbccEmail)) {
              ccAndbccEmail.push(e);
            }
          } else {
            ccAndbccEmail.push(e);
          }
          setBcc(ccAndbccEmail);
          setBCCEmail("");
          setBCCEmail(false);
        }
      } else {
        if (e && !e.name) {
          if (emailRegex.test(value)) {
            let ccAndbccEmail = ccAndbcc;
            if (!findDuplicate(value, ccAndbccEmail)) {
              ccAndbccEmail.push(value);
            }
            setccAndbcc(ccAndbccEmail);
            setCCEmail("");
            setCCEmail(false);
          } else {
            setEmailError("Invalid Email!");
            return;
          }
        } else {
          let ccAndbccEmail = ccAndbcc;
          if (e.email) {
            if (!findDuplicate(e.email, ccAndbccEmail)) {
              ccAndbccEmail.push(e);
            }
          } else {
            ccAndbccEmail.push(e);
          }
          setccAndbcc(ccAndbccEmail);
          setCCEmail("");
          setCCEmail(false);
        }
      }
    }
  };

  useEffect(() => {
    if (!toUpdate) {
      setToUpdate(true);
    }
    if (!CCUpdate) {
      setCCUpdate(true);
    }
    if (!BCCUpdate) {
      setBCCUpdate(true);
    }
  }, [toUpdate, CCUpdate, BCCUpdate]);

  useEffect(() => {
    if (user.organization && user.organization !== "None") {
      // get stream and collections
      dispatch(collectionActions.handleGetCollection());
      dispatch(streamActions.handleGetStream());
      dispatch(contactActions.handleContactListsFetch(`contacts`));
      dispatch(
        contactActions.handleDistributionListsFetch(
          `distributions?organization=${user.organization}`
        )
      );
      dispatch(
        settingsActions.handleESignListsFetch(
          `email/signatures?organization=${user.organization}`
        )
      );
    }
  }, [dispatch, user.organization, user.id]);

  useEffect(() => {
    let addAsset = addedStreamAsset;
    if (addedStream && addedStream.id && addedStreamShow) {
      addAsset.push(addedStream);
      setAddedStreamAsset(addAsset);
    }
  }, [addedStream, addedStreamAsset, addedStreamShow]);

  const onRemoveEmail = (name, value) => {
    if (name === "to") {
      let toEmail = to.filter(function (email) {
        if (value.id) {
          return email.id !== value.id;
        } else {
          return email !== value;
        }
      });
      setTo(toEmail);
    } else if (name === "bcc") {
      let ccAndbccEmail = bcc.filter(function (email) {
        if (value.id) {
          return email.id !== value.id;
        } else {
          return email !== value;
        }
      });
      setBcc(ccAndbccEmail);
    } else {
      let ccAndbccEmail = ccAndbcc.filter(function (email) {
        if (value.id) {
          return email.id !== value.id;
        } else {
          return email !== value;
        }
      });
      setccAndbcc(ccAndbccEmail);
    }
  };

  const handleAddAssetToggle = () => {
    setAddAssetToggle(!addAssetToggle);
    setFormError("");
  };

  const handleAssetCopySubmit = (selectedStream, selectedCollection) => {
    setSelectedStreamAsset(selectedStream);
    setSelectedCollectionAsset(selectedCollection);
    handleAddAssetToggle();
  };

  const handleCreateAsset = (data) => {
    const payload = {
      ...data,
    };
    setAddedStreamShow(true);
    dispatch(
      streamActions.handleCreateAsset(payload, handleAddAssetToggle, true)
    );
  };

  const onCancel = (e) => {
    e.preventDefault();
    if (onShareCollectionClose) {
      onShareCollectionClose();
    }
  };

  const getTemplate = (t) => {
    setSubject(t.subject);
    setMessage(t.message);
  };

  const onEmailSign = () => {
    if (eSign && eSign.length) {
      return `<table class="flex" style="width: 100%;"><tbody><tr><td style="border:0px;width: 60px;height:60px; border-radius:5px;">${
        eSign[0]["logo"]
          ? `<img style="width:60px;height:60px; border-radius:5px;" src=${
              eSign[0]["logo"] ? eSign[0]["logo"] : ""
            } alt="logo" loading="lazy" />`
          : ""
      }</td><td style="border:0px"><div dangerouslySetInnerHTML=__html: ${
        eSign[0]["signature"] ? eSign[0]["signature"] : null
      }</div></td></tr></tbody></table>`;
    }
    return "";
  };

  const onEmailSignPayload = () => {
    if (eSign && eSign.length) {
      return `<table class="flex" style="width: 100%;"><tbody><tr><td style="border:0px;width: 60px;height:60px; border-radius:5px;">${
        eSign[0]["logo"]
          ? `<img style="width:60px;height:60px; border-radius:5px;" src=${
              eSign[0]["logo"] ? eSign[0]["logo"] : ""
            } alt="logo" loading="lazy" />`
          : ""
      }</td><td style="border:0px"><div>${
        eSign[0]["signature"] ? eSign[0]["signature"] : null
      }</div></td></tr></tbody></table>`;
    }
    return "";
  };

  useEffect(() => {
    if ((!ndas || ndas.length === 0) && noNDAData) {
      dispatch(settingsActions.handleNDAListsFetch("ndas"));
      setNoNDAData(false);
    }
  }, [dispatch, ndas, noNDAData]);

  const statusToggle = (status) => {
    setStatusData((prev) => ({ ...prev, [status]: !statusData[status] }));
  };

  const slideLeft = (target) => {
    var slider = document.getElementById(target);
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = (target) => {
    var slider = document.getElementById(target);
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  const distributionWithSource =
    distribution && distribution.length > 0
      ? distribution.map((c) => ({ ...c, source: "distribution" }))
      : [];

  const contactsWithSource =
    contacts && contacts.length > 0
      ? contacts.map((c) => ({ ...c, source: "contacts" }))
      : [];
  const filteredContact =
    query === ""
      ? []
      : [...contactsWithSource, ...distributionWithSource].filter((c) => {
          if (c.source === "contacts") {
            return c.email.toLowerCase().includes(query.toLowerCase());
          } else if (
            c.source === "distribution" &&
            c.contacts !== undefined &&
            c.contacts.length > 0
          ) {
            return c.name.toLowerCase().includes(query.toLowerCase());
          }
          return false;
        });

  return (
    <>
      <div
        className={`${
          collection ? "" : "md:mx-20 md:pt-4 pt-4"
        } mx-4 grid grid-cols-1 md:grid-cols-2 justify-between bg-transparent`}
      >
        <div className="flex flex-col">
          <span className="text-[#FE4502] font-extrabold text-lg">Compose</span>
          <span className="text-[#757575] text-14">Quick Send</span>
        </div>
      </div>
      {/* collection 1---------------------------------------------------------------------------------------------------------------- */}

      <div
        className={`${
          collection
            ? "border-t-1 border-t-slate-300 mt-2"
            : "rounded-3xl m-4 shadow min-h-[75vh]"
        } bg-white text-slate-600 flex relative`}
      >
        {/* form */}
        <div
          className={`${
            templateToggle || advancedSettingToggle ? "w-3/5" : "w-full"
          } px-6 relative`}
        >
          <form onSubmit={formSubmit} className="relative w-full">
            <div className="relative flex p-2 md:p-4 w-full compose_body">
              {/* form */}
              <div className="compose_body flex flex-col w-full">
                <div className="flex flex-row compose-email-section py-1">
                  <div className="compose-email__input">
                    {to.length && toUpdate ? (
                      <div className="email-chip-wrap">
                        {to.map((t, i) => {
                          return (
                            <p key={i}>
                              {t.name ? t.name : t}{" "}
                              <FiXCircle
                                onClick={() => onRemoveEmail("to", t)}
                              />
                            </p>
                          );
                        })}
                      </div>
                    ) : null}
                    {position !== "resend" ? (
                      <Combobox
                        name="to"
                        value={toEmail}
                        onChange={(event) => {
                          onEmailChange("to", event);
                        }}
                      >
                        <Combobox.Input
                          id="to"
                          displayValue={toEmail}
                          onKeyDown={(e) => onEmailChange("to", e)}
                          onBlur={(e) => onEmailChange("to", e)}
                          onChange={(event) => {
                            setQuery(event.target.value);
                            onInputChange("to", event);
                          }}
                          placeholder="To"
                          className="w-full bg-white "
                        />
                        <Combobox.Options>
                          {filteredContact.map((c) => (
                            <Combobox.Option key={`to-${c.id}`} value={c}>
                              <div className=" flex flex-row items-center gap-2 cursor-pointer bg-white">
                                <div className="flex">
                                  <button>
                                    <div className="m-1 mr-2 w-10 h-10 relative flex justify-center items-center rounded-full bg-gradient-to-b from-[#000080] to-[#6363ff] text-xl text-white uppercase">
                                      {c?.name.charAt(0)}
                                    </div>
                                  </button>
                                </div>
                                <div className="flex flex-col justify-between h-full">
                                  <div className="flex flex-col">
                                    <span className="font-bold text-uberark-blue">
                                      {c?.email || c?.name}
                                    </span>
                                  </div>

                                  {c.contacts ? (
                                    <div className="text-[10px] text-[#757575] font-normal mt-2">
                                      <div className="flex flex-row divide-x-2 divide-black">
                                        <span>
                                          Contacts ({c.contacts.length})
                                        </span>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      </Combobox>
                    ) : null}
                  </div>
                  <div className="flex justify-end">
                    <button
                      className={`${
                        ccToggle ? "text-[#FE4502]" : ""
                      } px-2 cursor-pointer font-semibold`}
                      onClick={() => {
                        setCcToggle(!ccToggle);
                      }}
                    >
                      Cc
                    </button>
                    <button
                      className={`${
                        bccToggle ? "text-[#FE4502]" : ""
                      } px-2 cursor-pointer font-semibold`}
                      onClick={() => {
                        setBccToggle(!bccToggle);
                      }}
                    >
                      Bcc
                    </button>
                  </div>
                </div>

                {ccToggle ? (
                  <div className="flex compose-email-section w-full py-1">
                    <div className="compose-email__input w-full">
                      {ccAndbcc.length ? (
                        <div className="email-chip-wrap">
                          {ccAndbcc.map((t, i) => {
                            return (
                              <p key={i}>
                                {t.name ? t.name : t}{" "}
                                <FiXCircle
                                  onClick={() => onRemoveEmail("cc", t)}
                                />
                              </p>
                            );
                          })}
                        </div>
                      ) : null}
                      {position !== "resend" ? (
                        <Combobox
                          name="cc"
                          value={ccEmail}
                          onChange={(event) => {
                            onEmailChange("cc", event);
                          }}
                        >
                          <Combobox.Input
                            id="cc"
                            onKeyDown={(e) => onEmailChange("cc", e)}
                            onBlur={(e) => onEmailChange("cc", e)}
                            onChange={(event) => {
                              setQuery(event.target.value);
                              onInputChange("cc", event);
                            }}
                            displayValue={ccEmail}
                            placeholder="CC"
                            className="w-full"
                          />
                          <Combobox.Options>
                            {filteredContact.map((c) => (
                              <Combobox.Option key={`cc-${c.id}`} value={c}>
                                <div className="rounded-[30px] flex flex-row  items-center  gap-2 cursor-pointer">
                                  <div className="flex">
                                    <button>
                                      <div className="m-1 mr-2 w-10 h-10 relative flex justify-center items-center rounded-full bg-gradient-to-b from-[#000080] to-[#6363ff] text-xl text-white uppercase">
                                        {c?.name.charAt(0)}
                                      </div>
                                    </button>
                                  </div>
                                  <div className="flex flex-col justify-between h-full">
                                    <div className="flex flex-col">
                                      <span className="font-bold text-uberark-blue">
                                        {c?.email || c?.name}
                                      </span>
                                    </div>

                                    {c.contacts ? (
                                      <div className="text-[10px] text-[#757575] font-normal mt-2">
                                        <div className="flex flex-row divide-x-2 divide-black">
                                          <span>
                                            Contacts ({c.contacts.length})
                                          </span>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </Combobox.Option>
                            ))}
                          </Combobox.Options>
                        </Combobox>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {bccToggle ? (
                  <div className="flex compose-email-section w-full py-1">
                    <div className="compose-email__input w-full">
                      {bcc.length ? (
                        <div className="email-chip-wrap">
                          {bcc.map((t, i) => {
                            return (
                              <p key={i}>
                                {t.name ? t.name : t}{" "}
                                <FiXCircle
                                  onClick={() => onRemoveEmail("bcc", t)}
                                />
                              </p>
                            );
                          })}
                        </div>
                      ) : null}
                      {position !== "resend" ? (
                        <Combobox
                          name="bcc"
                          value={bccEmail}
                          onChange={(event) => {
                            onEmailChange("bcc", event);
                          }}
                        >
                          <Combobox.Input
                            onKeyDown={(e) => onEmailChange("bcc", e)}
                            onBlur={(e) => onEmailChange("bcc", e)}
                            onChange={(event) => {
                              setQuery(event.target.value);
                              onInputChange("bcc", event);
                            }}
                            displayValue={bccEmail}
                            placeholder="BCC"
                            className="w-full"
                          />
                          <Combobox.Options>
                            {filteredContact.map((c) => (
                              <Combobox.Option key={`bcc-${c.id}`} value={c}>
                                <div className="rounded-[30px] flex flex-row  items-center  gap-2 cursor-pointer">
                                  <div className="flex">
                                    <button>
                                      <div className="m-1 mr-2 w-10 h-10 relative flex justify-center items-center rounded-full bg-gradient-to-b from-[#000080] to-[#6363ff] text-xl text-white uppercase">
                                        {c?.name.charAt(0)}
                                      </div>
                                    </button>
                                  </div>
                                  <div className="flex flex-col justify-between h-full">
                                    <div className="flex flex-col">
                                      <span className="font-bold text-uberark-blue">
                                        {c?.email || c?.name}
                                      </span>
                                    </div>

                                    {c.contacts ? (
                                      <div className="text-[10px] text-[#757575] font-normal mt-2">
                                        <div className="flex flex-row divide-x-2 divide-black">
                                          <span>
                                            Contacts ({c.contacts.length})
                                          </span>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </Combobox.Option>
                            ))}
                          </Combobox.Options>
                        </Combobox>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {emailError.length ? (
                  <div className="text-red-600 compose-email-error mb-1">
                    {emailError}
                  </div>
                ) : null}

                <input
                  type="text"
                  placeholder="Subject"
                  className="py-1"
                  value={subject}
                  onChange={(e) => onInputChange("subject", e)}
                  readOnly={position !== "resend" ? false : true}
                />

                {position !== "resend" ? (
                  <div className="divide-x flex items-center justify-end text-uberark-blue py-1 text-sm">
                    <button
                      className={`${
                        templateToggle ? "text-[#FE4502]" : ""
                      } px-1 cursor-pointer`}
                      onClick={() => {
                        setTemplateToggle(!templateToggle);
                        setAdvancedSettingToggle(false);
                      }}
                      type="button"
                    >
                      Email Template
                    </button>
                    <button
                      className={`${
                        advancedSettingToggle ? "text-[#FE4502]" : ""
                      } px-1 cursor-pointer`}
                      onClick={() => {
                        setAdvancedSettingToggle(!advancedSettingToggle);
                        setTemplateToggle(false);
                      }}
                      type="button"
                    >
                      Advanced Settings
                    </button>
                    {!collection && (
                      <button
                        className={`px-1 cursor-pointer`}
                        onClick={() => handleAddAssetToggle()}
                        type="button"
                      >
                        Attach Documents
                      </button>
                    )}
                  </div>
                ) : null}

                <div className="pb-2 w-full relative">
                  <RichTextEditorComponent
                    change={(event) => setMessage(event.value)}
                    value={`${message}  ${onEmailSign()}`}
                    toolbarSettings={RichTextEditorToolbar}
                    className={
                      position === "resend"
                        ? "e-control e-richtexteditor e-lib e-rte-toolbar-enabled e-rte-tb-expand richtext-border-remove disable-richtext"
                        : "e-control e-richtexteditor e-lib e-rte-toolbar-enabled e-rte-tb-expand richtext-border-remove"
                    }
                  >
                    <Inject
                      services={[Toolbar, Link, HtmlEditor, QuickToolbar]}
                    />
                  </RichTextEditorComponent>
                </div>
              </div>
            </div>

            {formError.length ? (
              <div className="text-red-600" style={{ margin: 15 }}>
                {formError}
              </div>
            ) : null}

            {/* footer */}
            <div className="flex flex-col px-4">
              {/* Assets */}
              <div className="flex items-center justify-center w-full pt-2">
                <BsChevronLeft
                  onClick={() => slideLeft("slider-assets")}
                  size={30}
                  className="opacity-100 cursor-pointer hover:opacity-60 mr-6 text-[#000080]"
                />

                <div
                  id="slider-assets"
                  className="w-full h-[100px] flex flex-row items-center gap-x-2 overflow-x-scroll whitespace-nowrap scroll-smooth scrollbar-hide"
                >
                  {position !== "resend" && collection && collection.asset
                    ? collection.asset.map((asset) => {
                        return (
                          <AssetCard
                            asset={asset}
                            key={asset.id}
                            className="mx-2 w-[80px] h-[80px]"
                            size="asset__small"
                            withName={true}
                            nameClassname="opacity-0"
                          />
                        );
                      })
                    : null}
                  {(position === "resend" || position === "asset") &&
                  collection &&
                  collection.assets
                    ? collection.assets.map((asset) => {
                        return (
                          <AssetCard
                            asset={asset}
                            key={asset.id}
                            className="mx-2 w-[80px] h-[80px]"
                            size="asset__small"
                            withName={true}
                            nameClassname="opacity-0"
                          />
                        );
                      })
                    : null}
                  {selectedCollectionAsset.length
                    ? selectedCollectionAsset.map((asset) => {
                        return (
                          <AssetCard
                            asset={asset}
                            key={asset.id}
                            className="mx-2 w-[80px] h-[80px]"
                            size="asset__small"
                            withName={true}
                            nameClassname="opacity-0"
                          />
                        );
                      })
                    : null}
                  {selectedStreamAsset.length
                    ? selectedStreamAsset.map((asset) => {
                        return (
                          <AssetCard
                            asset={asset}
                            key={asset.id}
                            className="mx-2 w-[80px] h-[80px]"
                            size="asset__small"
                            withName={true}
                            nameClassname="opacity-0"
                          />
                        );
                      })
                    : addedStreamAsset.length
                    ? addedStreamAsset.map((asset) => {
                        return (
                          <AssetCard
                            asset={asset}
                            key={asset.id}
                            className="mx-2 w-[80px] h-[80px]"
                            size="asset__small"
                            withName={true}
                            nameClassname="opacity-0"
                          />
                        );
                      })
                    : null}
                </div>
                <BsChevronRight
                  onClick={() => slideRight("slider-assets")}
                  size={30}
                  className="opacity-100 cursor-pointer hover:opacity-60 ml-6 text-[#000080]"
                />
              </div>

              {/* Send/Cancel Buttons */}
              <div className="flex flex-row items-center justify-end text-uberark-blue gap-4 p-2 text-lg">
                <button
                  className=":px-2 bg-[#000080] hover:bg-white text-white hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl "
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <LoadingSpinner /> : "Send"}
                </button>
                {onShareCollectionClose && (
                  <button
                    className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent "
                    style={{ border: "0.24px solid #000080" }}
                    onClick={(e) => {
                      onCancel(e);
                    }}
                    type="button"
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
        {/* email templates */}
        {templateToggle ? (
          <div className="w-2/5 pl-2 bg-slate-50 h-screen ease-in-out duration-300 pt-4">
            <EmailTemplates eTemplateList={true} getTemplate={getTemplate} />
          </div>
        ) : null}
        {/* advanced settings */}
        {advancedSettingToggle && (
          <div className="w-2/5 px-3 bg-slate-50 h-screen ease-in-out duration-300 pt-4">
            <div className="pl-2">
              <div class="font-bold text-uberark-blue mt-9 space-between-flex">
                <span>Advanced Settings</span>
              </div>
              <div className="flex justify-between items-center py-2">
                <div>
                  Read Only
                  <div className="text-xs italic">
                    *This applies to PPTs, Docs, Google Slides and Google Docs.
                  </div>
                </div>
                <Switch
                  checked={statusData["read_only"]}
                  onChange={() => statusToggle("read_only")}
                  className={`${
                    statusData["read_only"]
                      ? " bg-uberark-blue"
                      : " bg-uberark-orange"
                  }
                            relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${
                      statusData["read_only"]
                        ? "translate-x-5"
                        : "translate-x-0"
                    }
                            pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
              </div>

              <div className="flex justify-between items-center py-2">
                <div>Enable Email Gating</div>{" "}
                <Switch
                  checked={statusData["email_gate"]}
                  onChange={() => statusToggle("email_gate")}
                  className={`${
                    statusData["email_gate"]
                      ? " bg-uberark-blue"
                      : " bg-uberark-orange"
                  }
                            relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${
                      statusData["email_gate"]
                        ? "translate-x-5"
                        : "translate-x-0"
                    }
                            pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>{" "}
              </div>

              <div className="flex justify-between items-center py-2">
                <div>Allow recipient to Reshare</div>{" "}
                <Switch
                  checked={statusData["share"]}
                  onChange={() => statusToggle("share")}
                  className={`${
                    statusData["share"]
                      ? " bg-uberark-blue"
                      : " bg-uberark-orange"
                  }
                            relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${
                      statusData["share"] ? "translate-x-5" : "translate-x-0"
                    }
                            pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>{" "}
              </div>

              <div className="flex justify-between items-center py-2">
                <div>Allow recipient to Download</div>{" "}
                <Switch
                  checked={statusData["download"]}
                  onChange={() => statusToggle("download")}
                  className={`${
                    statusData["download"]
                      ? " bg-uberark-blue"
                      : " bg-uberark-orange"
                  }
                            relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${
                      statusData["download"] ? "translate-x-5" : "translate-x-0"
                    }
                            pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>{" "}
              </div>

              <div className="flex justify-between items-center py-2">
                <div>Set a Password</div>{" "}
                <Switch
                  checked={passwordRequired}
                  onChange={() => setPasswordRequired(!passwordRequired)}
                  className={`${
                    passwordRequired ? " bg-uberark-blue" : " bg-uberark-orange"
                  }
                            relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${
                      passwordRequired ? "translate-x-5" : "translate-x-0"
                    }
                              pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>{" "}
              </div>
              {passwordRequired && (
                <>
                  <div className="flex items-center">
                    <input
                      placeholder="Enter Password"
                      className="p-2 outline-none"
                      name="password"
                      id="password"
                      type="password"
                      onChange={(e) => setPasswordValue(e.target.value)}
                    />
                  </div>
                </>
              )}

              <div className="flex justify-between items-center py-2">
                <div>Set expiry date & time</div>{" "}
                <Switch
                  checked={expiryRequired}
                  onChange={() => setExpiryRequired(!expiryRequired)}
                  className={`${
                    expiryRequired ? " bg-uberark-blue" : " bg-uberark-orange"
                  }
                            relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${
                      expiryRequired ? "translate-x-5" : "translate-x-0"
                    }
                              pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>{" "}
              </div>
              {expiryRequired && (
                <>
                  <div className="flex items-center">
                    <input
                      className="p-2 outline-none"
                      name="expiry_date"
                      id="expiry_date"
                      type="datetime-local"
                      onChange={(e) => setExpiryValue(e.target.value)}
                    />
                  </div>
                </>
              )}

              <div className="flex justify-between items-center py-2">
                <div>
                  Enable NDA
                  {(!ndas || ndas.length === 0) && (
                    <div className="text-xs text-[#ff0000] italic">
                      *You need to create an NDAs to enable NDA.{" "}
                      <div
                        className="underline font-semibold"
                        onMouseDown={() => navigate(`/UserProfile/NDA`)}
                      >
                        Create NDA
                      </div>
                    </div>
                  )}
                </div>{" "}
                <Switch
                  checked={ndaRequired}
                  onChange={() =>
                    ndas && ndas.length > 0 && setNdaRequired(!ndaRequired)
                  }
                  className={`${
                    ndaRequired ? " bg-uberark-blue" : " bg-uberark-orange"
                  }
                            relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${
                      ndaRequired ? "translate-x-5" : "translate-x-0"
                    }
                              pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>{" "}
              </div>
              {ndaRequired && (
                <>
                  <div className="flex items-center">
                    <select
                      name="nda"
                      onChange={(e) => setNdaValue(e.target.value)}
                      style={{ display: "block" }}
                    >
                      <option value="" label="Select an NDA">
                        Select an NDA{" "}
                      </option>
                      {ndas &&
                        ndas.map((nda, index) => (
                          <option value={nda.id} label={nda.title}>
                            {" "}
                            {nda.title}
                          </option>
                        ))}
                    </select>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <Slider
        float="left"
        title=""
        visible={addAssetToggle}
        onClose={handleAddAssetToggle}
        className="w-[50%]"
      >
        <AssetAdd
          handleModalClose={handleAddAssetToggle}
          handleAssetCopySubmit={handleAssetCopySubmit}
          handleAssetCreateSubmit={handleCreateAsset}
        />
      </Slider>
    </>
  );
};

export default ComposeChild;
