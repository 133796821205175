import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Collections from "./Collections";
import Streams from "./Streams";
import Selected from "./Selected";
import Upload from "./Upload";
import Url from "./Url";
import { LoadingSpinner } from "../../../../../components";

const AssetAdd = ({
  handleModalClose,
  handleAssetCopySubmit,
  handleAssetCreateSubmit,
  handleMoveAssetSubmit,
  handleMoveAssetClick,
  openGroup,
  collection,
  stream,
}) => {
  const { loading } = useSelector((state) => state.stream);
  const [selectedStream, setSelectedStream] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState([]);
  const [activeTab, setActiveTab] = useState("upload");
  const [tabs, setTabs] = useState({
    upload: "Upload",
    url: "URL",
    stream: "Stream",
    collection: "Collection",
  });

  useEffect(() => {
    if (handleMoveAssetClick) {
      setActiveTab("stream");
      setTabs({
        stream: "Stream",
        collection: "Collection",
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFileClick = (_, indexes, state) => {
    if (state.parentType === "stream") {
      // check if file exists; remove if exists
      const selectedStreamExists = selectedStream.filter(
        (item) => item.id === state.id
      );
      if (
        selectedStreamExists.length > 0 &&
        selectedStream[0] &&
        selectedStream[0].id
      ) {
        handleRemoveFile(state);
      } else {
        setSelectedStream((prevState) => [...prevState, state]);
      }
      return true;
    }

    // check if file exists; remove if exists
    const selectedCollectionExists = selectedCollection.filter(
      (item) => item.id === state.id
    );
    if (
      selectedCollectionExists.length > 0 &&
      selectedCollectionExists[0] &&
      selectedCollectionExists[0].id
    ) {
      handleRemoveFile(state);
    } else {
      setSelectedCollection((prevState) => [...prevState, state]);
    }
  };

  const handleRemoveFile = (file) => {
    if (file.parentType === "stream") {
      const filteredStream = selectedStream.filter(
        (item) => item.id !== file.id
      );
      return setSelectedStream(() => filteredStream);
    }

    const filteredCollection = selectedCollection.filter(
      (item) => item.id !== file.id
    );
    return setSelectedCollection(() => filteredCollection);
  };

  const handleSaveAssets = () =>
    handleAssetCopySubmit(selectedStream, selectedCollection);

  const handleMoveAsset = () => {
    if (openGroup) {
      handleMoveAssetSubmit(selectedStream, selectedCollection, openGroup.id);
    } else {
      handleMoveAssetSubmit(selectedStream, selectedCollection);
    }
  };

  const activeLink =
    "flex items-center px-2 text-base text-uberark-orange font-semibold hover:text-[#fe6702] ";
  const normalLink =
    "flex items-center px-2 text-base text-[#000080] font-semibold dark:text-gray-200 dark:hover:text-black hover:text-[#fe6702]";

  return (
    <div className="bg-white rounded-3xl relative flex flex-col justify-center m-2">
      <div className="flex flex-col items-center pb-2 w-full">
        <span className="text-uberark-blue md:text-xl pb-2 text-lg font-bold">
          {handleMoveAssetClick ? "Move" : "Add"} Asset
        </span>
        <span className="text-uberark-blue pb-2 text-xs text-base font-semibold">
          Choose your assets
        </span>
        <span className="text-uberark-orange text-xs text-base text-center font-semibold">
          {handleMoveAssetClick ? "Move" : "Add"} assets from streams,
          collections{handleMoveAssetClick ? "" : ", url or upload"}
        </span>
      </div>

      <div className="w-full flex flex-col items-center">
        <div className="flex flex-row justify-center gap-1 divide-x-1 divide-slate-400 w-full h-auto">
          {Object.keys(tabs).map((tab, index) => (
            <button
              key={index}
              className={`text-uberark-blue ${
                tab === activeTab ? activeLink : normalLink
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tabs[tab]}
            </button>
          ))}
        </div>

        {activeTab === "upload" && (
          <div className="w-3/4 flex flex-col mt-5 p-2 rounded-3xl border-uberark-blue border-[0.25px] h-56 md:p-4 md:m-4 overflow-y-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide">
            <Upload
              handleClose={handleModalClose}
              handleCreateAsset={handleAssetCreateSubmit}
            />
          </div>
        )}

        {activeTab === "url" && (
          <div className="w-3/4 flex flex-col mt-5 p-2 rounded-3xl border-uberark-blue border-[0.25px] h-56 md:p-4 md:m-4 overflow-y-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide">
            <Url
              collection={collection}
              stream={stream}
              handleModalClose={handleModalClose}
            />
          </div>
        )}

        {activeTab === "stream" && (
          <div className="w-3/4 flex flex-col mt-5 p-2 rounded-3xl border-uberark-blue border-[0.25px] h-56 md:p-4 md:m-4 overflow-y-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide">
            <Streams
              handleFileClick={handleFileClick}
              selectedAssets={[...selectedStream, ...selectedCollection]}
              openGroup={openGroup}
            />
          </div>
        )}

        {activeTab === "collection" && (
          <div className="w-3/4 flex flex-col mt-5 p-2 rounded-3xl border-uberark-blue border-[0.25px] h-56 md:p-4 md:m-4 overflow-y-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide">
            <Collections
              handleFileClick={handleFileClick}
              selectedAssets={[...selectedStream, ...selectedCollection]}
              openGroup={openGroup}
            />
          </div>
        )}

        {activeTab !== "upload" && activeTab !== "url" && (
          <>
            <Selected
              selectedStream={selectedStream}
              selectedCollection={selectedCollection}
              setSelectedStream={setSelectedStream}
              setSelectedCollection={setSelectedCollection}
            />
            <div className="flex flex-row w-3/4 text-uberark-blue gap-4 text-lg mb-5">
              {(selectedStream.length > 0 || selectedCollection.length > 0) && (
                <button
                  className=":px-2 bg-[#000080] text-white  hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl"
                  onClick={() =>
                    handleMoveAssetClick
                      ? handleMoveAsset()
                      : handleSaveAssets()
                  }
                  disabled={loading}
                >
                  {loading ? <LoadingSpinner /> : "Save"}
                </button>
              )}

              <button
                className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
                onClick={() => handleModalClose()}
                style={{ border: "0.24px solid #000080" }}
                type="button"
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AssetAdd;
