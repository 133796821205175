import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import { AiOutlinePlus } from "react-icons/ai";
import { FiInfo } from "react-icons/fi";
import ContactsSidebar from "./Sidebar";
import ContactsForm from "./Form";

import { contactActions } from "../../actions";
import no_content from "../../../../assets/images/no_content.svg";

export const Contacts = ({ isAdd }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contacts, totalContactPage: totalPaginationPages } = useSelector(
    (state) => state.contacts
  );
  const [activeContact, setActiveContact] = useState();
  const [activePaginationPage, setActivePaginationPage] = useState(1);

  useEffect(() => {
    dispatch(contactActions.handleContactListsFetch(`contacts-shallow?page=1`));
  }, [dispatch]);

  useEffect(() => {
    if (contacts && contacts.length) {
      setActiveContact(contacts[0]);
    }
  }, [contacts]);

  const changePage = ({ prevPage }) => {
    setActivePaginationPage(prevPage);
    dispatch(
      contactActions.handleContactListsFetch(
        `contacts-shallow?page=${prevPage + 1}`
      )
    );
  };

  const handleCloseForm = (update) => {
    if (update) {
      return dispatch(
        contactActions.handleContactListsFetch(
          `contacts-shallow?page=${activePaginationPage}`
        )
      );
    }
    setActiveContact(null);
    dispatch(contactActions.handleContactListsFetch(`contacts-shallow?page=1`));
    setTimeout(() => {
      navigate(`/contacts`);
    }, 100);
  };

  return (
    <div
      className={
        isAdd || contacts.length === 0 ? "flex" : "contact-layout relative"
      }
    >
      {contacts && contacts.length ? (
        <>
          {!isAdd && (
            <ContactsSidebar
              contacts={contacts}
              changePage={changePage}
              activeContact={activeContact}
              setActiveContact={setActiveContact}
              totalPaginationPages={totalPaginationPages}
              activePaginationPage={activePaginationPage}
            />
          )}
          <ContactsForm
            activeContact={isAdd ? null : activeContact}
            handleCloseForm={handleCloseForm}
          />
        </>
      ) : (
        <>
          {isAdd ? (
            <ContactsForm
              activeContact={isAdd ? null : activeContact}
              handleCloseForm={handleCloseForm}
            />
          ) : (
            <div className="w-full">
              <div className="flex flex-wrap mt-8">
                <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10">
                  <div className="w-full flex justify-center">
                    <img
                      src={no_content}
                      alt="nothing to show illustration"
                      style={{ height: "60px" }}
                      loading="lazy"
                    />
                  </div>
                  <div className="text-center relative p-5 text-[#757575] text-base flex items-center">
                    Nothing to show{" "}
                    <FiInfo className="text-uberark-orange ml-1" />
                  </div>
                  <Link
                    className="flex items-center justify-center py-1 px-5 cursor-pointer opacity-80 hover:scale-105 ease-in-out duration-300 border rounded"
                    alt={`/contacts/add`}
                    to={`/contacts/add`}
                  >
                    <AiOutlinePlus className="text-[#FE4502] mr-1" /> Create
                    Contact
                  </Link>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
