export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILED = 'SIGNUP_FAILED'

export const CONFIRM_REQUEST = 'CONFIRM_REQUEST'
export const CONFIRM_SUCCESS = 'CONFIRM_SUCCESS'
export const CONFIRM_FAILED = 'CONFIRM_FAILED'

export const GOOGLE_AUTH_REQUEST = 'GOOGLE_AUTH_REQUEST'
export const GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS'
export const GOOGLE_AUTH_FAILED = 'GOOGLE_AUTH_FAILED'

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED'

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILED = 'LOGOUT_FAILED'

export const GET_LOGGED_IN_USER_REQUEST = 'GET_LOGGED_IN_USER_REQUEST'
export const GET_LOGGED_IN_USER_SUCCESS = 'GET_LOGGED_IN_USER_SUCCESS'
export const GET_LOGGED_IN_USER_FAILED = 'GET_LOGGED_IN_USER_FAILED'

export const EDIT_USER_PROFILE_REQUEST = 'EDIT_USER_PROFILE_REQUEST'
export const EDIT_USER_PROFILE_SUCCESS = 'EDIT_USER_PROFILE_SUCCESS'
export const EDIT_USER_PROFILE_FAILED = 'EDIT_USER_PROFILE_FAILED'
