import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "../../components/commons/LoadingSpinner";
import {
  HtmlEditor,
  Inject,
  RichTextEditorComponent,
  Toolbar,
  Link,
} from "@syncfusion/ej2-react-richtexteditor";

import { BsXLg, BsFillEyeFill } from "react-icons/bs";
import { RiEditCircleFill } from "react-icons/ri";
import "./collection.css";

import { collectionActions } from "./actions";
import { toast } from "react-toastify";
import { RichTextEditorToolbar } from "../../app/utilities/helpers";

const EditSingleCollection = ({ imported, handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id: collectionId } = useParams();
  const { loading, previewCollection } = useSelector(
    (state) => state.collection
  );

  const [fileType, setFileType] = useState("");
  const [preview, setPreview] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    banner: "",
    logo: "",
  });
  const supportedLogoFileExt = ["image/jpeg", "image/jpg", "image/png"];
  const supportedBannerFileExt = [...supportedLogoFileExt, "video/mp4"];

  useEffect(() => {
    if (previewCollection) {
      // filter collection by id
      const collectionFiltered = previewCollection;
      setInitialValues({
        name: collectionFiltered?.name || "",
        description: collectionFiltered?.description || "",
        banner: collectionFiltered?.banner || "",
        logo: collectionFiltered?.logo || "",
      });
      setFileType(
        collectionFiltered.banner
          ? collectionFiltered.banner.includes(".mp4")
            ? "video/mp4"
            : ""
          : ""
      );
    }
  }, [previewCollection]); // eslint-disable-line

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    description: Yup.string().nullable(),
    banner: Yup.mixed().test("fileType", "Unsupported File Format", (value) => {
      if (typeof value === "string") {
        return true;
      }
      return value ? supportedBannerFileExt.includes(value?.type) : true;
    }),
    logo: Yup.mixed().test("fileType", "Unsupported File Format", (value) => {
      if (typeof value === "string") {
        return true;
      }
      return value ? supportedLogoFileExt.includes(value?.type) : true;
    }),
  });

  const onSubmitCallback = () => {
    if (imported) {
      handleClose();
    } else {
      navigate("/collection/" + collectionId);
    }
  };

  const handleEditCollection = (data) => {
    const payload = {
      ...data,
      id: collectionId,
    };

    if (typeof data.banner === "string") {
      delete payload.banner;
    }
    if (typeof data.logo === "string") {
      delete payload.logo;
    }
    if (initialValues.name === data.name) {
      delete payload.name;
    }
    if (initialValues.description === data.description) {
      delete payload.description;
    }
    if (Object.keys(payload).length > 1) {
      return dispatch(
        collectionActions.handleEditCollection(payload, onSubmitCallback)
      );
    } else {
      return toast.info("You have not made any changes");
    }
  };

  return (
    <div className="relative">
      <div
        className={`${
          imported ? "" : "absolute"
        } sm:top-0 top-20 w-full h-full mb-5`}
      >
        {/* head */}
        {!imported ? (
          <div className="md:mt-4 md:mx-20 mx-4 mt-4 grid grid-cols-1 md:grid-cols-2 justify-between bg-transparent">
            <div className="flex flex-col justify-items-end">
              <span className="text-[#FE4502] font-extrabold text-lg">
                Collections
              </span>

              <span className="text-[#757575] text-base">Edit collection.</span>
            </div>
            <div className="grid grid-flow-col items-center justify-between md:justify-end py-4 pt-4 md:p-0 text-slate-600">
              <button
                onClick={() => navigate("/collection/" + collectionId)}
                className="inline-block font-medium text-xl leading-tight hover:shadow-md hover:bg-light-gray shadow-gray-900 focus:bg-white focus:shadow-xl focus:outline-none focus:ring-0 active:shadow-lg hover:scale-105 ease-in-out duration-300 p-3 bg-white w-10 h-10 flex justify-center items-center"
                style={{ borderRadius: "100%", border: "0.24px solid #000080" }}
              >
                <BsXLg className="text-[#FE4502] font-bold" />
              </button>
            </div>
          </div>
        ) : null}

        {/* Edit collection form------------------------------------------------- */}

        <div
          className={`${
            imported ? "" : "p-6"
          } m-4 rounded-3xl bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] shadow grid`}
        >
          <div className="flex flex-col w-full">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleEditCollection}
              validationSchema={FormSchema}
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form>
                  <div
                    className="relative rounded-3xl min-h-[370px] bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] shadow object-contain flex flex-wrap text-gray-600"
                    style={{
                      backgroundImage:
                        !fileType.includes("video/mp4") &&
                        values?.banner &&
                        typeof values.banner !== "string"
                          ? `url(${URL.createObjectURL(values.banner)})`
                          : `url(${initialValues.banner})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    {fileType.includes("video/mp4") ? (
                      <video
                        className="absolute w-full h-full rounded-2xl"
                        style={{ objectFit: "fill" }}
                        controls={false}
                        loop
                        muted
                        autoPlay
                      >
                        <source
                          src={
                            typeof values.banner !== "string"
                              ? URL.createObjectURL(values.banner)
                              : initialValues.banner
                          }
                          type="video/mp4"
                        />
                        <source
                          src={
                            typeof values.banner !== "string"
                              ? URL.createObjectURL(values.banner)
                              : initialValues.banner
                          }
                          type="video/ogg"
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : null}

                    <div
                      className="absolute right-10 top-5 flex"
                      style={{ zIndex: "100" }}
                    >
                      {!preview ? (
                        <>
                          <label className="bg-transparent flex flex-row items-center justify-center rounded hover:bg-white drop-shadow-xl mr-1">
                            <span
                              className="rounded border text-sm px-2 flex items-center"
                              style={{ backgroundColor: "white" }}
                            >
                              <RiEditCircleFill className="hover:scale-105 ease-in-out duration-300" />
                              Edit Logo
                            </span>
                            <input
                              className="floating-input object-contain h-14 pl-2 text-lg text-uberark-blue hidden"
                              name="logo"
                              id="logo"
                              type="file"
                              onChange={(event) => {
                                if (
                                  supportedLogoFileExt.includes(
                                    event.currentTarget.files[0]["type"]
                                  )
                                ) {
                                  setFieldValue(
                                    "logo",
                                    event.currentTarget.files[0]
                                  );
                                } else {
                                  toast.error("The logo should be image!");
                                }
                              }}
                            />
                          </label>
                          <label className="bg-transparent flex flex-row items-center justify-center rounded hover:bg-white drop-shadow-xl">
                            <span
                              className="rounded border text-sm px-2 flex items-center"
                              style={{ backgroundColor: "white" }}
                            >
                              <RiEditCircleFill className="hover:scale-105 ease-in-out duration-300" />
                              Edit Banner
                            </span>
                            <input
                              className="floating-input h-14 pl-2 text-lg text-uberark-blue hidden"
                              name="banner"
                              id="banner"
                              type="file"
                              onChange={(event) => {
                                if (
                                  supportedBannerFileExt.includes(
                                    event.currentTarget.files[0]["type"]
                                  )
                                ) {
                                  setFileType(
                                    event.currentTarget.files[0]["type"]
                                  );
                                  setFieldValue(
                                    "banner",
                                    event.currentTarget.files[0]
                                  );
                                } else {
                                  toast.error(
                                    "The banner should be image or video!"
                                  );
                                }
                              }}
                            />
                          </label>
                        </>
                      ) : null}

                      <button
                        className={`${
                          preview ? "text-[#FE4502]" : ""
                        } ml-2 py-1 px-2 bg-white flex flex-row items-center justify-center rounded hover:bg-white drop-shadow-xl mr-1`}
                        type="button"
                        onClick={() => setPreview(!preview)}
                      >
                        <BsFillEyeFill className="hover:scale-105 ease-in-out duration-300" />
                      </button>

                      {errors.banner && touched.banner && (
                        <div className="text-red-600 pb-2">{errors.banner}</div>
                      )}
                      {errors.logo && touched.logo && (
                        <div className="text-red-600 pb-2">{errors.logo}</div>
                      )}
                    </div>

                    <div
                      className="relative p-8 pt-2 justify-between w-full relative bg-[#fafafd4f] rounded-3xl"
                      style={{
                        background:
                          "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QgdBy0F1sAe4QAAACFJREFUCNdj/P//vy8DBNgzMDAwMDGgARaYDAMDw0GsKgD/0gSTo/gcFwAAAABJRU5ErkJggg==) repeat hsla(0,0%,100%,.4)",
                      }}
                    >
                      <div className="w-full">
                        <div className="flex flex-col h-[40px] w-[80px]">
                          <img
                            src={
                              typeof values.logo !== "string" &&
                              supportedLogoFileExt.includes(values.logo["type"])
                                ? URL.createObjectURL(values.logo)
                                : initialValues.logo
                            }
                            alt="logo"
                            style={{
                              visibility: values.logo ? "visible" : "hidden",
                            }}
                            className="object-contain h-[40px] w-[80px] rounded"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col items-center md:flex-row">
                        <div className="flex sm:flex-row flex-col pt-2 gap-4 min-h-[166px] md:w-3/4 w-full pr-2">
                          <div className="flex flex-col gap-2">
                            <span className="text-[#FE4502] font-bold text-2xl text-left">
                              {!preview ? (
                                <Field
                                  placeholder="Enter collection name"
                                  className=" p-0 outline-none input-transparent w-full"
                                  name="name"
                                  id="name"
                                />
                              ) : (
                                <>{values.name}</>
                              )}

                              {errors.name && touched.name && (
                                <div className="text-red-600 pb-2">
                                  {errors.name}
                                </div>
                              )}
                            </span>
                            {!preview ? (
                              <RichTextEditorComponent
                                change={(event) => {
                                  setFieldValue("description", event.value);
                                }}
                                value={values.description}
                                toolbarSettings={RichTextEditorToolbar}
                                className="richtext-border-remove"
                              >
                                <Inject
                                  services={[HtmlEditor, Toolbar, Link]}
                                />
                              </RichTextEditorComponent>
                            ) : (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: values?.description,
                                }}
                              />
                            )}
                            {errors.description && touched.description && (
                              <div className="text-red-600 pb-2">
                                {errors.description}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="flex flex-col flex-wrap h-full md:w-1/4 w-full"></div>
                      </div>
                    </div>

                    <div className="flex flex-row text-uberark-blue gap-4 text-lg mb-3 absolute bottom-2 right-5">
                      <button
                        className={`${
                          !imported ? "" : "ml-2"
                        } :px-2 bg-[#000080] text-white  hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl`}
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <LoadingSpinner /> : "Save"}
                      </button>
                      <button
                        className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
                        onClick={() => onSubmitCallback()}
                        style={{ border: "0.24px solid #000080" }}
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSingleCollection;
