import {
  handleResponse,
  axiosInstance
} from '../../../app/utilities/helpers/'

const handleCompose = (data) => axiosInstance.post('compose/send', data).then(handleResponse)

export const authService = {
  handleCompose
}
