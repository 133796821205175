import {
  handleResponse,
  axiosInstance
} from '../../../app/utilities/helpers/'

const handleConversationsListsFetch = (queryUrl) => axiosInstance.get(`${queryUrl}`).then(handleResponse)

const handleConversationsDelete = (id) => axiosInstance.delete(`compose/send/${id}`).then(handleResponse)

const handleConversationsAccess = (payload) => axiosInstance.put(`compose/contact`, payload).then(handleResponse)

export const authService = {
  handleConversationsListsFetch,
  handleConversationsDelete,
  handleConversationsAccess
}
