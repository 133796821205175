import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { AiOutlinePlus } from "react-icons/ai";
import { BsXLg } from "react-icons/bs";
import { Contacts as ContactsPage } from "./components/Contacts";
import Distributions from "./components/Distributions";

const Contacts = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState("contact");
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const activeLink =
    "flex items-center gap-2 pl-4 text-base text-md font-semibold text-uberark-orange hover:text-[#fe6702]";
  const normalLink =
    "flex items-center gap-2 pl-4 text-base text-md font-semibold text-[#000080] dark:text-gray-200 dark:hover:text-black hover:text-[#fe6702]";

  useEffect(() => {
    if (location) {
      if (location.pathname.toLowerCase().includes("contact")) {
        setPage("contact");
      } else if (location.pathname.toLowerCase().includes("distribution")) {
        setPage("distribution");
      }

      if (location.pathname.toLowerCase().includes("add")) {
        setIsAdd(true);
      } else if (location.pathname.toLowerCase().includes("edit")) {
        setIsEdit(true);
      } else {
        setIsAdd(false);
        setIsEdit(false);
      }
    }
  }, [location]);

  return (
    <div className="relative">
      <div className="absolute sm:top-0 top-20 w-full h-full mb-5">
        {/* head ------------------------------------------------------------------------------------------------------------- */}
        <div className="md:mt-4 md:mx-20 mx-4 mt-4 grid grid-cols-1 md:grid-cols-2 justify-between bg-transparent">
          <div className="flex flex-col justify-items-end">
            <span className="text-[#FE4502] font-extrabold text-xl">
              {page === "contact" ? "Contacts" : "Distributions"}
            </span>

            <span className="text-[#757575] text-base">
              your very own rolodex
            </span>
          </div>

          <div className="grid grid-flow-col items-center justify-between md:justify-end py-4 pt-4 md:p-0 text-slate-600">
            {!isAdd ? (
              <button
                onClick={() => navigate("./add")}
                className="inline-block font-medium text-xl leading-tight hover:shadow-md hover:bg-light-gray shadow-gray-900 focus:bg-white focus:shadow-xl focus:outline-none focus:ring-0 active:shadow-lg hover:scale-105 ease-in-out duration-300 p-3 bg-white w-10 h-10 flex justify-center items-center"
                style={{
                  borderRadius: "100%",
                  border: "0.24px solid #000080",
                }}
              >
                <AiOutlinePlus className="text-[#FE4502] font-bold" />
              </button>
            ) : (
              <button
                onClick={() =>
                  page === "contact"
                    ? navigate("/contacts")
                    : navigate("/distributions")
                }
                className="inline-block font-medium text-xl leading-tight hover:shadow-md hover:bg-light-gray shadow-gray-900 focus:bg-white focus:shadow-xl focus:outline-none focus:ring-0 active:shadow-lg hover:scale-105 ease-in-out duration-300 p-3 bg-white w-10 h-10 flex justify-center items-center"
                style={{
                  borderRadius: "100%",
                  border: "0.24px solid #000080",
                }}
              >
                <BsXLg className="text-[#FE4502] font-bold" />
              </button>
            )}
          </div>
        </div>

        {/* body  ------------------------------------------------------------------------------------------------------------- */}
        <div className="rounded-3xl bg-white m-4 py-6 px-2 shadow flex flex-wrap min-h-[75vh]">
          <div className="w-full">
            <div className="flex flex-row justify-center gap-4 divide-x-2 divide-slate-500 w-full">
              <div
                onMouseDown={() => navigate("/contacts")}
                className={page === "contact" ? activeLink : normalLink}
              >
                Contact
              </div>
              <div
                onMouseDown={() => navigate("/distributions")}
                className={page === "distribution" ? activeLink : normalLink}
              >
                Distribution
              </div>
            </div>
            {page === "contact" ? (
              <ContactsPage isAdd={isAdd} />
            ) : (
              <Distributions isAdd={isAdd} isEdit={isEdit} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
