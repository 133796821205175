/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Tree from "react-tree-file-system";
import "react-tree-file-system/index.css";
import { BsXCircleFill, BsFillCheckCircleFill } from "react-icons/bs";

import AssetCard from "../AssetCard";
import { collectionActions } from "../../../../Collections/actions";

const AssetAddCollections = ({
  handleFileClick,
  selectedAssets,
  openGroup,
}) => {
  const dispatch = useDispatch();
  const { collectionTree } = useSelector((state) => state.collection);
  const { user } = useSelector((state) => state.auth);
  const [data, setData] = useState({ treeValue: [] });

  useEffect(() => {
    if (user.organization && !openGroup) {
      dispatch(collectionActions.handleGetAllCollection(user.organization));
    }
  }, [user.organization, dispatch]);

  useEffect(() => {
    const formatedCollections = [];
    if (collectionTree && collectionTree.length) {
      collectionTree.map((item, index) => {
        const formatedAssets = [];
        if (item?.asset && item?.asset.length && !item.deleted) {
          item.asset.map((asset, assetIdx) => {
            if (!asset.deleted) {
              formatedAssets.push({
                fileIcon: (
                  <FileIcon asset={asset} selectedAssets={selectedAssets} />
                ),
                title: asset.name,
                id: asset.id,
                file: asset.file,
                parentType: "collections",
              });
            }

            return true;
          });
        }

        formatedCollections.push({
          title: item.name,
          type: "folder",
          id: item.id,
          parent_group: item.id,
          children: formatedAssets,
          isOpen: true,
        });

        return true;
      });

      setData(() => ({
        treeValue: formatedCollections,
      }));
    }
  }, [collectionTree, selectedAssets]);

  const recursiveSetState = (tree, indexes, currIndex, key, value) => {
    const dupTree = tree.slice();
    const getIndex = indexes[currIndex];
    if (currIndex === indexes.length - 1) {
      dupTree[getIndex][key] = value;
      return tree;
    }
    dupTree[getIndex].children = recursiveSetState(
      (dupTree[getIndex].children || []).slice(0),
      indexes,
      currIndex + 1,
      key,
      value
    );
    return dupTree;
  };

  return (
    <Tree
      value={data.treeValue}
      style={{ width: "-webkit-fill-available" }}
      folderOnClick={(_, indexes, state) => {
        setData((prevState) => {
          return {
            treeValue: recursiveSetState(
              prevState.treeValue,
              indexes,
              0,
              "isOpen",
              state
            ),
          };
        });
      }}
      fileOnClick={(_, indexes, state) => handleFileClick(_, indexes, state)}
    />
  );
};

export default AssetAddCollections;

const FileIcon = ({ asset, selectedAssets }) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (selectedAssets) {
      const selected = selectedAssets.filter((item) => item.id === asset.id);
      setIsSelected(() =>
        selected.length === 1 && selected[0].id ? true : false
      );
    }
  }, [selectedAssets]);

  return (
    <div className="flex flex-col mt-1">
      {isSelected ? (
        <div className="file-card__delete animate-pulse">
          <BsXCircleFill />
        </div>
      ) : (
        <div className="file-card__add animate-pulse">
          <BsFillCheckCircleFill />
        </div>
      )}
      <AssetCard asset={asset} size="asset__small" className="file-tree" />
    </div>
  );
};
