import React, { useState, useEffect } from "react";

import ReactPaginate from "react-paginate";

import data from "../../../app/utilities/data/mock/contentTableData.json";
import headData from "../../../app/utilities/data/mock/contentTableHead.json";
import "./chart.css";

const RecentUpload = () => {
  const [files, setFiles] = useState();
  const [heading, setHeading] = useState();
  const [pageNumber, setPageNumber] = useState(0);

  const filesPerPage = 4;
  const pagesVisited = pageNumber * filesPerPage;

  useEffect(() => {
    setHeading(headData);
  }, []);

  useEffect(() => {
    setFiles(data.slice(0, 50));
  }, []);

  const displayFiles = files
    ?.slice(pagesVisited, pagesVisited + filesPerPage)
    .map((file, index) => (
      <tr key={index}>
        <td className="border-b-0">{file.name} </td>
        <td className="text-center  border-b-0">{file.uploaded} </td>
        <td className="text-center border-b-0 text-gray-500">
          {file.fileType}
        </td>
        <td className="flex items-center justify-center w-full border-b-0">
          <div className="border border-uberark-blue rounded w-1/2 flex justify-center">
            View
          </div>
        </td>
      </tr>
    ));

  const displayHeading = heading
    ?.slice(pagesVisited, pagesVisited + filesPerPage)
    .map((heading, index) => (
      <div
        key={index}
        className="space-y-3 p-4 phoneTable sm:grid-cols-2 tableShadow"
      >
        <div className="grid grid-flow-col justify-between">
          <div className="font-bold">Name:</div>
          <div>{heading.name}</div>
        </div>
        <div className="grid grid-flow-col justify-between">
          <div className="font-bold">Uploaded On:</div>
          {heading.uploaded}
        </div>
        <div className="grid grid-flow-col justify-between">
          <div className="font-bold">File Type:</div>
          <span className="text-gray-500">{heading.views}</span>
        </div>
        <div className="grid grid-flow-col justify-between">
          <div className="font-bold">Action:</div>
          <div className="border border-uberark-blue rounded px-1 flex justify-center">
            View
          </div>
        </div>
      </div>
    ));

  const pageCount = files ? Math.ceil(files.length / filesPerPage) : 0;

  const changePage = ({ selected }) => setPageNumber(selected);

  return (
    <div>
      <div className="hidden md:flex">
        <table className="border-collapse w-full text-uberark-blue mb-10">
          <thead>
            <tr>
              <th className="border-b-0">Name</th>
              <th className="text-center border-b-0">Uploaded On</th>
              <th className="text-center border-b-0">File Type</th>
              <th className="text-center border-b-0">Action</th>
            </tr>
          </thead>
          <tbody>{displayFiles}</tbody>
        </table>
      </div>

      <div>
        {/* .map((file) => {
         (
          <tr>
            <td>{file.name}</td>
            <td>{file.fileType} </td>
            <td className="text-center">{file.views}</td>
            <td className="text-center">{file.shares}</td>
            <td className="text-center">{file.downloads}</td>
          </tr>
        );
      }); */}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:hidden p-2 text-uberark-blue">
        {displayHeading}
      </div>

      <div className="py-4">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          marginPagesDisplayed={0}
          pageRangeDisplayed={1}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
    </div>
  );
};

export default RecentUpload;
