import * as types from "../actions/actionTypes";

const initialState = {
  organization: {},
  users: [],
  loading: false,
  eSignDltloading: false,
  inviteConfirmed: true,
  deleteConfirmation: false,
  message: "",
  errors: {},
  userListCount: 1,
  nda: [],
  ndaCreation: false,
  ndaDeleteConfirmation: false,
  eTemplate: [],
  eTemplateCreation: false,
  eTemplateDeleteConfirmation: false,
  tac: [],
  tacCreation: false,
  tacDeleteConfirmation: false,
  eSign: [],
  eSignCreation: false,
  eSignDeleteConfirmation: false,
  notifications: [],
  notificationsUpdate: false,
  notificationSettings: null,
  customFields: [],
  customFieldsCreation: false,
  customFieldsDeleteConfirmation: false,
  privacyPolicy: [],
  privacyPolicyCreation: false,
  privacyPolicyDeleteConfirmation: false,
  loadingCustomFields: false,
  share: {},
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ACCOUNT_MANAGEMENT_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
        inviteConfirmed: true,
      };
    case types.ACCOUNT_MANAGEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        organization: action.data,
        inviteConfirmed: true,
      };
    case types.ACCOUNT_MANAGEMENT_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        inviteConfirmed: true,
      };

    case types.USER_INVITE_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
        inviteConfirmed: true,
      };
    case types.USER_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        inviteConfirmed: false,
      };
    case types.USER_INVITE_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        inviteConfirmed: true,
      };

    case types.FETCH_USER_LISTS_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
        deleteConfirmation: false,
        inviteConfirmed: true,
      };
    case types.FETCH_USER_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.data.user.results,
        userListCount: action.data.user.count,
        deleteConfirmation: false,
        inviteConfirmed: true,
      };
    case types.FETCH_USER_LISTS_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        deleteConfirmation: false,
        inviteConfirmed: true,
      };

    case types.USER_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
        deleteConfirmation: false,
        inviteConfirmed: true,
      };
    case types.USER_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteConfirmation: true,
      };
    case types.USER_DELETE_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        deleteConfirmation: false,
        inviteConfirmed: true,
      };

    case types.FETCH_NDA_LISTS_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
        inviteConfirmed: true,
        deleteConfirmation: false,
        ndaCreation: false,
      };
    case types.FETCH_NDA_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        nda: action.data,
        inviteConfirmed: true,
        deleteConfirmation: false,
        ndaCreation: false,
        ndaDeleteConfirmation: false,
      };
    case types.FETCH_NDA_LISTS_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        inviteConfirmed: true,
        deleteConfirmation: false,
        ndaCreation: false,
      };

    case types.CREATE_NDA_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
        inviteConfirmed: true,
        deleteConfirmation: false,
        ndaCreation: false,
      };
    case types.CREATE_NDA_SUCCESS:
      return {
        ...state,
        loading: false,
        inviteConfirmed: true,
        deleteConfirmation: false,
        ndaCreation: true,
      };
    case types.CREATE_NDA_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        inviteConfirmed: true,
        deleteConfirmation: false,
        ndaCreation: false,
      };

    case types.DELETE_NDA_REQUEST:
      return {
        ...state,
        ndaDeleteConfirmation: false,
        errors: {},
        message: "",
        loading: true,
      };
    case types.DELETE_NDA_SUCCESS:
      return {
        ...state,
        ndaDeleteConfirmation: true,
        loading: false,
      };
    case types.DELETE_NDA_FAILED:
      return {
        ...state,
        errors: action.error,
        ndaDeleteConfirmation: false,
        loading: false,
      };

    case types.FETCH_ETEMPLATE_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        eTemplate: action.data,
        deleteConfirmation: false,
        eTemplateCreation: false,
        eTemplateDeleteConfirmation: false,
      };
    case types.FETCH_ETEMPLATE_LISTS_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        deleteConfirmation: false,
        eTemplateCreation: false,
      };

    case types.CREATE_ETEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
        deleteConfirmation: false,
        eTemplateCreation: false,
      };
    case types.CREATE_ETEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteConfirmation: false,
        eTemplateCreation: true,
      };
    case types.CREATE_ETEMPLATE_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        inviteConfirmed: true,
        deleteConfirmation: false,
        eTemplateCreation: false,
      };

    case types.DELETE_ETEMPLATE_REQUEST:
      return {
        ...state,
        eTemplateDeleteConfirmation: false,
        errors: {},
        message: "",
        loading: true,
      };
    case types.DELETE_ETEMPLATE_SUCCESS:
      return {
        ...state,
        eTemplateDeleteConfirmation: true,
        loading: false,
      };
    case types.DELETE_ETEMPLATE_FAILED:
      return {
        ...state,
        errors: action.error,
        eTemplateDeleteConfirmation: false,
        loading: false,
      };

    case types.FETCH_TAC_LISTS_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
        tacCreation: false,
        tacDeleteConfirmation: false,
      };
    case types.FETCH_TAC_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        tac: action.data,
        tacCreation: false,
        tacDeleteConfirmation: false,
      };
    case types.FETCH_TAC_LISTS_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        tacCreation: false,
        tacDeleteConfirmation: false,
      };

    case types.CREATE_TAC_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
        tacCreation: false,
        tacDeleteConfirmation: false,
      };
    case types.CREATE_TAC_SUCCESS:
      return {
        ...state,
        loading: false,
        tacCreation: true,
        tacDeleteConfirmation: false,
      };
    case types.CREATE_TAC_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        tacCreation: false,
        tacDeleteConfirmation: false,
      };

    case types.DELETE_TAC_REQUEST:
      return {
        ...state,
        tacDeleteConfirmation: false,
        tacCreation: false,
        errors: {},
        message: "",
        loading: true,
      };
    case types.DELETE_TAC_SUCCESS:
      return {
        ...state,
        tacDeleteConfirmation: true,
        tacCreation: false,
        loading: false,
      };
    case types.DELETE_TAC_FAILED:
      return {
        ...state,
        errors: action.error,
        tacDeleteConfirmation: false,
        tacCreation: false,
        loading: false,
      };

    case types.FETCH_ESIGN_LISTS_SUCCESS:
      return {
        ...state,
        loadingEsign: false,
        eSign: action.data,
        eSignCreation: false,
        eSignDeleteConfirmation: false,
      };
    case types.FETCH_ESIGN_LISTS_FAILED:
      return {
        ...state,
        errors: action.error,
        loadingEsign: false,
        eSignCreation: false,
      };

    case types.CREATE_ESIGN_REQUEST:
      return {
        ...state,
        loadingEsign: true,
        errors: {},
        message: "",
        eSignCreation: false,
      };
    case types.CREATE_ESIGN_SUCCESS:
      return {
        ...state,
        loadingEsign: false,
        eSignCreation: true,
      };
    case types.CREATE_ESIGN_FAILED:
      return {
        ...state,
        errors: action.error,
        loadingEsign: false,
        eSignCreation: false,
      };

    case types.DELETE_ESIGN_REQUEST:
      return {
        ...state,
        eSignDeleteConfirmation: false,
        errors: {},
        message: "",
        loadingEsign: true,
      };
    case types.DELETE_ESIGN_SUCCESS:
      return {
        ...state,
        eSignDeleteConfirmation: true,
        loadingEsign: false,
      };
    case types.DELETE_ESIGN_FAILED:
      return {
        ...state,
        errors: action.error,
        eSignDeleteConfirmation: false,
        loadingEsign: false,
      };

    case types.FETCH_NOTIFICATION_LISTS_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
        notificationsUpdate: false,
      };
    case types.FETCH_NOTIFICATION_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.data,
        notificationsUpdate: false,
      };
    case types.FETCH_NOTIFICATION_LISTS_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        notificationsUpdate: false,
      };

    case types.UPDATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
        notificationsUpdate: false,
      };
    case types.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationsUpdate: true,
      };
    case types.UPDATE_NOTIFICATION_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        notificationsUpdate: false,
      };

    case types.GET_NOTIFICATION_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
      };
    case types.GET_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationSettings: action.data,
      };
    case types.GET_NOTIFICATION_SETTINGS_FAILURE:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };

    case types.EDIT_NOTIFICATION_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
      };
    case types.EDIT_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        notificationSettings: action.data,
        loading: false,
      };
    case types.EDIT_NOTIFICATION_SETTINGS_FAILURE:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };

    case types.FETCH_CF_LISTS_REQUEST:
      return {
        ...state,
        loadingCustomFields: true,
        errors: {},
        customFieldsCreation: false,
        customFieldsDeleteConfirmation: false,
        message: "",
      };
    case types.FETCH_CF_LISTS_SUCCESS:
      return {
        ...state,
        customFields: action.data,
        customFieldsCreation: false,
        customFieldsDeleteConfirmation: false,
        loadingCustomFields: false,
      };
    case types.FETCH_CF_LISTS_FAILED:
      return {
        ...state,
        errors: action.error,
        loadingCustomFields: false,
      };

    case types.CREATE_CF_REQUEST:
      return {
        ...state,
        loadingCustomFields: true,
        errors: {},
        customFieldsCreation: false,
        message: "",
      };
    case types.CREATE_CF_SUCCESS:
      return {
        ...state,
        customFieldsCreation: true,
        loadingCustomFields: false,
      };
    case types.CREATE_CF_FAILED:
      return {
        ...state,
        errors: action.error,
        customFieldsCreation: false,
        loadingCustomFields: false,
      };

    case types.DELETE_CF_REQUEST:
      return {
        ...state,
        loadingCustomFields: true,
        errors: {},
        customFieldsDeleteConfirmation: false,
        message: "",
      };
    case types.DELETE_CF_SUCCESS:
      return {
        ...state,
        customFieldsDeleteConfirmation: true,
        loadingCustomFields: false,
      };
    case types.DELETE_CF_FAILED:
      return {
        ...state,
        errors: action.error,
        customFieldsDeleteConfirmation: true,
        loadingCustomFields: false,
      };

    case types.FETCH_PRIVACY_POLICY_LISTS_REQUEST:
      return {
        ...state,
        errors: {},
        privacyPolicyCreation: false,
        privacyPolicyDeleteConfirmation: false,
        message: "",
      };
    case types.FETCH_PRIVACY_POLICY_LISTS_SUCCESS:
      return {
        ...state,
        privacyPolicy: action.data,
        privacyPolicyCreation: false,
        privacyPolicyDeleteConfirmation: false,
      };
    case types.FETCH_PRIVACY_POLICY_LISTS_FAILED:
      return {
        ...state,
        errors: action.error,
      };

    case types.CREATE_PRIVACY_POLICY_REQUEST:
      return {
        ...state,
        errors: {},
        privacyPolicyCreation: false,
        message: "",
      };
    case types.CREATE_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        privacyPolicyCreation: true,
      };
    case types.CREATE_PRIVACY_POLICY_FAILED:
      return {
        ...state,
        errors: action.error,
        privacyPolicyCreation: false,
      };

    case types.DELETE_PRIVACY_POLICY_REQUEST:
      return {
        ...state,
        errors: {},
        privacyPolicyDeleteConfirmation: false,
        message: "",
      };
    case types.DELETE_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        privacyPolicyDeleteConfirmation: true,
      };
    case types.DELETE_PRIVACY_POLICY_FAILED:
      return {
        ...state,
        errors: action.error,
        privacyPolicyDeleteConfirmation: true,
      };

    case types.GET_SHARE_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
      };
    case types.GET_SHARE_SUCCESS:
      return {
        ...state,
        loading: false,
        share: action.data,
      };
    case types.GET_SHARE_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };

    case types.CREATE_SHARE_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
      };
    case types.CREATE_SHARE_SUCCESS:
      return {
        ...state,
        loading: false,
        share: action.data,
      };
    case types.CREATE_SHARE_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };

    case types.UPDATE_SHARE_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
      };
    case types.UPDATE_SHARE_SUCCESS:
      return {
        ...state,
        loading: false,
        share: action.data,
      };
    case types.UPDATE_SHARE_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default settingsReducer;
