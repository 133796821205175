import React from 'react';

const Pp = () => {
  return (
    <div className="bg-white rounded-xl md:m-4 md:p-14 p-2 w-full flex gap-6 flex-col">
      <div className="font-bold text-uberark-orange text-xl my-6">
        {' '}
        Privacy Policy
      </div>
      <div className="rounded-2xl " style={{ border: '1px solid #000080' }}>
        <div className="rounded-2xl m-2 overflow-y-auto lg:h-[700px] h-[400px] ppolicy">
          <p className="p-6  h-[700x]">
            Ubermark enables the visitors of its site www.Ubermark.ai, to be in
            control of their personal data. We also provide necessary controls
            that allow Ubermark ‘s users/customers to have control over the
            privacy of personal data that is captured by our service. The new
            European Union’s General Data Protection Regulation (GDPR) is a new
            piece of legislation that has impact on anyone whose business
            involves handling personal data about EU residents or within the EU.
            At Ubermark, we have leveraged this opportunity to establish a data
            protection standard across our operations globally. Ubermark
            considers data protection and privacy to be of paramount importance.
            We DO NOT sell your personal data and all processing operations are
            in strict compliance with applicable laws. Ubermark enables the
            visitors of its site www.Ubermark.ai, to be in control of their
            personal data. We also provide necessary controls that allow
            Ubermark ‘s users/customers to have control over the privacy of
            personal data that is captured by our service. The new European
            Union’s General Data Protection Regulation (GDPR) is a new piece of
            legislation that has impact on anyone whose business involves
            handling personal data about EU residents or within the EU. At
            Ubermark , we have leveraged this opportunity to establish a data
            protection standard across our operations globally. Ubermark
            considers data protection and privacy to be of paramount importance.
            We DO NOT sell your personal data and all processing operations are
            in strict compliance with applicable laws. Ubermark enables the
            visitors of its site www.Ubermark.ai, to be in control of their
            personal data. We also provide necessary controls that allow
            Ubermark ‘s users/customers to have control over the privacy of
            personal data that is captured by our service. Ubermark enables the
            visitors of its site www.Ubermark.ai, to be in control of their
            personal data. We also provide necessary controls that allow
            Ubermark ‘s users/customers to have control over the privacy of
            personal data that is captured by our service. The new European
            Union’s General Data Protection Regulation (GDPR) is a new piece of
            legislation that has impact on anyone whose business involves
            handling personal data about EU residents or within the EU. At
            Ubermark, we have leveraged this opportunity to establish a data
            protection standard across our operations globally. Ubermark
            considers data protection and privacy to be of paramount importance.
            We DO NOT sell your personal data and all processing operations are
            in strict compliance with applicable laws. Ubermark enables the
            visitors of its site www.Ubermark.ai, to be in control of their
            personal data. We also provide necessary controls that allow
            Ubermark ‘s users/customers to have control over the privacy of
            personal data that is captured by our service. The new European
            Union’s General Data Protection Regulation (GDPR) is a new piece of
            legislation that has impact on anyone whose business involves
            handling personal data about EU residents or within the EU. At
            Ubermark , we have leveraged this opportunity to establish a data
            protection standard across our operations globally. Ubermark
            considers data protection and privacy to be of paramount importance.
            We DO NOT sell your personal data and all processing operations are
            in strict compliance with applicable laws. Ubermark enables the
            visitors of its site www.Ubermark.ai, to be in control of their
            personal data. We also provide necessary controls that allow
            Ubermark ‘s users/customers to have control over the privacy of
            personal data that is captured by our service.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Pp;
