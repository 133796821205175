import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "../../components/commons/LoadingSpinner";
import {
  HtmlEditor,
  Inject,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  Link,
} from "@syncfusion/ej2-react-richtexteditor";
import { toast } from "react-toastify";

import { FiEdit } from "react-icons/fi";
import { BsXLg, BsFillEyeFill } from "react-icons/bs";
import "./stream.css";

import { streamActions } from "./actions";
import { RichTextEditorToolbar } from "../../app/utilities/helpers";

const StreamAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.stream);
  const { organization } = useSelector((state) => state.auth.user);
  const [preview, setPreview] = useState(false);
  const supportedFileExt = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "video/mp4",
    "video/ogg",
  ];

  const initialValues = {
    title: "",
    description: "",
    cover_image: "",
  };

  const FormSchema = Yup.object().shape({
    title: Yup.string().required("Please enter title"),
    description: Yup.string().nullable(),
    cover_image: Yup.mixed()
      .nullable()
      .test("fileType", "Unsupported File Format", (value) =>
        value ? supportedFileExt.includes(value?.type) : true
      ),
  });

  const onSubmitCallback = (id) => {
    navigate(`/stream/${id}`);
  };

  const handleCreateStream = (data) => {
    if (organization) {
      const payload = {
        ...data,
      };
      return dispatch(
        streamActions.handleCreateStream(payload, onSubmitCallback)
      );
    }
    toast.error("You need to be in an organization to create");
  };

  return (
    <div className="relative">
      <div className="absolute sm:top-0 top-20 w-full h-full mb-5">
        <div className="md:mt-4 md:mx-20 mx-4 mt-4 grid grid-cols-1 md:grid-cols-2 justify-between bg-transparent">
          <div className="flex flex-col justify-items-end">
            <span className="text-[#FE4502] font-extrabold text-lg">
              Stream
            </span>

            <span className="text-[#757575] text-base">Create new stream.</span>
          </div>
          <div className="grid grid-flow-col items-center justify-between md:justify-end py-4 pt-4 md:p-0 text-slate-600">
            <button
              onClick={() => navigate("/hub")}
              className="inline-block font-medium text-lg leading-tight hover:shadow-md hover:bg-light-gray shadow-gray-900 focus:bg-white focus:shadow-xl focus:outline-none focus:ring-0 active:shadow-lg hover:scale-105 ease-in-out duration-300 p-3 bg-white w-10 h-10 flex justify-center items-center"
              style={{ borderRadius: "100%", border: "0.24px solid #000080" }}
            >
              <BsXLg className="text-[#FE4502] font-bold" />
            </button>
          </div>
        </div>

        {/* Add stream ------------------------------------------------------------------------------------------------------- */}

        <div className="rounded-3xl bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] m-4 shadow grid">
          <div className="flex flex-col w-full">
            <Formik
              initialValues={initialValues}
              onSubmit={handleCreateStream}
              validationSchema={FormSchema}
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form>
                  <div
                    className="relative bg-white min-h-[370px] rounded-3xl object-contain flex flex-wrap text-gray-600 w-full"
                    style={{
                      backgroundImage:
                        values?.cover_image &&
                        supportedFileExt.includes(values.cover_image["type"])
                          ? `url(${URL.createObjectURL(values.cover_image)})`
                          : "",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    {values.cover_image &&
                    values.cover_image["type"].includes("mp4") ? (
                      <video
                        className="absolute w-full h-full rounded-3xl"
                        style={{ objectFit: "fill" }}
                        controls={false}
                        loop
                        muted
                        autoPlay
                      >
                        <source
                          src={URL.createObjectURL(values.cover_image)}
                          type="video/mp4"
                        />
                        <source
                          src={URL.createObjectURL(values.cover_image)}
                          type="video/ogg"
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : !values.cover_image["type"] &&
                      values.cover_image.includes(".mp4") ? (
                      <video
                        className="absolute w-full h-full rounded-3xl"
                        style={{ objectFit: "fill" }}
                        controls={false}
                        loop
                        muted
                        autoPlay
                      >
                        <source src={values.cover_image} type="video/mp4" />
                        <source src={values.cover_image} type="video/ogg" />
                        Your browser does not support the video tag.
                      </video>
                    ) : null}

                    <div
                      className="absolute right-10 top-5 flex"
                      style={{ zIndex: "100" }}
                    >
                      {!preview ? (
                        <label className="bg-transparent flex flex-row items-center justify-center rounded hover:bg-white drop-shadow-xl mr-1">
                          <span
                            className="rounded border px-2 flex items-center"
                            style={{ backgroundColor: "white" }}
                          >
                            <FiEdit className="hover:scale-105 ease-in-out duration-300" />
                            {values?.cover_image ? "Edit" : "Add"} Cover Image
                          </span>
                          <input
                            className="floating-input h-14 pl-2 text-lg text-uberark-blue hidden"
                            name="cover_image"
                            id="cover_image"
                            type="file"
                            onChange={(event) => {
                              if (
                                supportedFileExt.includes(
                                  event.currentTarget.files[0]["type"]
                                )
                              ) {
                                setFieldValue(
                                  "cover_image",
                                  event.currentTarget.files[0]
                                );
                              } else {
                                toast.error("The file should be an image!");
                              }
                            }}
                          />
                        </label>
                      ) : null}

                      <button
                        className={`${
                          preview ? "text-[#FE4502]" : ""
                        } ml-2 border px-2 bg-white flex flex-row items-center justify-center rounded hover:bg-white drop-shadow-xl mr-1`}
                        type="button"
                        onClick={() => setPreview(!preview)}
                      >
                        <BsFillEyeFill className="hover:scale-105 ease-in-out duration-300" />
                      </button>
                    </div>

                    <div
                      className="flex flex-col lg:flex-row md:justify-between w-full bg-[#fafafd4f] p-4 rounded-3xl"
                      style={{
                        background:
                          "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QgdBy0F1sAe4QAAACFJREFUCNdj/P//vy8DBNgzMDAwMDGgARaYDAMDw0GsKgD/0gSTo/gcFwAAAABJRU5ErkJggg==) repeat hsla(0,0%,100%,.4)",
                        zIndex: "1",
                      }}
                    >
                      <div className="flex sm:flex-row flex-col gap-2 mt-5">
                        <div className="flex flex-col p-4 w-80 md:w-[550px] min-h-[166px] gap-2">
                          {errors.cover_image && touched.cover_image && (
                            <div className="text-red-600 pb-2 font-normal text-sm">
                              {errors.cover_image}
                            </div>
                          )}

                          <div className="text-[#FE4502] font-bold text-2xl text-left">
                            {!preview ? (
                              <Field
                                placeholder="Enter stream title"
                                className="p-2 outline-none input-transparent"
                                name="title"
                                id="title"
                              />
                            ) : (
                              <>{values.title}</>
                            )}

                            {errors.title && touched.title && (
                              <div className="text-red-600 pb-2 font-normal text-sm">
                                {errors.title}
                              </div>
                            )}
                          </div>
                          <div>
                            {!preview ? (
                              <RichTextEditorComponent
                                change={(event) => {
                                  setFieldValue("description", event.value);
                                }}
                                value={values.description}
                                toolbarSettings={RichTextEditorToolbar}
                                className="richtext-border-remove text-[#FE4502]"
                                placeholder="Enter stream description..."
                              >
                                <Inject
                                  services={[
                                    HtmlEditor,
                                    Toolbar,
                                    QuickToolbar,
                                    Link,
                                  ]}
                                />
                              </RichTextEditorComponent>
                            ) : (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: values?.description,
                                }}
                              />
                            )}

                            {errors.description && touched.description && (
                              <div className="text-red-600 pb-2">
                                {errors.description}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="flex gap-4 justify-center flex-wrap"></div>
                    </div>

                    <div
                      className="flex flex-row text-uberark-blue gap-4 text-lg mb-3 absolute bottom-2 right-5"
                      style={{ zIndex: "100" }}
                    >
                      <button
                        className=":px-2 bg-[#000080] text-white hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl "
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <LoadingSpinner /> : "Save"}
                      </button>
                      <button
                        className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
                        onClick={() => navigate("/streams")}
                        style={{ border: "0.24px solid #000080" }}
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreamAdd;
