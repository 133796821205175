export const RichTextEditorToolbar = {
  items: [
    "Bold",
    "Italic",
    "Underline",
    "Formats",
    "Alignments",
    "OrderedList",
    "UnorderedList",
    "Outdent",
    "Indent",
    "CreateLink",
    "Undo",
    "Redo",
  ],
  type: "Expand",
};

export const imageExtensions = ["jpeg", "jpg", "png"];
export const videoExtensions = ["mp4"];
export const iframeExtensions = [
  "pdf",
  "ppt",
  "pptx",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "csv",
];
export const allFileExtensions =
  imageExtensions + videoExtensions + iframeExtensions;

const units = ["bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

export const handleGetReadableBytes = (x) => {
  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};
