import React from "react";

const LoadingLinear = ({ LoadingProgressColor, absolute }) => {
  return (
    <div className={`${absolute ? "absolute" : ""} indeterminate-progress-bar`}>
      <div
        className="indeterminate-progress-bar__progress"
        style={{ backgroundColor: LoadingProgressColor }}
      ></div>
    </div>
  );
};

export default LoadingLinear;
