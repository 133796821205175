import React from 'react';

// GoogleOAuthProvider
import { GoogleOAuthProvider } from '@react-oauth/google';
import Router from './app/router/'

import './App.css';


const App = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router />
    </GoogleOAuthProvider>
  );
};

export default App;
