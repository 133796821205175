import React from "react";

import AssetCard from "../../commons/Asset/components/AssetCard";
import { BsFillShareFill, BsXCircle } from "react-icons/bs";

import { onDateDDMMMYYYYHHMM } from "../../../app/utilities/helpers";
import "../Conversation.css";

const ConversationPreview = ({
  collection,
  onShareCollectionClose,
  onResendConversation,
}) => {
  return (
    <div className="w-full h-full">
      <div className="mt-1 lg:mx-20  flex flex-row items-center justify-between bg-transparent">
        <div className="flex flex-col ml-6 lg:ml-0">
          <span className="text-[#FE4502] font-extrabold  text-lg">
            {collection?.subject}
          </span>
          <span className="text-[#757575] text-14">
            {onDateDDMMMYYYYHHMM(collection?.updated_at)}
          </span>
        </div>
        <div className="divide-x text-sm divide-slate-500 grid grid-flow-col">
          <div
            className="grid justify-center px-2"
            onMouseDown={() => onResendConversation()}
          >
            <button className="grid grid-flow-col items-center gap-1 hover:text-[#000080]">
              <BsFillShareFill />
              <span className="hidden md:block">Resend</span>
            </button>
          </div>
          <div
            className="grid justify-center px-2"
            onMouseDown={() => onShareCollectionClose()}
          >
            <button className="grid grid-flow-col items-center gap-1 hover:text-[#000080]">
              <BsXCircle />
              <span className="hidden md:block">Cancel</span>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`rounded-3xl bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] mx-4 p-6 shadow grid gap-4 grid-cols-1`}
      >
        {collection &&
          collection.contacts &&
          collection.contacts.map((item) => <p key={item.id}>{item.email}</p>)}
        <div dangerouslySetInnerHTML={{ __html: collection?.message }}></div>
        <div className="flex sm:flex-row flex-col gap-4 p-4 justify-between items-end">
          <div className="flex flex-col w-full">
            <div className="child-fleft">
              {collection && collection.assets
                ? collection.assets.map((asset) => {
                    return (
                      <AssetCard
                        asset={asset}
                        size="asset__small"
                        key={asset.id}
                        className="sm:w-[108px] sm:h-[115px] w-20 h-20 rounded-xl hover:bg-white hover:rounded-[20px] drop-shadow-xl border024"
                      />
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationPreview;
