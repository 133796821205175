import * as types from '../actions/actionTypes'

const initialState = {
  loading: false,
  message: '',
  errors: {},
  conversations: [],
  conversationsDeleteConfirmation: false,
  conversationsUpdateConfirmation: false,
  totalConversationPage: 1,
  previewConversation: {}
}

const conversationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CONVERSATIONS_LISTS_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: '',
        conversations: [],
        conversationsCreation: false,
        conversationsDeleteConfirmation: false,
        conversationsUpdateConfirmation: false
      }
    case types.FETCH_CONVERSATIONS_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        conversations: action.data.results,
        conversationsCreation: false,
        conversationsDeleteConfirmation: false,
        totalConversationPage: action.data.count ? `${Math.ceil(action.data.count / 10)}` : 1
      }
    case types.FETCH_CONVERSATIONS_LISTS_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        conversationsCreation: false,
        conversationsDeleteConfirmation: false
      }
    case types.DELETE_CONVERSATIONS_REQUEST:
      return {
        ...state,
        conversationsDeleteConfirmation: false,
        errors: {},
        message: '',
        loading: true
      }
    case types.DELETE_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        conversationsDeleteConfirmation: true,
        loading: false
      }
    case types.DELETE_CONVERSATIONS_FAILED:
      return {
        ...state,
        errors: action.error,
        conversationsDeleteConfirmation: false,
        loading: false
      }
    case types.UPDATE_CONVERSATIONS_REQUEST:
      return {
        ...state,
        conversationsUpdateConfirmation: false,
        errors: {},
        message: '',
        loading: true
      }
    case types.UPDATE_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        conversationsUpdateConfirmation: true,
        loading: false
      }
    case types.UPDATE_CONVERSATIONS_FAILED:
      return {
        ...state,
        errors: action.error,
        conversationsUpdateConfirmation: false,
        loading: false
      }
      case types.PREVIEW_CONVERSATIONS_REQUEST:
        return {
          ...state,
          loading: true,
          errors: {},
          message: '',
          previewConversation: {},
          conversationsCreation: false,
          conversationsDeleteConfirmation: false,
          conversationsUpdateConfirmation: false
        }
      case types.PREVIEW_CONVERSATIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          previewConversation: action.data,
          conversationsCreation: false,
          conversationsDeleteConfirmation: false
        }
      case types.PREVIEW_CONVERSATIONS_FAILED:
        return {
          ...state,
          errors: action.error,
          loading: false,
          conversationsCreation: false,
          conversationsDeleteConfirmation: false
        }

    default:
      return state
  }
}

export default conversationsReducer
