import React from "react";

import { BsFileEarmark } from "react-icons/bs";

const Other = ({ size, className }) => {
  return (
    <div
      className={`${size || ""} ${className || ""} ${
        size === "asset__large"
          ? ""
          : "hover:scale-105 ease-in-out duration-300 flex items-center justify-center bg-white"
      }`}
    >
      <BsFileEarmark className="text-uberark-orange drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]" />
    </div>
  );
};

export default Other;
