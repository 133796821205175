import {
  handleResponse,
  axiosInstance,
  axiosInstanceNoAuth,
} from "../../../../app/utilities/helpers/";

const handleGetAsset = (assetId) =>
  axiosInstance.get(`assets/${assetId}`).then(handleResponse);

const handleSaveEvent = (data) =>
  axiosInstanceNoAuth.post(`assetevents`, data).then(handleResponse);

const handleCreateSession = (data) =>
  axiosInstanceNoAuth.post("link/sessions", data).then(handleResponse);

export const assetService = {
  handleGetAsset,
  handleSaveEvent,
  handleCreateSession,
};
