import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { AiOutlinePlus } from "react-icons/ai";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { FiInfo } from "react-icons/fi";

import { streamActions } from "../Streams/actions";
import { collectionActions } from "../Collections/actions";
import { settingsActions } from "../Settings/actions";
import no_content from "../../assets/images/no_content.svg";
import AssetCard from "../commons/Asset/components/AssetCard";
import { LoadingSpinner } from "../../components";
import ReactPaginate from "react-paginate";
import Organization from "../Streams/components/Organization";
import { LazyLoadComponent } from "react-lazy-load-image-component";

const StreamHub = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    stream,
    loading,
    loadingAssets,
    totalStreamPage,
    allAssets,
    totalAssetsPage,
  } = useSelector((state) => state.stream);
  const {
    collection,
    previewCollection,
    loading: loadingCollections,
  } = useSelector((state) => state.collection);
  const { organization, id } = useSelector((state) => state.auth.user);
  const [filter, setFilter] = useState("All");
  const [promotedCollectionId, setPromotedCollectionId] = useState(null);
  const [promotedCollection, setPromotedCollection] = useState({});
  const [lastAddedAssets, setLastAddedAssets] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentAssetPage, setCurrentAssetPage] = useState(0);

  useLayoutEffect(() => {
    fetchStreamData(null);
    if (organization) {
      dispatch(
        settingsActions.handleNotificationListsFetch("inappnotifications")
      );
    }
  }, [organization]); // eslint-disable-line

  useEffect(() => {
    if (collection && collection.length > 0) {
      // get promoted collection
      collection.map((item) => {
        if (item.promote && !item.deleted) {
          setPromotedCollectionId(item["id"]);
        }
        return true;
      });
    }
  }, [collection]);

  useEffect(() => {
    if (promotedCollectionId) {
      return dispatch(
        collectionActions.handleGetCollectioPreview(promotedCollectionId)
      );
    }
  }, [promotedCollectionId]); // eslint-disable-line

  useEffect(() => {
    if (
      previewCollection &&
      Object.keys(previewCollection).length &&
      promotedCollectionId
    ) {
      setPromotedCollection(previewCollection);
    }
  }, [previewCollection]); // eslint-disable-line

  useEffect(() => {
    if (allAssets && allAssets.length > 0) {
      // sort assets based on last created
      allAssets.sort((a, b) =>
        new Date(a.created_at).getTime() < new Date(b.created_at).getTime()
          ? 1
          : -1
      );
      const allAssetsFiltered = allAssets.filter((item) => !item.deleted);
      setLastAddedAssets(allAssetsFiltered);
    } else {
      setLastAddedAssets([]);
    }
  }, [allAssets]);

  const fetchStreamData = (type) => {
    if (organization && organization !== "None") {
      dispatch(collectionActions.handleGetCollection());
      dispatch(streamActions.handleGetAllAssets());
      dispatch(streamActions.handleGetStream(id, type));
      dispatch(settingsActions.handleAccountManagementFetch(organization));
      dispatch(settingsActions.handleShareGet());
    }
  };

  const slideLeft = (target) => {
    var slider = document.getElementById(target);
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = (target) => {
    var slider = document.getElementById(target);
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  const handleFilterCollection = (name) => {
    setFilter(name);
    if (name === "user") {
      fetchStreamData("user");
    } else {
      fetchStreamData(null);
    }
  };

  const changePage = ({ selected }) => {
    setCurrentPage(selected);
    return dispatch(
      streamActions.handleGetStream(id, null, null, null, selected + 1)
    );
  };

  const changeAssetPage = ({ selected }) => {
    setCurrentAssetPage(selected);
    return dispatch(streamActions.handleGetAllAssets(selected + 1));
  };

  return (
    <div className="relative">
      <div className="absolute sm:top-0 top-20 w-full h-full mb-5">
        {/* head ------------------------------------------------------------------------------------------------------------- */}
        <div className="md:mt-4 md:mx-20 mx-4 mt-4 grid grid-cols-1 md:grid-cols-2 justify-between bg-transparent">
          <div className="flex flex-col justify-items-end">
            <span className="text-[#FE4502] font-extrabold text-lg">
              Stream Hub
            </span>

            <span className="text-[#757575] text-base">
              discover, curate, distribute
            </span>
          </div>
          {!organization || organization === "None" ? (
            <Organization slide={true} />
          ) : (
            <div className="grid grid-flow-col items-center justify-between md:justify-end py-4 pt-4 md:p-0 text-slate-600">
              <button
                onClick={() => navigate("/stream/add")}
                className="inline-block font-medium text-base leading-tight hover:shadow-md hover:bg-light-gray shadow-gray-900 focus:bg-white focus:shadow-xl focus:outline-none focus:ring-0 active:shadow-lg hover:scale-105 ease-in-out duration-300 p-3 bg-white w-10 h-10 flex justify-center items-center"
                style={{ borderRadius: "100%", border: "0.24px solid #000080" }}
              >
                <AiOutlinePlus className="text-[#FE4502] font-bold" />
              </button>
            </div>
          )}
        </div>

        {/* stream ----------------------------------------------------------------------------------------------------------- */}
        <LazyLoadComponent>
          <div
            className={`rounded-3xl bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] m-4 p-8 shadow flex flex-wrap ${
              loading ? "min-h-[75vh]" : ""
            }`}
          >
            {loading ? (
              <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10">
                <div className="w-full flex justify-center">
                  <LoadingSpinner colorClassName="border-[#000080]" />
                </div>
              </div>
            ) : (
              <>
                <div className="w-full">
                  <Link
                    to="/hub"
                    className="text-[#000080] font-semibold text-base pl-4"
                  >
                    Streams
                  </Link>
                  {!organization || organization === "None" ? null : (
                    <div className="pt-2 divide-x text-slate-600 divide-slate-500 flex pl-4 pb-1">
                      <div
                        className={`px-2 ${
                          filter === "All"
                            ? " text-uberark-orange font-bold"
                            : ""
                        }`}
                        onClick={() => handleFilterCollection("All")}
                        style={{ paddingLeft: 0 }}
                      >
                        All
                      </div>
                      <div
                        className={`px-2 ${
                          filter === "user"
                            ? " text-uberark-orange font-bold"
                            : ""
                        }`}
                        onClick={() => handleFilterCollection("user")}
                      >
                        Owned By Me
                      </div>
                    </div>
                  )}
                </div>
                {stream && stream.length > 0 ? (
                  <div className="relative flex items-center w-full">
                    <BsChevronLeft
                      onClick={() => slideLeft("slider-stream")}
                      size={30}
                      className="opacity-100 cursor-pointer hover:opacity-60 mr-6 text-[#000080]"
                    />

                    <div
                      id="slider-stream"
                      className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide"
                    >
                      {stream?.map((item) => (
                        <Link
                          key={item.id}
                          className="relative"
                          to={`/stream/${item.id}`}
                        >
                          <img
                            className="w-[166px] h-[166px] inline-block p-2 cursor-pointer opacity-80 hover:scale-105 ease-in-out duration-300 rounded-[20px]"
                            style={{
                              filter: "brightness(0.5)",
                              visibility:
                                item.cover_image || loading
                                  ? "visible"
                                  : "hidden",
                            }}
                            src={
                              item.cover_image_thumbnail
                                ? item.cover_image_thumbnail
                                : item.cover_image
                            }
                            alt="cover"
                            loading="lazy"
                          />
                          <span className="absolute inset-0 flex items-center justify-center font-bold text-white p-2 cursor-pointer">
                            <span
                              className={`${
                                item.cover_image
                                  ? "text-white"
                                  : "text-slate-600"
                              } font-bold truncate w-90 px-1 w-[166px] h-[166px] flex items-center justify-center `}
                            >
                              {item.title}{" "}
                            </span>
                          </span>
                        </Link>
                      ))}
                    </div>

                    <BsChevronRight
                      onClick={() => slideRight("slider-stream")}
                      size={30}
                      className="opacity-100 cursor-pointer hover:opacity-60 ml-6 text-[#000080]"
                    />
                  </div>
                ) : (
                  <div className="h-[155px] w-full mt-5 mb-10 flex flex-col justify-center items-center text-base">
                    <div className="w-full flex justify-center">
                      <img
                        src={no_content}
                        alt="nothing to show illustration"
                        style={{ height: "60px" }}
                        loading="lazy"
                      />
                    </div>
                    <div className="text-center relative p-5 text-[#757575] flex items-center">
                      Nothing to show{" "}
                      <FiInfo className="text-uberark-orange ml-1" />
                    </div>
                    <Link
                      className="flex items-center justify-center py-1 px-5 cursor-pointer opacity-80 hover:scale-105 ease-in-out duration-300 border rounded"
                      alt={`/stream/add`}
                      to={`/stream/add`}
                    >
                      <AiOutlinePlus className="text-[#FE4502] mr-1" /> Create
                      Streams
                    </Link>
                  </div>
                )}
              </>
            )}
            {totalStreamPage && totalStreamPage > 1 ? (
              <div className="py-4">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={totalStreamPage}
                  onPageChange={changePage}
                  marginPagesDisplayed={0}
                  pageRangeDisplayed={1}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                  id={"content-pagination"}
                  forcePage={currentPage}
                />
              </div>
            ) : null}
          </div>
        </LazyLoadComponent>

        {/* streams - where you left off--------------------------------------------------------------------------------------- */}
        <LazyLoadComponent>
          <div className="rounded-3xl bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] m-4 p-8 shadow flex flex-wrap">
            {loading ? (
              <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10">
                <div className="w-full flex justify-center">
                  <LoadingSpinner colorClassName="border-[#000080]" />
                </div>
              </div>
            ) : (
              <>
                <div className="w-full text-[#000080] text-base font-semibold pl-4 pb-1">
                  Continue where you left
                </div>
                {stream && stream.length > 0 ? (
                  <div className="relative flex items-center w-full">
                    <BsChevronLeft
                      onClick={() => slideLeft("slider-stream-left")}
                      size={30}
                      className="opacity-100 cursor-pointer hover:opacity-60 mr-6 text-[#000080]"
                    />

                    <div
                      id="slider-stream-left"
                      className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide"
                    >
                      {stream?.map((item) => (
                        <Link
                          key={item.id}
                          className="relative"
                          to={`/stream/${item.id}`}
                        >
                          <img
                            className="w-[166px] h-[166px] inline-block p-2 cursor-pointer opacity-80 hover:scale-105 ease-in-out duration-300 rounded-[20px]"
                            style={{
                              filter: "brightness(0.5)",
                              visibility:
                                item.cover_image || loading
                                  ? "visible"
                                  : "hidden",
                            }}
                            src={
                              item.cover_image_thumbnail
                                ? item.cover_image_thumbnail
                                : item.cover_image
                            }
                            alt="cover"
                            loading="lazy"
                          />

                          <span className="absolute inset-0 flex items-center justify-center font-bold text-white p-2 cursor-pointer">
                            <span
                              className={`${
                                item.cover_image || loading
                                  ? "text-white"
                                  : "text-slate-600"
                              } font-bold truncate w-90 px-1 w-[166px] h-[166px] flex items-center justify-center`}
                            >
                              {item.title}{" "}
                            </span>
                          </span>
                        </Link>
                      ))}
                    </div>
                    <BsChevronRight
                      onClick={() => slideRight("slider-stream-left")}
                      size={30}
                      className="opacity-100 cursor-pointer hover:opacity-60 ml-6 text-[#000080]"
                    />
                  </div>
                ) : (
                  <div className="h-[155px] w-full mt-5 flex flex-col justify-center items-center">
                    <div className="w-full flex justify-center">
                      <img
                        src={no_content}
                        alt="nothing to show illustration"
                        style={{ height: "60px" }}
                        loading="lazy"
                      />
                    </div>
                    <div className="text-center relative p-5 text-[#757575] text-base flex items-center">
                      Nothing to show{" "}
                      <FiInfo className="text-uberark-orange ml-1" />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </LazyLoadComponent>

        {/* collections */}
        {collection && collection.length > 0 ? (
          <LazyLoadComponent>
            <div className="rounded-3xl bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] m-4 p-8 shadow flex flex-wrap">
              {loadingCollections ? (
                <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10">
                  <div className="w-full flex justify-center">
                    <LoadingSpinner colorClassName="border-[#000080]" />
                  </div>
                </div>
              ) : (
                <>
                  <div className="text-[#000080] font-semibold text-base pl-4 pb-1">
                    Collections
                  </div>
                  <div className="relative flex items-center w-full">
                    <BsChevronLeft
                      onClick={() => slideLeft("slider-collections")}
                      size={30}
                      className="opacity-100 cursor-pointer hover:opacity-60 mr-6 text-[#000080]"
                    />

                    <div
                      id="slider-collections"
                      className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide"
                    >
                      {collection.map((item) => (
                        <Link
                          key={item.id}
                          className="relative w-[166px] h-[166px]"
                          to={`/collection/${item.id}`}
                          style={{ display: "inline-grid" }}
                        >
                          <div
                            key={item.id}
                            className="w-[166px] h-[166px] relative hover:scale-105 ease-in-out duration-300 rounded-[20px]"
                          >
                            <img
                              className="w-[166px] h-[166px] inline-block p-2 cursor-pointer opacity-80 rounded-[25px]"
                              style={{
                                filter: "brightness(0.5)",
                                visibility: item.banner ? "visible" : "hidden",
                              }}
                              src={
                                item.banner_thumbnail
                                  ? item.banner_thumbnail
                                  : item.banner
                              }
                              alt="cover"
                              loading="lazy"
                            />
                            <span className="absolute inset-0 flex m-2 items-center justify-center p-2 cursor-pointer rounded-[20px]">
                              <span
                                className={`${
                                  item.banner ? "text-white" : "text-slate-600"
                                } font-bold truncate w-90 px-1 w-[166px] h-[166px] flex items-center justify-center`}
                              >
                                {item.name}{" "}
                              </span>
                            </span>
                          </div>
                        </Link>
                      ))}
                    </div>

                    <BsChevronRight
                      onClick={() => slideRight("slider-collections")}
                      size={30}
                      className="opacity-100 cursor-pointer hover:opacity-60 ml-6 text-[#000080]"
                    />
                  </div>
                </>
              )}
            </div>
          </LazyLoadComponent>
        ) : null}

        {/* promoted collection*/}
        {collection &&
          collection.length > 0 &&
          promotedCollection &&
          promotedCollection.id && (
            <LazyLoadComponent>
              <div className="rounded-3xl bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] m-4 shadow">
                {loading ? (
                  <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10">
                    <div className="w-full flex justify-center">
                      <LoadingSpinner colorClassName="border-[#000080]" />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-wrap w-full relative h-[360px]">
                      <div className="promate-banner-wrap">
                        <div className="promate-banner-overlay"></div>
                        <img
                          className="w-full h-[360px] inline-block cursor-pointer opacity-80 rounded-r-[25px] object-cover"
                          src={promotedCollection.banner}
                          alt="cover"
                          loading="lazy"
                        />
                      </div>
                      <div className="flex flex-col justify-center m-2 p-2 pl-8 cursor-pointer rounded-[20px]">
                        <div className="font-bold  text-uberark-orange">
                          {promotedCollection.name}
                        </div>
                        <p className="text-slate-600">
                          {promotedCollection.asset.length} document(s) in this
                          collection.
                        </p>
                        <button
                          type="button"
                          onClick={() =>
                            navigate(`/collection/${promotedCollection.id}`)
                          }
                          style={{ width: "fit-content" }}
                          className="bg-[#000080] mt-4 hover:bg-white text-white hover:text-[#000080] hover:drop-shadow-lg font-bold py-1 px-2 hover:scale-105 ease-in-out duration-200 rounded"
                        >
                          Explore
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </LazyLoadComponent>
          )}

        {/* assets */}
        {lastAddedAssets && lastAddedAssets.length > 0 ? (
          <LazyLoadComponent>
            <div className="rounded-3xl bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] m-4 p-8 shadow flex flex-wrap h-fit">
              {loadingAssets ? (
                <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10">
                  <div className="w-full flex justify-center">
                    <LoadingSpinner colorClassName="border-[#000080]" />
                  </div>
                </div>
              ) : (
                <>
                  <div className="text-[#000080] text-base font-semibold pl-4 pb-1">
                    Assets
                  </div>
                  <React.Fragment>
                    <div className="relative flex items-center w-full">
                      <BsChevronLeft
                        onClick={() => slideLeft("slider-assets")}
                        size={30}
                        className="opacity-100 cursor-pointer hover:opacity-60 mr-6 text-[#000080]"
                      />

                      <div
                        id="slider-assets"
                        className="w-full h-[180px] flex items-center overflow-x-scroll whitespace-nowrap scroll-smooth scrollbar-hide"
                      >
                        {lastAddedAssets?.map((asset) => (
                          <div
                            key={asset.id}
                            className="relative mx-1"
                            onMouseDown={(e) => {
                              navigate(`/asset/${asset.id}`);
                            }}
                          >
                            <AssetCard
                              asset={asset}
                              withName={true}
                              size="asset__medium"
                            />
                          </div>
                        ))}
                      </div>
                      <BsChevronRight
                        onClick={() => slideRight("slider-assets")}
                        size={30}
                        className="opacity-100 cursor-pointer hover:opacity-60 ml-6 text-[#000080]"
                      />
                    </div>
                    {totalAssetsPage > 1 ? (
                      <div className="py-4 items-start">
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={totalAssetsPage}
                          onPageChange={changeAssetPage}
                          marginPagesDisplayed={0}
                          pageRangeDisplayed={1}
                          containerClassName={"paginationBttns"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"paginationDisabled"}
                          activeClassName={"paginationActive"}
                          id={"asset-pagination"}
                          forcePage={currentAssetPage}
                        />
                      </div>
                    ) : null}
                  </React.Fragment>
                </>
              )}
            </div>
          </LazyLoadComponent>
        ) : null}
      </div>
    </div>
  );
};

export default StreamHub;
