export const handleGenerateEventPayload = (params) => {
  // EVENTS: 'share','download','link_open','link_close','view_start','view_stop','video_play','video_pause','video_stop','document_page_change'
  const { eventName, asset, session, user } = params;
  const videoExtensions = ["mp4"];
  const iframeCompatibleDocuments = ["pdf", "ppt", "pptx", "doc", "docx"];

  let payload = {
    event_name: eventName,
    asset: asset?.id || null,
    session: session || null,
    user: user || null,
  };

  if (asset) {
    // Video
    if (videoExtensions.includes(asset.name.split(".").at(-1))) {
      payload = handleVideoEvents(eventName, "video-lg", payload);
    }

    // Documents
    if (iframeCompatibleDocuments.includes(asset.name.split(".").at(-1))) {
      payload = handleDocumentEvents(eventName, payload);
    }
  }

  return payload;
};

const handleVideoEvents = (eventName, videoId, payload) => {
  if (eventName === "view_start") {
    payload.video_point = null;
  }
  if (eventName === "video_play") {
    payload.video_point =
      window.document.getElementById(videoId)?.currentTime || null;
  }
  if (eventName === "video_pause") {
    payload.video_point =
      window.document.getElementById(videoId)?.currentTime || null;
  }
  if (eventName === "video_stop") {
    payload.video_point =
      window.document.getElementById(videoId)?.currentTime || null;
  }
  if (eventName === "link_close" || eventName === "view_stop") {
    payload.video_point =
      window.document.getElementById(videoId)?.currentTime || null;
  }

  return payload;
};

const handleDocumentEvents = (eventName, payload) => {
  if (eventName === "view_start") {
    payload.document_page = 1;
  }
  if (eventName === "document_page_change") {
    payload.document_page = window.document.getElementById("page_num")
      ? Number(window.document.getElementById("page_num")?.innerText)
      : null;
  }
  if (eventName === "link_close" || eventName === "view_stop") {
    payload.document_page = window.document.getElementById("page_num")
      ? Number(window.document.getElementById("page_num")?.innerText)
      : null;
  }
  return payload;
};
