import * as types from "../actions/actionTypes";

const initialState = {
  loading: false,
  loadingAssets: false,
  loadingCollections: false,
  dltConfirm: false,
  stream: [],
  addedStream: {},
  fileLoading: "initial",
  message: "",
  errors: {},
  previewStream: {},
  showStream: true,
  streamUpdate: true,
  streamTree: [],
  allAssets: [],
  totalStreamPage: 1,
  totalAssetsPage: 1,
  sectionId: null,
  groupId: null,
  editAsset: false,
};

const streamReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_STREAM_REQUEST:
      return {
        ...state,
        loading: true,
        dltConfirm: false,
        errors: {},
        message: "",
      };
    case types.CREATE_STREAM_SUCCESS:
      const streamData = state.stream;
      streamData.push(action.data);
      return {
        ...state,
        loading: false,
        dltConfirm: false,
        stream: streamData,
      };
    case types.CREATE_STREAM_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        dltConfirm: false,
      };

    case types.EDIT_STREAM_REQUEST:
      return {
        ...state,
        loading: true,
        dltConfirm: false,
        errors: {},
        message: "",
        streamUpdate: false,
      };
    case types.EDIT_STREAM_SUCCESS:
      const streamRef = state.stream;
      const objIndex = streamRef.findIndex((obj) => obj.id === action.data.id);
      streamRef[objIndex] = action.data;
      return {
        ...state,
        loading: false,
        dltConfirm: false,
        stream: streamRef,
        streamUpdate: true,
      };
    case types.EDIT_STREAM_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        dltConfirm: false,
      };

    case types.DELETE_STREAM_REQUEST:
      return {
        ...state,
        loading: true,
        dltConfirm: false,
        errors: {},
        message: "",
        streamUpdate: false,
      };
    case types.DELETE_STREAM_SUCCESS:
      let streamRefe = state.stream;
      streamRefe = streamRefe.filter((obj) => obj.id !== action.data);
      return {
        ...state,
        loading: false,
        dltConfirm: true,
        stream: streamRefe,
        streamUpdate: true,
      };
    case types.DELETE_STREAM_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        dltConfirm: false,
      };

    case types.GET_STREAM_REQUEST:
      return {
        ...state,
        loading: true,
        dltConfirm: false,
        errors: {},
        message: "",
        addedStream: {},
        fileLoading: "stop",
        previewStream: {},
        showStream: false,
        streamUpdate: false,
      };
    case types.GET_STREAM_SUCCESS:
      return {
        ...state,
        loading: false,
        dltConfirm: false,
        stream: action.data.results,
        addedStream: {},
        showStream: true,
        streamUpdate: false,
        totalStreamPage: action.data.count
          ? `${Math.ceil(action.data.count / 10)}`
          : 1,
      };
    case types.GET_STREAM_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        dltConfirm: false,
        addedStream: {},
      };

    case types.CREATE_GROUP_REQUEST:
      return {
        ...state,
        errors: {},
        message: "",
        loading: true,
        dltConfirm: false,
        streamUpdate: false,
      };
    case types.CREATE_GROUP_SUCCESS:
      const stream = state.stream;
      const index = state.stream.findIndex(
        (item) => item.id === action.data.content
      );
      if (stream[index]?.group) {
        stream[index].group.push(action.data);
      } else {
        stream[index]["group"] = [action.data];
      }

      return {
        ...state,
        stream,
        loading: false,
        dltConfirm: false,
        streamUpdate: true,
      };
    case types.CREATE_GROUP_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        dltConfirm: false,
      };

    case types.CREATE_FOLLOW_STREAM_REQUEST:
      return {
        ...state,
        loading: true,
        dltConfirm: false,
        errors: {},
        message: "",
        streamUpdate: false,
      };

    case types.CREATE_FOLLOW_STREAM_SUCCESS:
      return {
        ...state,
        loading: false,
        dltConfirm: false,
        streamUpdate: true,
        // stream: follow
      };
    case types.CREATE_FOLLOW_STREAM_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        dltConfirm: false,
      };

    case types.CREATE_ASSET_REQUEST:
      return {
        ...state,
        loadingAssets: true,
        dltConfirm: false,
        fileLoading: "progress",
        errors: {},
        message: "",
        addedStream: {},
        streamUpdate: false,
      };
    case types.CREATE_ASSET_SUCCESS:
      const streamStateRef = state.stream;
      state.stream.map((item, index) => {
        const groupIndex = state.stream.findIndex(
          (item) => item.id === action.data.group
        );
        if (groupIndex && groupIndex !== -1) {
          streamStateRef[index].group[groupIndex].asset.push(action.data);
        }
        return true;
      });

      return {
        ...state,
        stream: streamStateRef,
        addedStream: action.data,
        loadingAssets: false,
        dltConfirm: false,
        fileLoading: "success",
        streamUpdate: true,
      };
    case types.CREATE_ASSET_FAILED:
      return {
        ...state,
        errors: action.error,
        loadingAssets: false,
        dltConfirm: false,
        addedStream: {},
        fileLoading: "failure",
      };

    case types.DELETE_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
        dltConfirm: false,
        errors: {},
        message: "",
        streamUpdate: false,
      };
    case types.DELETE_GROUP_SUCCESS:
      const previewStream = state.previewStream;
      const groups = state.previewStream.group.filter(
        (item) => item.id !== action.data
      );
      previewStream["group"] = groups;

      return {
        ...state,
        loading: false,
        dltConfirm: true,
        // streamUpdate: true,
        previewStream: previewStream,
      };
    case types.DELETE_GROUP_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        dltConfirm: false,
      };

    case types.DELETE_ASSET_REQUEST:
      return {
        ...state,
        loadingAssets: true,
        dltConfirm: false,
        errors: {},
        message: "",
        streamUpdate: false,
      };
    case types.DELETE_ASSET_SUCCESS:
      return {
        ...state,
        loadingAssets: false,
        dltConfirm: true,
        streamUpdate: true,
      };
    case types.DELETE_ASSET_FAILED:
      return {
        ...state,
        errors: action.error,
        loadingAssets: false,
        dltConfirm: false,
      };

    case types.GET_STREAM_FOLLOWERS_REQUEST:
      return {
        ...state,
        loading: true,
        dltConfirm: false,
        errors: {},
        message: "",
      };
    case types.GET_STREAM_FOLLOWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        dltConfirm: false,
        stream: action.data,
      };
    case types.GET_STREAM_FOLLOWERS_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        dltConfirm: false,
      };

    case types.COPY_ASSET_REQUEST:
      return {
        ...state,
        loadingAssets: true,
        dltConfirm: false,
        errors: {},
        message: "",
        streamUpdate: false,
      };
    case types.COPY_ASSET_SUCCESS:
      return {
        ...state,
        loadingAssets: false,
        streamUpdate: true,
      };
    case types.COPY_ASSET_FAILED:
      return {
        ...state,
        errors: action.error,
        loadingAssets: false,
        dltConfirm: false,
      };

    case types.STREAM_PREVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        dltConfirm: false,
        errors: {},
        message: "",
        addedStream: {},
        fileLoading: "stop",
        previewStream: {},
        showStream: false,
        streamUpdate: false,
        groupId: null,
      };
    case types.STREAM_PREVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        dltConfirm: false,
        previewStream: action.data,
        addedStream: {},
        showStream: true,
        groupId: action.data.groupId,
      };
    case types.STREAM_PREVIEW_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        dltConfirm: false,
        addedStream: {},
        streamUpdate: false,
      };

    case types.ADD_COLLABORATOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.ADD_COLLABORATOR_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };

    case types.DELETE_COLLABORATOR_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: "",
      };
    case types.DELETE_COLLABORATOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_COLLABORATOR_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };

    case types.GET_ALL_STREAM_REQUEST:
      return {
        ...state,
        loading: true,
        dltConfirm: false,
        errors: {},
        message: "",
        addedStream: {},
        fileLoading: "stop",
        showStream: false,
      };
    case types.GET_ALL_STREAM_SUCCESS:
      return {
        ...state,
        loading: false,
        dltConfirm: false,
        streamTree: action.data,
        addedStream: {},
        showStream: true,
      };

    case types.GET_ALL_STREAM_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
        dltConfirm: false,
        addedStream: {},
      };

    case types.GET_ALL_ASSETS_REQUEST:
      return {
        ...state,
        loadingAssets: true,
        dltConfirm: false,
        errors: {},
        message: "",
        addedStream: {},
        fileLoading: "stop",
        allAssets: [],
        showStream: false,
      };
    case types.GET_ALL_ASSETS_SUCCESS:
      return {
        ...state,
        loadingAssets: false,
        dltConfirm: false,
        allAssets: action.data.results,
        addedStream: {},
        showStream: true,
        totalAssetsPage: action.data.count
          ? `${Math.ceil(action.data.count / 10)}`
          : 1,
      };
    case types.GET_ALL_ASSETS_FAILED:
      return {
        ...state,
        errors: action.error,
        loadingAssets: false,
        dltConfirm: false,
        addedStream: {},
      };

    case types.PREVIEW_ASSET_REQUEST:
      return {
        ...state,
        loadingAssets: true,
        dltConfirm: false,
        errors: {},
        message: "",
        addedStream: {},
        fileLoading: "stop",
        previewAsset: {},
        showStream: false,
      };
    case types.PREVIEW_ASSET_SUCCESS:
      return {
        ...state,
        loadingAssets: false,
        dltConfirm: false,
        previewAsset: action.data,
        addedStream: {},
        showStream: true,
      };
    case types.PREVIEW_ASSET_FAILED:
      return {
        ...state,
        errors: action.error,
        loadingAssets: false,
        dltConfirm: false,
        addedStream: {},
      };

    case types.MOVE_ASSET_REQUEST:
      return {
        ...state,
        loadingAssets: true,
        dltConfirm: false,
        errors: {},
        message: "",
        streamUpdate: false,
        previewStream: {},
        sectionId: null,
      };
    case types.MOVE_ASSET_SUCCESS:
      return {
        ...state,
        loadingAssets: false,
        streamUpdate: true,
        previewStream: {},
        sectionId: action.data.section,
      };
    case types.MOVE_ASSET_FAILED:
      return {
        ...state,
        errors: action.error,
        loadingAssets: false,
        dltConfirm: false,
        streamUpdate: false,
      };

    case types.EDIT_ASSET_REQUEST:
      return {
        ...state,
        editAsset: action.error ? false : true,
      };
    case types.EDIT_ASSET_SUCCESS:
      return {
        ...state,
        editAsset: false,
      };
    case types.EDIT_ASSET_FAILED:
      return {
        ...state,
        errors: action.error,
        editAsset: false,
      };

    default:
      return state;
  }
};

export default streamReducer;
