import * as types from './actionTypes'
import {
  request,
  success,
  failure
} from '../../../app/utilities/helpers/'
import { authService } from './_service'
import { toast } from 'react-toastify'

const handleCompose = (data, handleClose) => {
  return (dispatch) => {
    dispatch(request(null, types.COMPOSE_REQUEST))
    authService.handleCompose(data).then(
      (res) => {
        toast.success("Composed Successfully!")
        dispatch(success(res, types.COMPOSE_SUCCESS))
        setTimeout(() => { handleClose && handleClose(false) }, 300);
      },
      (error) => {
        dispatch(failure(error, types.COMPOSE_FAILED))
        if (error?.response?.status === 403 || error?.response?.status === 401) {
          return true
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message)
        } else {
          toast.error("Something went wrong. It's us not you")
        }
      }
    )
  }
}

export const composeActions = {
  handleCompose
}
