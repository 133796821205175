import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { LoadingSpinner, Slider } from "../../../components";

import { streamActions } from "../actions";

const GroupEdit = ({ group, handleGroupEditClose, show }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.stream);

  const initialValues = {
    name: group.name,
  };

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Please enter title"),
  });

  const handleEditGroup = (data) => {
    const payload = {
      id: group.id,
      content: group.stream,
      name: data.name,
    };
    dispatch(streamActions.handleEditGroup(payload, handleGroupEditClose));
  };

  return (
    <Slider
      direction="right"
      title="Edit Group"
      visible={show}
      onClose={() => handleGroupEditClose()}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleEditGroup}
        validationSchema={FormSchema}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form>
            <div className="flex flex-col justify-between text-gray-600">
              <div className="my-5">
                <p className="font-medium text-uberark-blue text-left">
                  <span>Group Name</span>{" "}
                  <span className="text-14 text-red-500 ml-1">*</span>:
                </p>
                <Field
                  placeholder="Enter group name"
                  className="floating-input pl-2 text-14 text-uberark-blue"
                  name="name"
                  id="name"
                />
                {errors.name && touched.name && (
                  <div
                    className="text-red-600 pb-2"
                    style={{
                      textAlign: "initial",
                    }}
                  >
                    {errors.name}
                  </div>
                )}
              </div>
              <div className="flex flex-row items-center text-uberark-blue gap-4 text-lg">
                <button
                  className=":px-2 bg-[#000080] text-white  hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <LoadingSpinner /> : "Save"}
                </button>
                <button
                  className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
                  onClick={() => handleGroupEditClose()}
                  style={{ border: "0.24px solid #000080" }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Slider>
  );
};

export default GroupEdit;
