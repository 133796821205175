import React from "react";

import ComposeChild from "../../../Compose/components/ComposeChild";

const AssetShareSlide = ({ shareAsset, setShareAsset, asset }) => {
  if (!shareAsset) {
    return null;
  }
  return (
    <div
      onClick={() => setShareAsset(false)}
      className="pop_up_container fixed inset-0 bg-gray-50 bg-opacity-30 backdrop-blur-sm text-black h-full"
      style={{ zIndex: "100", overflowY: "scroll" }}
    >
      <div onClick={(e) => e.stopPropagation()} className="h-full flex">
        <div className="bg-white pl-5 pt-5 md:w-4/6 w-full">
          <ComposeChild
            onShareCollectionClose={() => setShareAsset(false)}
            collection={{ assets: [asset] }}
            position="asset"
          />
        </div>
      </div>
    </div>
  );
};

export default AssetShareSlide;
