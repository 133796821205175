const localStorageTokensKey = 'tokens'
const localStorageStateKey = 'state'

export const getUser = () => {
  const user = localStorage.getItem(localStorageStateKey)
  return user ? JSON.parse(user).auth.user : null
}

export const getTokens = () => {
  const tokens = localStorage.getItem(localStorageTokensKey)
  return tokens ? JSON.parse(tokens) : null
}

export const setTokens = (tokens) => {
  localStorage.removeItem('tokens')
  localStorage.setItem(localStorageTokensKey, JSON.stringify(tokens))
}

export const forgetUser = () => {
  localStorage.removeItem('tokens')
  localStorage.removeItem('state')
}
