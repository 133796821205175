import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { BsXLg, BsFillBarChartFill } from "react-icons/bs";
import {
  RiShareFill,
  RiDownloadCloud2Fill,
  RiEditCircleFill,
} from "react-icons/ri";

import AssetCard from "./components/AssetCard";
import AssetEdit from "./components/AssetEdit";
import AssetShare from "./components/AssetShare";
import AssetAnalytics from "./components/AssetAnalytics";
import { saveAs } from "file-saver";
import {
  handleGetReadableBytes,
  handleGetReadableDuration,
  handleGenerateEventPayload,
  getUserIdentity,
} from "../../../app/utilities/helpers";
import { assetActions } from "./actions/";

const AssetPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { asset, session } = useSelector((state) => state.asset);
  const { user } = useSelector((state) => state.auth);
  let { id: assetId } = useParams();
  const [editAsset, setEditAsset] = useState(false);
  const [shareAsset, setShareAsset] = useState(false);
  const [assetAnalytics, setAssetAnalytics] = useState(false);
  const [identity, setIdentity] = useState(false);

  useEffect(() => {
    handleGetIP();
    if (assetId) {
      dispatch(assetActions.handleGetAsset(assetId));
    }
  }, [assetId, dispatch]);

  // page close listener
  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      e.preventDefault();
      assetId && handleSaveEvent({ eventName: "view_stop" });
      for (var i = 0; i < 100000; i++) {
        console.log(i);
      }
      return true;
    });
    return () => {
      window.removeEventListener("beforeunload", (event) => {});
    };
  }, []); // eslint-disable-line

  // create session
  useEffect(() => {
    if (assetId && identity && user) {
      // create session
      dispatch(
        assetActions.handleCreateSession({
          ip: identity?.IPv4 || null,
          os: identity?.os || null,
          browser: identity?.browser || null,
          city: identity?.city || null,
          country_code: identity?.country_code || null,
          country_name: identity?.country_name || null,
          user: user.id || null,
          asset: assetId,
        })
      );
    }
  }, [identity, dispatch, assetId, user]);

  // view_start event
  useEffect(() => {
    if (session && session.asset && session.asset === assetId && identity) {
      handleSaveEvent({
        eventName: "view_start",
      });
    }
  }, [session]); // eslint-disable-line

  const handleDownloadFile = async () => {
    fetch(asset.file.toString(), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        handleSaveEvent({
          eventName: "download",
        });
        response.blob().then((blob) => {
          let data = new File([blob], asset.name, {
            type: "text/json;charset=utf-8",
          });
          saveAs(data, asset.name);
        });
      })
      .catch(function (error) {
        window.open(asset.file, "_blank");
      });
  };

  const handleGetIP = async () => {
    const userIdentity = await getUserIdentity();
    setIdentity(userIdentity);
  };

  const handleSaveEvent = (args) => {
    const data = {
      ...args,
      asset: asset,
      session: session.id,
      user: user.id,
    };
    if (session && session.asset && session.asset === assetId) {
      const payload = handleGenerateEventPayload(data);
      dispatch(assetActions.handleSaveEvent(payload));
    }
  };

  return (
    <div className="relative">
      <div className="absolute sm:top-0 top-20 w-full h-full mb-5">
        {/* page head */}
        <div className="md:mt-4 md:mx-20 mx-4 mt-4 grid grid-cols-1 md:grid-cols-2 justify-between bg-transparent">
          <div className="flex flex-col justify-items-end">
            <span className="text-[#FE4502] font-extrabold text-base">
              Asset
            </span>

            <span className="text-[#757575] text-base">View Asset.</span>
          </div>
          <div className="grid grid-flow-col items-center justify-between md:justify-end py-4 pt-4 md:p-0 text-slate-600">
            <button
              onClick={() => {
                handleSaveEvent({ eventName: "view_stop" });
                setTimeout(() => {
                  navigate(-1);
                }, 300);
              }}
              className="inline-block font-medium text-xl leading-tight hover:shadow-md hover:bg-light-gray shadow-gray-900 focus:bg-white focus:shadow-xl focus:outline-none focus:ring-0 active:shadow-lg hover:scale-105 ease-in-out duration-300 p-3 bg-white w-10 h-10 flex justify-center items-center"
              style={{ borderRadius: "100%", border: "0.24px solid #000080" }}
            >
              <BsXLg className="text-[#FE4502] font-bold" />
            </button>
          </div>
        </div>
        {/* body goes here */}
        <div className="rounded-3xl bg-white m-4 pb-10 shadow grid text-slate-600 relative">
          {/* body Head */}
          <div className="flex justify-between p-5">
            <div className="flex flex-col">
              {asset?.name && (
                <div className="px-2 font-semibold">
                  {asset?.url
                    ? asset?.name
                    : asset?.name.split(".").slice(0, 1)}
                </div>
              )}
              <div className="italic flex flex-wrap text-sm divide-x text-slate-600 divide-slate-500 w-full">
                {asset?.file_size && (
                  <div className="px-2">
                    {handleGetReadableBytes(asset.file_size)}
                  </div>
                )}
                {asset?.name && (
                  <div className="px-2">
                    {asset?.url ? asset?.name : asset?.name.split(".").pop()}
                  </div>
                )}
                {asset?.page_count && (
                  <div className="px-2">{asset.page_count} pages</div>
                )}
                {asset?.video_duration && (
                  <div className="px-2">
                    {handleGetReadableDuration(asset.video_duration)}
                  </div>
                )}
                <div className="px-2">
                  {asset?.url
                    ? asset?.is_youtube_url
                      ? "Youtube"
                      : "Link"
                    : "Uberark Cloud"}
                </div>
              </div>
            </div>
            <div className="flex items-center">
              {asset?.share && (
                <button
                  onClick={() => setShareAsset(true)}
                  className="flex justify-center items-center mx-1"
                >
                  <RiShareFill className="text-[#FE4502] mr-1" /> Share
                </button>
              )}
              {asset?.download && !asset?.url && (
                <button
                  onClick={() => handleDownloadFile()}
                  className="flex justify-center items-center mx-1"
                >
                  <RiDownloadCloud2Fill className="text-[#FE4502] mr-1" />{" "}
                  Download
                </button>
              )}
              <button
                onClick={() => setAssetAnalytics(true)}
                className="flex justify-center items-center mx-1"
              >
                <BsFillBarChartFill className="text-[#FE4502] mr-1" /> Analytics
              </button>
              <button
                onClick={() => setEditAsset(true)}
                className="flex justify-center items-center mx-1"
              >
                <RiEditCircleFill className="text-[#FE4502] mr-1" /> Edit
              </button>
            </div>
          </div>
          {/* body content */}
          <AssetCard
            asset={asset}
            size="asset__large"
            handleSaveEvent={handleSaveEvent}
          />
        </div>
        {/* modals/popups */}
        <AssetEdit
          visible={editAsset}
          onClose={() => setEditAsset(false)}
          assetDetails={asset}
        />
        <AssetShare
          shareAsset={shareAsset}
          setShareAsset={setShareAsset}
          asset={asset}
        />
        <AssetAnalytics
          assetAnalytics={assetAnalytics}
          setAssetAnalytics={setAssetAnalytics}
          asset={asset}
        />
      </div>
    </div>
  );
};

export default AssetPage;
