import React, { useState } from "react";
import { useDispatch } from "react-redux";

import LoadingSpinner from "../../../components/commons/LoadingSpinner";
import { contactActions } from "../../Contacts/actions";

const DeleteConversation = ({
  visible,
  onClose,
  contactDetails,
  onFetchContact,
  deleteDetails,
  dltTitle,
  onDeleteEvent,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleOnClose = (e) => {
    if (e.target.id === "deleteContainer") onClose();
  };

  const onDelete = () => {
    setLoading(true);
    if (onDeleteEvent) {
      onDeleteEvent();
      setLoading(false);
    } else {
      return contactDetails
        ? dispatch(
            contactActions.handleContactDelete(contactDetails.id, () => {
              onFetchContact();
              setLoading(false);
            })
          )
        : deleteDetails
        ? dispatch(
            contactActions.handleDistributionDelete(deleteDetails.id, () => {
              onClose();
              setLoading(false);
            })
          )
        : null;
    }
  };

  if (!visible) return null;

  return (
    <div
      id="deleteContainer"
      onClick={handleOnClose}
      className="fixed inset-0 bg-gray-50 bg-opacity-30 backdrop-blur-sm text-black grid items-center justify-center "
    >
      <div className="bg-white flex flex-col justify-between items-center text-center h-96 w-96 p-12 rounded-2xl">
        <span className="font-bold text-xl text-[#000080]">Confirm Action</span>
        <div className="flex flex-col gap-4">
          <span className="text-[#FE4502] text-base font-bold">
            Deleting{" "}
            {contactDetails
              ? contactDetails.name
              : deleteDetails
              ? deleteDetails.name
              : null}
          </span>
          <span className="font-semibold text-base text-[#000080]">
            Are you sure you would like to delete this{" "}
            {dltTitle ? dltTitle : "Conversation"}?{" "}
          </span>
        </div>

        <div className="flex gap-4">
          <button
            className="bg-[#000080] hover:bg-red-600 hover:text-white text-white drop-shadow-lg font-bold py-2 px-4 rounded-3xl"
            disabled={loading}
            onClick={() => onDelete()}
          >
            {loading ? <LoadingSpinner /> : "Delete"}
          </button>

          <button
            onClick={onClose}
            className="bg-uberark-orange hover:bg-white text-white hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 rounded-3xl"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConversation;
