import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Formik, Form, Field } from "formik";
import { FiEdit } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { FiXCircle, FiChevronDown } from "react-icons/fi";
import { Combobox } from "@headlessui/react";
import { Switch } from "@headlessui/react";
import { LoadingSpinner, Slider } from "../../../../components";
import AssetCard from "./AssetCard";

import { settingsActions } from "../../../Settings/actions";
import { streamActions } from "../../../Streams/actions";
import { collectionActions } from "../../../Collections/actions";
import { assetActions } from "../actions";
import { validateYouTubeUrl } from "../../../../app/utilities/helpers";

const AssetEditModal = ({ visible, onClose, assetDetails }) => {
  const dispatch = useDispatch();
  const { customFields } = useSelector((state) => state.settings);
  const { editAsset, previewStream } = useSelector((state) => state.stream);
  const { previewCollection } = useSelector((state) => state.collection);
  const initialValues = {
    file: assetDetails?.file || null,
    name: assetDetails?.name
      ? assetDetails?.url
        ? assetDetails?.name
        : assetDetails?.name.split(".").slice(0, -1).join(".")
      : "",
    description: assetDetails?.description || "",
    url: assetDetails?.url || "",
  };
  const [statusData, setStatusData] = useState({
    share: true,
    download: true,
  });
  const [tagValue, setTagValue] = useState("");
  const [tagValues, setTagValues] = useState([]);
  const [tagValueValid, setTagValueValid] = useState(false);
  const [listedCF, setListedCF] = useState([]);
  const [listShow, setListShow] = useState(false);
  const [noData, setNoData] = useState(true);

  const onLVChange = () => {
    let lv = tagValues;
    lv.push(tagValue);
    setTagValues([]);
    setTagValue("");
    setTimeout(() => {
      setTagValues(lv);
    }, 500);
  };

  const onRemoveLV = (value) => {
    let lv = tagValues.filter(function (l) {
      if (value.id) {
        return l.id !== value.id;
      } else {
        return l !== value;
      }
    });
    setTagValues(lv);
  };

  useEffect(() => {
    setStatusData({
      share: assetDetails.share || false,
      download: assetDetails.download || false,
    });
    setTagValues(
      assetDetails && assetDetails.tags && assetDetails.tags.value
        ? assetDetails.tags.value
        : []
    );
  }, [assetDetails]);

  useEffect(() => {
    if ((!customFields || customFields.length === 0) && noData) {
      dispatch(settingsActions.handleCustomFieldListsFetch(`customfields`));
      setNoData(false);
    }
  }, [customFields, dispatch, noData]);

  useEffect(() => {
    if (customFields && customFields.length && assetDetails) {
      let lcf = [];
      customFields.forEach((cf, ci) => {
        if (
          assetDetails &&
          assetDetails.custom_field_responses &&
          assetDetails.custom_field_responses.length
        ) {
          assetDetails.custom_field_responses.forEach((ad) => {
            if (cf.id === ad.custom_field) {
              if (cf.list_value && cf.list_value.length) {
                cf.list_value.forEach((lv, li) => {
                  if (ad.value === lv.id) {
                    cf["list_value"][li]["selected"] = true;
                  }
                });
              }
            }
          });
        }
        lcf.push(cf);
      });
      setListedCF(lcf);
      setTimeout(() => {
        setListShow(true);
      }, 500);
    }
  }, [customFields, assetDetails]);

  const successCallback = () => {
    dispatch(assetActions.handleGetAsset(assetDetails.id));

    if (previewStream && previewStream?.id) {
      dispatch(streamActions.handleGetStreamPreview(previewStream.id));
    }
    if (previewCollection && previewCollection?.id) {
      dispatch(
        collectionActions.handleGetCollectioPreview(previewCollection.id)
      );
    }
    setTimeout(() => {
      onClose();
    }, 500);
  };

  const statusToggle = (status) => {
    setStatusData((prev) => ({ ...prev, [status]: !statusData[status] }));
  };

  const handleSubmit = (data) => {
    let payload = new FormData();
    payload.append("description", data.description);
    payload.append("share", statusData.share);
    payload.append("download", statusData.download);

    if (data.file && typeof data.file === "object") {
      payload.append("file", data.file);
      if (data.file.name.split(".").slice(0, -1).join(".") !== data.name) {
        payload.append(
          "name",
          data.name + "." + data.file.name.split(".").slice(-1)[0]
        );
      } else {
        payload.append("name", data.file.name);
      }
    } else if (data.url) {
      payload.append("url", data.url);
      payload.append("name", data.name);

      // check if the url is a youtube url
      if (validateYouTubeUrl(data.url)) {
        payload.append("is_youtube_url", "True");
      } else {
        payload.append("is_youtube_url", "False");
      }
    }

    if (tagValues.length) {
      payload.append("tags", tagValues);
    }
    if (listedCF && listedCF.length) {
      let scfresponse = [];
      listedCF.forEach((ci) => {
        ci.list_value.forEach((lv) => {
          if (lv.selected) {
            scfresponse.push({
              value: lv.id,
              custom_field: ci.id,
              selected: "True",
            });
          } else {
            scfresponse.push({
              value: lv.id,
              custom_field: ci.id,
              selected: "False",
            });
          }
        });
      });
      payload.append("custom_fields_responses", JSON.stringify(scfresponse));
    }
    dispatch(
      streamActions.handleEditAsset(payload, assetDetails.id, successCallback)
    );
  };

  const onCFSelection = (li, ci, ims) => {
    let lcf = listedCF;
    if (!ims) {
      lcf[ci]["list_value"].forEach((clv, clvi) => {
        if (lcf[ci]["list_value"][li]["id"] === clv.id) {
          lcf[ci]["list_value"][clvi]["selected"] =
            lcf[ci]["list_value"][clvi]["selected"] !== undefined
              ? !lcf[ci]["list_value"][clvi]["selected"]
              : true;
        } else {
          lcf[ci]["list_value"][clvi]["selected"] = false;
        }
      });
    } else {
      if (lcf[ci]["list_value"][li]["selected"] !== undefined) {
        lcf[ci]["list_value"][li]["selected"] =
          !lcf[ci]["list_value"][li]["selected"];
      } else {
        lcf[ci]["list_value"][li]["selected"] = true;
      }
    }
    setListShow(false);
    setListedCF(lcf);
    setTimeout(() => {
      setListShow(true);
    }, 500);
  };

  const onRemoveSelectedLV = (li, ci) => {
    let lcf = listedCF;
    lcf[ci]["list_value"][li]["selected"] = false;
    setListShow(false);
    setListedCF(lcf);
    setTimeout(() => {
      setListShow(true);
    }, 500);
  };

  return (
    <Slider
      direction="right"
      title="Asset Edit"
      visible={visible}
      onClose={onClose}
    >
      <div className="flex flex-col gap-4">
        <div
          className="floating-form flex flex-col gap-2 w-full"
          style={{ height: "90vh", overflow: "scroll" }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
            validator={() => ({})}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form className="w-full">
                {values.url ? (
                  <>
                    <div className="relative w-full">
                      <label className="font-medium text-uberark-blue flex justify-left w-full">
                        <span>URL</span>:
                      </label>
                      <div className="pb-3 relative w-full">
                        <Field
                          className="floating-input pl-2 text-lg text-uberark-blue"
                          name="url"
                          id="url"
                          placeholder="Enter URL"
                        />
                        {errors.url && touched.url && (
                          <div className="text-red-600">{errors.url}</div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="mb-4 flex justify-center w-full">
                    <label
                      className={`w-[166px] h-[166px] img-min-height-100 relative bg-transparent flex flex-row items-center justify-center hover:bg-white drop-shadow-xl obj-height-fit`}
                      style={{ border: "0.24px solid #000080" }}
                    >
                      {values.file ? (
                        <AssetCard
                          asset={{
                            file:
                              typeof values.file !== "string"
                                ? URL.createObjectURL(values.file)
                                : values.file,
                            name:
                              values.file?.name &&
                              values.file?.name
                                .split(".")
                                .slice(0, -1)
                                .join(".") !== values.name
                                ? values.name +
                                  "." +
                                  values.file.name.split(".").slice(-1)[0]
                                : assetDetails?.name,
                          }}
                          size="asset__medium"
                          className="file-card__lg relative w-full h-full cursor-pointer opacity-80 hover:scale-10 ease-in-out duration-300 object-contain"
                        />
                      ) : null}
                      <span
                        className="rounded bg-white text-sm flex items-center text-slate-600 p-2 bottom-[65px]"
                        style={{ position: "absolute" }}
                      >
                        {values.file ? (
                          <>
                            <FiEdit className="hover:scale-105 ease-in-out duration-300 mr-1" />{" "}
                            Edit Asset
                          </>
                        ) : (
                          <>
                            <AiOutlinePlus className="hover:scale-105 ease-in-out duration-300 mr-1" />{" "}
                            Add Picture
                          </>
                        )}
                      </span>

                      <input
                        className="floating-input h-14 pl-2 text-lg text-uberark-blue hidden"
                        name="file"
                        id="file"
                        type="file"
                        onChange={(event) => {
                          setFieldValue("file", event.currentTarget.files[0]);
                        }}
                      />
                    </label>
                    {errors.file && touched.file && (
                      <div
                        className="text-red-600 pb-2"
                        style={{ right: "-120%", position: "absolute" }}
                      >
                        {errors.file}
                      </div>
                    )}
                  </div>
                )}

                <div className="relative w-full">
                  <label className="font-medium text-uberark-blue flex justify-left w-full">
                    <span>Name</span>:
                  </label>
                  <div className="pb-3 relative w-full">
                    <Field
                      className="floating-input pl-2 text-lg text-uberark-blue"
                      name="name"
                      id="name"
                      placeholder="Enter Name"
                    />
                    {errors.name && touched.name && (
                      <div className="text-red-600">{errors.name}</div>
                    )}
                  </div>
                </div>

                <div className="relative w-full">
                  <label className="font-medium text-uberark-blue flex justify-left w-full">
                    <span>Description</span>:
                  </label>
                  <div className="pb-3 relative w-full">
                    <Field
                      className="floating-input pl-2 text-lg text-uberark-blue"
                      name="description"
                      id="description"
                      as="textarea"
                      placeholder="Enter description"
                    />
                    {errors.description && touched.description && (
                      <div className="text-red-600">{errors.description}</div>
                    )}
                  </div>
                </div>

                <div className="flex justify-between items-center py-2">
                  <div className="font-medium text-uberark-blue">
                    Allow recipient to Reshare
                  </div>{" "}
                  <Switch
                    checked={statusData["share"]}
                    onChange={() => statusToggle("share")}
                    className={`${
                      statusData["share"]
                        ? " bg-uberark-blue"
                        : " bg-uberark-orange"
                    }
                            relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">Share</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        statusData["share"] ? "translate-x-5" : "translate-x-0"
                      }
                            pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>{" "}
                </div>

                <div className="flex justify-between items-center py-2">
                  <div className="font-medium text-uberark-blue">
                    Allow recipient to Download
                  </div>{" "}
                  <Switch
                    checked={statusData["download"]}
                    onChange={() => statusToggle("download")}
                    className={`${
                      statusData["download"]
                        ? " bg-uberark-blue"
                        : " bg-uberark-orange"
                    }
                            relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">Download</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        statusData["download"]
                          ? "translate-x-5"
                          : "translate-x-0"
                      }
                            pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>{" "}
                </div>

                <div className="mb-1">
                  <label className="font-medium text-uberark-blue flex justify-left w-full">
                    <span>Tags</span>:
                  </label>
                  <div className="relative">
                    {tagValues && tagValues.length ? (
                      <div className="email-chip-wrap">
                        {tagValues.map((t, i) => {
                          return (
                            <p key={i}>
                              {t.name ? t.name : t}{" "}
                              <FiXCircle onClick={() => onRemoveLV(t)} />
                            </p>
                          );
                        })}
                      </div>
                    ) : null}
                    <div className="relative">
                      <Field
                        type="text"
                        placeholder="Enter Tag"
                        name="list_value"
                        className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                        onChange={(e) => {
                          e.preventDefault();
                          setTagValue(e.target.value);
                          setTagValueValid(false);
                        }}
                        onKeyPress={(e) => {
                          e.preventDefault();
                          e.key === "Enter" && onLVChange();
                        }}
                        value={tagValue}
                      />
                      {tagValueValid && (
                        <div
                          style={{ bottom: -25 }}
                          className="text-red-600 absolute"
                        >
                          Please add one list value
                        </div>
                      )}
                      <span className="absolute top-[10px] right-2 cursor-pointer">
                        <BsArrowRightCircleFill
                          size={20}
                          className="text-[#666666]"
                          onClick={() => onLVChange()}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                {customFields &&
                customFields.length &&
                listedCF &&
                listedCF.length
                  ? listedCF.map((cf, ci) => {
                      return (
                        <div className="mb-1" key={cf?.id}>
                          <label className="font-medium text-uberark-blue flex justify-left w-full">
                            <span>{cf?.name}:</span>
                          </label>
                          <div className="relative combobox-input">
                            <Combobox
                              value={cf.list_value}
                              multiple={cf.is_multi_select}
                            >
                              {cf.list_value &&
                              cf.list_value.length &&
                              listShow ? (
                                <ul>
                                  {cf.list_value.map((scf, scfi) =>
                                    scf.selected ? (
                                      <li
                                        key={scf.id}
                                        className="no-hover lv-chips"
                                        style={{
                                          borderRadius: 0,
                                          border: "none",
                                        }}
                                      >
                                        {scf.name}{" "}
                                        <span
                                          onClick={() =>
                                            onRemoveSelectedLV(scfi, ci)
                                          }
                                        >
                                          <FiXCircle />
                                        </span>
                                      </li>
                                    ) : null
                                  )}
                                </ul>
                              ) : null}
                              <div
                                className="relative"
                                style={{ clear: "both" }}
                              >
                                <Combobox.Input placeholder="Select Custom Field" />

                                <Combobox.Button
                                  className="absolute inset-y-0 right-0 flex pr-2"
                                  style={{ top: "12px" }}
                                >
                                  <FiChevronDown />
                                </Combobox.Button>
                              </div>

                              <Combobox.Options>
                                {cf.list_value && cf.list_value.length
                                  ? cf.list_value.map((lv, li) => (
                                      <Combobox.Option
                                        key={lv.id + cf.id}
                                        value={lv.id}
                                        onClick={(e) => {
                                          onCFSelection(
                                            li,
                                            ci,
                                            cf.is_multi_select
                                          );
                                        }}
                                      >
                                        {lv.name}
                                      </Combobox.Option>
                                    ))
                                  : null}
                              </Combobox.Options>
                            </Combobox>
                          </div>
                        </div>
                      );
                    })
                  : null}
                <br />
                <div className="w-full relative">
                  <div className="flex gap-4">
                    <button
                      type="submit"
                      className=":px-2 bg-[#000080] text-white hover:drop-shadow-lg font-bold py-2 px-6 hover:scale-105 ease-in-out duration-200 rounded-2xl"
                      disabled={editAsset}
                    >
                      {editAsset ? <LoadingSpinner /> : "Edit"}
                    </button>

                    <button
                      className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
                      style={{ border: "0.24px solid #000080" }}
                      type="button"
                      onClick={() => onClose()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Slider>
  );
};

export default AssetEditModal;
