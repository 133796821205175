export const GET_STREAM_REQUEST = "GET_STREAM_REQUEST";
export const GET_STREAM_SUCCESS = "GET_STREAM_SUCCESS";
export const GET_STREAM_FAILED = "GET_STREAM_FAILED";

export const CREATE_STREAM_REQUEST = "CREATE_STREAM_REQUEST";
export const CREATE_STREAM_SUCCESS = "CREATE_STREAM_SUCCESS";
export const CREATE_STREAM_FAILED = "CREATE_STREAM_FAILED";

export const EDIT_STREAM_REQUEST = "EDIT_STREAM_REQUEST";
export const EDIT_STREAM_SUCCESS = "EDIT_STREAM_SUCCESS";
export const EDIT_STREAM_FAILED = "EDIT_STREAM_FAILED";

export const DELETE_STREAM_REQUEST = "DELETE_STREAM_REQUEST";
export const DELETE_STREAM_SUCCESS = "DELETE_STREAM_SUCCESS";
export const DELETE_STREAM_FAILED = "DELETE_STREAM_FAILED";

export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAILED = "CREATE_GROUP_FAILED";

export const CREATE_ASSET_REQUEST = "CREATE_ASSET_REQUEST";
export const CREATE_ASSET_SUCCESS = "CREATE_ASSET_SUCCESS";
export const CREATE_ASSET_FAILED = "CREATE_ASSET_FAILED";

export const DELETE_GROUP_REQUEST = "DELETE_GROUP_REQUEST";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAILED = "DELETE_GROUP_FAILED";

export const DELETE_ASSET_REQUEST = "DELETE_ASSET_REQUEST";
export const DELETE_ASSET_SUCCESS = "DELETE_ASSET_SUCCESS";
export const DELETE_ASSET_FAILED = "DELETE_ASSET_FAILED";

export const EDIT_GROUP_REQUEST = "EDIT_GROUP_REQUEST";
export const EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS";
export const EDIT_GROUP_FAILED = "EDIT_GROUP_FAILED";

export const CREATE_FOLLOW_STREAM_REQUEST = "CREATE_FOLLOW_STREAM_REQUEST";
export const CREATE_FOLLOW_STREAM_SUCCESS = "CREATE_FOLLOW_STREAM_SUCCESS";
export const CREATE_FOLLOW_STREAM_FAILED = "CREATE_FOLLOW_STREAM_FAILED";

export const GET_STREAM_FOLLOWERS_REQUEST = "GET_STREAM_FOLLOWERS_REQUEST";
export const GET_STREAM_FOLLOWERS_SUCCESS = "GET_STREAM_FOLLOWERS_SUCCESS";
export const GET_STREAM_FOLLOWERS_FAILED = "GET_STREAM_FOLLOWERS_FAILED";

export const COPY_ASSET_REQUEST = "COPY_ASSET_REQUEST";
export const COPY_ASSET_SUCCESS = "COPY_ASSET_SUCCESS";
export const COPY_ASSET_FAILED = "COPY_ASSET_FAILED";

export const STREAM_PREVIEW_REQUEST = "STREAM_PREVIEW_REQUEST";
export const STREAM_PREVIEW_SUCCESS = "STREAM_PREVIEW_SUCCESS";
export const STREAM_PREVIEW_FAILED = "STREAM_PREVIEW_FAILED";

export const ADD_COLLABORATOR_REQUEST = "ADD_COLLABORATOR_REQUEST";
export const ADD_COLLABORATOR_SUCCESS = "ADD_COLLABORATOR_SUCCESS";
export const ADD_COLLABORATOR_FAILED = "ADD_COLLABORATOR_FAILED";

export const DELETE_COLLABORATOR_REQUEST = "DELETE_COLLABORATOR_REQUEST";
export const DELETE_COLLABORATOR_SUCCESS = "DELETE_COLLABORATOR_SUCCESS";
export const DELETE_COLLABORATOR_FAILED = "DELETE_COLLABORATOR_FAILED";

export const GET_ALL_STREAM_REQUEST = "GET_ALL_STREAM_REQUEST";
export const GET_ALL_STREAM_SUCCESS = "GET_ALL_STREAM_SUCCESS";
export const GET_ALL_STREAM_FAILED = "GET_ALL_STREAM_FAILED";

export const GET_ALL_ASSETS_REQUEST = "GET_ALL_ASSETS_REQUEST";
export const GET_ALL_ASSETS_SUCCESS = "GET_ALL_ASSETS_SUCCESS";
export const GET_ALL_ASSETS_FAILED = "GET_ALL_ASSETS_FAILED";

export const PREVIEW_ASSET_REQUEST = "PREVIEW_ASSET_REQUEST";
export const PREVIEW_ASSET_SUCCESS = "PREVIEW_ASSET_SUCCESS";
export const PREVIEW_ASSET_FAILED = "PREVIEW_ASSET_FAILED";

export const MOVE_ASSET_REQUEST = "MOVE_ASSET_REQUEST";
export const MOVE_ASSET_SUCCESS = "MOVE_ASSET_SUCCESS";
export const MOVE_ASSET_FAILED = "MOVE_ASSET_REQUEST";

export const EDIT_ASSET_REQUEST = "EDIT_ASSET_REQUEST";
export const EDIT_ASSET_SUCCESS = "EDIT_ASSET_SUCCESS";
export const EDIT_ASSET_FAILED = "EDIT_ASSET_REQUEST";
