import { handleResponse, axiosInstance } from "../../../app/utilities/helpers";

const handleContactListsFetch = (queryUrl) =>
  axiosInstance.get(`${queryUrl}`).then(handleResponse);

const handleContactCreation = (data) =>
  axiosInstance.post("contacts", data).then(handleResponse);

const handleContactUpdate = (data, id) =>
  axiosInstance.put(`contacts/${id}`, data).then(handleResponse);

const handleContactDelete = (id) =>
  axiosInstance.delete(`contacts/${id}`).then(handleResponse);

const handleDistributionListsFetch = (queryUrl) =>
  axiosInstance.get(`${queryUrl}`).then(handleResponse);

const handleDistributionCreation = (data) =>
  axiosInstance.post("distributions", data).then(handleResponse);

const handleDistributionUpdate = (data, id) =>
  axiosInstance.put(`distributions/${id}`, data).then(handleResponse);

const handleDistributionDelete = (id) =>
  axiosInstance.delete(`distributions/${id}`).then(handleResponse);

const handleAddContactDistributionCreation = (data) =>
  axiosInstance.post("distribution/contacts", data).then(handleResponse);

const handleContactDistributionDelete = (id) =>
  axiosInstance.delete(`distribution/contacts/${id}`).then(handleResponse);

export const authService = {
  handleContactListsFetch,
  handleContactCreation,
  handleContactUpdate,
  handleContactDelete,
  handleDistributionListsFetch,
  handleDistributionCreation,
  handleDistributionUpdate,
  handleDistributionDelete,
  handleAddContactDistributionCreation,
  handleContactDistributionDelete,
};
