/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";

import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { BiLogOut } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import logo from "../../assets/images/logos/logo.svg";
import { links } from "../../app/utilities/data/mock/linkDatas";
import { useStateContext } from "../../app/context/ContextProvider";
import { authActions } from "../../pages/Auth/actions";
import "./sidebar.css";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { currentColor, activeMenu, setActiveMenu, screenSize } =
    useStateContext();

  const handleCloseSideBar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  useEffect(() => {
    handleCloseSideBar();
  }, [screenSize]);

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 text-base text-[#FF4501] border-r-4 border-r-[#FF4501] text-md m-2 font-semibold";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 text-base text-[#000080] hover:text-red-600 m-2 font-semibold";

  const handleLogout = () => {
    dispatch(authActions.handleLogout());
  };

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      <div className="flex justify-between items-center">
        <Link
          to="/"
          onClick={handleCloseSideBar}
          className="items-center ml-3 mt-4 flex text-xl font-extrabold tracking-tight text-slate-900"
        >
          <img src={logo} alt="logo" />
          {activeMenu && <span>Uberark</span>}
        </Link>

        <TooltipComponent content="Menu" position="BottomCenter">
          <button
            type="button"
            onClick={() => setActiveMenu(!activeMenu)}
            style={{ color: currentColor }}
            className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
          >
            <MdOutlineCancel />
          </button>
        </TooltipComponent>
      </div>
      <div className="mt-10">
        {links.map((item, index) => (
          <div key={index}>
            {item.links.map((Link) => (
              <NavLink
                to={`/${Link.path}`}
                key={Link.name}
                onClick={handleCloseSideBar}
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                <TooltipComponent content={Link.name}>
                  {Link.icon}
                </TooltipComponent>
                {activeMenu && <span>{Link.name}</span>}
              </NavLink>
            ))}
            <button onClick={() => handleLogout()} className={normalLink}>
              <TooltipComponent content="Logout">
                <BiLogOut className="text-[16px]" />
              </TooltipComponent>
              {activeMenu && <span>Logout</span>}
            </button>
          </div>
        ))}
        {activeMenu && (
          <div className="bg-local relative">
            <div className="footerCard absolute inset-0 bg-uberark-blue">
              <div className="cardLogo">
                <img src={logo} alt="logo" />
              </div>
              <Link to="/">
                <div className="flogo">
                  <span>Uberark</span>
                </div>
              </Link>
              <div className="subtitle">
                <span className="subtext">
                  Get access to all
                  <br />
                  features
                </span>
              </div>
              <button className="cardButton">Get Pro</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
