import {
  handleResponse,
  axiosInstance,
  fileRequestConfig,
} from "../../../app/utilities/helpers";

const handleGetStream = (queryUrl) =>
  axiosInstance.get(queryUrl).then(handleResponse);

const handleCreateStream = (payload) => {
  // Initialize the form data
  const data = new FormData();
  payload.cover_image &&
    data.append("cover_image", payload.cover_image, payload.cover_image.name);
  data.append("title", payload.title);
  data.append("description", payload.description || "");

  return axiosInstance
    .post(`contents`, data, fileRequestConfig)
    .then(handleResponse);
};

const handleEditStream = (payload) => {
  // Initialize the form data
  const data = new FormData();
  if (payload.cover_image) {
    data.append("cover_image", payload.cover_image, payload.cover_image.name);
  }
  if (payload.title) {
    data.append("title", payload.title);
  }
  data.append("description", payload.description || "");
  if (payload.user) {
    data.append("user", payload.user);
  }

  return axiosInstance
    .put(`contents/${payload.id}`, data, fileRequestConfig)
    .then(handleResponse);
};

const handleDeleteStream = (streamId) =>
  axiosInstance.delete(`contents/${streamId}`).then(handleResponse);

const handleCreateGroup = (data) =>
  axiosInstance.post("groups", data).then(handleResponse);

const handleCreateAsset = (payload) => {
  // Initialize the form data
  const data = new FormData();
  payload.file && data.append("file", payload.file, payload.file.name);
  payload.file && data.append("name", payload.file.name);
  payload.url && data.append("url", payload.url);
  payload.is_youtube_url &&
    data.append("is_youtube_url", payload.is_youtube_url);
  payload.group && data.append("group", payload.group);
  payload.collection && data.append("collection", payload.collection);
  payload.section && data.append("section", payload.section);
  payload.page_count && data.append("page_count", payload.page_count);
  payload.video_duration &&
    data.append("video_duration", payload.video_duration);
  payload.file_size && data.append("file_size", payload.file_size);

  return axiosInstance
    .post(`assets`, data, fileRequestConfig)
    .then(handleResponse);
};

const handleDeleteGroup = (groupId) =>
  axiosInstance.delete(`groups/${groupId}`).then(handleResponse);

const handleDeleteAsset = (assetId) =>
  axiosInstance.delete(`assets/${assetId}`).then(handleResponse);

const handleEditGroup = (data) =>
  axiosInstance.put(`groups/${data.id}`, data).then(handleResponse);

const handleFollowUnfollowStream = async (payload) => {
  const res = await axiosInstance.post("content/followers", payload);
  return handleResponse(res);
};

const handleGetFollowers = async () => {
  const res = await axiosInstance.get("content/followers");
  return handleResponse(res);
};

const handleCopyAsset = (data) =>
  axiosInstance.post(`asset/copy`, data).then(handleResponse);

const handleAddCollaborators = async (payload) => {
  const res = await axiosInstance.post(`content/collaborators`, payload);
  return handleResponse(res);
};

const handleDeleteCollaborator = (collaboratorId) =>
  axiosInstance
    .delete(`content/collaborators/${collaboratorId}`)
    .then(handleResponse);

const handleMoveAsset = (data) =>
  axiosInstance.post(`asset/move`, data).then(handleResponse);

const handleEditAsset = (data, id) =>
  axiosInstance.put(`assets/${id}`, data).then(handleResponse);

export const streamService = {
  handleGetStream,
  handleCreateStream,
  handleEditStream,
  handleDeleteStream,
  handleCreateGroup,
  handleCreateAsset,
  handleDeleteGroup,
  handleDeleteAsset,
  handleEditGroup,
  handleFollowUnfollowStream,
  handleGetFollowers,
  handleCopyAsset,
  handleAddCollaborators,
  handleDeleteCollaborator,
  handleMoveAsset,
  handleEditAsset,
};
