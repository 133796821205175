/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Tree from "react-tree-file-system";
import "react-tree-file-system/index.css";
import { BsXCircleFill, BsFillCheckCircleFill } from "react-icons/bs";
import AssetCard from "../AssetCard";

import { streamActions } from "../../../../Streams/actions";

const AssetAddStreams = ({ handleFileClick, selectedAssets, openGroup }) => {
  const dispatch = useDispatch();
  const { streamTree } = useSelector((state) => state.stream);
  const { user } = useSelector((state) => state.auth);
  const [data, setData] = useState({ treeValue: [] });

  useEffect(() => {
    if (user.organization && !openGroup) {
      dispatch(streamActions.handleGetAllStream(user.organization));
    }
  }, [user.organization, dispatch]);

  useEffect(() => {
    const groups = [];
    const streamDataGroups = [];
    if (streamTree && streamTree.length) {
      streamTree.map((item) => {
        streamDataGroups.push(...item.group);
        return true;
      });
      streamDataGroups.map((item, index) => {
        const formatedAssets = [];
        if (item?.asset && item?.asset.length) {
          item.asset.map((asset, assetIdx) => {
            if (!asset.deleted) {
              formatedAssets.push({
                fileIcon: (
                  <FileIcon asset={asset} selectedAssets={selectedAssets} />
                ),
                title: asset.name,
                id: asset.id,
                group: asset.group,
                file: asset.file,
                parentType: "stream",
              });
            }

            return true;
          });
        }
        const homeFolder = streamTree.filter(
          (streamItem) => streamItem.id === item.name
        );
        groups.push({
          title:
            homeFolder && homeFolder.length > 0
              ? homeFolder[0].title
              : item.name,
          type: "folder",
          id: item.id,
          parent_group: item.parent_group,
          children: formatedAssets,
          isOpen: true,
        });
        return true;
      });

      const treeLevelGroups = buildTree(groups, null);
      setData(() => ({
        treeValue: treeLevelGroups,
      }));
    }
  }, [streamTree, selectedAssets]);

  const buildTree = (items, parent_group) => {
    const tree = [];

    for (const item of items) {
      if (item.parent_group === parent_group) {
        const children = buildTree(items, item.id);

        if (children.length) {
          item.children.push(...children);
        }
        tree.push(item);
      }
    }

    return tree;
  };

  const recursiveSetState = (tree, indexes, currIndex, key, value) => {
    const dupTree = tree.slice();
    const getIndex = indexes[currIndex];
    if (currIndex === indexes.length - 1) {
      dupTree[getIndex][key] = value;
      return tree;
    }
    dupTree[getIndex].children = recursiveSetState(
      (dupTree[getIndex].children || []).slice(0),
      indexes,
      currIndex + 1,
      key,
      value
    );
    return dupTree;
  };

  return (
    <Tree
      value={data.treeValue}
      style={{ width: "-webkit-fill-available" }}
      folderOnClick={(_, indexes, state) => {
        setData((prevState) => {
          return {
            treeValue: recursiveSetState(
              prevState.treeValue,
              indexes,
              0,
              "isOpen",
              state
            ),
          };
        });
      }}
      fileOnClick={(_, indexes, state) => handleFileClick(_, indexes, state)}
    />
  );
};

export default AssetAddStreams;

const FileIcon = ({ asset, selectedAssets }) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (selectedAssets) {
      const selected = selectedAssets.filter((item) => item.id === asset.id);
      setIsSelected(() => (selected.length === 1 ? true : false));
    }
  }, [selectedAssets]);

  return (
    <div className="flex flex-col mt-1">
      {isSelected ? (
        <div className="file-card__delete animate-pulse">
          <BsXCircleFill />
        </div>
      ) : (
        <div className="file-card__add animate-pulse">
          <BsFillCheckCircleFill />
        </div>
      )}
      <AssetCard asset={asset} size="asset__small" className="file-tree" />
    </div>
  );
};
