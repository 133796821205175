import React, { useEffect, Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Menu, Transition } from "@headlessui/react";
import AddFolder from "./components/AddFolder";
import EditFolder from "./components/EditFolder";

import {
  BsChevronDown,
  BsFolderFill,
  BsCaretDownFill,
  BsCheckCircleFill,
  BsTrash,
  BsSortUp,
  BsSortDownAlt,
} from "react-icons/bs";
import { IoCopy } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import { RiEditCircleFill } from "react-icons/ri";
import { FiInfo } from "react-icons/fi";
import "./collection.css";
import no_content from "../../assets/images/no_content.svg";

import { collectionActions } from "./actions";
import { PopUpDelete } from "../../components";
import DeleteConversation from "../Conversations/components/DeleteConversation";
import { LoadingSpinner } from "../../components";
import ReactPaginate from "react-paginate";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Collections = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();
  const {
    collection,
    folders,
    cloneCollection,
    deleteCollection,
    dltFolder,
    loading,
    totalCollectionPage,
  } = useSelector((state) => state.collection);
  const { user } = useSelector((state) => state.auth);

  const [filter, setFilter] = useState("All");
  const [addFolderToggle, setAddFolderToggle] = useState(false);
  const [editFolderToggle, setEditFolderToggle] = useState(false);
  const [cloneToggle, setCloneToggle] = useState(false);
  const [renamedToggle, setRenamedToggle] = useState(false);
  const [clonePopup, setClonePopup] = useState(false);
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState({});
  const [selectedCollection, setSelectedCollection] = useState({});
  const [dltFolderShow, setDltFolderShow] = useState(false);
  const [selectedDltFolder, setSelectedDltFolder] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortValues, setSortValues] = useState({
    name: "",
    created_at: "",
    updated_at: "",
  });

  useEffect(() => {
    if (user.organization && user.organization !== "None") {
      dispatch(collectionActions.handleGetFolder());
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (user.organization && user.organization !== "None") {
      setCloneToggle(false);
      setClonePopup(false);
      setDeleteToggle(false);
      setDeletePopup(false);
      setRenamedToggle(false);
      if (selectedFolder && selectedFolder.id) {
        handleFilterCollection("folder", selectedFolder.id);
      } else {
        handleFilterCollection();
      }
    }
  }, [cloneCollection, deleteCollection]); // eslint-disable-line

  const handleFilterCollection = (name, value) => {
    const filter = {};
    if (name && value) {
      filter.name = name;
      filter.value = value;
      setFilter(name);
    } else if (name && !value) {
      setFilter(name);
    } else if (!name && !value) {
      setFilter("All");
    }

    if ((name && name !== "folder") || !name) {
      setSelectedFolder({});
    }

    dispatch(collectionActions.handleGetCollection(filter));
  };

  const handleSortCollection = (s) => {
    const updatedSortValues = sortValues;
    Object.keys(updatedSortValues).map((item) => {
      if (item === s) {
        updatedSortValues[item] =
          updatedSortValues[item] === "asc" ? "desc" : "asc";
      } else {
        updatedSortValues[item] = "";
      }
      return true;
    });

    dispatch(
      collectionActions.handleGetCollection({
        name: "order_by",
        value: updatedSortValues[s] === "asc" ? s : `-${s}`,
      })
    );

    setSortValues(updatedSortValues);
    return updatedSortValues;
  };

  const handleAddFolderToggle = () => setAddFolderToggle(!addFolderToggle);

  const handleEditFolderToggle = () => setEditFolderToggle(!editFolderToggle);

  const handleCreateFolder = (data) =>
    dispatch(
      collectionActions.handleCreateFolder(
        { ...data, organization: user.organization },
        handleAddFolderToggle
      )
    );

  const handleEditFolder = (data) => {
    const payload = {
      ...selectedFolder,
      name: data.name,
    };
    dispatch(
      collectionActions.handleEditFolder(payload, handleEditFolderToggle)
    );
  };

  const handleDeleteFolder = (folder) => {
    setSelectedDltFolder(folder);
    setDltFolderShow(true);
  };

  const handleSelectFolder = (folder) => {
    setSortValues({
      name: "",
      created_at: "",
      updated_at: "",
    });
    if (folder.id === selectedFolder.id) {
      handleFilterCollection("All");
      return setSelectedFolder({});
    }
    handleFilterCollection("folder", folder.id);
    setSelectedFolder(folder);
  };

  const onCollectionClick = (item) => {
    setSelectedCollection(item);
    if (cloneToggle) {
      setClonePopup(true);
    } else if (deleteToggle) {
      setDeletePopup(true);
    } else if (renamedToggle) {
      navigate(`/collection/edit/${item.id}`);
    } else {
      navigate(`/collection/${item.id}`);
    }
  };

  const handleCloneCollection = () => {
    return dispatch(
      collectionActions.handleCloneCollection(selectedCollection.id)
    );
  };

  const handleDeleteCollection = () => {
    return dispatch(
      collectionActions.handleDeleteCollection(selectedCollection.id)
    );
  };

  const onCloseClone = () => {
    setCloneToggle(false);
    setClonePopup(false);
  };

  const onCloseDelete = () => {
    setDeleteToggle(false);
    setDeletePopup(false);
  };

  const handleDltPopupClose = () => {
    setDltFolderShow(false);
  };

  useEffect(() => {
    if (dltFolder) {
      setDltFolderShow(false);
    }
  }, [dltFolder]);

  const onConfirmDlt = () => {
    return dispatch(collectionActions.handleDeleteFolder(selectedDltFolder.id));
  };

  const changePage = ({ selected }) => {
    setCurrentPage(selected);
    return dispatch(
      collectionActions.handleGetCollection(null, null, selected + 1)
    );
  };

  return (
    <div className="relative">
      <div className="absolute sm:top-0 top-20 w-full h-full mb-5">
        <div className="md:mt-4 md:mx-20 mx-4 mt-4 grid grid-cols-1 md:grid-cols-2 justify-between bg-transparent">
          <div className="flex flex-row justify-between w-full">
            <div className="flex flex-col">
              <span className="text-[#FE4502] font-extrabold  text-lg">
                Collections
              </span>

              <span className="text-[#757575] text-base">
                Personalized collection storyboards
              </span>
            </div>
          </div>

          <div className="grid grid-flow-col items-center justify-between md:justify-end py-4 pt-4 md:p-0 text-slate-600">
            <div className="divide-x text-sm divide-slate-500 grid grid-flow-col">
              {/* <div className="grid justify-center px-2">Rename</div> */}
              {cloneToggle ? (
                <div className="space-between-flex px-1">
                  Select collection to be cloned or
                  <button
                    className="bg-gray-100 text-[#000080] block p-1 m-1 text-sm shadow-xl rounded-lg"
                    onClick={() => setCloneToggle(false)}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div
                  className="flex items-center justify-center px-1 cursor-pointer text-[#000080] hover:text-uberark-orange"
                  onClick={() => {
                    setCloneToggle(true);
                    setDeleteToggle(false);
                    setRenamedToggle(false);
                  }}
                >
                  <IoCopy className="mr-1" />
                  Clone
                </div>
              )}
              {renamedToggle ? (
                <div className="space-between-flex px-1">
                  Select collection to be renamed or
                  <button
                    className="bg-gray-100 text-[#000080] block p-1 m-1 text-sm shadow-xl rounded-lg"
                    onClick={() => setRenamedToggle(false)}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div
                  className="flex items-center justify-center px-1 cursor-pointer text-[#000080] hover:text-uberark-orange"
                  onClick={() => {
                    setRenamedToggle(true);
                    setCloneToggle(false);
                    setDeleteToggle(false);
                  }}
                >
                  <RiEditCircleFill className="mr-1" />
                  Rename
                </div>
              )}
              {deleteToggle ? (
                <div className="space-between-flex px-1">
                  Select collection to be deleted or
                  <button
                    className="bg-gray-100 text-[#000080] block p-1 m-1 text-sm shadow-xl rounded-lg"
                    onClick={() => setDeleteToggle(false)}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div
                  className="flex items-center justify-center px-1 cursor-pointer text-[#000080] hover:text-uberark-orange"
                  onClick={() => {
                    setDeleteToggle(true);
                    setCloneToggle(false);
                    setRenamedToggle(false);
                  }}
                >
                  <BsTrash className="mr-1" />
                  Delete
                </div>
              )}
            </div>

            <div>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex justify-center w-full rounded-md p-2 bg-transparent text-sm font-base text-[#000080] hover:bg-gray-50 focus:outline-none">
                    Sort By
                    <BsChevronDown
                      className="-mr-1 ml-2 h-5 w-5 text-uberark-orange chevron"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-inherit rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-50">
                    <div className="py-1">
                      {Object.keys(sortValues).map((s, i) => {
                        return (
                          <Menu.Item key={i}>
                            {({ active }) => (
                              <button
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-[#000080]"
                                    : "text-gray-500",
                                  "px-2 py-1 text-sm flex items-center w-full text-left"
                                )}
                                onClick={() => handleSortCollection(s)}
                              >
                                {s.split("_")[0]}
                                {sortValues[s] === "asc" && (
                                  <BsSortUp className="ml-1" />
                                )}
                                {sortValues[s] === "desc" && (
                                  <BsSortDownAlt className="ml-1" />
                                )}
                              </button>
                            )}
                          </Menu.Item>
                        );
                      })}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        {/* collections 1------------------------------------------------------------------------------*/}
        <div className="m-4">
          <div className="rounded-3xl bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] p-6 shadow w-full relative min-h-[75vh]">
            {loading ? (
              <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10">
                <div className="w-full flex justify-center">
                  <LoadingSpinner colorClassName="border-[#000080]" />
                </div>
              </div>
            ) : !user.organization || user.organization === "None" ? (
              <>
                <div
                  className="flex items-center bg-orange-500 text-white text-sm font-bold px-4 py-3 w-full"
                  role="alert"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                  </svg>
                  <p>
                    You need to be in an organization to create collections.{" "}
                    <span
                      className="underline"
                      onMouseDown={() => navigate("/UserProfile/Account")}
                    >
                      Click here
                    </span>{" "}
                    to create organization
                  </p>
                </div>
                <div className="w-full">
                  <div className="flex flex-wrap mt-8">
                    <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10">
                      <div className="w-full flex justify-center">
                        <img
                          src={no_content}
                          alt="nothing to show illustration"
                          style={{ height: "60px" }}
                          loading="lazy"
                        />
                      </div>
                      <div className="text-center relative p-5 text-[#757575] text-base flex items-center">
                        Nothing to show.{" "}
                        <FiInfo className="text-uberark-orange ml-1" />
                      </div>
                      <Link
                        className="flex items-center justify-center py-1 px-5 cursor-pointer opacity-80 hover:scale-105 ease-in-out duration-300 border rounded"
                        alt={`/collection/add${
                          selectedFolder && selectedFolder.id
                            ? `/${selectedFolder.id}`
                            : ""
                        }`}
                        to={`/collection/add${
                          selectedFolder && selectedFolder.id
                            ? `/${selectedFolder.id}`
                            : ""
                        }`}
                      >
                        <AiOutlinePlus className="text-[#FE4502] mr-1" /> Create
                        Collection
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-wrap mb-5 text-sm text-slate-600 divide-slate-300 w-full">
                  <div>
                    <button
                      className="hover:text-[#000080] border border-slate-300 rounded drop-shadow-lg flex items-center mr-2 px-2 cursor-pointer"
                      onClick={() => handleAddFolderToggle()}
                    >
                      {" "}
                      <AiOutlinePlus className="hover:text-uberark-orange" />
                      Add Folder
                    </button>
                  </div>
                  <div
                    className={`border rounded drop-shadow-lg flex items-center mr-2 px-2 cursor-pointer hover:text-uberark-orange ${
                      filter === "All"
                        ? "text-uberark-orange border-color-blue"
                        : "border-slate-300"
                    }`}
                    onMouseDown={() => handleFilterCollection("All")}
                  >
                    All
                  </div>
                  <div
                    className={`border rounded drop-shadow-lg flex items-center mr-2 px-2 cursor-pointer hover:text-uberark-orange ${
                      filter === "user"
                        ? "text-uberark-orange border-color-blue"
                        : "border-slate-300"
                    }`}
                    onMouseDown={() => handleFilterCollection("user", user.id)}
                  >
                    Owned By Me
                  </div>
                  <div
                    className={`border rounded drop-shadow-lg flex items-center mr-2 px-2 cursor-pointer hover:text-uberark-orange ${
                      filter === "internal"
                        ? "text-uberark-orange border-color-blue"
                        : "border-slate-300"
                    }`}
                    onMouseDown={() =>
                      handleFilterCollection("internal", "True")
                    }
                  >
                    Internal Use
                  </div>
                  <div
                    className={`border rounded drop-shadow-lg flex items-center mr-2 px-2 cursor-pointer hover:text-uberark-orange ${
                      filter === "live"
                        ? "text-uberark-orange border-color-blue"
                        : "border-slate-300"
                    }`}
                    onMouseDown={() => handleFilterCollection("live", "True")}
                  >
                    Live
                  </div>
                  <div
                    className={`border rounded drop-shadow-lg flex items-center mr-2 px-2 cursor-pointer hover:text-uberark-orange ${
                      filter === "template"
                        ? "text-uberark-orange border-color-blue"
                        : "border-slate-300"
                    }`}
                    onMouseDown={() =>
                      handleFilterCollection("template", "True")
                    }
                  >
                    Templates
                  </div>
                  {folders &&
                    folders.length > 0 &&
                    folders.map((folder, index) => (
                      <div key={index} className="px-2 animate-pulse">
                        <button
                          className={`inline-flex justify-center items-center border-white border pl-2 pr-2 text-sm font-medium shadow-sm ${
                            selectedFolder && selectedFolder.id === folder.id
                              ? "bg-[#FE4502] border-[#FE4502] text-white rounded-l"
                              : "bg-white border-gray-300 text-gray-700 rounded"
                          }`}
                          onMouseDown={() => handleSelectFolder(folder)}
                        >
                          <BsFolderFill className="mr-1" />
                          {folder.name}
                        </button>
                        {selectedFolder && selectedFolder.id === folder.id && (
                          <Menu
                            as="div"
                            className="relative inline-block text-left outline-none"
                          >
                            {({ open }) => (
                              <>
                                <div className="outline-none">
                                  <Menu.Button
                                    className={`inline-flex justify-center items-center rounded-r px-2 py-1 text-sm font-medium shadow-sm focus:outline-none outline-none bg-[#FE4502] border-[#FE4502] ml-[1px] text-white`}
                                  >
                                    <BsCaretDownFill />
                                  </Menu.Button>
                                </div>
                                {open && (
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      static
                                      className={`absolute right-0 z-10 mt-2 w-35 origin-top-right rounded-md bg-white shadow-lg border-none`}
                                    >
                                      <div className="py-3">
                                        <Menu.Item>
                                          <button
                                            className="hover:text-[#000080] text-slate-600 drop-shadow-lg flex items-center px-5 py-2"
                                            onClick={() =>
                                              handleEditFolderToggle(folder.id)
                                            }
                                          >
                                            {" "}
                                            <RiEditCircleFill />
                                            Edit{" "}
                                          </button>
                                        </Menu.Item>
                                        <Menu.Item>
                                          <button
                                            className="hover:text-[#000080] text-slate-600 drop-shadow-lg flex items-center px-5 py-2"
                                            onClick={() =>
                                              handleDeleteFolder(folder)
                                            }
                                          >
                                            {" "}
                                            <BsTrash />
                                            Delete{" "}
                                          </button>
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                )}
                              </>
                            )}
                          </Menu>
                        )}
                      </div>
                    ))}
                </div>

                <div className="w-full">
                  <div className="flex flex-wrap">
                    {collection && collection.length > 0 ? (
                      <div className="mt-2">
                        <Link
                          className="relative"
                          alt={`/collection/add${
                            selectedFolder && selectedFolder.id
                              ? `/${selectedFolder.id}`
                              : ""
                          }`}
                          to={`/collection/add${
                            selectedFolder && selectedFolder.id
                              ? `/${selectedFolder.id}`
                              : ""
                          }`}
                        >
                          <label
                            className="w-155px h-[155px] md:h-[155px] md:w-[155px]
                    inline-grid items-center justify-center p-2 cursor-pointer
                    opacity-80 hover:scale-105 ease-in-out duration-300
                    rounded-[20px]"
                            style={{ border: "0.25px solid #000080" }}
                          >
                            <AiOutlinePlus
                              size={70}
                              className="text-[#FE4502]"
                            />
                          </label>
                        </Link>
                      </div>
                    ) : (
                      <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10 mt-10">
                        <div className="w-full flex justify-center">
                          <img
                            src={no_content}
                            alt="nothing to show illustration"
                            style={{ height: "60px" }}
                            loading="lazy"
                          />
                        </div>
                        <div className="text-center relative p-5 text-[#757575] text-base flex items-center">
                          Nothing to show.{" "}
                          <FiInfo className="text-uberark-orange ml-1" />
                        </div>

                        <Link
                          className="flex items-center justify-center py-1 px-5 cursor-pointer opacity-80 hover:scale-105 ease-in-out duration-300 border rounded"
                          alt="/collection"
                          to={`/collection/add${
                            selectedFolder && selectedFolder.id
                              ? `/${selectedFolder.id}`
                              : ""
                          }`}
                        >
                          <AiOutlinePlus className="text-[#FE4502] mr-1" />{" "}
                          Create Collection
                        </Link>
                      </div>
                    )}

                    {collection && collection.length > 0
                      ? collection.map((item) => (
                          <div
                            key={item.id}
                            className="relative hover:scale-105 ease-in-out duration-300 rounded-[20px]"
                            onMouseDown={() => onCollectionClick(item)}
                          >
                            {cloneToggle ||
                            (deleteToggle && item.created_by.id === user.id) ||
                            (renamedToggle &&
                              item.created_by.id === user.id) ? (
                              <BsCheckCircleFill
                                className="absolute animate-pulse text-uberark-orange cursor-pointer"
                                style={{
                                  color: "text-uberark-orange",
                                  fontSize: "larger",
                                  marginLeft: "5px",
                                  zIndex: "10",
                                }}
                              />
                            ) : (
                              ""
                            )}
                            <img
                              className="w-[166px] h-[166px] inline-block p-2 cursor-pointer opacity-80 rounded-[25px]"
                              style={{
                                filter: "brightness(0.5)",
                                visibility: item.banner ? "visible" : "hidden",
                              }}
                              src={
                                item.banner_thumbnail
                                  ? item.banner_thumbnail
                                  : item.banner
                              }
                              alt="cover"
                              loading="lazy"
                            />

                            <span className="absolute inset-0 flex m-2 items-center justify-center p-2 cursor-pointer rounded-[20px]">
                              <span
                                className={`${
                                  item.banner ? "text-white" : "text-slate-600"
                                } font-bold truncate w-90 px-1 w-[166px] h-[166px] flex items-center justify-center `}
                              >
                                {item.name}{" "}
                              </span>
                            </span>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="flex items-start">
                  {totalCollectionPage > 1 ? (
                    <div className="py-4">
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={totalCollectionPage}
                        onPageChange={changePage}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={1}
                        forcePage={currentPage}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                      />
                    </div>
                  ) : null}
                </div>
              </>
            )}

            <AddFolder
              visible={addFolderToggle}
              handleModalClose={handleAddFolderToggle}
              handleCreateFolderSubmit={handleCreateFolder}
            />

            <EditFolder
              visible={editFolderToggle}
              handleModalClose={handleEditFolderToggle}
              handleEditFolderSubmit={handleEditFolder}
              folder={selectedFolder || {}}
            />

            {clonePopup ? (
              <PopUpDelete
                onClose={onCloseClone}
                visible={clonePopup}
                itemName={selectedCollection?.name}
                deleteFn={handleCloneCollection}
                actionName="Clone"
                confirmingTxt="Cloning"
              />
            ) : null}

            {deletePopup ? (
              <PopUpDelete
                onClose={onCloseDelete}
                visible={deletePopup}
                itemName={selectedCollection?.name}
                deleteFn={handleDeleteCollection}
                actionName="Delete"
                confirmingTxt="Deleting"
              />
            ) : null}
          </div>
        </div>
      </div>

      <DeleteConversation
        visible={dltFolderShow}
        dltTitle={"Folder"}
        onClose={handleDltPopupClose}
        onDeleteEvent={onConfirmDlt}
        deleteDetails={selectedDltFolder}
      />
    </div>
  );
};

export default Collections;
