import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import LoadingSpinner from "../../components/commons/LoadingSpinner";
import { Formik, Form, Field } from "formik";
import { BsFillEnvelopeFill } from "react-icons/bs";
import * as Yup from "yup";
import { settingsActions } from "./actions";
import "./settings.css";

export default function InviteUser(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organization, loading, inviteConfirmed } = useSelector(
    (state) => state.settings
  );
  const { organization: userOrganization } = useSelector(
    (state) => state.auth.user
  );
  const initialValues = {
    email: "",
  };
  const FormSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Please enter email"),
  });

  const handleSubmit = (e) => {
    if (
      (organization && organization.id) ||
      (userOrganization && userOrganization !== "None")
    ) {
      let orgId =
        organization && organization.id
          ? organization.id
          : userOrganization
          ? userOrganization
          : null;
      const payload = new FormData();
      payload.append("email", e.email);
      payload.append("organization", orgId);
      dispatch(settingsActions.handleSendUserInvite(payload));
    }
  };

  useEffect(() => {
    if (inviteConfirmed === false) {
      props.onCloseInviteForm();
    }
  }, [inviteConfirmed, props]);

  return (
    <div className="invite-user-wrap">
      {!(
        (organization && organization.id) ||
        (userOrganization && userOrganization !== "None")
      ) && (
        <div
          className="flex items-center bg-orange-500 text-white text-sm font-bold px-4 py-3 my-2"
          role="alert"
        >
          <svg
            className="fill-current w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
          </svg>
          <p>
            You need to be in an organization to add collaborators.{" "}
            <span
              className="underline"
              onMouseDown={() => navigate("/UserProfile/Account")}
            >
              Click here
            </span>{" "}
            to create organization
          </p>
        </div>
      )}
      <div className="flex flex-col gap-4 floating-form">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={FormSchema}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form>
              <div className="w-full">
                <label className="font-medium text-uberark-blue">
                  Email <span className="text-red-500">*</span>:{" "}
                </label>
                <div className="mb-3 relative w-full">
                  <Field
                    className="floating-input pl-2 text-lg text-uberark-blue"
                    name="email"
                    id="email"
                    type="email"
                    placeholder="Enter email"
                  />

                  {errors.email && touched.email && (
                    <div className="text-red-600">{errors.email}</div>
                  )}

                  <span className="absolute top-[10px] right-5">
                    <BsFillEnvelopeFill size={20} className="text-[#666666]" />
                  </span>
                </div>
              </div>

              <div className="flex flex-row lg:justify-end justify-center text-uberark-blue gap-4  text-lg">
                <button
                  className=":px-2 bg-[#000080] text-white hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl "
                  type="submit"
                  disabled={
                    loading ||
                    !(
                      (organization && organization.id) ||
                      (userOrganization && userOrganization !== "None")
                    )
                  }
                >
                  {loading ? <LoadingSpinner /> : "Save"}
                </button>
                <button
                  className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
                  style={{ border: "0.24px solid #000080" }}
                  type="button"
                  onClick={() => props?.onCloseInviteForm()}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
