import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { FiMenu } from "react-icons/fi";
import { RiNotificationLine } from "react-icons/ri";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Notification, LoadingSpinner } from "../";

import { useDebounce } from "usehooks-ts";
import { useStateContext } from "../../app/context/ContextProvider";
import { conversationsActions } from "../../pages/Conversations/actions";
import { collectionActions } from "../../pages/Collections/actions";
import { streamActions } from "../../pages/Streams/actions";
import avatarPlaceholder from "../../assets/images/avatar-placeholder.png";
import "./navbar.css";

const NavButton = ({ title, customFunc, icon, color, dotColor, bg }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2 animate-pulse delay-75"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const { notifications } = useSelector((state) => state.settings);
  const { loading: collectionLoading } = useSelector(
    (state) => state.collection
  );
  const { loading: streamLoading } = useSelector((state) => state.stream);

  const [searchChange, setSearchChange] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [currentPathname, setCurrentPathname] = useState("");
  const [notificationsCount, setNotificationsCount] = useState(0);
  const debouncedValue = useDebounce(searchValue, 800);

  const {
    // activeMenu,
    handleClick,
    setActiveMenu,
    isClicked,
    setIsClicked,
    setScreenSize,
    screenSize,
  } = useStateContext();

  const searchTerm = {
    "/hub": "Streams",
    "/collections": "Collections",
    "/conversations": "Conversations",
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [setScreenSize]);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize, setActiveMenu]);

  const resetSearch = () => setSearchChange(false);

  useEffect(() => {
    if (currentPathname !== location.pathname) {
      setSearchValue(null);
      setCurrentPathname(location.pathname);
    }
  }, [location, currentPathname]);

  useEffect(() => {
    let value = debouncedValue;
    let filter = {
      name: "name",
      value: value,
    };
    if (debouncedValue !== null && debouncedValue === searchValue) {
      if (value.length) {
        if (user.organization && currentPathname.length) {
          switch (currentPathname) {
            case "/hub":
              return dispatch(
                streamActions.handleGetStream(
                  null,
                  null,
                  filter.value,
                  resetSearch
                )
              );
            case "/collections":
              return dispatch(
                collectionActions.handleGetCollection(filter, resetSearch)
              );
            case "/conversations":
              return dispatch(
                conversationsActions.handleConversationsListsFetch(
                  `compose/send-shallow?subject_contact=${filter.value}`
                )
              );
            default:
              break;
          }
        }
      } else {
        if (user.organization && currentPathname.length && value.length === 0) {
          switch (currentPathname) {
            case "/hub":
              return dispatch(
                streamActions.handleGetStream(
                  null,
                  null,
                  filter.value,
                  resetSearch
                )
              );
            case "/collections":
              return dispatch(
                collectionActions.handleGetCollection(filter, resetSearch)
              );
            case "/conversations":
              return dispatch(
                conversationsActions.handleConversationsListsFetch(
                  `compose/send-shallow?page=1`
                )
              );
            default:
              break;
          }
        }
      }
    }
  }, [
    debouncedValue,
    currentPathname,
    dispatch,
    user.organization,
    searchValue,
  ]);

  useEffect(() => {
    if (notifications) {
      let unreadnotifications = 0;
      if (notifications.results && notifications.results.length > 0) {
        unreadnotifications = notifications.results.filter(
          (item) => !item.is_read
        ).length;
      }
      setNotificationsCount(unreadnotifications);
    }
  }, [notifications]); // eslint-disable-line

  return (
    <>
      <div className="flex justify-between items-center p-2  md:mr-6 relative">
        <div className="flex gap-4">
          <NavButton
            title="Menu"
            customFunc={() =>
              setActiveMenu((prevActiveMenu) => !prevActiveMenu)
            }
            color="#008"
            icon={<FiMenu />}
          />
          {currentPathname === "/hub" ? (
            <div className="lg:flex lg:flex-col hidden">
              <div className="flex md:text-xl font-semibold text-uberark-blue">
                {user?.username ? (
                  <>
                    <div>Hello </div>
                    <div className="max-w-[200px] truncate">
                      , {user.username}
                    </div>
                  </>
                ) : null}
              </div>
              <span className="text-xs md:text-sm">Have a nice day</span>
            </div>
          ) : null}
        </div>
        {Object.keys(searchTerm).includes(currentPathname) && (
          <div className="searchBar hidden md:flex items-center">
            <label className="relative flex w-96">
              <span className="sr-only">Search</span>
              <span className="absolute inset-y-0 left-0 flex items-center ml-6 pl-2">
                <BiSearch className="w-5 h-5 text-uberark-blue" />
              </span>
              <input
                className="placeholder:text-slate-400 flex bg-white w-full rounded-2xl ml-6 py-2 pl-9 pr-3 shadow-[4px_4px_12px_rgba(0,0,0,0.05)]  focus:outline-none focus:border-[#008] focus:ring-[#008] focus:ring-1 sm:text-sm "
                placeholder={`Search in ${searchTerm[currentPathname]}`}
                type="text"
                name="search"
                value={searchValue === null ? "" : searchValue}
                onChange={(e) => {
                  setSearchChange(true);
                  setSearchValue(e.target.value);
                }}
              />
              {(collectionLoading || streamLoading) && searchChange ? (
                <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <LoadingSpinner colorClassName="border-[#000080]" />
                </span>
              ) : null}
            </label>
          </div>
        )}
        <div className="flex items-center gap-2">
          <NavButton
            title="Notifications"
            dotColor={notificationsCount > 0 ? "#FF3F01" : ""}
            customFunc={() => handleClick("notification")}
            color="blue"
            icon={<RiNotificationLine className="text-uberark-blue" />}
            style={{ background: "#fff" }}
          />
          <Notification
            visible={isClicked.notification}
            onClose={() => {
              setIsClicked({ notification: false });
            }}
          />

          <Link
            to="/settings"
            className="flex items-center gap-2 cursor-pointer p-1 rounded-lg"
            onClick={() => handleClick("userProfile")}
          >
            <img
              className="rounded-full w-8 h-8 hover:bg-light-gray"
              src={user?.avatar ? user.avatar : avatarPlaceholder}
              alt="user-profile"
            />
            <div>
              <div className="text-black font-bold text-14 max-w-[150px] truncate">
                {user?.username ? <>{user.username}</> : null}
              </div>
              <div className="text-black  text-14">User</div>
            </div>
            <MdKeyboardArrowDown className="text-black  text-14" />
          </Link>
          {/* </TooltipComponent> */}

          {/* {isClicked.userProfile && <UserProfile />} */}
        </div>
      </div>
    </>
  );
};

export default Navbar;
