import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { AiOutlinePlus } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { RiEditCircleFill } from "react-icons/ri";
import ReactPaginate from "react-paginate";
import AddEditCustomField from "./AddAndEditCustomField";
import DeleteConversation from "../../Conversations/components/DeleteConversation";

import { settingsActions } from "../actions";
import no_content from "../../../assets/images/no_content.svg";

const CustomFields = () => {
  const dispatch = useDispatch();
  const { customFields, customFieldsCreation, customFieldsDeleteConfirmation } =
    useSelector((state) => state.settings);
  const [pageNumber, setPageNumber] = useState(0);
  const [popupcont, setPopupcont] = useState([]);
  const [popTogle, setPopTogle] = useState(false);
  const [noData, setNoData] = useState(true);
  const [initialTimeCall, setInitialTimeCall] = useState(true);
  const [initialValues, setInitialValues] = useState({
    name: "",
    help_text: "",
    list_value: [],
    is_multi_select: "",
    enable_in_search: "",
  });
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [newCF, setNewCF] = useState(false);
  const customFieldsPerPage = 5;
  const pagesVisited = pageNumber * customFieldsPerPage;

  const pageCount =
    customFields && customFields.length > 0
      ? Math.ceil(customFields.length / customFieldsPerPage)
      : 0;

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    // changeContent(customFields[(selected * customFieldsPerPage)])
  };

  const changeContent = (conv) => {
    setPopTogle(false);
    setPopupcont([conv]);
    setInitialValues({ ...conv });
    setTimeout(() => {
      setPopTogle(true);
    }, 1000);
  };

  useEffect(() => {
    if (
      ((!customFields || customFields.length === 0) && noData) ||
      initialTimeCall ||
      customFieldsCreation ||
      customFieldsDeleteConfirmation
    ) {
      dispatch(settingsActions.handleCustomFieldListsFetch(`customfields`));
      setNoData(false);
      setInitialTimeCall(false);
      setShowDeletePopUp(false);
      setPopTogle(false);
    } else if (customFields && customFields.length) {
      setInitialValues({ ...customFields[0] });
      setPopTogle(false);
      setPageNumber(
        Math.ceil(customFields.length / customFieldsPerPage) === 1
          ? 0
          : pageNumber
      );
    }
  }, [
    dispatch,
    customFields,
    noData,
    customFieldsCreation,
    customFieldsDeleteConfirmation,
    initialTimeCall,
    pageNumber,
  ]);

  const onUpdateCF = (payload) => {
    return dispatch(
      settingsActions.handleCustomFieldUpdate(payload, popupcont[0]["id"])
    );
  };

  const onDeleteCF = () => {
    setShowDeletePopUp(true);
  };

  const onDeleteCFData = () => {
    return dispatch(
      settingsActions.handleCustomFieldDelete(popupcont[0]["id"])
    );
  };

  const onCreateCF = () => {
    setInitialValues({
      name: "",
      help_text: "",
      list_value: [],
      is_multi_select: "",
      enable_in_search: "",
    });
    setNewCF(true);
    setPopTogle(true);
  };

  return (
    <div className={`relative`}>
      {!newCF ? (
        <div className="bg-transparent rounded-xl p-2 h-full w-full flex justify-start flex-col lg:h-full md:h-[800px] overflow-y-scroll">
          {customFields && customFields.length ? (
            <div className="table-auto border-collapse w-full text-uberark-blue mb-10 my-4">
              <div
                className="grid justify-center px-2 absolute"
                style={{ top: -35, right: 10 }}
              >
                <button
                  className="grid grid-flow-col items-center gap-1 hover:text-[#000080]"
                  onClick={() => {
                    onCreateCF();
                  }}
                >
                  <AiOutlinePlus />
                  <span className="md:block">Add Custom Field</span>
                </button>
              </div>
              <div
                className="md:block"
                style={{
                  overflow: window.innerWidth < 768 ? "scroll" : "none",
                }}
              >
                <table className="table-auto border-collapse w-full text-uberark-blue">
                  <thead>
                    <tr>
                      <th className="text-left">Name</th>
                      <th className="text-center">Help Text</th>
                      <th className="text-center">List Value</th>
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customFields
                      .slice(pagesVisited, pagesVisited + customFieldsPerPage)
                      .map((conv) => (
                        <tr
                          key={conv.id}
                          onClick={() => changeContent(conv)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>{conv.name}</td>
                          <td>{conv.help_text}</td>
                          <td>
                            {conv.list_value && conv.list_value.length
                              ? conv.list_value.map((item, i) => {
                                  return (
                                    <span key={i}>
                                      {item.name ? item.name : item}{" "}
                                      {conv.list_value.length === i + 1
                                        ? ""
                                        : ", "}
                                    </span>
                                  );
                                })
                              : ""}
                          </td>
                          <td>
                            <div className="flex justify-center">
                              <div
                                className="flex items-center mx-1"
                                onMouseDown={() => changeContent(conv)}
                              >
                                <RiEditCircleFill /> Edit
                              </div>
                              <div
                                className="flex items-center mx-1"
                                onMouseDown={() => {
                                  setPopupcont([conv]);
                                  setShowDeletePopUp(true);
                                }}
                              >
                                <BsTrash /> Delete
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="w-full">
              <div className="flex flex-wrap mt-8">
                <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10">
                  <div className="w-full flex justify-center">
                    <img
                      src={no_content}
                      alt="nothing to show illustration"
                      style={{ height: "60px" }}
                    />
                  </div>
                  <div className="text-center relative p-5 text-[#757575] text-14">
                    Nothing to show.
                  </div>
                  <div
                    className="flex items-center justify-center py-1 px-5 cursor-pointer opacity-80 hover:scale-105 ease-in-out duration-300 border rounded"
                    onClick={() => {
                      setPopTogle(true);
                      setNewCF(true);
                    }}
                  >
                    <AiOutlinePlus className="text-[#FE4502] mr-1" /> Create
                    Custom Field
                  </div>
                </div>
              </div>
            </div>
          )}

          <DeleteConversation
            dltTitle={"Custom Field"}
            onClose={() => setShowDeletePopUp(false)}
            visible={showDeletePopUp}
            contactDetails={popupcont["0"]}
            onDeleteEvent={() => onDeleteCFData()}
          />
        </div>
      ) : null}

      {popTogle && (
        <div
          className="pop_up_container fixed inset-0 bg-gray-50 bg-opacity-30 backdrop-blur-sm text-black flex justify-center items-center"
          style={{ zIndex: "100", overflowY: "scroll" }}
        >
          <AddEditCustomField
            initialValues={initialValues}
            onUpdateCF={onUpdateCF}
            onCloseCF={() => {
              setPopTogle(false);
              setNewCF(false);
            }}
            onDeleteCF={() => onDeleteCF()}
          />
        </div>
      )}
      {pageCount > 1 ? (
        <div className="p-4">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      ) : null}
    </div>
  );
};
export default CustomFields;
