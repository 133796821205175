import * as types from "./actionTypes";
import { request, success, failure } from "../../../app/utilities/helpers";
import { authService } from "./_service";
import { toast } from "react-toastify";

const handleContactListsFetch = (queryUrl) => {
  return (dispatch) => {
    dispatch(request(null, types.FETCH_CONTACT_LISTS_REQUEST));
    authService.handleContactListsFetch(queryUrl).then(
      (res) => {
        dispatch(success(res, types.FETCH_CONTACT_LISTS_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.FETCH_CONTACT_LISTS_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleContactCreation = (data, onContactPage) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_CONTACT_REQUEST));
    authService.handleContactCreation(data).then(
      (res) => {
        toast.success("Contact Created");
        dispatch(success(res, types.CREATE_CONTACT_SUCCESS));
        if (onContactPage) {
          setTimeout(function () {
            onContactPage();
          }, 300);
        }
      },
      (error) => {
        dispatch(failure(error, types.CREATE_CONTACT_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.email) {
          toast.error(error?.response?.data?.email?.[0]);
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else if (
          error?.response?.data?.errors?.email &&
          error?.response?.data?.errors?.email.length
        ) {
          toast.error("Incorrect order of data provided");
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleContactUpdate = (data, id, onFetchContact) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_CONTACT_REQUEST));
    authService.handleContactUpdate(data, id).then(
      (res) => {
        toast.success("Contact updated");
        dispatch(success(res, types.CREATE_CONTACT_SUCCESS));
        if (onFetchContact) {
          setTimeout(function () {
            onFetchContact();
          }, 300);
        }
      },
      (error) => {
        dispatch(failure(error, types.CREATE_CONTACT_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleContactDelete = (id, onFetchContact) => {
  return (dispatch) => {
    dispatch(request(null, types.DELETE_CONTACT_REQUEST));
    authService.handleContactDelete(id).then(
      (res) => {
        toast.success("Contact deleted successfully");
        dispatch(success(res, types.DELETE_CONTACT_SUCCESS));
        if (onFetchContact) {
          setTimeout(function () {
            onFetchContact();
          }, 300);
        }
      },
      (error) => {
        dispatch(failure(error, types.DELETE_CONTACT_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleDistributionListsFetch = (queryUrl) => {
  return (dispatch) => {
    dispatch(request(null, types.FETCH_DISTRIBUTION_LISTS_REQUEST));
    authService.handleDistributionListsFetch(queryUrl).then(
      (res) => {
        dispatch(success(res, types.FETCH_DISTRIBUTION_LISTS_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.FETCH_DISTRIBUTION_LISTS_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleDistributionCreation = (data, onContactPage) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_DISTRIBUTION_REQUEST));
    authService.handleDistributionCreation(data).then(
      (res) => {
        toast.success("Distribution Created");
        dispatch(success(res, types.CREATE_DISTRIBUTION_SUCCESS));
        if (onContactPage) {
          setTimeout(function () {
            onContactPage();
          }, 300);
        }
      },
      (error) => {
        dispatch(failure(error, types.CREATE_DISTRIBUTION_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleDistributionUpdate = (data, id, onFetchContact) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_DISTRIBUTION_REQUEST));
    authService.handleDistributionUpdate(data, id).then(
      (res) => {
        toast.success("Distribution updated");
        dispatch(success(res, types.CREATE_DISTRIBUTION_SUCCESS));
        if (onFetchContact) {
          setTimeout(function () {
            onFetchContact();
          }, 300);
        }
      },
      (error) => {
        dispatch(failure(error, types.CREATE_DISTRIBUTION_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleDistributionDelete = (id, onFetchContact) => {
  return (dispatch) => {
    dispatch(request(null, types.DELETE_DISTRIBUTION_REQUEST));
    authService.handleDistributionDelete(id).then(
      (res) => {
        toast.success("Distribution delete");
        dispatch(success(res, types.DELETE_DISTRIBUTION_SUCCESS));
        if (onFetchContact) {
          setTimeout(function () {
            onFetchContact();
          }, 300);
        }
      },
      (error) => {
        dispatch(failure(error, types.DELETE_DISTRIBUTION_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleAddContactDistributionCreation = (data, onSubmitCallback) => {
  return (dispatch) => {
    dispatch(request(null, types.ADD_CONTACT_DISTRIBUTION_REQUEST));
    authService.handleAddContactDistributionCreation(data).then(
      (res) => {
        toast.success("Contact Added into the Distribution");
        dispatch(success(res, types.ADD_CONTACT_DISTRIBUTION_SUCCESS));
        if (onSubmitCallback) {
          setTimeout(function () {
            onSubmitCallback();
          }, 300);
        }
      },
      (error) => {
        dispatch(failure(error, types.ADD_CONTACT_DISTRIBUTION_FAILED));
        if (onSubmitCallback) {
          setTimeout(function () {
            onSubmitCallback();
          }, 300);
        }
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleContactDistributionDelete = (id, onFetchContact) => {
  return (dispatch) => {
    dispatch(request(null, types.DELETE_CONTACT_DISTRIBUTION_REQUEST));
    authService.handleContactDistributionDelete(id).then(
      (res) => {
        toast.success("Contact delete into the Distribution");
        dispatch(success(res, types.DELETE_CONTACT_DISTRIBUTION_SUCCESS));
        if (onFetchContact) {
          setTimeout(function () {
            onFetchContact();
          }, 300);
        }
      },
      (error) => {
        dispatch(failure(error, types.DELETE_CONTACT_DISTRIBUTION_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

export const contactActions = {
  handleContactListsFetch,
  handleContactCreation,
  handleContactUpdate,
  handleContactDelete,
  handleDistributionListsFetch,
  handleDistributionCreation,
  handleDistributionUpdate,
  handleDistributionDelete,
  handleAddContactDistributionCreation,
  handleContactDistributionDelete,
};
