import React, { useEffect } from "react";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const Iframe = ({ asset, size, className, handleSaveEvent }) => {
  useEffect(() => {
    if (size === "asset__large" && asset.name.split(".").at(-1) === "pdf") {
      setTimeout(function () {
        // If absolute URL from the remote server is provided, configure the CORS
        // header on that server.
        var url = asset.file;

        // Loaded via <script> tag, create shortcut to access PDF.js exports.
        var pdfjsLib = window["pdfjs-dist/build/pdf"];

        // The workerSrc property shall be specified.
        pdfjsLib.GlobalWorkerOptions.workerSrc =
          "//mozilla.github.io/pdf.js/build/pdf.worker.js";

        let pdfDoc = null;
        let pageNum = 1;
        let pageRendering = false;
        let pageNumPending = null;
        const scale = 0.9;
        const canvas = document.getElementById("the-canvas");
        const ctx = canvas?.getContext("2d", { alpha: false }) || null;

        /**
         * Get page info from document, resize canvas accordingly, and render page.
         * @param num Page number.
         */
        function renderPage(num) {
          pageRendering = true;
          // Using promise to fetch the page
          pdfDoc.getPage(num).then(function (page) {
            var viewport = page.getViewport({ scale: scale });
            // canvas.height = viewport.height;
            // canvas.width = viewport.width;

            // Get the DPR and size of the canvas
            const dpr = window.devicePixelRatio;
            // const rect = canvas.getBoundingClientRect();
            // Set the "actual" size of the canvas
            // canvas.width = rect.width * dpr;
            // canvas.height = rect.height * dpr;

            // Set the "actual" size of the canvas
            canvas.width = viewport.width * dpr;
            canvas.height = viewport.height * dpr;

            // Scale the context to ensure correct drawing operations
            ctx.scale(dpr, dpr);

            // Set the "drawn" size of the canvas
            canvas.style.width = `${viewport.width}px`;
            canvas.style.height = `${viewport.height}px`;

            // Render PDF page into canvas context
            var renderContext = {
              canvasContext: ctx,
              viewport: viewport,
            };
            var renderTask = page.render(renderContext);

            // Wait for rendering to finish
            renderTask.promise.then(function () {
              pageRendering = false;
              if (pageNumPending !== null) {
                // New page rendering is pending
                renderPage(pageNumPending);
                pageNumPending = null;
              }
            });
          });

          // Update page counters
          if (document.getElementById("page_num")) {
            document.getElementById("page_num").textContent = num;
          }
        }

        /**
         * If another page rendering in progress, waits until the rendering is
         * finised. Otherwise, executes rendering immediately.
         */
        function queueRenderPage(num) {
          if (pageRendering) {
            pageNumPending = num;
          } else {
            renderPage(num);
          }
        }

        /**
         * Displays previous page.
         */
        function onPrevPage() {
          if (pageNum <= 1) {
            return;
          }
          pageNum--;
          queueRenderPage(pageNum);
        }
        if (document.getElementById("prev")) {
          document.getElementById("prev").addEventListener("click", onPrevPage);
        }

        /**
         * Displays next page.
         */
        function onNextPage() {
          if (pageNum >= pdfDoc.numPages) {
            return;
          }
          pageNum++;
          queueRenderPage(pageNum);
        }
        if (document.getElementById("next")) {
          document.getElementById("next").addEventListener("click", onNextPage);
        }

        /**
         * Asynchronously downloads PDF.
         */
        pdfjsLib.getDocument(url).promise.then(function (pdfDoc_) {
          pdfDoc = pdfDoc_;
          if (document.getElementById("page_count")) {
            document.getElementById("page_count").textContent = pdfDoc.numPages;
          }

          // Initial/first page rendering
          renderPage(pageNum);
        });
      }, 1000);
    }
  }, [asset, size]);

  return (
    <>
      {asset.name.split(".").at(-1) === "pdf" && size === "asset__large" ? (
        <div className="flex flex-col flex-wrap w-full bg-white px-5 rounded">
          <div className="flex justify-between mb-2 text-white bg-black fixed bottom-2 rounded py-2">
            <button
              id="prev"
              onClick={() =>
                handleSaveEvent &&
                handleSaveEvent({ eventName: "document_page_change" })
              }
              className="flex items-center text-xs px-1 mx-2"
            >
              <BsChevronLeft />
            </button>
            <div className="text-xs mx-2">
              Page: <span id="page_num"></span> / <span id="page_count"></span>
            </div>
            <button
              id="next"
              onClick={() =>
                handleSaveEvent &&
                handleSaveEvent({ eventName: "document_page_change" })
              }
              className="flex items-center text-xs px-1 mx-2"
            >
              <BsChevronRight />
            </button>
          </div>
          <canvas
            id="the-canvas"
            style={{ marginLeft: "auto", marginRight: "auto" }}
          ></canvas>
        </div>
      ) : (
        <object
          className={`${size || ""} ${className || ""} ${
            size === "asset__large"
              ? ""
              : "hover:scale-105 ease-in-out duration-300"
          }`}
          data={`https://docs.google.com/viewer?url=${encodeURIComponent(
            asset.file + "#page=2"
          )}&embedded=true`}
          frameBorder="0"
          loading="lazy"
          key={Math.random()}
        >
          <p className="flex justify-center mt-5">File not supported</p>
        </object>
      )}
    </>
  );
};

export default Iframe;
