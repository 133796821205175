import React, { useEffect, useState } from "react";

import { LazyLoadComponent } from "react-lazy-load-image-component";
import Image from "./Image";
import Video from "./Video";
import Iframe from "./Iframe";
import Url from "./Url";
import Other from "./Other";

import {
  imageExtensions,
  videoExtensions,
  iframeExtensions,
} from "../../../../../app/utilities/helpers";
import "../../Asset.css";

/**
 * @param {object} asset { name, file, thumbnail, url, is_youtube_url }
 * @param {boolean} withName
 * @param {string} size asset__x-small(40px 40px) asset__small(80px 80px) asset__medium(150px 150px) asset__large(100% 80vh)
 * @param {string} className
 * @param {Function} handleAssetClick
 * @param {string} namePosition 'bottom'/'center'
 * @param {string} nameClassname 'text-white'/'text-black'
 * @returns {ReactElement}
 * @description display asset based on their file types.
 */
const AssetCard = ({
  asset,
  withName,
  size,
  className,
  handleAssetClick,
  namePosition,
  nameClassname,
  handleSaveEvent,
}) => {
  const [assetType, setAssetType] = useState(null);
  const AssetComponents = {
    image: <Image asset={asset} size={size} className={className} />,
    video: (
      <Video
        asset={asset}
        size={size}
        className={className}
        handleSaveEvent={handleSaveEvent}
      />
    ),
    iframe: (
      <Iframe
        asset={asset}
        size={size}
        className={className}
        handleSaveEvent={handleSaveEvent}
      />
    ),
    url: <Url asset={asset} size={size} className={className} />,
    other: <Other asset={asset} size={size} className={className} />,
  };

  useEffect(() => {
    if (asset) {
      const { name, url } = asset;
      const extension = name ? name.split(".").pop() : null;
      if (
        (extension && imageExtensions.includes(extension)) ||
        (asset.thumbnail && size !== "asset__large")
      ) {
        setAssetType("image");
      } else if (extension && videoExtensions.includes(extension)) {
        setAssetType("video");
      } else if (extension && iframeExtensions.includes(extension)) {
        setAssetType("iframe");
      } else if (url) {
        setAssetType("url");
      } else {
        setAssetType("other");
      }
    }
  }, [asset, size]);

  return (
    <div
      className="flex flex-col cursor-pointer"
      onMouseDown={() => handleAssetClick && handleAssetClick(asset)}
    >
      <LazyLoadComponent>{AssetComponents[assetType]}</LazyLoadComponent>
      {withName && (
        <div
          className={`${size ? size + "-name" : ""} asset__name truncate ${
            nameClassname ? nameClassname : ""
          }`}
        >
          {asset.name || asset.url}
        </div>
      )}
    </div>
  );
};

export default AssetCard;
