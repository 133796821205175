/* eslint-disable */
import axios from "axios";
import jwt_decode from "jwt-decode";
import * as localStorageHelpers from "../helpers";
// import { isEmpty } from 'lodash'
import store from "../../store";

let isAlreadyFetchingAccessToken = false;
let isAlreadyUnauthorized = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];

export const requestDefaults = {
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    "Content-Type": "application/json",
  },
};

export const authHeader = () => {
  // return authorization header with jwt token
  const user = localStorageHelpers.getUser();
  if (user && user.token) {
    return {
      Authorization: `Bearer ${user.token}`,
    };
  }

  return {};
};

// axios instance no authorization
export const axiosInstanceNoAuth = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    Accept: "application/json",
  },
});

// axios instance requiring authorization
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    ...authHeader(),
  },
});

export const fileRequestConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const handleResponse = (res) => {
  if (res.status === 200 || res.status === 201) {
    // const { data } = res.data
    if (res?.data) {
      return res?.data;
    }
    return [];
  }
  const { response } = res;
  const { data } = response;
  const { message } = data;
  return Promise.reject(message);
};

export const handleResponseError = (error) => {
  const { response } = error;

  if (!response) {
    return error.toString();
  }

  const { data } = response;

  if (!data) {
    return error.toString();
  }

  const { message } = data;

  if (!message) {
    return error.toString();
  }

  return message;
};

/**
 * Handle user details from access token
 */
export const handleUserResponse = (token, refreshToken) => {
  // Decode token to get user data and save in storage
  const tokens = jwt_decode(token);

  tokens.token = token;
  tokens.refresh_token = refreshToken;

  localStorageHelpers.setTokens(tokens);

  return tokens;
};

/**
 * Fetch new access Token from remote using refresh_token
 */
export const refreshAccessToken = (refresh_token) => {
  const args = {
    method: "post",
    baseURL: process.env.REACT_APP_API_BASE,
    url: "token/refresh",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: `Bearer ${refresh_token}`,
    },
    data: {
      refresh: refresh_token,
    },
  };

  return axios(args)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

/**
 * Check if token is expired
 */
export const isTokenExpiredError = (error) => {
  const { response } = error;
  if (response?.data?.code && response?.data?.code === "token_not_valid") {
    return true;
  }

  if (
    response?.data?.messages &&
    response?.data?.messages.length > 0 &&
    response?.data?.messages[0].message === "Token is invalid or expired"
  ) {
    return true;
  }

  return false;
};

/**
 * Check if Missing Authorization Header
 */
export const isMissingAuthHeaderError = (error) => {
  const { response } = error;
  if (!response) {
    return false;
  }

  let status = false;
  if (response?.status === 403) {
    status = true;
  }

  if (
    response?.status === 401 &&
    response?.data?.detail &&
    response?.data?.detail === "Authentication credentials were not provided."
  ) {
    status = true;
  }

  if (status) {
    isAlreadyUnauthorized = true;
  }

  return status;
};

/**
 * Check if user is not found
 */
export const isUser404 = (error) => {
  const { response } = error;
  if (!response) {
    return false;
  }

  if (
    response?.status === 401 &&
    response?.data?.code &&
    response?.data?.code === "user_not_found"
  ) {
    status = true;
    window.location.href = "/login/1";
  }
};

export const addSubscriber = (callback) => {
  subscribers.push(callback);
};

/**
 * When the refresh is successful,
 * we start retrying the requests one by one and empty the queue
 */
export const onAccessTokenFetched = (user) => {
  const { token } = user;
  subscribers.forEach((callback) => callback(token));
  subscribers = [];
};

/**
 * Fetch new token and retry failed requests
 * @param {*} error
 */
export const resetTokenAndReattemptRequest = (error) => {
  try {
    const originalRequest = error.config;

    /* Proceed to the token refresh procedure
      We create a new Promise that will retry the request,
      clone all the request configuration from the failed
      request in the error object. */
    const retryOriginalRequest = new Promise((resolve) => {
      /* We need to add the request retry to the queue
        since there another request that already attempt to
        refresh the token */
      addSubscriber((token) => {
        axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
        originalRequest.headers.Authorization = `Bearer ${token}`;
        resolve(axios(originalRequest));
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      const tokens = localStorageHelpers.getTokens();
      const { refresh_token } = tokens;

      if (refresh_token) {
        refreshAccessToken(refresh_token)
          .then((response) => {
            if (!response || response?.status !== 200) {
              // log user out
              localStorageHelpers.forgetUser();
              window.location.href = "/login/1";
            }

            if (response?.status === 200) {
              const { access: token } = response.data;

              // Handle user data
              const user = handleUserResponse(token, refresh_token);

              return user;
            }

            const tokenError = response?.data?.message || response.statusText;
            return Promise.reject(tokenError);
          })
          .then((user) => {
            return user;
          })
          .then((user) => {
            isAlreadyFetchingAccessToken = false;
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            onAccessTokenFetched(user);
            return user;
          });
      } else {
        setTimeout(function () {
          const userTokens = localStorageHelpers.getTokens();
          const { refresh_token } = userTokens;
          if (refresh_token) {
            refreshAccessToken(refresh_token)
              .then((response) => {
                if (!response || response?.status !== 200) {
                  // log user out
                  localStorageHelpers.forgetUser();
                  window.location.href = "/login/1";
                }

                if (response?.status === 200) {
                  const { access: token } = response.data;

                  // Handle user data
                  const user = handleUserResponse(token, refresh_token);

                  return user;
                }

                const tokenError =
                  response?.data?.message || response.statusText;
                return Promise.reject(tokenError);
              })
              .then((user) => {
                return user;
              })
              .then((user) => {
                isAlreadyFetchingAccessToken = false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                onAccessTokenFetched(user);
                return user;
              });
          }
        }, 1500);
      }
    }

    return retryOriginalRequest;
  } catch (err) {
    window.location.href = "/login/1";
    return Promise.reject(err);
  }
};

/**
 * Fetch new token and retry failed requests
 * @param {*} error
 */
export const reattemptRequest = (error) => {
  try {
    const originalRequest = error.config;

    /* We create a new Promise that will retry the request,
      clone all the request configuration from the failed
      request in the error object. */
    const retryOriginalRequest = new Promise((resolve) => {
      const user = localStorageHelpers.getTokens();
      let token = "";
      if (user && user.token) {
        token = user.token;
      }

      if (store.getState().auth?.user?.token) {
        // check in store
        token = store.getState().auth?.user?.token;
      }

      axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
      originalRequest.headers.Authorization = `Bearer ${token}`;
      resolve(axios(originalRequest));
    });

    isAlreadyUnauthorized = false;

    return retryOriginalRequest;
  } catch (err) {
    window.location.href = "/login";
    return Promise.reject(err);
  }
};

/**
 * Intercept requests and check for token expiry in case of error in request
 * initiate token reset incase token expired
 * @param {*} error
 */
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (isTokenExpiredError(error)) {
      return resetTokenAndReattemptRequest(error);
    } else if (isMissingAuthHeaderError(error)) {
      if (store.getState().auth?.user?.token) {
        return reattemptRequest(error);
      } else {
        isAlreadyUnauthorized = false;
        window.location.href = "/login/1";
      }
    } else if (isUser404(error)) {
      window.location.href = "/login/1";
    }
    return Promise.reject(error);
  }
);

//creating function to load ip address from the API
export const getUserIdentity = async () => {
  const res = await axios.get("https://geolocation-db.com/json/");
  const browserName = getUserBrowser();
  const osName = getOSName();
  return { ...res.data, ip: res.data.ip, browser: browserName, os: osName };
};

export const getUserBrowser = () => {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Edge") !== -1) {
    return "Edge";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode === true
  ) {
    return "IE";
  } else {
    return "unknown";
  }
};

export const getOSName = () => {
  let OSName = "Unknown";
  if (window.navigator.userAgent.indexOf("Windows NT 10.0") !== -1)
    OSName = "Windows 10";
  if (window.navigator.userAgent.indexOf("Windows NT 6.3") !== -1)
    OSName = "Windows 8.1";
  if (window.navigator.userAgent.indexOf("Windows NT 6.2") !== -1)
    OSName = "Windows 8";
  if (window.navigator.userAgent.indexOf("Windows NT 6.1") !== -1)
    OSName = "Windows 7";
  if (window.navigator.userAgent.indexOf("Windows NT 6.0") !== -1)
    OSName = "Windows Vista";
  if (window.navigator.userAgent.indexOf("Windows NT 5.1") !== -1)
    OSName = "Windows XP";
  if (window.navigator.userAgent.indexOf("Windows NT 5.0") !== -1)
    OSName = "Windows 2000";
  if (window.navigator.userAgent.indexOf("Mac") !== -1) OSName = "Mac/iOS";
  if (window.navigator.userAgent.indexOf("X11") !== -1) OSName = "UNIX";
  if (window.navigator.userAgent.indexOf("Linux") !== -1) OSName = "Linux";

  return OSName;
};
