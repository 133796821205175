// add reducers to store
import { combineReducers } from "redux";
import { forgetUser } from "../utilities/helpers/";
import { LOGOUT_SUCCESS } from "../../pages/Auth/actions/actionTypes";
import authReducer from "../../pages/Auth/reducer/";
import collectionReducer from "../../pages/Collections/reducer";
import settingsReducer from "../../pages/Settings/reducer";
import composeReducer from "../../pages/Compose/reducer";
import contactReducer from "../../pages/Contacts/reducer";
import streamsReducer from "../../pages/Streams/reducer";
import conversationsReducer from "../../pages/Conversations/reducer";
import assetReducer from "../../pages/commons/Asset/reducer";

const allReducers = combineReducers({
  auth: authReducer,
  collection: collectionReducer,
  settings: settingsReducer,
  compose: composeReducer,
  contacts: contactReducer,
  conversations: conversationsReducer,
  asset: assetReducer,
  stream: streamsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    forgetUser();
    localStorage.removeItem("state");

    return allReducers(undefined, action);
  }
  return allReducers(state, action);
};

export default rootReducer;
