import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useStateContext } from "../../app/context/ContextProvider";
import back from "../../assets/images/back.svg";
import background from "../../assets/images/background.svg";
import { redirect } from "../utilities/helpers/";

// Components pages routes
import {
  Navbar,
  Sidebar,
  Line,
  PiCharts,
  Dcalender,
  LoadingLinear,
} from "../../components";

// Pages routes
import {
  // Auth Pages
  ForgotPassword,
  LoginPage,
  ConfirmAccount,
  ResetPassword,
  // ErrorPg
  ErrorPg,
  // Dashboard
  Dashboard,
  // ContentHub
  ContentHub,
  // Collections
  Collections,
  Collection,
  CollectionEdit,
  CollectionAdd,
  Links,
  // Stream
  Stream,
  StreamAdd,
  StreamEdit,
  // Compose
  Compose,
  // Conversations
  Conversations,
  // Contacts
  Contacts,
  // common pages
  Asset,
  Collaborate,
  // settings
  Settings,
  Profile,
  Account,
  Calender,
  EmailTemplates,
  Integrations,
  Users,
  NDA,
  PrivacyPolicy,
  PrivacyControl,
  Promote,
  Terms,
  Notifications,
} from "../../pages";

const Router = () => {
  const { activeMenu } = useStateContext();
  const { user } = useSelector((state) => state.auth);
  const { loading: collectionLoading } = useSelector(
    (state) => state.collection
  );
  const { loading: streamLoading, loadingAssets } = useSelector(
    (state) => state.stream
  );
  const { loading: conversationLoading } = useSelector(
    (state) => state.conversations
  );
  const { loading: settingsLoading } = useSelector((state) => state.settings);

  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      setAuthenticated(true);
    } else {
      const publicRoutes = [
        "/login",
        "/forgotpassword",
        "/api/activate/",
        "/reset/",
      ];
      const url = window.location.href;
      const publicRoute = publicRoutes.filter((item) => url.includes(item));
      if (publicRoute.length === 0) {
        redirect("/login");
      }
    }
  }, [user]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      setAuthenticated(true);
    } else {
      const publicRoutes = [
        "/login",
        "/forgotpassword",
        "/api/activate/",
        "/reset/",
      ];
      const url = window.location.href;
      const publicRoute = publicRoutes.filter((item) => url.includes(item));
      setAuthenticated(Object.keys(user).length > 0 ? true : false);
      if (publicRoute.length === 0) {
        redirect("/login");
      }
    }
  }, [user]);

  return (
    <BrowserRouter>
      {(collectionLoading ||
        streamLoading ||
        conversationLoading ||
        loadingAssets ||
        settingsLoading) && (
        <LoadingLinear LoadingProgressColor={"#FE4502"} absolute={true} />
      )}
      <div className="flex relative">
        {authenticated && (
          <>
            {activeMenu ? (
              <div
                className="w-64 fixed sidebar bg-[#f5f8ff] duration-300"
                style={{ backgroundImage: `url(${back})` }}
              >
                {<Sidebar />}
              </div>
            ) : (
              <div className="w-0">{<Sidebar />}</div>
            )}
          </>
        )}

        <div
          className={`bg-[#e5edff]  bg-cover
         min-h-screen w-full ${activeMenu ? "md:ml-64" : "flex-2"}`}
          style={{ backgroundImage: `url(${background})` }}
        >
          {authenticated && (
            <div className="fixed md:static md:bg-transparent bg-[#ecf2ff] navbar w-full">
              {<Navbar />}
            </div>
          )}
          <div>
            <Routes>
              {/* Default paths */}
              <Route path="*" element={<ErrorPg />} />
              <Route
                path="/"
                element={
                  authenticated ? (
                    <Navigate replace to="/home" />
                  ) : (
                    <Navigate replace to="/login" />
                  )
                }
              />
              {/* auth pages */}
              <Route path="/login" element={<LoginPage />} />
              <Route path="/login/:logout" element={<LoginPage />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route
                path="/api/activate/:pre_token/:token"
                element={<ConfirmAccount />}
              />
              <Route
                path="/api/reset/:pre_token/:token"
                element={<ResetPassword />}
              />
              {/* dashboard */}
              <Route path="/home" element={<Dashboard />} />
              <Route path="dashboard" element={<Dashboard />} />
              {/* Settings */}
              <Route path="settings" element={<Settings />}>
                <Route index element={<Navigate to="profile" replace />} />
                <Route path="profile" element={<Profile />} />
                <Route path="users" element={<Users />} />
                <Route path="account" element={<Account />} />
                <Route path="promote" element={<Promote />} />
                <Route path="integrations" element={<Integrations />} />
                <Route path="email-templates" element={<EmailTemplates />} />
                <Route path="nda-templates" element={<NDA />} />
                <Route path="privacy-control" element={<PrivacyControl />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="terms" element={<Terms />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="calender" element={<Calender />} />
              </Route>
              {/* Content Hub Components */}
              <Route path="hub" element={<ContentHub />}></Route>
              {/* Stream Components */}
              <Route path="stream/add" element={<StreamAdd />} />
              <Route path="stream/edit/:id" element={<StreamEdit />} />
              <Route path="stream/:id" element={<Stream />} />
              {/* collections */}
              <Route path="collections" element={<Collections />} />
              <Route path="collection/:id" element={<Collection />} />
              <Route path="collection/links/:id" element={<Links />} />
              <Route path="collection/edit/:id" element={<CollectionEdit />} />
              <Route path="collection/add" element={<CollectionAdd />} />
              <Route
                path="collection/add/:folder"
                element={<CollectionAdd />}
              />
              {/* Contacts -------------- */}
              <Route path="contacts" element={<Contacts />} />
              <Route path="contacts/add" element={<Contacts />} />
              <Route path="contacts/edit/:id" element={<Contacts />} />
              <Route path="distributions" element={<Contacts />} />
              <Route path="distributions/add" element={<Contacts />} />
              <Route path="distributions/edit/:id" element={<Contacts />} />
              {/* compose Components */}
              <Route path="compose" element={<Compose />} />
              {/* conversations */}
              <Route path="conversations" element={<Conversations />} />
              <Route path="conversations/:id" element={<Conversations />} />
              {/* common pages */}
              <Route path="asset/:id" element={<Asset />} />
              <Route path="collaborators" element={<Collaborate />} />
              {/* Dashboard Components */}
              <Route path="calender" element={<Calender />} />
              <Route path="dcalender" element={<Dcalender />} />
              <Route path="line" element={<Line />} />
              <Route path="picharts" element={<PiCharts />} />
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default Router;
