import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { BsFillCloudUploadFill } from "react-icons/bs";
import LoadingSpinner from "../../../../components/commons/LoadingSpinner";
import { toast } from "react-toastify";

import DownloadSampleCSV from "../../../../assets/data.csv";
import DownloadSampleXLXS from "../../../../assets/data.xlsx";
import { contactActions } from "../../actions";

const FormBulkUpload = ({ toggleBulkUpload }) => {
  const dispatch = useDispatch();
  const supportedFileExt = [
    "text/csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "xlxs",
    "xls",
    "text/xlxs",
    "text/xls",
  ];
  const { loading, contactsCreation } = useSelector((state) => state.contacts);
  const [filename, setFilename] = useState(null);

  const initialValues = {
    file: "",
  };

  const FormSchema = Yup.object().shape({
    file: Yup.mixed("Invalid upload").required("Please select File"),
  });

  const handleSubmit = (data) => {
    const payload = new FormData();
    payload.append("file", data.file);
    return dispatch(contactActions.handleContactCreation(payload, null));
  };

  const onCloseContact = () => {
    toggleBulkUpload();
  };

  useEffect(() => {
    if (contactsCreation) {
      toggleBulkUpload();
    }
  }, [contactsCreation, toggleBulkUpload]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={FormSchema}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form>
          <div className="mb-4">
            <div className="mt-3">
              <label className="flex flex-col items-center justify-center bg-[whitesmoke] rounded py-5">
                <div className="flex flex-col items-center py-5">
                  <span className="p-2 flex items-center text-slate-700">
                    <BsFillCloudUploadFill className="mr-1" />
                    {values.file ? "Change File" : "Upload File"}
                  </span>
                  <span className="p-2 text-sm text-slate-600">
                    {filename ? (
                      <div className="text-uberark-blue py-[15px]">
                        {filename}
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center">
                        <div className="">Click to upload contacts</div>
                        <div className="text-xs text-slate-900">
                          Only .csv, xls and xlxs files allowed
                        </div>
                      </div>
                    )}
                  </span>
                </div>

                <input
                  className="floating-input h-14 pl-2 text-lg text-uberark-blue hidden"
                  name="file"
                  id="file"
                  type="file"
                  onChange={(event) => {
                    const fileType =
                      event.currentTarget &&
                      event.currentTarget.files &&
                      event.currentTarget.files[0]
                        ? event.currentTarget.files[0].name.split(".")[1]
                        : "";
                    const xlsxTxt = ".xlsx";
                    if (
                      (event?.currentTarget?.files &&
                        event?.currentTarget?.files.length &&
                        supportedFileExt.includes(
                          event.currentTarget.files[0]["type"]
                        )) ||
                      (event?.currentTarget?.files &&
                        event?.currentTarget?.files.length &&
                        xlsxTxt.includes(String(fileType).toLowerCase()))
                    ) {
                      setFieldValue("file", event.currentTarget.files[0]);
                      setFilename(event.currentTarget.files[0]["name"]);
                    } else if (
                      event?.currentTarget?.files &&
                      event?.currentTarget?.files.length
                    ) {
                      const selectedFile = event.currentTarget.files[0];
                      const extension = selectedFile.name.split(".")[1];
                      toast.error(
                        `.${extension} file format is not supported!`
                      );
                    }
                  }}
                />
              </label>
              {errors.file && touched.file && (
                <div className="text-red-600 text-xs py-2">{errors.file}</div>
              )}

              <div className="text-xs text-slate-700 py-2">
                Know the file structure? Get Sample
                <a
                  href={DownloadSampleCSV}
                  className="text-uberark-blue ml-1"
                  download={"Sample Contacts CSV File.csv"}
                >
                  CSV /
                </a>
                <a
                  href={DownloadSampleXLXS}
                  className="text-uberark-blue ml-1"
                  download={"Sample Contacts XLXS File.xlsx"}
                >
                  XLSX
                </a>
              </div>
            </div>

            <div className="text-uberark-blue gap-4 text-lg font-bold">
              <button
                className="bg-[#000080] text-white hover:drop-shadow-lg py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-3xl mr-2"
                type="submit"
                disabled={loading}
              >
                {loading ? <LoadingSpinner /> : "Save"}
              </button>
              <button
                className="bg-white text-uberark-blue hover:bg-white hover:text-[#000080] hover:drop-shadow-lg py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-3xl sm:bg-transparent sm:text-uberark-blue"
                style={{ border: "0.24px solid #000080" }}
                onClick={() => onCloseContact()}
                disabled={loading}
              >
                Cancel
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormBulkUpload;
