export const onDateDDMMMYYYY = (d) => {
  let dt = String(new Date(d)).split(" ");
  return `${dt[2]} ${dt[1]} ${dt[3]}`;
};

export const dateCalculation = (passedDate, currentDate) => {
  if (passedDate === currentDate) return "now";

  if (passedDate > currentDate) {
    var dtmp = currentDate;
    currentDate = passedDate;
    passedDate = dtmp;
  }

  var year1 = passedDate.getFullYear();
  var year2 = currentDate.getFullYear();

  var month1 = passedDate.getMonth() + 1;
  var month2 = currentDate.getMonth() + 1;

  var day1 = passedDate.getDate();
  var day2 = currentDate.getDate();

  var hour1 = passedDate.getHours();
  var hour2 = currentDate.getHours();

  var minute1 = passedDate.getMinutes();
  var minute2 = currentDate.getMinutes();
  var second1 = passedDate.getSeconds();
  var second2 = currentDate.getSeconds();
  let dateDiffValue = "";
  if (year1 !== year2) {
    let ymc = 12 - month1 + month2;
    if (ymc + month2 <= 12) {
      dateDiffValue = `${ymc + month2} months`;
    } else {
      let y = year2 - year1;
      dateDiffValue = `${y > 1 ? y + " years" : y + " year"}`;
    }
  }
  if (year1 === year2) {
    if (month1 === month2) {
      if (day1 === day2) {
        if (hour1 === hour2) {
          if (minute1 === minute2) {
            let s = second2 - second1;
            dateDiffValue =
              second1 === second2
                ? "now"
                : `${s > 1 ? s + " seconds" : s + " second"}`;
          } else {
            let m = minute2 - minute1;
            dateDiffValue = `${m > 1 ? m + " minutes" : m + " minute"}`;
          }
        } else {
          let mc = 60 - minute1 + minute2;
          let h = hour2 - hour1;
          if (mc < 60 && h === 1) {
            dateDiffValue = `${mc > 1 ? mc + " minutes" : mc + " minute"}`;
          } else {
            dateDiffValue = `${h > 1 ? h + " hours" : h + " hour"}`;
          }
        }
      } else {
        let d = day2 - day1;
        dateDiffValue = `${d > 1 ? d + " days" : d + " day"}`;
      }
    } else {
      let m = month2 - month1;
      dateDiffValue = `${m > 1 ? m + " months" : m + " month"}`;
    }
  }

  return `${dateDiffValue === "now" ? dateDiffValue : dateDiffValue + " ago"}`;
};

export const onDateDDMMMYYYYHHMM = (d) => {
  let dt = String(new Date(d)).split(" ");
  let h = new Date(d).getHours();
  let m = new Date(d).getMinutes();
  let mode =
    h > 12
      ? `${h - 12 === 0 ? 12 : h - 12 >= 10 ? h - 12 : `0${h - 12}`}:${
          m >= 10 ? m : `0${m}`
        } PM`
      : `${h >= 10 ? h : `0${h}`}:${m >= 10 ? m : `0${m}`} AM`;
  return `${dt[2]} ${dt[1]} ${dt[3]} ${mode}`;
};

export const handleGetReadableTimeRange = (timeSpent) => {
  if (!timeSpent) {
    return null;
  }
  let readableTime;
  if (timeSpent < 60) {
    readableTime = `${timeSpent} secs`;
  } else if (timeSpent >= 60 && timeSpent < 3600) {
    const minutes = Math.floor(timeSpent / 60);
    const seconds = Math.floor(timeSpent % 60);
    readableTime = `${minutes}min${minutes === 1 ? "" : "s"} ${seconds}sec${
      seconds === 0 || seconds === 1 ? "" : "s"
    }`;
  } else if (timeSpent >= 3600) {
    const hours = Math.floor(timeSpent / 3600);
    const minutes = Math.floor((timeSpent % 3600) / 60);
    readableTime = `${hours}hr${hours === 1 ? "" : "s"} ${minutes}min${
      minutes === 1 ? "" : "s"
    }`;
  }

  return readableTime;
};

export const handleGetReadableDuration = (timeSpent) => {
  let readableTime;
  if (timeSpent < 60) {
    readableTime = timeSpent + " seconds";
  } else if (timeSpent >= 3600 && timeSpent < 216000) {
    const minutes = Math.floor(timeSpent / 60);
    const seconds = Math.floor(timeSpent % 60);
    readableTime = `${minutes}min${minutes === 1 ? "" : "s"} ${seconds}sec${
      seconds === 0 || seconds === 1 ? "" : "s"
    }`;
  } else if (timeSpent >= 216000) {
    const hours = Math.floor(timeSpent / 3600);
    const minutes = Math.floor((timeSpent % 3600) / 60);
    readableTime = `${hours}hr${hours === 1 ? "" : "s"} ${minutes}min${
      minutes === 1 ? "" : "s"
    }`;
  }

  return readableTime;
};

export const notificationsCategories = (notifications) => {
  if (notifications && notifications.length) {
    const groupedNotifications = notifications.reduce(
      (groups, notification) => {
        // Calculate the difference in days between today and the notification date
        const diffInDays = Math.floor(
          (new Date() - new Date(notification.created_at)) /
            (1000 * 60 * 60 * 24)
        );
        // Determine the group title based on the difference in days
        let groupTitle;
        if (diffInDays === 0) {
          groupTitle = "Today";
        } else if (diffInDays === 1) {
          groupTitle = "Yesterday";
        } else if (diffInDays <= 7) {
          groupTitle = "This week";
        } else if (diffInDays <= 14) {
          groupTitle = "Last week";
        } else {
          groupTitle = "Older";
        }

        // Add the notification to the appropriate group
        if (!groups[groupTitle]) {
          groups[groupTitle] = [];
        }
        groups[groupTitle].push(notification);

        return groups;
      },
      []
    );

    return groupedNotifications;
  } else {
    return [];
  }
};
