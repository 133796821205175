import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch } from "@headlessui/react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { settingsActions } from "./actions";
import "./settings.css";

const NotificationSettings = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { notificationSettings } = useSelector((state) => state.settings);
  const [notifications, setNotifications] = useState({});
  const [emailDailyDigest, setEmailDailyDigest] = useState(true);
  const [streamDailyDigest, setStreamDailyDigest] = useState(true);
  const [emailOpenedPush, setEmailOpenedPush] = useState(true);
  const [emailOpenedEmail, setEmailOpenedEmail] = useState(true);
  const [assetViewedPush, setAssetViewedPush] = useState(true);
  const [assetViewedEmail, setAssetViewedEmail] = useState(true);
  const [assetDownloadPush, setAssetDownloadPush] = useState(true);
  const [assetDownloadEmail, setAssetDownloadEmail] = useState(true);
  const [assetSharedPush, setAssetSharedPush] = useState(true);
  const [assetSharedEmail, setAssetSharedEmail] = useState(true);
  const [collectionViewedPush, setCollectionViewedPush] = useState(true);
  const [collectionViewedEmail, setCollectionViewedEmail] = useState(true);
  const [collectionClonedPush, setCollectionClonedPush] = useState(true);
  const [collectionClonedEmail, setCollectionClonedEmail] = useState(true);
  const [collectionResharedPush, setCollectionResharedPush] = useState(true);
  const [collectionResharedEmail, setCollectionResharedEmail] = useState(true);
  const [collectionCollaboratorPush, setCollectionCollaboratorPush] =
    useState(true);
  const [collectionCollaboratorEmail, setCollectionCollaboratorEmail] =
    useState(true);
  const [streamCollaboratorPush, setStreamCollaboratorPush] = useState(true);
  const [streamCollaboratorEmail, setStreamCollaboratorEmail] = useState(true);
  const [inAppNotifications, setInAppNotifications] = useState(true);
  const [emailNotifications, setEmailNotifications] = useState(true);

  useEffect(() => {
    if (user.id) {
      dispatch(settingsActions.handleGetNotificationSettings());
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    setNotifications(notificationSettings);
    if (notifications?.id) {
      setEmailDailyDigest(notifications?.email_daily_digest);
      setStreamDailyDigest(notifications?.stream_daily_digest);
      setEmailOpenedEmail(notifications?.email_opened_email);
      setEmailOpenedPush(notifications?.email_opened_push);
      setAssetViewedPush(notifications?.asset_viewed_push);
      setAssetViewedEmail(notifications?.asset_viewed_email);
      setAssetDownloadPush(notifications?.asset_download_push);
      setAssetDownloadEmail(notifications?.asset_download_email);
      setAssetSharedPush(notifications?.asset_shared_push);
      setAssetSharedEmail(notifications?.asset_shared_email);
      setCollectionViewedPush(notifications?.collection_viewed_push);
      setCollectionViewedEmail(notifications?.collection_viewed_email);
      setCollectionClonedPush(notifications?.collection_cloned_push);
      setCollectionClonedEmail(notifications?.collection_cloned_email);
      setCollectionResharedPush(notifications?.collection_reshared_push);
      setCollectionResharedEmail(notifications?.collection_reshared_email);
      setCollectionCollaboratorPush(
        notifications?.collection_collaborator_push
      );
      setCollectionCollaboratorEmail(
        notifications?.collection_collaborator_email
      );
      setStreamCollaboratorPush(notifications?.stream_collaborator_push);
      setStreamCollaboratorEmail(notifications?.stream_collaborator_email);
      setInAppNotifications(notifications?.in_app_notifications);
      setEmailNotifications(notifications?.email_notifications);
    } else if (Array.isArray(notifications)) {
      setEmailDailyDigest(notifications[0]?.email_daily_digest);
      setStreamDailyDigest(notifications[0]?.stream_daily_digest);
      setEmailOpenedEmail(notifications[0]?.email_opened_email);
      setEmailOpenedPush(notifications[0]?.email_opened_push);
      setAssetViewedPush(notifications[0]?.asset_viewed_push);
      setAssetViewedEmail(notifications[0]?.asset_viewed_email);
      setAssetDownloadPush(notifications[0]?.asset_download_push);
      setAssetDownloadEmail(notifications[0]?.asset_download_email);
      setAssetSharedPush(notifications[0]?.asset_shared_push);
      setAssetSharedEmail(notifications[0]?.asset_shared_email);
      setCollectionViewedPush(notifications[0]?.collection_viewed_push);
      setCollectionViewedEmail(notifications[0]?.collection_viewed_email);
      setCollectionClonedPush(notifications[0]?.collection_cloned_push);
      setCollectionClonedEmail(notifications[0]?.collection_cloned_email);
      setCollectionResharedPush(notifications[0]?.collection_reshared_push);
      setCollectionResharedEmail(notifications[0]?.collection_reshared_email);
      setCollectionCollaboratorPush(
        notifications[0]?.collection_collaborator_push
      );
      setCollectionCollaboratorEmail(
        notifications[0]?.collection_collaborator_email
      );
      setStreamCollaboratorPush(notifications[0]?.stream_collaborator_push);
      setStreamCollaboratorEmail(notifications[0]?.stream_collaborator_email);
      setInAppNotifications(notifications[0]?.in_app_notifications);
      setEmailNotifications(notifications[0]?.email_notifications);
    }
  }, [notificationSettings, notifications]);

  const statusToggle = (status) => {
    const payload = {
      email_daily_digest:
        status === "email daily digest" ? !emailDailyDigest : emailDailyDigest,
      stream_daily_digest:
        status === "stream daily digest"
          ? !streamDailyDigest
          : streamDailyDigest,
      email_opened_push:
        status === "email opened push" ? !emailOpenedPush : emailOpenedPush,
      email_opened_email:
        status === "email opened email" ? !emailOpenedEmail : emailOpenedEmail,
      asset_viewed_push:
        status === "asset viewed push" ? !assetViewedPush : assetViewedPush,
      asset_viewed_email:
        status === "asset viewed email" ? !assetViewedEmail : assetViewedEmail,
      asset_download_push:
        status === "asset download push"
          ? !assetDownloadPush
          : assetDownloadPush,
      asset_download_email:
        status === "asset download email"
          ? !assetDownloadEmail
          : assetDownloadEmail,
      asset_shared_push:
        status === "asset shared push" ? !assetSharedPush : assetSharedPush,
      asset_shared_email:
        status === "asset shared email" ? !assetSharedEmail : assetSharedEmail,
      collection_viewed_push:
        status === "collection viewed push"
          ? !collectionViewedPush
          : collectionViewedPush,
      collection_viewed_email:
        status === "collection viewed email"
          ? !collectionViewedEmail
          : collectionViewedEmail,
      collection_cloned_push:
        status === "collection cloned push"
          ? !collectionClonedPush
          : collectionClonedPush,
      collection_cloned_email:
        status === "collection cloned email"
          ? !collectionClonedEmail
          : collectionClonedEmail,
      collection_reshared_email:
        status === "collection reshared email"
          ? !collectionResharedEmail
          : collectionResharedEmail,
      collection_reshared_push:
        status === "collection reshared push"
          ? !collectionResharedPush
          : collectionResharedPush,
      collection_collaborator_push:
        status === "collection collaborator push"
          ? !collectionCollaboratorPush
          : collectionCollaboratorPush,
      collection_collaborator_email:
        status === "collection collaborator email"
          ? !collectionCollaboratorEmail
          : collectionCollaboratorEmail,
      stream_collaborator_push:
        status === "stream collaborator push"
          ? !streamCollaboratorPush
          : streamCollaboratorPush,
      stream_collaborator_email:
        status === "stream collaborator email"
          ? !streamCollaboratorEmail
          : streamCollaboratorEmail,
      in_app_notifications:
        status === "in app notifications"
          ? !inAppNotifications
          : inAppNotifications,
      email_notifications:
        status === "email notifications"
          ? !emailNotifications
          : emailNotifications,
    };
    if (status === "email daily digest") {
      setEmailDailyDigest(!emailDailyDigest);
    }
    if (status === "stream daily digest") {
      setStreamDailyDigest(!streamDailyDigest);
    }
    if (status === "email opened push") {
      setEmailOpenedPush(!emailOpenedPush);
    }
    if (status === "email opened email") {
      setEmailOpenedEmail(!emailOpenedEmail);
    }
    if (status === "asset viewed push") {
      setAssetViewedPush(!assetViewedPush);
    }
    if (status === "asset viewed email") {
      setAssetViewedEmail(!assetViewedEmail);
    }
    if (status === "asset download push") {
      setAssetDownloadPush(!assetDownloadPush);
    }
    if (status === "asset download email") {
      setAssetDownloadEmail(!assetDownloadEmail);
    }
    if (status === "asset shared push") {
      setAssetSharedPush(!assetSharedPush);
    }
    if (status === "asset shared email") {
      setAssetSharedEmail(!assetSharedEmail);
    }
    if (status === "collection viewed push") {
      setCollectionViewedPush(!collectionViewedPush);
    }
    if (status === "collection viewed email") {
      setCollectionViewedEmail(!collectionViewedEmail);
    }
    if (status === "collection cloned push") {
      setCollectionClonedPush(!collectionClonedPush);
    }
    if (status === "collection cloned email") {
      setCollectionClonedEmail(!collectionClonedEmail);
    }
    if (status === "collection reshared email") {
      setCollectionResharedEmail(!collectionResharedEmail);
    }
    if (status === "collection reshared push") {
      setCollectionResharedPush(!collectionResharedPush);
    }
    if (status === "collection collaborator push") {
      setCollectionCollaboratorPush(!collectionCollaboratorPush);
    }
    if (status === "collection collaborator email") {
      setCollectionCollaboratorEmail(!collectionCollaboratorEmail);
    }
    if (status === "stream collaborator push") {
      setStreamCollaboratorPush(!streamCollaboratorPush);
    }
    if (status === "stream collaborator email") {
      setStreamCollaboratorEmail(!streamCollaboratorEmail);
    }
    if (status === "in app notifications") {
      setInAppNotifications(!inAppNotifications);
    }
    if (status === "email notifications") {
      setEmailNotifications(!emailNotifications);
    }
    return dispatch(settingsActions.handleEditNotificationSettings(payload));
  };

  return (
    <div className="bg-white rounded-xl md:m-4 md:p-14 p-2 w-full flex flex-col">
      <div className="font-bold text-uberark-orange text-xl border-slate-600 border-b-1 pb-2 mb-2">
        {" "}
        Notification Centre
      </div>

      <div
        className={`${
          window.innerWidth < 768
            ? ""
            : "flex flex-row items-start justify-between"
        } w-full`}
      >
        <div
          className={`${
            window.innerWidth < 768 ? "w-2/2" : "w-1/2"
          } flex items-baseline`}
        >
          <div className="text-sm pt-2 w-3/5 inline-block">
            <div className="box-border font-bold text-uberark-blue">
              Email Daily Digest
            </div>
            <div className="text-xs mt-1 block text-gray-400">
              Receive a daily digest communication with a summary of activities
              over email
            </div>
          </div>
          <div className="inline-block ml-8">
            <div className="relative inline-block box-border m-0">
              <div className="grid grid-flow-col gap-2 items-center justify-between px-5 py-2">
                <Switch
                  checked={emailDailyDigest}
                  onChange={() => statusToggle("email daily digest")}
                  className={`${
                    !emailDailyDigest ? "bg-uberark-blue" : "bg-uberark-orange"
                  } relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${
                      emailDailyDigest ? "translate-x-5" : "translate-x-0"
                    } pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>{" "}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            window.innerWidth < 768
              ? "w-full h-1 mt-2 border-b"
              : "h-12 border-l mt-8"
          } border-solid pr-9 ml-0 align-bottom`}
        ></div>
        <div
          className={`${
            window.innerWidth < 768 ? "w-2/2" : "w-1/2"
          } flex items-baseline`}
        >
          <div className="text-sm pt-2 w-3/5 inline-block">
            <div className="box-border font-bold text-uberark-blue">
              Stream Daily Digest
            </div>
            <div className="text-xs mt-1 block text-gray-400">
              Stay on top of all updates in the Streams you follow.
            </div>
          </div>
          <div className="inline-block ml-8">
            <div className="relative inline-block box-border m-0">
              <div className="grid grid-flow-col gap-2 items-center justify-between px-5 py-2">
                <Switch
                  checked={streamDailyDigest}
                  onChange={() => statusToggle("stream daily digest")}
                  className={`${
                    !streamDailyDigest ? "bg-uberark-blue" : "bg-uberark-orange"
                  } relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${
                      streamDailyDigest ? "translate-x-5" : "translate-x-0"
                    } pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="font-bold pt-8 w-3/5 inline-block text-sm text-uberark-blue">
        Transactional Notifications
      </div>
      <div className="pb-2 block mt-1 font-light text-xs text-gray-400">
        Setup transactional notification triggers for browser based push
        notification and/or emails
      </div>
      <div className="py-2 w-full border-slate-600 border-b-1">
        <div className="text-xs font-bold items-center w-full grid grid-cols-3 gap-4">
          <div className="inline-block align-middle text-uberark-blue">
            TRANSACTION TRIGGER
          </div>
          <div className="box-border text-uberark-blue col-span-2 text-center">
            COMMUNICATION CHANNEL
          </div>
        </div>
      </div>
      <div className="pt-2 divide-y divide-solid w-full box-border">
        <div className="items-center w-full grid grid-cols-2 gap-4">
          <div className="font-medium inline-block align-middle text-black text-xs py-4 px-0">
            Email Opened
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="select-none font-medium inline-block align-middle text-sm py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() => statusToggle("email opened push")}
                    className={`${
                      emailOpenedPush ? "text-uberark-orange" : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1">
                    Push Notification
                  </span>
                </div>
              </span>
            </div>
            <div className="flex select-none font-medium inline-block items-center text-sm py-4 px-0">
              <span
                onMouseDown={() => statusToggle("email opened email")}
                className={`${
                  emailOpenedEmail ? "text-uberark-orange" : "text-slate-600"
                } cursor-pointer`}
              >
                <BsFillCheckCircleFill />
              </span>
              <span className="text-xs text-black pl-1">Email</span>
            </div>
          </div>
        </div>

        <div className="items-center w-full grid grid-cols-2 gap-4">
          <div className="font-medium inline-block align-middle text-black text-xs py-4 px-0">
            Asset Viewed
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="select-none font-medium inline-block align-middle text-sm py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() => statusToggle("asset viewed push")}
                    className={`${
                      assetViewedPush ? "text-uberark-orange" : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1">
                    Push Notification
                  </span>
                </div>
              </span>
            </div>
            <div className="flex select-none font-medium inline-block items-center text-sm py-4 px-0">
              <span
                onMouseDown={() => statusToggle("asset viewed email")}
                className={`${
                  assetViewedEmail ? "text-uberark-orange" : "text-slate-600"
                } cursor-pointer`}
              >
                <BsFillCheckCircleFill />
              </span>
              <span className="text-xs text-black pl-1">Email</span>
            </div>
          </div>
        </div>

        <div className="items-center w-full grid grid-cols-2 gap-4">
          <div className="font-medium inline-block align-middle text-black text-xs py-4 px-0">
            Asset Download
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="select-none font-medium inline-block align-middle text-sm py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() => statusToggle("asset download push")}
                    className={`${
                      assetDownloadPush
                        ? "text-uberark-orange"
                        : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1">
                    Push Notification
                  </span>
                </div>
              </span>
            </div>
            <div className="select-none font-medium inline-block align-middle text-xs py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() => statusToggle("asset download email")}
                    className={`${
                      assetDownloadEmail
                        ? "text-uberark-orange"
                        : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1">Email</span>
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className="items-center w-full grid grid-cols-2 gap-4">
          <div className="font-medium inline-block align-middle text-black text-xs py-4 px-0">
            Asset Shared
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="select-none font-medium inline-block align-middle text-black text-sm py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() => statusToggle("asset shared push")}
                    className={`${
                      assetSharedPush ? "text-uberark-orange" : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1">
                    Push Notification
                  </span>
                </div>
              </span>
            </div>
            <div className="select-none font-medium inline-block align-middle text-xs py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() => statusToggle("asset shared email")}
                    className={`${
                      assetSharedEmail
                        ? "text-uberark-orange"
                        : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1">Email</span>
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className="items-center w-full grid grid-cols-2 gap-4">
          <div className="font-medium inline-block align-middle text-black text-xs py-4 px-0">
            Collection Viewed
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="select-none font-medium inline-block align-middle text-sm py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() => statusToggle("collection viewed push")}
                    className={`${
                      collectionViewedPush
                        ? "text-uberark-orange"
                        : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1">
                    Push Notification
                  </span>
                </div>
              </span>
            </div>
            <div className="select-none font-medium inline-block align-middle text-xs py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() => statusToggle("collection viewed email")}
                    className={`${
                      collectionViewedEmail
                        ? "text-uberark-orange"
                        : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1">Email</span>
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className="items-center w-full grid grid-cols-2 gap-4">
          <div className="font-medium inline-block align-middle text-black text-xs py-4 px-0">
            Collection Collaborator
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="select-none font-medium inline-block align-middle text-sm py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() =>
                      statusToggle("collection collaborator push")
                    }
                    className={`${
                      collectionCollaboratorPush
                        ? "text-uberark-orange"
                        : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1">
                    Push Notification
                  </span>
                </div>
              </span>
            </div>
            <div className="select-none font-medium inline-block align-middle text-xs py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() =>
                      statusToggle("collection collaborator email")
                    }
                    className={`${
                      collectionCollaboratorEmail
                        ? "text-uberark-orange"
                        : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1">Email</span>
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className="items-center w-full grid grid-cols-2 gap-4">
          <div className="font-medium inline-block align-middle text-black text-xs py-4 px-0">
            Collection Cloned
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="select-none font-medium inline-block align-middle text-sm py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() => statusToggle("collection cloned push")}
                    className={`${
                      collectionClonedPush
                        ? "text-uberark-orange"
                        : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1">
                    Push Notification
                  </span>
                </div>
              </span>
            </div>
            <div className="select-none font-medium inline-block align-middle text-xs py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() => statusToggle("collection cloned email")}
                    className={`${
                      collectionClonedEmail
                        ? "text-uberark-orange"
                        : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1">Email</span>
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className="items-center w-full grid grid-cols-2 gap-4">
          <div className="font-medium inline-block align-middle text-black text-xs py-4 px-0">
            Collection Reshared
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="select-none font-medium inline-block align-middle text-sm py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() => statusToggle("collection reshared push")}
                    className={`${
                      collectionResharedPush
                        ? "text-uberark-orange"
                        : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1">
                    Push Notification
                  </span>
                </div>
              </span>
            </div>
            <div className="select-none font-medium inline-block align-middle text-xs py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() =>
                      statusToggle("collection reshared email")
                    }
                    className={`${
                      collectionResharedEmail
                        ? "text-uberark-orange"
                        : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1">Email</span>
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className="items-center w-full grid grid-cols-2 gap-4">
          <div className="font-medium inline-block align-middle text-black text-xs py-4 px-0">
            Stream Collaborator
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="select-none font-medium inline-block align-middle text-sm py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() => statusToggle("stream collaborator push")}
                    className={`${
                      streamCollaboratorPush
                        ? "text-uberark-orange"
                        : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1">
                    Push Notification
                  </span>
                </div>
              </span>
            </div>
            <div className="select-none font-medium inline-block align-middle text-xs py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() =>
                      statusToggle("stream collaborator email")
                    }
                    className={`${
                      streamCollaboratorEmail
                        ? "text-uberark-orange"
                        : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1">Email</span>
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className="items-center w-full grid grid-cols-2 gap-4">
          <div className="font-medium inline-block align-middle text-black text-xs py-4 px-0">
            In App Notifications
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="select-none text-sm">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() => statusToggle("in app notifications")}
                    className={`${
                      inAppNotifications
                        ? "text-uberark-orange"
                        : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1"></span>
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className="items-center w-full grid grid-cols-2 gap-4">
          <div className="font-medium inline-block align-middle text-black text-xs py-4 px-0">
            Email Notifications
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="select-none font-medium inline-block align-middle text-sm py-4 px-0">
              <span className="align-middle text-lg">
                <div className="flex items-center text-sm">
                  <span
                    onMouseDown={() => statusToggle("email notifications")}
                    className={`${
                      emailNotifications
                        ? "text-uberark-orange"
                        : "text-slate-600"
                    } cursor-pointer`}
                  >
                    <BsFillCheckCircleFill />
                  </span>
                  <span className="text-xs text-black pl-1"></span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
