export const GET_COLLECTION_REQUEST = "GET_COLLECTION_REQUEST";
export const GET_COLLECTION_SUCCESS = "GET_COLLECTION_SUCCESS";
export const GET_COLLECTION_FAILED = "GET_COLLECTION_FAILED";

export const CREATE_COLLECTION_REQUEST = "CREATE_COLLECTION_REQUEST";
export const CREATE_COLLECTION_SUCCESS = "CREATE_COLLECTION_SUCCESS";
export const CREATE_COLLECTION_FAILED = "CREATE_COLLECTION_FAILED";

export const EDIT_COLLECTION_REQUEST = "EDIT_COLLECTION_REQUEST";
export const EDIT_COLLECTION_SUCCESS = "EDIT_COLLECTION_SUCCESS";
export const EDIT_COLLECTION_FAILED = "EDIT_COLLECTION_FAILED";

export const DELETE_COLLECTION_REQUEST = "DELETE_COLLECTION_REQUEST";
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS";
export const DELETE_COLLECTION_FAILED = "DELETE_COLLECTION_FAILED";

export const CREATE_SECTION_REQUEST = "CREATE_SECTION_REQUEST";
export const CREATE_SECTION_SUCCESS = "CREATE_SECTION_SUCCESS";
export const CREATE_SECTION_FAILED = "CREATE_SECTION_FAILED";

export const EDIT_SECTION_REQUEST = "EDIT_SECTION_REQUEST";
export const EDIT_SECTION_SUCCESS = "EDIT_SECTION_SUCCESS";
export const EDIT_SECTION_FAILED = "EDIT_SECTION_FAILED";

export const DELETE_SECTION_REQUEST = "DELETE_SECTION_REQUEST";
export const DELETE_SECTION_SUCCESS = "DELETE_SECTION_SUCCESS";
export const DELETE_SECTION_FAILED = "DELETE_SECTION_FAILED";

export const CREATE_LINK_REQUEST = "CREATE_LINK_REQUEST";
export const CREATE_LINK_SUCCESS = "CREATE_LINK_SUCCESS";
export const CREATE_LINK_FAILED = "CREATE_LINK_FAILED";

export const DELETE_LINK_REQUEST = "DELETE_LINK_REQUEST";
export const DELETE_LINK_SUCCESS = "DELETE_LINK_SUCCESS";
export const DELETE_LINK_FAILED = "DELETE_LINK_FAILED";

export const EDIT_LINK_REQUEST = "EDIT_LINK_REQUEST";
export const EDIT_LINK_SUCCESS = "EDIT_LINK_SUCCESS";
export const EDIT_LINK_FAILED = "EDIT_LINK_FAILED";

export const GET_FOLDER_REQUEST = "GET_FOLDER_REQUEST";
export const GET_FOLDER_SUCCESS = "GET_FOLDER_SUCCESS";
export const GET_FOLDER_FAILED = "GET_FOLDER_FAILED";

export const CREATE_FOLDER_REQUEST = "CREATE_FOLDER_REQUEST";
export const CREATE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS";
export const CREATE_FOLDER_FAILED = "CREATE_FOLDER_FAILED";

export const DELETE_FOLDER_REQUEST = "DELETE_FOLDER_REQUEST";
export const DELETE_FOLDER_SUCCESS = "DELETE_FOLDER_SUCCESS";
export const DELETE_FOLDER_FAILED = "DELETE_FOLDER_FAILED";

export const EDIT_FOLDER_REQUEST = "EDIT_FOLDER_REQUEST";
export const EDIT_FOLDER_SUCCESS = "EDIT_FOLDER_SUCCESS";
export const EDIT_FOLDER_FAILED = "EDIT_FOLDER_FAILED";

export const ADD_COLLABORATOR_REQUEST = "ADD_COLLABORATOR_REQUEST";
export const ADD_COLLABORATOR_SUCCESS = "ADD_COLLABORATOR_SUCCESS";
export const ADD_COLLABORATOR_FAILED = "ADD_COLLABORATOR_FAILED";

export const DELETE_COLLABORATOR_REQUEST = "DELETE_COLLABORATOR_REQUEST";
export const DELETE_COLLABORATOR_SUCCESS = "DELETE_COLLABORATOR_SUCCESS";
export const DELETE_COLLABORATOR_FAILED = "DELETE_COLLABORATOR_FAILED";

export const CLONE_COLLECTION_REQUEST = "CLONE_COLLECTION_REQUEST";
export const CLONE_COLLECTION_SUCCESS = "CLONE_COLLECTION_SUCCESS";
export const CLONE_COLLECTION_FAILED = "CLONE_COLLECTION_FAILED";

export const PREVIEW_COLLECTION_REQUEST = "PREVIEW_COLLECTION_REQUEST";
export const PREVIEW_COLLECTION_SUCCESS = "PREVIEW_COLLECTION_SUCCESS";
export const PREVIEW_COLLECTION_FAILED = "PREVIEW_COLLECTION_FAILED";

export const GET_ALL_COLLECTION_REQUEST = "GET_ALL_COLLECTION_REQUEST";
export const GET_ALL_COLLECTION_SUCCESS = "GET_ALL_COLLECTION_SUCCESS";
export const GET_ALL_COLLECTION_FAILED = "GET_ALL_COLLECTION_FAILED";

export const GET_COLLECTION_TEMPLATE_REQUEST =
  "GET_COLLECTION_TEMPLATE_REQUEST";
export const GET_COLLECTION_TEMPLATE_SUCCESS =
  "GET_COLLECTION_TEMPLATE_SUCCESS";
export const GET_COLLECTION_TEMPLATE_FAILED = "GET_COLLECTION_TEMPLATE_FAILED";
