import * as types from "./actionTypes";
import { request, success, failure } from "../../../app/utilities/helpers";
import { settingsService } from "./_service";
import { toast } from "react-toastify";
import { authActions } from "../../Auth/actions";

const handleAccountManagementCreation = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.ACCOUNT_MANAGEMENT_REQUEST));
    settingsService.handleAccountManagementCreation(data).then(
      (res) => {
        toast.success("Organization Added");
        dispatch(success(res, types.ACCOUNT_MANAGEMENT_SUCCESS));
        // get user
        dispatch(authActions.handleGetLoggedInUser());
      },
      (error) => {
        dispatch(failure(error, types.ACCOUNT_MANAGEMENT_FAILED));
      }
    );
  };
};

const handleAccountManagementUpdate = (data, orgId) => {
  return (dispatch) => {
    dispatch(request(null, types.ACCOUNT_MANAGEMENT_REQUEST));
    settingsService.handleAccountManagementUpdate(data, orgId).then(
      (res) => {
        toast.success("Organization Updated");
        dispatch(success(res, types.ACCOUNT_MANAGEMENT_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.ACCOUNT_MANAGEMENT_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleAccountManagementFetch = (orgId) => {
  return (dispatch) => {
    dispatch(request(null, types.ACCOUNT_MANAGEMENT_REQUEST));
    settingsService.handleAccountManagementFetch(orgId).then(
      (res) => {
        dispatch(success(res, types.ACCOUNT_MANAGEMENT_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.ACCOUNT_MANAGEMENT_FAILED));
      }
    );
  };
};

const handleSendUserInvite = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.USER_INVITE_REQUEST));
    settingsService.handleSendUserInvite(data).then(
      (res) => {
        toast.success(res.message);
        dispatch(success(res, types.USER_INVITE_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.USER_INVITE_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleUserListsFetch = (queryUrl) => {
  return (dispatch) => {
    dispatch(request(null, types.FETCH_USER_LISTS_REQUEST));
    settingsService.handleUserListsFetch(queryUrl).then(
      (res) => {
        dispatch(success(res, types.FETCH_USER_LISTS_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.FETCH_USER_LISTS_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleDeleteUser = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.USER_DELETE_REQUEST));
    settingsService.handleDeleteUser(data).then(
      (res) => {
        toast.success(res.message);
        dispatch(success(res, types.USER_DELETE_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.USER_DELETE_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleNDAListsFetch = (queryUrl) => {
  return (dispatch) => {
    dispatch(request(null, types.FETCH_NDA_LISTS_REQUEST));
    settingsService.handleNDAListsFetch(queryUrl).then(
      (res) => {
        dispatch(success(res, types.FETCH_NDA_LISTS_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.FETCH_NDA_LISTS_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleNDACreation = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_NDA_REQUEST));
    settingsService.handleNDACreation(data).then(
      (res) => {
        toast.success("NDA Created");
        dispatch(success(res, types.CREATE_NDA_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.CREATE_NDA_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleNDAUpdate = (data, id) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_NDA_REQUEST));
    settingsService.handleNDAUpdate(data, id).then(
      (res) => {
        toast.success("NDA updated");
        dispatch(success(res, types.CREATE_NDA_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.CREATE_NDA_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleNDADelete = (id) => {
  return (dispatch) => {
    dispatch(request(null, types.DELETE_NDA_REQUEST));
    settingsService.handleNDADelete(id).then(
      (res) => {
        toast.success("NDA Template delete");
        dispatch(success(res, types.DELETE_NDA_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.DELETE_NDA_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleETemplateListsFetch = (queryUrl) => {
  return (dispatch) => {
    dispatch(request(null, types.FETCH_ETEMPLATE_LISTS_REQUEST));
    settingsService.handleETemplateListsFetch(queryUrl).then(
      (res) => {
        dispatch(success(res, types.FETCH_ETEMPLATE_LISTS_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.FETCH_ETEMPLATE_LISTS_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleETemplateCreation = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_ETEMPLATE_REQUEST));
    settingsService.handleETemplateCreation(data).then(
      (res) => {
        toast.success("Email Template Created");
        dispatch(success(res, types.CREATE_ETEMPLATE_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.CREATE_ETEMPLATE_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleETemplateUpdate = (data, id) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_ETEMPLATE_REQUEST));
    settingsService.handleETemplateUpdate(data, id).then(
      (res) => {
        toast.success("Email Template updated");
        dispatch(success(res, types.CREATE_ETEMPLATE_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.CREATE_ETEMPLATE_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleETemplateDelete = (id) => {
  return (dispatch) => {
    dispatch(request(null, types.DELETE_ETEMPLATE_REQUEST));
    settingsService.handleETemplateDelete(id).then(
      (res) => {
        toast.success("Email Template delete");
        dispatch(success(res, types.DELETE_ETEMPLATE_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.DELETE_ETEMPLATE_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleTACListsFetch = (queryUrl) => {
  return (dispatch) => {
    dispatch(request(null, types.FETCH_TAC_LISTS_REQUEST));
    settingsService.handleTACListsFetch(queryUrl).then(
      (res) => {
        dispatch(success(res, types.FETCH_TAC_LISTS_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.FETCH_TAC_LISTS_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleTACCreation = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_TAC_REQUEST));
    settingsService.handleTACCreation(data).then(
      (res) => {
        toast.success("Terms And Condition Created");
        dispatch(success(res, types.CREATE_TAC_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.CREATE_TAC_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleTACUpdate = (data, id) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_TAC_REQUEST));
    settingsService.handleTACUpdate(data, id).then(
      (res) => {
        toast.success("Terms And Condition updated");
        dispatch(success(res, types.CREATE_TAC_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.CREATE_TAC_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleTACDelete = (id) => {
  return (dispatch) => {
    dispatch(request(null, types.DELETE_TAC_REQUEST));
    settingsService.handleTACDelete(id).then(
      (res) => {
        toast.success("Terms And Condition delete");
        dispatch(success(res, types.DELETE_TAC_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.DELETE_TAC_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleESignListsFetch = (queryUrl) => {
  return (dispatch) => {
    dispatch(request(null, types.FETCH_ESIGN_LISTS_REQUEST));
    settingsService.handleESignListsFetch(queryUrl).then(
      (res) => {
        dispatch(success(res, types.FETCH_ESIGN_LISTS_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.FETCH_ESIGN_LISTS_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleESignCreation = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_ESIGN_REQUEST));
    settingsService.handleESignCreation(data).then(
      (res) => {
        toast.success("Email Signatures Created");
        dispatch(success(res, types.CREATE_ESIGN_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.CREATE_ESIGN_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleESignUpdate = (data, id) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_ESIGN_REQUEST));
    settingsService.handleESignUpdate(data, id).then(
      (res) => {
        toast.success("Email Signatures updated");
        dispatch(success(res, types.CREATE_ESIGN_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.CREATE_ESIGN_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleESignDelete = (id) => {
  return (dispatch) => {
    dispatch(request(null, types.DELETE_ESIGN_REQUEST));
    settingsService.handleESignDelete(id).then(
      (res) => {
        if (res?.message) {
          toast.success(res?.message);
        } else {
          toast.success("Email Signatures delete");
        }
        dispatch(success(res, types.DELETE_ESIGN_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.DELETE_ESIGN_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleNotificationListsFetch = (queryUrl) => {
  return (dispatch) => {
    dispatch(request(null, types.FETCH_NOTIFICATION_LISTS_REQUEST));
    settingsService.handleNotificationListsFetch(queryUrl).then(
      (res) => {
        dispatch(success(res, types.FETCH_NOTIFICATION_LISTS_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.FETCH_NOTIFICATION_LISTS_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleNotificationUpdate = (data, id) => {
  return (dispatch) => {
    dispatch(request(null, types.UPDATE_NOTIFICATION_REQUEST));
    settingsService.handleNotificationUpdate(data, id).then(
      (res) => {
        dispatch(success(res, types.UPDATE_NOTIFICATION_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.UPDATE_NOTIFICATION_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleGetNotificationSettings = () => {
  return (dispatch) => {
    dispatch(request(null, types.GET_NOTIFICATION_SETTINGS_REQUEST));
    settingsService.handleGetNotificationSettings().then(
      (res) => {
        dispatch(success(res, types.GET_NOTIFICATION_SETTINGS_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.GET_NOTIFICATION_SETTINGS_FAILURE));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleEditNotificationSettings = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.EDIT_NOTIFICATION_SETTINGS_REQUEST));
    settingsService.handleEditNotificationSettings(data).then(
      (res) => {
        toast.success("Notification updated successfully");
        if (res.id) {
          dispatch(success(res, types.EDIT_NOTIFICATION_SETTINGS_SUCCESS));
        } else if (Array.isArray(res)) {
          dispatch(success(res[0], types.EDIT_NOTIFICATION_SETTINGS_SUCCESS));
        }
      },
      (error) => {
        dispatch(failure(error, types.EDIT_NOTIFICATION_SETTINGS_FAILURE));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleCustomFieldListsFetch = (queryUrl) => {
  return (dispatch) => {
    dispatch(request(null, types.FETCH_CF_LISTS_REQUEST));
    settingsService.handleNotificationListsFetch(queryUrl).then(
      (res) => {
        dispatch(success(res, types.FETCH_CF_LISTS_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.FETCH_CF_LISTS_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleCustomFieldCreate = (data, callback) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_CF_REQUEST));
    settingsService.handleCustomFieldCreate(data).then(
      (res) => {
        toast.success("Custom Field created successfully.");
        callback && callback();
        dispatch(success(res, types.CREATE_CF_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.CREATE_CF_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleCustomFieldUpdate = (data, id) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_CF_REQUEST));
    settingsService.handleCustomFieldUpdate(data, id).then(
      (res) => {
        toast.success("Custom Field updated successfully.");
        dispatch(success(res, types.CREATE_CF_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.CREATE_CF_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleCustomFieldDelete = (id) => {
  return (dispatch) => {
    dispatch(request(null, types.DELETE_CF_REQUEST));
    settingsService.handleCustomFieldDelete(id).then(
      (res) => {
        toast.success("Custom Field updated successfully.");
        dispatch(success(res, types.DELETE_CF_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.DELETE_CF_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handlePrivacyPolicyListsFetch = (queryUrl) => {
  return (dispatch) => {
    dispatch(request(null, types.FETCH_PRIVACY_POLICY_LISTS_REQUEST));
    settingsService.handleNotificationListsFetch(queryUrl).then(
      (res) => {
        dispatch(success(res, types.FETCH_PRIVACY_POLICY_LISTS_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.FETCH_PRIVACY_POLICY_LISTS_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handlePrivacyPolicyCreate = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_PRIVACY_POLICY_REQUEST));
    settingsService.handlePrivacyPolicyCreate(data).then(
      (res) => {
        toast.success("Privacy Policy created successfully.");
        dispatch(success(res, types.CREATE_PRIVACY_POLICY_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.CREATE_PRIVACY_POLICY_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handlePrivacyPolicyUpdate = (data, id) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_PRIVACY_POLICY_REQUEST));
    settingsService.handlePrivacyPolicyUpdate(data, id).then(
      (res) => {
        toast.success("Privacy Policy updated successfully.");
        dispatch(success(res, types.CREATE_PRIVACY_POLICY_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.CREATE_PRIVACY_POLICY_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handlePrivacyPolicyDelete = (id) => {
  return (dispatch) => {
    dispatch(request(null, types.DELETE_PRIVACY_POLICY_REQUEST));
    settingsService.handlePrivacyPolicyDelete(id).then(
      (res) => {
        toast.success("Privacy Policy deleted successfully.");
        dispatch(success(res, types.DELETE_PRIVACY_POLICY_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.DELETE_PRIVACY_POLICY_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleShareGet = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.GET_SHARE_REQUEST));
    settingsService.handleShareGet(data).then(
      (res) => {
        dispatch(success(res, types.GET_SHARE_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.GET_SHARE_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleShareCreate = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_SHARE_REQUEST));
    settingsService.handleShareCreate(data).then(
      (res) => {
        toast.success("Created successfully.");
        dispatch(success(res, types.CREATE_SHARE_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.CREATE_SHARE_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleShareUpdate = (data, id) => {
  return (dispatch) => {
    dispatch(request(null, types.UPDATE_SHARE_REQUEST));
    settingsService.handleShareUpdate(data, id).then(
      (res) => {
        toast.success("Updated successfully.");
        dispatch(success(res, types.UPDATE_SHARE_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.UPDATE_SHARE_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleGmailAuth = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.POST_GMAIL_AUTH_REQUEST));
    settingsService.handleGmailAuth(data).then(
      (res) => {
        dispatch(success(res, types.POST_GMAIL_AUTH_SUCCESS));
        // get user
        dispatch(authActions.handleGetLoggedInUser());
      },
      (error) => {
        dispatch(failure(error, types.POST_GMAIL_AUTH_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

export const settingsActions = {
  handleAccountManagementCreation,
  handleAccountManagementUpdate,
  handleAccountManagementFetch,
  handleSendUserInvite,
  handleUserListsFetch,
  handleDeleteUser,
  handleNDAListsFetch,
  handleNDACreation,
  handleNDAUpdate,
  handleNDADelete,
  handleETemplateListsFetch,
  handleETemplateCreation,
  handleETemplateUpdate,
  handleETemplateDelete,
  handleTACListsFetch,
  handleTACCreation,
  handleTACUpdate,
  handleTACDelete,
  handleESignListsFetch,
  handleESignCreation,
  handleESignUpdate,
  handleESignDelete,
  handleNotificationListsFetch,
  handleNotificationUpdate,
  handleGetNotificationSettings,
  handleEditNotificationSettings,
  handleCustomFieldListsFetch,
  handleCustomFieldCreate,
  handleCustomFieldUpdate,
  handleCustomFieldDelete,
  handlePrivacyPolicyListsFetch,
  handlePrivacyPolicyCreate,
  handlePrivacyPolicyUpdate,
  handlePrivacyPolicyDelete,
  handleShareGet,
  handleShareCreate,
  handleShareUpdate,
  handleGmailAuth,
};
