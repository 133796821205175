import React from "react";
import { useNavigate } from "react-router-dom";

import AssetCard from "../../commons/Asset/components/AssetCard";
import { BiConversation } from "react-icons/bi";
import { FiGitMerge } from "react-icons/fi";
import { dateCalculation } from "../../../app/utilities/helpers";

const SideBar = ({
  conversations,
  conversationId,
  setConversationId,
  loading,
}) => {
  const navigate = useNavigate();
  return (
    <div className="conversation-list-wrap relative px-1 border-r-1 border-l-0 border-b-0 min-h-[75vh] w-full scroll-smooth">
      {conversations &&
        conversations.length &&
        conversations.map((conversation, conversationIndex) => (
          <div
            key={conversationIndex}
            className={
              conversationId &&
              conversationId.toString() === conversation.id.toString()
                ? "selected-conversation"
                : ""
            }
          >
            <div className="flex flex-col">
              <button
                onClick={() => {
                  if (!loading) {
                    setConversationId(conversation.id);
                  }
                }}
                className={`${
                  loading ? "cursor-wait" : "cursor-pointer"
                } flex flex-row items-center w-full gap-2 px-2 py-2 mx-1 pr-5 rounded-l`}
                style={{
                  background: `${
                    conversationId &&
                    conversationId.toString() === conversation.id.toString()
                      ? "#fe450212"
                      : "whitesmoke"
                  }`,
                }}
              >
                <div className="flex">
                  <div className="md:w-20 flex justify-center md:h-20 w-12 h-12 imgShadow">
                    {conversation.asset && conversation.asset.id ? (
                      <AssetCard
                        asset={conversation.asset}
                        size="asset__small"
                        className="md:w-20 md:h-20 w-11 h-11 conversation-asset"
                      />
                    ) : (
                      <BiConversation className="md:w-12 md:h-12 w-11 h-11" />
                    )}

                    {conversation.parent_email ? (
                      <div className="absolute right-1">
                        <button
                          className="flex items-center justify-center px-1 w-[20px] h-[20px] text-xs hover:text-[#000080] rounded-full text-uberark-orange bg-white border"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            navigate(
                              "/conversations/" + conversation.parent_email
                            );
                          }}
                        >
                          <FiGitMerge />
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col h-full w-2/3">
                  <div className="flex flex-col">
                    <span className="text-[11px] text-[#757575] font-normal text-left truncate">
                      {conversation.contacts &&
                      conversation.contacts.length &&
                      conversation.to_email.length ? (
                        <>
                          {conversation.to_email[0].split("@")[0]}
                          {conversation.contacts.length > 1 ? (
                            <span className="text-uberark-orange font-semibold">
                              +{conversation.contacts.length - 1}
                            </span>
                          ) : null}
                        </>
                      ) : (
                        "Anonymous"
                      )}
                    </span>
                    <span className="text-uberark-blue font-semibold text-left truncate ">
                      {conversation.subject}
                    </span>
                  </div>

                  <div className="text-left">
                    <span className="text-[11px] text-[#757575] font-normal text-left">
                      Last Active{" "}
                      {conversation.last_activity
                        ? dateCalculation(
                            new Date(conversation.last_activity),
                            new Date()
                          )
                        : "never"}
                    </span>
                  </div>
                </div>
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default SideBar;
