import * as types from '../actions/actionTypes'

const initialState = {
  loading: false,
  createCompose: false,
  errors: {}
}

const composeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPOSE_REQUEST:
      return {
        ...state,
        loading: true,
        createCompose: false,
        errors: {}
      }
    case types.COMPOSE_SUCCESS:
      return {
        ...state,
        createCompose: true,
        loading: false
      }
    case types.COMPOSE_FAILED:
      return {
        ...state,
        errors: action.error,
        createCompose: false,
        loading: false
      }

    default:
      return state
  }
}

export default composeReducer
