import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  BsXLg,
  BsPersonFillLock,
  BsFillPeopleFill,
  BsPersonDash,
  BsPersonFillAdd,
} from "react-icons/bs";
import { FiInfo } from "react-icons/fi";
import LoadingSpinner from "../../../components/commons/LoadingSpinner";

import { collectionActions } from "../../Collections/actions";
import { settingsActions } from "../../Settings/actions";
import { streamActions } from "../../Streams/actions";
import avatarPlaceholder from "../../../assets/images/avatar-placeholder.png";
import no_content from "../../../assets/images/no_content.svg";

const Collaborators = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { collectionId } = location.state;
  const { streamId } = location.state;
  const { previewCollection, loading } = useSelector(
    (state) => state.collection
  );
  const { previewStream, loading: streamLoading } = useSelector(
    (state) => state.stream
  );
  const { users } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);

  const [collectionCollaboratorUserIds, setCollectionCollaboratorUserIds] =
    useState([]);
  const [collection, setCollection] = useState({});

  useEffect(() => {
    if (user.organization && user.organization !== "None") {
      dispatch(settingsActions.handleUserListsFetch(user.organization));
    }
  }, []); /* eslint-disable-line */

  useEffect(() => {
    if (previewCollection) {
      // get collection
      const activeCollection = previewCollection;
      setCollection(activeCollection);
    }
    if (previewStream && streamId) {
      // get stream
      if (Object.keys(previewStream).length) {
        const activeCollection = previewStream;
        setCollection(activeCollection);
      }
    }
  }, [previewCollection, previewStream]); // eslint-disable-line

  useEffect(() => {
    if (user && users && users.length) {
      let collaborators = [];
      collaborators.push({ ...user, writePermision: true });
      users.forEach((userElt) => {
        if (userElt.is_active) {
          if (
            collection &&
            collection.collaborators &&
            collection.collaborators.length > 0
          ) {
            let isCollaborator = false;
            collection.collaborators.forEach((collaborator) => {
              if (
                userElt.id === collaborator.user ||
                userElt.id === collaborator.user_id
              ) {
                isCollaborator = true;
              }
            });
            collaborators.push({ ...userElt, writePermision: isCollaborator });
          } else {
            collaborators.push({ ...userElt, writePermision: false });
          }
        }
      });
      setCollectionCollaboratorUserIds(collaborators);
    }
  }, [collection, users]); // eslint-disable-line

  const handleCollaboratorAccess = (c) => {
    let userId = c.id;
    let writePermision = c.writePermision;

    if (writePermision) {
      // get collection collaborator id
      const collectionCollaborator = collection.collaborators.filter(
        (item) => item.user === userId || item.user_id === userId
      );
      return streamId
        ? dispatch(
            streamActions.handleDeleteCollaborator(
              collectionCollaborator[0].id,
              streamId
            )
          )
        : dispatch(
            collectionActions.handleDeleteCollaborator(
              collectionCollaborator[0].id,
              collectionId
            )
          );
    } else {
      return streamId
        ? dispatch(
            streamActions.handleAddCollaborators(
              {
                user: userId,
                stream: streamId,
              },
              streamId
            )
          )
        : dispatch(
            collectionActions.handleAddCollaborators({
              user: userId,
              collection: collectionId,
            })
          );
    }
  };

  return (
    <div className="relative">
      <div className="absolute sm:top-0 top-20 w-full h-full mb-5">
        <div className="md:mt-4 md:mx-20 mx-4 mt-4 grid grid-cols-1 md:grid-cols-2 justify-between bg-transparent">
          <div className="flex flex-col justify-items-end">
            <span className="text-[#FE4502] font-extrabold text-base">
              Collaborate
            </span>
            <span className="text-[#757575] text-base">
              Create and manage collaborations.
            </span>
          </div>
          <div className="grid grid-flow-col items-center justify-between md:justify-end py-4 pt-4 md:p-0 text-slate-600">
            <button
              onClick={() =>
                navigate(
                  streamId
                    ? `/stream/${streamId}`
                    : `/collection/${collectionId}`
                )
              }
              className="inline-block font-medium text-xl leading-tight hover:shadow-md hover:bg-light-gray shadow-gray-900 focus:bg-white focus:shadow-xl focus:outline-none focus:ring-0 active:shadow-lg hover:scale-105 ease-in-out duration-300 p-3 bg-white w-10 h-10 flex justify-center items-center"
              style={{ borderRadius: "100%", border: "0.24px solid #000080" }}
            >
              <BsXLg className="text-[#FE4502] font-bold" />
            </button>
          </div>
        </div>

        <div className="rounded-3xl bg-white m-4 p-6 shadow grid text-slate-600 pb-20">
          <div className="flex justify-between font-semibold mt-3 mb-2 text-base">
            <div>Manage your Collaborators</div>
          </div>
          {collectionCollaboratorUserIds &&
          collectionCollaboratorUserIds.length > 0 &&
          Object.keys(collection).length > 0 ? (
            <div className="flex flex-col items-center">
              <div className="flex w-full items-center justify-center">
                <table className="border-collapse w-full text-uberark-blue relative">
                  <tbody>
                    {collectionCollaboratorUserIds.length &&
                      collectionCollaboratorUserIds.map((collaborator_item) => (
                        <tr key={collaborator_item.id}>
                          <td className="flex">
                            <div className="bg-white flex justify-center items-center w-12 h-12">
                              <img
                                className="rounded-full w-8 h-8 hover:bg-light-gray"
                                src={
                                  collaborator_item?.avatar
                                    ? collaborator_item.avatar
                                    : avatarPlaceholder
                                }
                                alt="user-profile"
                              />
                            </div>
                            <div className="flex flex-col justify-center">
                              <div className="text-sm font-semi-bold">
                                {collaborator_item.username}
                              </div>
                              <div className="text-xs">
                                {collaborator_item.email}
                              </div>
                            </div>
                          </td>
                          <td className="">
                            {collaborator_item.id ===
                            collection.created_by.id ? (
                              <div className="flex items-center text-uberark-orange">
                                <BsPersonFillLock className="mr-1" /> Owner
                              </div>
                            ) : (
                              <div
                                className={`${
                                  collaborator_item.writePermision
                                    ? "text-uberark-orange"
                                    : "text-uberark-blue"
                                } flex items-center`}
                              >
                                <BsFillPeopleFill className="mr-1" />{" "}
                                Collaborator
                              </div>
                            )}
                          </td>
                          {user.id === collection.created_by.id ? (
                            <td className=" text-center">
                              {collaborator_item.id !==
                                collection.created_by.id && (
                                <>
                                  {loading && streamLoading ? (
                                    <LoadingSpinner />
                                  ) : (
                                    <div
                                      className="flex items-center"
                                      onMouseDown={() =>
                                        handleCollaboratorAccess(
                                          collaborator_item
                                        )
                                      }
                                    >
                                      {collaborator_item.writePermision ? (
                                        <div className="flex items-center text-uberark-orange cursor-pointer">
                                          <BsPersonDash className="mr-1" />{" "}
                                          Remove Write Access
                                        </div>
                                      ) : (
                                        <div className="flex items-center text-uberark-blue cursor-pointer">
                                          <BsPersonFillAdd className="mr-1" />{" "}
                                          Grant Write Access
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                            </td>
                          ) : null}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="w-full">
              <div className="flex flex-wrap mt-8">
                <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10">
                  <div className="w-full flex justify-center">
                    <img
                      src={no_content}
                      alt="nothing to show illustration"
                      style={{ height: "60px" }}
                    />
                  </div>
                  <div className="text-center relative p-5 text-[#757575] text-base flex items-center">
                    Nothing to show.{" "}
                    <FiInfo className="text-uberark-orange ml-1" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Collaborators;
