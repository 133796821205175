import React from "react";

import Button from "../Button";
import { MdOutlineCancel } from "react-icons/md";

const Slider = ({
  float = "right",
  className,
  title,
  visible,
  onClose,
  children,
}) => {
  if (!visible) {
    return null;
  }
  return (
    <div className="notification-show-wrap transition-all ease-in-out delay-100">
      <div className={`notification-show-${float} ${className || ""}`}>
        <div className="mt-18 h-full bg-white py-1 px-3">
          <div className="flex justify-between items-center px-3 mb-2">
            <div className="flex flex-col gap-3">
              <p className="font-semibold text-uberark-orange text-xl">
                {title}
              </p>
            </div>
            <div className="flex" onClick={() => onClose()}>
              <Button
                icon={<MdOutlineCancel />}
                bgHoverColor="light-gray"
                size="2xl"
                borderRadius="50%"
              />
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Slider;
