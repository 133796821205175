import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { MdOutlineCancel } from "react-icons/md";
import LoadingSpinner from "../../../../components/commons/LoadingSpinner";

import { contactActions } from "../../actions";

const DistributionContacts = ({ visible, onClose, distribution }) => {
  const dispatch = useDispatch();
  const { contacts } = useSelector((state) => state.contacts);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (distribution && distribution.contacts && distribution.contacts.length) {
      const distributionContacts = [];
      distribution.contacts.map((item) =>
        distributionContacts.push(item.contact)
      );
      setSelectedContacts(distributionContacts);
    } else {
      setSelectedContacts([]);
    }
  }, [distribution]);

  if (!visible) return null;

  const onSubmitCallback = () => {
    setLoading(false);
    onClose();
  };

  const onSelectContact = (contactId) => {
    if (contactId) {
      if (selectedContacts.includes(contactId)) {
        setSelectedContacts(
          selectedContacts.filter((item) => item !== contactId)
        );
      } else {
        setSelectedContacts([...selectedContacts, contactId]);
      }
      return true;
    }

    const selectedContactsUpdate = [];
    if (selectedContacts.length === contacts.length) {
      setSelectedContacts([]);
      return true;
    }
    contacts.map((item) => selectedContactsUpdate.push(item.id));
    setSelectedContacts(selectedContactsUpdate);
  };

  const onSubmit = () => {
    let payload = {
      contact: selectedContacts,
      distribution: distribution.id,
    };
    setLoading(true);
    dispatch(
      contactActions.handleAddContactDistributionCreation(
        payload,
        onSubmitCallback
      )
    );
  };

  console.log({ selectedContacts });

  return (
    <div className="pop_up_container fixed inset-0 bg-gray-50 bg-opacity-30 backdrop-blur-sm text-black flex justify-center items-center">
      <div>
        <div className="pop_up_content">
          <div className="bg-white flex flex-col justify-between text-center w-96 px-12 pb-10 rounded-2xl relative">
            <div className="flex justify-between items-center py-5 pb-2">
              <div>
                <span className="text-base text-uberark-orange font-bold">
                  Select Contacts
                </span>
              </div>
              <div className="right-2 cursor-pointer">
                <MdOutlineCancel
                  onClick={() => {
                    onClose();
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row  items-center pb-2 gap-2">
              <div className="flex flex-col w-full justify-between h-full">
                <div className="flex flex-col">
                  <span className="text-black text-left font-normal">
                    Select All Contacts
                  </span>
                </div>
              </div>
              <div className="flex justify-end">
                <input
                  type="checkbox"
                  className="checked:bg-#00ff00"
                  checked={selectedContacts.length === contacts.length}
                  onChange={(e) => onSelectContact()}
                />
              </div>
            </div>

            <div className="h-[250px] overflow-y-scroll bg-white pr-2 mb-2">
              {contacts && contacts.length
                ? contacts.map((contact) => (
                    <div key={contact.id}>
                      <div className="rounded-[30px] flex flex-row  items-center pb-2 gap-2">
                        <div className="flex">
                          <div className="w-12 h-12 relative flex justify-center items-center rounded-full bg-gradient-to-b from-[#ff4501] to-[#ffbd14] text-xl text-white uppercase">
                            {contact.name.charAt(0)}
                          </div>
                        </div>
                        <div className="flex flex-col justify-between h-full">
                          <div className="flex flex-col">
                            {" "}
                            <span className="text-black text-left font-normal truncate">
                              {contact.name}
                            </span>
                            <span className="text-xs text-black text-left font-semibold truncate">
                              {contact.email}
                            </span>
                          </div>
                        </div>
                        <div className="flex w-full justify-end">
                          <input
                            type="checkbox"
                            className="checked:bg-#00ff00"
                            checked={selectedContacts.includes(contact.id)}
                            onChange={(e) => onSelectContact(contact.id)}
                          />
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
            <div className="flex flex-row lg:justify-start justify-start text-uberark-blue gap-4  text-lg">
              <button
                className=":px-2 bg-[#000080] text-white  hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl"
                disabled={loading}
                onClick={() => onSubmit()}
              >
                {loading ? <LoadingSpinner /> : "Submit"}
              </button>
              <button
                className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
                to="/"
                onClick={() => onClose()}
                type="button"
                style={{ border: "0.24px solid #000080" }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistributionContacts;
