import React from "react";

const Video = ({ asset, size, className, handleSaveEvent }) => {
  return (
    <>
      {size === "asset__large" ? (
        <video
          controls
          id="video-lg"
          className={`${size || ""} ${className || ""} ${
            size === "asset__large"
              ? ""
              : "hover:scale-105 ease-in-out duration-300"
          }`}
          onPlay={() =>
            handleSaveEvent && handleSaveEvent({ eventName: "video_play" })
          }
          onPause={(e) => {
            if (e.target.currentTime !== e.target.duration) {
              handleSaveEvent && handleSaveEvent({ eventName: "video_pause" });
            }
          }}
          onEnded={() =>
            handleSaveEvent && handleSaveEvent({ eventName: "video_stop" })
          }
        >
          <source src={asset.file} type="video/mp4" />
          <source src={asset.file} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          controls={true}
          loop
          muted
          autoPlay
          className={`${size || ""} ${
            className || ""
          } hover:scale-105 ease-in-out duration-300`}
        >
          <source src={asset.file} type="video/mp4" />
          <source src={asset.file} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      )}
    </>
  );
};

export default Video;
