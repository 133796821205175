import {
  handleResponse,
  axiosInstanceNoAuth,
  axiosInstance,
  fileRequestConfig
} from '../../../app/utilities/helpers/'

const handleSignup = (data) => axiosInstanceNoAuth.post('auth/signup', data).then(handleResponse)

const handleLogin = (data) => axiosInstanceNoAuth.post('auth/login', data).then(handleResponse)

const handleGetLoggedInUser = () => axiosInstance.get(`user`).then(handleResponse)

const handleConfirm = (token) => axiosInstanceNoAuth.get(`activate/${token}`).then(handleResponse)

const handleGoogleAuthSignup = (data) =>
  axiosInstanceNoAuth.post(`auth/google/signup`, data).then(handleResponse)

const handleGoogleAuthLogin = (data) =>
  axiosInstanceNoAuth.post(`auth/google/login`, data).then(handleResponse)

const handleForgotPassword = (data) => axiosInstanceNoAuth.post(`forgot`, data).then(handleResponse)

const handleResetPassword = (token, data) =>
  axiosInstanceNoAuth.post(`reset/${token}`, data).then(handleResponse)

const handleUpdateUser = (payload) => {
  const data = new FormData()
  if (payload.avatar) {
    data.append('avatar', payload.avatar)
  }

  if (payload.password) {
    data.append('password', payload.password)
  }
  data.append('username', payload.username)

  return axiosInstance
    .put(`user`, data, fileRequestConfig)
    .then(handleResponse)
};

export const authService = {
  handleSignup,
  handleConfirm,
  handleLogin,
  handleGetLoggedInUser,
  handleGoogleAuthSignup,
  handleGoogleAuthLogin,
  handleForgotPassword,
  handleResetPassword,
  handleUpdateUser,
}
