import * as types from "./actionTypes";
import { request, success, failure } from "../../../app/utilities/helpers";
import { streamService } from "./_service";
import { collectionActions } from "../../Collections/actions";

import { toast } from "react-toastify";

const handleGetStream = (
  userId,
  filterType,
  search,
  resetSearch,
  pageNumber
) => {
  return (dispatch) => {
    dispatch(request(null, types.GET_STREAM_REQUEST));
    let queryUrl = `contents-shallow?${
      filterType
        ? `&user=${userId}`
        : search
        ? `&title=${search}`
        : `&page=${pageNumber ? pageNumber : 1}`
    }`;
    streamService.handleGetStream(queryUrl).then(
      (res) => {
        dispatch(success(res, types.GET_STREAM_SUCCESS));
        if (resetSearch) {
          setTimeout(function () {
            resetSearch();
          }, 300);
        }
      },
      (error) => {
        dispatch(failure(error, types.GET_STREAM_FAILED));
      }
    );
  };
};

const handleGetAllStream = (organizationId) => {
  return (dispatch) => {
    dispatch(request(null, types.GET_ALL_STREAM_REQUEST));
    let queryUrl = `contents?organization=${organizationId}`;
    streamService.handleGetStream(queryUrl).then(
      (res) => {
        dispatch(success(res, types.GET_ALL_STREAM_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.GET_ALL_STREAM_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleCreateStream = (data, onSubmitCallback) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_STREAM_REQUEST));
    streamService.handleCreateStream(data).then(
      (res) => {
        toast.success("Stream created successfully");
        dispatch(success(res, types.CREATE_STREAM_SUCCESS));
        setTimeout(function () {
          onSubmitCallback(res.id);
        }, 300);
      },
      (error) => {
        dispatch(failure(error, types.CREATE_STREAM_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleEditStream = (data, onSubmitCallback) => {
  return (dispatch) => {
    dispatch(request(null, types.EDIT_STREAM_REQUEST));
    streamService.handleEditStream(data).then(
      (res) => {
        toast.success("Stream edited successfully");
        dispatch(success(res, types.EDIT_STREAM_SUCCESS));
        setTimeout(function () {
          onSubmitCallback();
        }, 300);
      },
      (error) => {
        dispatch(failure(error, types.EDIT_STREAM_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleDeleteStream = (streamId, onSubmitCallback) => {
  return (dispatch) => {
    dispatch(request(null, types.DELETE_STREAM_REQUEST));
    streamService.handleDeleteStream(streamId).then(
      (res) => {
        toast.success("Stream deleted successfully");
        dispatch(success(streamId, types.DELETE_STREAM_SUCCESS));
        setTimeout(function () {
          onSubmitCallback();
        }, 1000);
      },
      (error) => {
        dispatch(failure(error, types.DELETE_STREAM_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleCreateGroup = (data, handleClose) => {
  return (dispatch, getState) => {
    dispatch(request(null, types.CREATE_GROUP_REQUEST));
    streamService.handleCreateGroup(data).then(
      (res) => {
        // handleClose && toast.success('Create group successful')
        dispatch(success(res, types.CREATE_GROUP_SUCCESS));
        setTimeout(() => {
          dispatch(handleGetStreamPreview(data.content, data.parent_group));
          handleClose && handleClose(false);
        }, 10);
      },
      (error) => {
        dispatch(failure(error, types.CREATE_GROUP_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleFollowUnfollowStream = (payload) => {
  return (dispatch, getState) => {
    dispatch(request(null, types.CREATE_FOLLOW_STREAM_REQUEST));
    streamService.handleFollowUnfollowStream(payload).then(
      (res) => {
        toast.success("Successfull");
        dispatch(success(payload, types.CREATE_FOLLOW_STREAM_SUCCESS));
        setTimeout(() => {
          dispatch(handleGetStreamPreview(payload.stream_id));
        }, 10);
      },
      (error) => {
        dispatch(failure(error, types.CREATE_FOLLOW_STREAM_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleCreateAsset = (data, handleClose, collectionPage) => {
  return (dispatch, getState) => {
    dispatch(request(null, types.CREATE_ASSET_REQUEST));
    streamService.handleCreateAsset(data).then(
      (res) => {
        toast.success("Asset created successfully");
        dispatch(success(res, types.CREATE_ASSET_SUCCESS));
        setTimeout(function () {
          if (collectionPage && data.collection) {
            dispatch(
              collectionActions.handleGetCollectioPreview(
                data.collection,
                data.section
              )
            );
          }
          handleClose();
        }, 300);
      },
      (error) => {
        dispatch(failure(error, types.CREATE_ASSET_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleDeleteGroup = (groupId) => {
  return (dispatch, getState) => {
    dispatch(request(null, types.DELETE_GROUP_REQUEST));
    streamService.handleDeleteGroup(groupId).then(
      (res) => {
        toast.success("Group deleted successfully");
        dispatch(success(groupId, types.DELETE_GROUP_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.DELETE_GROUP_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleDeleteAsset = (
  assetId,
  collectionPage,
  collectionId,
  sectionId
) => {
  return (dispatch) => {
    dispatch(request(null, types.DELETE_ASSET_REQUEST));
    streamService.handleDeleteAsset(assetId).then(
      (res) => {
        toast.success("Asset deleted successfully");
        setTimeout(function () {
          if (collectionPage && collectionId) {
            dispatch(
              collectionActions.handleGetCollectioPreview(
                collectionId,
                sectionId
              )
            );
          }
        }, 10);

        dispatch(request(assetId, types.DELETE_ASSET_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.DELETE_ASSET_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleEditGroup = (data, onSubmitCallback) => {
  return (dispatch, getState) => {
    dispatch(request(null, types.EDIT_GROUP_REQUEST));
    streamService.handleEditGroup(data).then(
      (res) => {
        toast.success("Group edited successfully");
        dispatch(success(res, types.EDIT_GROUP_SUCCESS));

        setTimeout(() => {
          dispatch(handleGetStreamPreview(data.stream, res.parent_group));
          onSubmitCallback();
        }, 10);
      },
      (error) => {
        dispatch(failure(error, types.EDIT_GROUP_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleGetFollowers = () => {
  return (dispatch, getState) => {
    // const organization = getState().auth.user.organization;
    dispatch(request(null, types.GET_STREAM_FOLLOWERS_REQUEST));
    streamService.handleGetFollowers().then(
      (res) => {
        dispatch(success(res, types.GET_STREAM_FOLLOWERS_SUCCESS));
        // setTimeout(() => { dispatch(handleGetStream(organization)) }, 10);
      },
      (error) => {
        dispatch(failure(error, types.GET_STREAM_FOLLOWERS_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleCopyAsset = (data, handleClose, collectionPage) => {
  return (dispatch) => {
    dispatch(request(null, types.COPY_ASSET_REQUEST));
    streamService.handleCopyAsset(data).then(
      (res) => {
        toast.success("Asset created successfully");
        dispatch(success(res, types.COPY_ASSET_SUCCESS));
        setTimeout(function () {
          if (collectionPage) {
            // from collections page
            dispatch(
              collectionActions.handleGetCollectioPreview(
                data[0].collection,
                data[0].section
              )
            );
          }
          handleClose();
        }, 300);
      },
      (error) => {
        dispatch(failure(error, types.COPY_ASSET_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleGetStreamPreview = (streamID, groupId) => {
  return (dispatch) => {
    dispatch(request(null, types.STREAM_PREVIEW_REQUEST));
    let queryUrl = `contents/${streamID}`;
    streamService.handleGetStream(queryUrl).then(
      (res) => {
        dispatch(
          success({ ...res, groupId: groupId }, types.STREAM_PREVIEW_SUCCESS)
        );
      },
      (error) => {
        dispatch(failure(error, types.STREAM_PREVIEW_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleAddCollaborators = (data, streamID) => {
  return (dispatch, getState) => {
    dispatch(request(null, types.ADD_COLLABORATOR_REQUEST));
    streamService.handleAddCollaborators(data).then(
      (res) => {
        toast.success("Collaborator added successfully");
        dispatch(success(true, types.ADD_COLLABORATOR_SUCCESS));
        setTimeout(() => {
          dispatch(handleGetStreamPreview(streamID));
        }, 200);
      },
      (error) => {
        dispatch(failure(error, types.ADD_COLLABORATOR_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.status === 409) {
          toast.warning(error?.response?.data?.message);
          setTimeout(() => {
            dispatch(handleGetStreamPreview(streamID));
          }, 200);
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleDeleteCollaborator = (collaboratorId, streamID) => {
  return (dispatch, getState) => {
    // const organizationId = getState().auth.user.organization
    dispatch(request(null, types.DELETE_COLLABORATOR_REQUEST));
    streamService.handleDeleteCollaborator(collaboratorId).then(
      (res) => {
        toast.success("Collaborator deleted successfully");
        dispatch(success(collaboratorId, types.DELETE_COLLABORATOR_SUCCESS));
        setTimeout(() => {
          dispatch(handleGetStreamPreview(streamID));
        }, 200);
      },
      (error) => {
        dispatch(failure(error, types.DELETE_COLLABORATOR_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleGetAllAssets = (pageNumber) => {
  return (dispatch) => {
    dispatch(request(null, types.GET_ALL_ASSETS_REQUEST));
    let queryUrl = `assets?page=${pageNumber ? pageNumber : 1}`;
    streamService.handleGetStream(queryUrl).then(
      (res) => {
        dispatch(success(res, types.GET_ALL_ASSETS_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.GET_ALL_ASSETS_FAILED));
      }
    );
  };
};

const handlePreviewAssets = (assetId) => {
  return (dispatch) => {
    dispatch(request(null, types.PREVIEW_ASSET_REQUEST));
    let queryUrl = `assets/${assetId}`;
    streamService.handleGetStream(queryUrl).then(
      (res) => {
        dispatch(success(res, types.PREVIEW_ASSET_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.PREVIEW_ASSET_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleMoveAsset = (data, handleClose, streamId) => {
  return (dispatch) => {
    dispatch(request(null, types.MOVE_ASSET_REQUEST));
    streamService.handleMoveAsset(data).then(
      (res) => {
        toast.success("Move successful!");
        dispatch(success(data, types.MOVE_ASSET_SUCCESS));
        setTimeout(function () {
          if (streamId !== false) {
            // dispatch(handleGetStreamPreview(streamId, openGroupId))
          } else {
            // from collections page
            dispatch(
              collectionActions.handleGetCollectioPreview(
                data[0].collection,
                data[0].section
              )
            );
          }
          handleClose();
        }, 300);
      },
      (error) => {
        dispatch(failure(error, types.MOVE_ASSET_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleEditAsset = (data, assetId, handleClose) => {
  return (dispatch) => {
    dispatch(request(null, types.EDIT_ASSET_REQUEST));
    streamService.handleEditAsset(data, assetId).then(
      (res) => {
        toast.success("The asset has been edited successful!");
        dispatch(success(data, types.EDIT_ASSET_SUCCESS));
        setTimeout(function () {
          handleClose();
        }, 300);
      },
      (error) => {
        dispatch(failure(error, types.EDIT_ASSET_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          return true;
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

export const streamActions = {
  handleGetStream,
  handleCreateStream,
  handleEditStream,
  handleDeleteStream,
  handleCreateGroup,
  handleCreateAsset,
  handleDeleteGroup,
  handleDeleteAsset,
  handleEditGroup,
  handleFollowUnfollowStream,
  handleGetFollowers,
  handleCopyAsset,
  handleGetStreamPreview,
  handleAddCollaborators,
  handleDeleteCollaborator,
  handleGetAllStream,
  handleGetAllAssets,
  handlePreviewAssets,
  handleMoveAsset,
  handleEditAsset,
};
