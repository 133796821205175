import React, { useEffect, useState } from "react";

import { Slider } from "../../../../components";

import {
  BsFillEyeFill,
  BsFillArrowDownCircleFill,
  BsClockFill,
  BsPeopleFill,
  BsPersonFillSlash,
} from "react-icons/bs";
import { RiShareFill } from "react-icons/ri";
import AssetCard from "./AssetCard";
import AssetGraph from "../../../Conversations/components/AssetGraph";

import { handleGetReadableTimeRange } from "../../../../app/utilities/helpers";

const AssetAnalyticsSlide = ({ assetAnalytics, setAssetAnalytics, asset }) => {
  const [assetType, setAssetType] = useState();
  const [isExternal, setIsExternal] = useState(true);
  const [statistics, setStatistics] = useState({
    views: 0,
    downloads: 0,
    shares: 0,
  });
  const [events, setEvents] = useState([]);

  const sameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  useEffect(() => {
    if (asset && asset.name) {
      const imageExtensions = ["jpeg", "jpg", "png"];
      const videoExtensions = ["mp4"];
      const iframeCompatibleDocuments = [
        "pdf",
        "ppt",
        "pptx",
        "doc",
        "docx",
        "xls",
        "xlxs",
      ];
      iframeCompatibleDocuments.includes(
        asset.name.split(".").at(-1).toLowerCase()
      ) && setAssetType("document");
      videoExtensions.includes(asset.name.split(".").at(-1).toLowerCase()) &&
        setAssetType("video");
      imageExtensions.includes(asset.name.split(".").at(-1).toLowerCase()) &&
        setAssetType("image");

      if (asset?.events) {
        let views = 0;
        let downloads = 0;
        let shares = 0;
        let prevViewTime = null;
        asset.events.forEach((event) => {
          if (event?.event_name === "view_start") {
            if (event?.contact && isExternal) {
              views++;
            }
            if (event?.user && !isExternal) {
              if (prevViewTime) {
                if (
                  !sameDay(new Date(prevViewTime), new Date(event.created_at))
                ) {
                  views++;
                }
              } else {
                views++;
              }
              prevViewTime = event.created_at;
            }
          } else if (event?.event_name === "download") {
            if (event?.contact && isExternal) {
              downloads++;
            }
            if (event?.user && !isExternal) {
              downloads++;
            }
          } else if (event?.event_name === "share") {
            if (event?.contact && isExternal) {
              shares++;
            }
            if (event?.user && !isExternal) {
              shares++;
            }
          }
        });

        setStatistics({
          views,
          downloads,
          shares,
        });

        setEvents((prev) =>
          asset.events.filter((event) =>
            isExternal ? event?.contact : event?.user
          )
        );
      }
    }
  }, [asset, isExternal]);

  return (
    <Slider
      direction="right"
      title="Asset Analytics"
      visible={assetAnalytics}
      onClose={() => setAssetAnalytics(false)}
    >
      <div key={asset.id} className="w-full">
        <div className="flex flex-col">
          <div className="">
            <div className="flex flex-row items-center">
              <div className="flex relative">
                <AssetCard
                  asset={asset}
                  size="asset__small"
                  className="md:w-20 md:h-20 w-11 h-11 conversation-asset"
                />
              </div>

              <div className="flex flex-col">
                <span className="text-[#000000] truncate font-normal pl-2">
                  {asset?.name}
                </span>
                <div className="flex items-center px-2 text-[10px] text-[#757575] font-normal italic">
                  Last Viewed
                  <div className="ml-1">{asset?.last_viewed || "Never"}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full mx-1 mt-5">
            <div className="flex w-full text-sm">
              <div className="flex justify-center items-center px-2 mt-2">
                AUDIENCE:
              </div>
              <div
                onMouseDown={() => setIsExternal(true)}
                className={`${
                  isExternal ? "text-uberark-orange" : "text-slate-600"
                } flex items-center px-2 mt-2`}
              >
                <BsPersonFillSlash className="mr-1" />
                External Users
              </div>
              <div
                onMouseDown={() => setIsExternal(false)}
                className={`${
                  !isExternal ? "text-uberark-orange" : "text-slate-600"
                } flex items-center px-2 mt-2`}
              >
                <BsPeopleFill className="mr-1" />
                My Team
              </div>
            </div>
          </div>

          <div className="flex text-slate-600 w-full mx-1 my-5">
            <div className="flex justify-between w-full">
              <div className="flex justify-center items-center px-2 mt-2">
                <BsFillEyeFill className="text-slate-600" />
                <div className="text-xs ml-1 text-slate-500">
                  {statistics?.views || 0}
                </div>
              </div>
              <div className="flex justify-center items-center px-2 mt-2">
                <RiShareFill className="text-slate-600" />
                <div className="text-xs ml-1 text-slate-500">
                  {statistics?.shares || 0}
                </div>
              </div>
              <div className="flex justify-center items-center px-2 mt-2">
                <BsFillArrowDownCircleFill className="text-slate-600" />
                <div className="text-xs ml-1 text-slate-500">
                  {statistics?.downloads || 0}
                </div>
              </div>
              <div className="flex justify-center items-center px-2 mt-2">
                <BsClockFill className="text-slate-600" />
                <div className="text-xs ml-1 text-slate-500">
                  {isExternal
                    ? asset?.time_spent?.external_time_spent
                      ? handleGetReadableTimeRange(
                          asset?.time_spent?.external_time_spent
                        )
                      : "0"
                    : asset?.time_spent?.team_time_spent
                    ? handleGetReadableTimeRange(
                        asset?.time_spent?.team_time_spent
                      )
                    : "0"}
                </div>
              </div>
            </div>
          </div>

          {assetType === "video" || assetType === "document" ? (
            <div
              className="flex w-full bg-[whitesmoke] rounded p-5"
              key={Math.random()}
            >
              <AssetGraph
                events={events}
                assetId={asset.id}
                graphCount={Math.ceil(asset.video_duration || asset.page_count)}
                assetType={assetType}
              />
            </div>
          ) : null}
        </div>
      </div>
    </Slider>
  );
};

export default AssetAnalyticsSlide;
