import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { MdOutlineCancel } from "react-icons/md";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FiInfo } from "react-icons/fi";
import ReactPaginate from "react-paginate";

import no_content from "../../../assets/images/no_content.svg";
import { collectionActions } from "../actions";
import { LoadingSpinner } from "../../../components";

const Templates = ({ visible, onClose, onSelectedTemplate }) => {
  const dispatch = useDispatch();
  const { collectionTemplate, loading, totalCollectionTemplatePage } =
    useSelector((state) => state.collection);
  const [selectedCollectionTemplate, setSelectedCollectionTemplate] = useState(
    {}
  );
  const [currentPage, setCurrentPage] = useState(0);

  const onSelectCollection = (item) => {
    setSelectedCollectionTemplate(item);
  };

  const onSelectTemplate = () => {
    onSelectedTemplate(selectedCollectionTemplate);
  };

  const changePage = ({ selected }) => {
    setCurrentPage(selected);
    return dispatch(
      collectionActions.handleGetCollectionTemplate(selected + 1, "True")
    );
  };

  if (!visible) return null;

  return (
    <div
      className="pop_up_container fixed inset-0 bg-gray-50 bg-opacity-30 backdrop-blur-sm text-black flex justify-center items-center "
      style={{ zIndex: 1111 }}
    >
      <div>
        <div className="pop_up_content">
          <div className="bg-white flex flex-col justify-between  text-center w-96 p-12 rounded-2xl relative">
            <span className="font-bold text-xl text-[#000080]">
              Template collections
            </span>
            <div className="absolute top-2 right-2 cursor-pointer">
              <MdOutlineCancel
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className="mt-5">
              {loading ? (
                <div className="w-full h-40 flex justify-center items-center">
                  <LoadingSpinner colorClassName="border-[#000080]" />
                </div>
              ) : (
                <>
                  {collectionTemplate && collectionTemplate.length ? (
                    collectionTemplate.map((item) => (
                      <>
                        <div
                          key={item.id}
                          className={`${
                            item.id === selectedCollectionTemplate.id
                              ? "border-1 border-uberark-orange"
                              : "border-white"
                          } flex flex-wrap relative rounded`}
                          onClick={() => onSelectCollection(item)}
                        >
                          <div className="flex justify-between border-1 border-white cursor-pointer relative w-full p-1 align-items-center rounded">
                            {item.banner && (
                              <div className="w-[155px] h-[60px] pr-2 w-1/4">
                                {!item.banner.includes(".mp4") ? (
                                  <img
                                    className="object-contain w-[80px] h-[60px] inline-block p-2 cursor-pointer opacity-80 rounded-lg"
                                    src={item.banner}
                                    alt="cover"
                                  />
                                ) : (
                                  <video
                                    className="object-contain w-[80px] h-[60px] inline-block p-2 cursor-pointer opacity-80 rounded-lg"
                                    controls={false}
                                    loop
                                    muted
                                    autoPlay
                                  >
                                    <source
                                      src={item.banner}
                                      type="video/mp4"
                                    />
                                    <source
                                      src={item.banner}
                                      type="video/ogg"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                )}
                              </div>
                            )}
                            <div className={`w-full flex flex-col relative`}>
                              <div
                                className={`justify-between flex items-center`}
                              >
                                <div
                                  className={`text-sm font-semibold truncate shrink`}
                                >
                                  {item?.name}
                                </div>
                                <BsFillCheckCircleFill
                                  className={`${
                                    item.id === selectedCollectionTemplate.id
                                      ? "text-uberark-orange"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {totalCollectionTemplatePage > 1 ? (
                          <div className="py-4 w-full flex justify-center items-center">
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={totalCollectionTemplatePage}
                              onPageChange={changePage}
                              marginPagesDisplayed={0}
                              pageRangeDisplayed={1}
                              forcePage={currentPage}
                              containerClassName={"paginationBttns"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"paginationDisabled"}
                              activeClassName={"paginationActive"}
                            />
                          </div>
                        ) : null}
                      </>
                    ))
                  ) : (
                    <div className="h-[155px] w-full mt-5 flex flex-col justify-center items-center">
                      <div className="w-full flex justify-center">
                        <img
                          src={no_content}
                          alt="nothing to show illustration"
                          style={{ height: "60px" }}
                        />
                      </div>
                      <div className="text-center relative p-5 text-[#757575] text-base flex items-center">
                        Nothing to show{" "}
                        <FiInfo className="text-uberark-orange ml-1" />
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="flex flex-row text-uberark-blue gap-4 text-lg mb-3 mt-5 justify-end">
                {collectionTemplate && collectionTemplate.length ? (
                  <button
                    className=":px-2 bg-[#000080] text-white  hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-3xl "
                    type="submit"
                    onClick={() => onSelectTemplate()}
                  >
                    Save
                  </button>
                ) : null}

                <button
                  onClick={() => onClose()}
                  className="text-uberark-blue hover:text-[#000080] hover:shadow-lg border-1 border-uberark-blue font-bold py-2 px-4 rounded-3xl"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Templates;
