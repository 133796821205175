import React from "react";

import ReactPaginate from "react-paginate";

import avatarPlaceholder from "../../../../assets/images/avatar-placeholder.png";
import { dateCalculation } from "../../../../app/utilities/helpers";

const ContactsSidebar = ({
  contacts,
  activeContact,
  setActiveContact,
  changePage,
  activePaginationPage,
  totalPaginationPages,
}) => {
  return (
    <div className="">
      <div className="h-full w-full flex flex-col lg:h-full md:h-[800px] border-r-1 border-l-0 border-b-0">
        {contacts && contacts.length
          ? contacts.map((contact) => (
              <div
                className="flex flex-row items-center gap-1 px-2 mb-1 cursor-pointer rounded-l"
                key={contact.id}
                onClick={() => setActiveContact(contact)}
                style={{
                  background: `${
                    activeContact &&
                    activeContact.id &&
                    activeContact.id.toString() === contact.id.toString()
                      ? "#fe450212"
                      : "whitesmoke"
                  }`,
                }}
              >
                <div className="flex">
                  <div className="bg-white flex justify-center items-center md:w-16 md:h-16 w-10 h-10 imgShadow rounded-full bg-gradient-to-b from-[#000080] to-[#6363ff] text-xl text-white uppercase">
                    {contact.picture ? (
                      <img
                        src={contact.picture}
                        alt={contact.name}
                        className={"md:w-16 md:h-16 w-10 h-10 rounded-full"}
                        loading="lazy"
                      />
                    ) : (
                      <img
                        src={avatarPlaceholder}
                        alt="avatar"
                        className={"md:w-16 md:h-16 w-10 h-10 rounded-full"}
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>
                <div className="flex flex-col md:p-3 h-full justify-center">
                  <div className="flex flex-col">
                    {" "}
                    <span className="text-xs text-[#757575] font-normal">
                      {contact.email}
                    </span>
                    <span className="text-base text-uberark-blue font-semibold">
                      {contact.name}
                    </span>
                  </div>

                  <div>
                    <span className="text-[10px] text-[#757575] font-normal">
                      Updated{" "}
                      {dateCalculation(
                        new Date(contact.updated_at),
                        new Date()
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>

      {totalPaginationPages > 1 ? (
        <div className="p-4">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={totalPaginationPages}
            onPageChange={changePage}
            forcePage={activePaginationPage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ContactsSidebar;
