import * as types from "./actionTypes";
import { request, success, failure } from "../../../../app/utilities/helpers/";
import { assetService } from "./_service";
import { toast } from "react-toastify";

const handleGetAsset = (assetId) => {
  return (dispatch) => {
    dispatch(request(null, types.GET_ASSET_REQUEST));
    assetService.handleGetAsset(assetId).then(
      (res) => {
        dispatch(success(res, types.GET_ASSET_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.GET_ASSET_FAILED));
      }
    );
  };
};

const handleSaveEvent = (data) => {
  return (dispatch) => {
    dispatch(request(data, types.SAVE_EVENT_REQUEST));
    assetService.handleSaveEvent(data).then(
      (res) => {
        dispatch(success(res, types.SAVE_EVENT_SUCCESS));
        if (data.eventName === "link_close") {
          dispatch({ type: "CLEAR_STORE" });
        }
      },
      (error) => {
        dispatch(failure(error, types.SAVE_EVENT_FAILED));
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleCreateSession = (data, callBack) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_SESSION_REQUEST));
    assetService.handleCreateSession(data).then(
      (res) => {
        dispatch(success(res, types.CREATE_SESSION_SUCCESS));
        setTimeout(function () {
          callBack && callBack(true);
        }, 500);
      },
      (error) => {
        callBack && callBack(false);
        dispatch(failure(error, types.CREATE_SESSION_FAILED));
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

export const assetActions = {
  handleGetAsset,
  handleSaveEvent,
  handleCreateSession,
};
