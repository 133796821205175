import React, { useState, useEffect } from "react";

import AssetCard from "../AssetCard";

import { BsXCircleFill } from "react-icons/bs";

const AssetAddSelected = ({
  selectedStream,
  selectedCollection,
  setSelectedStream,
  setSelectedCollection,
}) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles(() => [...selectedStream, ...selectedCollection]);
  }, [selectedStream, selectedCollection]);

  const handleRemoveFile = (file) => {
    if (file.parentType === "stream") {
      const filteredStream = selectedStream.filter(
        (item) => item.id !== file.id
      );
      return setSelectedStream(() => filteredStream);
    }

    const filteredCollection = selectedCollection.filter(
      (item) => item.id !== file.id
    );
    return setSelectedCollection(() => filteredCollection);
  };

  return (
    <div className="w-3/4 mb-2">
      <div className="text-uberark-blue pb-2 text-xs text-14 font-semibold">
        {files.length === 0 ? (
          <div className="w-full text-uberark-orange">
            Note:{" "}
            <span className="w-full font-normal">Select Assets above!</span>
          </div>
        ) : (
          `Selected Assets (${files.length}):`
        )}
      </div>
      <div className="flex flex-row flex-wrap">
        {files &&
          files.length > 0 &&
          files.map((file, index) => (
            <div
              className="flex flex-col m-1"
              key={file.id}
              onMouseDown={() => handleRemoveFile(file)}
            >
              <div className="file-card__delete animate-pulse w-[80px] h-[80px]">
                <BsXCircleFill />
              </div>
              <AssetCard
                asset={file}
                size="asset__medium"
                className="file-tree"
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default AssetAddSelected;
