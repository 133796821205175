import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { LoadingSpinner } from "../../../../../components";
import { streamActions } from "../../../../Streams/actions";
import {
  validateYouTubeUrl,
  validUrlRegex,
} from "../../../../../app/utilities/helpers";

const AssetAddUrl = ({ collection, stream, handleModalClose }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.collection);
  const initialValues = {
    url: "",
  };

  const FormSchema = Yup.object().shape({
    url: Yup.string()
      .required("Required")
      .matches(validUrlRegex, "URL is not valid"),
  });

  const handleCreateUrl = async (data) => {
    const payload = data;
    let collectionPage = false;

    if (collection && collection.id) {
      payload["collection"] = collection.id;
      if (collection?.sectionId) {
        payload["section"] = collection.sectionId;
      }
      collectionPage = true;
    } else if (stream && stream?.id) {
      payload["stream"] = stream.id;
      if (stream?.groupId) {
        payload["group"] = stream.groupId;
      }
    }

    // check if the url is a youtube url
    if (validateYouTubeUrl(payload.url)) {
      // payload.url = payload.url.replace("watch?v=", "embed/");
      payload.is_youtube_url = "True";
    } else {
      payload.is_youtube_url = "False";
    }
    dispatch(
      streamActions.handleCreateAsset(payload, handleModalClose, collectionPage)
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleCreateUrl}
      validationSchema={FormSchema}
    >
      {({ errors, touched }) => (
        <Form autoComplete="off" role="presentation">
          <div className="p-4 gap-4 text-gray-600">
            <div className="flex items-center">
              <Field
                placeholder="Enter URL here eg. https://www.google.com"
                className="p-2 outline-none w-full"
                name="url"
                id="url"
              />
            </div>
            {errors.url && touched.url && (
              <div className="text-red-600 pb-2">{errors.url}</div>
            )}

            <div className="flex flex-row items-center text-uberark-blue gap-4 pt-5 text-lg">
              <button
                className=":px-2 bg-[#000080] text-white hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl"
                type="submit"
                disabled={loading}
              >
                {loading ? <LoadingSpinner /> : "Save"}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AssetAddUrl;
