/* eslint-disable */
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import Logocombo from '../../assets/images/logos/logo-lg.svg';
import avatar from '../../assets/images/avatar.jpg';

import { authActions } from './actions'
import './LoginPage.css';


const ConfirmAccount = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, accountConfirmed } = useSelector((state) => state.auth);

  useEffect(() => {
    handleSubmit()
  }, []);

  const handleSubmit = (e) => {
    // make a confirm request to backend
    const url = window.location.href
    const token =  url.split('/').at(-2) + '/' + url.substr(url.lastIndexOf('/') + 1)
    return dispatch(authActions.handleConfirm(token));
  };


  return (
    <div
      className="grid md:grid-cols-2 grid-cols-1  fixed inset-0 w-full bg-white overflow-y-scroll p-4"
      style={{ margin: '0px!important' }}
    >
      <div className="grid grid-cols-1 px-4">
        <div className="login_logo">
          <div className="mb-4">
            <img src={Logocombo} alt="Logocombo.svg" className="" />
          </div>

          <div className="px-6 py-3 w-full grid">
            <span className="text-4xl font-bold text-uberark-blue mb-8">
              Account Confirmation
            </span>

            <div>
              <div className="floating-form flex flex-col gap-2">
                {loading ? (
                  <>Loading... </>
                ) : (
                  <>
                  {accountConfirmed
                    ? (
                      <div class="relative py-3 pl-4 pr-10 leading-normal text-green-700 bg-green-100 rounded-lg" role="alert">
                        <p>Account Confirmed Successful</p>
                        <span class="absolute inset-y-0 right-0 flex items-center mr-4">
                          <svg class="w-4 h-4 fill-current" role="button" viewBox="0 0 20 20"><path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" fill-rule="evenodd"></path></svg>
                        </span>
                      </div>
                    )
                    : (
                      <div class="relative py-3 pl-4 pr-10 leading-normal text-red-700 bg-red-100 rounded-lg" role="alert">
                        <p>Account Confirmation failed.</p>
                        <span class="absolute inset-y-0 right-0 flex items-center mr-4">
                          <svg class="w-4 h-4 fill-current" role="button" viewBox="0 0 20 20"><path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" fill-rule="evenodd"></path></svg>
                        </span>
                      </div>
                    )
                  }
                  <button
                    onClick={() => navigate('/login')}
                    className="text-white h-16 bg-uberark-blue w-full rounded"
                  >
                    Proceed to login
                  </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 greeting w-full">
        <div className="greetingArea flex flex-col gap-6">
          <span
            className="text-[#1a3447] md:text-6xl text-5xl font-bold"
            component="div"
          >
            Welcome to <br></br>Uberark
          </span>
          <span className="text-[#1A3447B2] font-medium text-base">
            Vitae ut tempore. Molestiae blanditiis quasi voluptas dolor ullam
            quasi voluptatem odio. Sed deserunt impedit assumenda non corrupti
            debitis consequatur temporibus.
          </span>

          <div className="flex flex-col md:flex-row gap-2">
            <div>
              <div className="mt-3 flex -space-x-2 overflow-hidden">
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={avatar}
                  alt="{avatar}"
                />
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={avatar}
                  alt="{avatar}"
                />
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={avatar}
                  alt="{avatar}"
                />
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={avatar}
                  alt="{avatar}"
                />
              </div>
              <div className="mt-3 text-sm font-medium">
                <a
                  href="##"
                  className="text-uberark-blue font-medium text-base"
                >
                  3k+ agents have joined us, now it’s your turn!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAccount;
