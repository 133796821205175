import React from "react";

import { BsYoutube } from "react-icons/bs";
import { BiLink } from "react-icons/bi";

const Url = ({ asset, size, className }) => {
  return (
    <div
      className={`${size || ""} ${className || ""} ${
        size === "asset__large"
          ? ""
          : "hover:scale-105 ease-in-out duration-300"
      } flex flex-col items-center justify-center bg-white p-2`}
      onMouseDown={(e) => {
        if (size === "asset__large") {
          return window.open(asset.url, "_blank");
        }
        return null;
      }}
    >
      <div className="">
        {asset.is_youtube_url ? (
          <BsYoutube className="text-red-700" />
        ) : (
          <BiLink className="text-uberark-orange" />
        )}
      </div>
      {size === "asset__large" && (
        <a
          href={asset.url}
          rel="noopener noreferrer"
          target="_blank"
          className="w-full flex flex-col text-center justify-center truncate mt-2"
        >
          <div className="truncate">{asset.url}</div>
        </a>
      )}
    </div>
  );
};

export default Url;
