import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BsXCircleFill } from "react-icons/bs";
import AssetCard from "../../commons/Asset/components/AssetCard";

import { streamActions } from "../../Streams/actions";
import DeleteConversation from "../../Conversations/components/DeleteConversation";

const FileCard = ({
  assetsData,
  deleteAssetToggle,
  setDeleteAssetToggle,
  onAssetClick,
  fileHover,
  collectionId,
  sectionId,
  cardLoader,
}) => {
  const { dltConfirm } = useSelector((state) => state.stream);
  const dispatch = useDispatch();
  const [dltPopup, setDltPopup] = useState(false);
  const [dltID, setDltID] = useState(null);
  const [dltType, setDltType] = useState(null);
  const [selectedDltItem, setSelectedDltItem] = useState(null);

  useEffect(() => {
    if (dltConfirm) {
      setDltPopup(false);
    }
  }, [dltConfirm]);

  const handleAssetSelect = (id, type, item) => {
    setSelectedDltItem(item);
    setDltID(id);
    setDltType(type);
    setDltPopup(true);
  };

  const handleAssetClick = (asset) => onAssetClick && onAssetClick(asset);

  const handleOnClose = () => setDltPopup(false);

  const onConfirmDlt = () => {
    if (dltID !== null) {
      setDeleteAssetToggle(false);
      return dispatch(
        streamActions.handleDeleteAsset(dltID, true, collectionId, sectionId)
      );
    }
  };

  return (
    <>
      {assetsData && assetsData.length
        ? assetsData.map((item, index) => (
            <div
              key={index}
              className={`${
                !cardLoader ? "file-loader mx-2 mt-2" : "mx-2 mt-2"
              }`}
            >
              {deleteAssetToggle && (
                <div
                  className="file-card__delete animate-pulse"
                  style={{ marginBottom: "-20px", marginLeft: "3px" }}
                >
                  <BsXCircleFill
                    onMouseDown={() =>
                      handleAssetSelect(item.id, "asset", item)
                    }
                  />
                </div>
              )}
              <div
                onMouseDown={() => handleAssetClick(item)}
                className={fileHover ? "file_img_wrap" : "pdf-viewer"}
              >
                {item.name.includes(".pdf") && fileHover ? (
                  <p onMouseDown={() => handleAssetClick(item)}></p>
                ) : null}
                <AssetCard
                  asset={item}
                  size="asset__medium"
                  withName={true}
                  onClick={() => handleAssetClick(item)}
                />
              </div>
            </div>
          ))
        : null}
      <DeleteConversation
        visible={dltPopup}
        dltTitle={dltType}
        onClose={handleOnClose}
        onDeleteEvent={onConfirmDlt}
        deleteDetails={selectedDltItem}
        dltLoader={dltConfirm}
      />
    </>
  );
};

export default FileCard;
