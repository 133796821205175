import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import ReactPaginate from "react-paginate";
import Distribution from "./Distribution";
import DistributionForm from "./Form";
import { AiOutlinePlus } from "react-icons/ai";
import { FiInfo } from "react-icons/fi";

import { contactActions } from "../../actions";
import no_content from "../../../../assets/images/no_content.svg";

const Distributions = ({ isAdd, isEdit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { distribution: distributions, totalDistributionPages } = useSelector(
    (state) => state.contacts
  );
  const [pageNumber, setPageNumber] = useState(1);

  const changePage = ({ page }) => {
    setPageNumber(page);
    dispatch(
      contactActions.handleDistributionListsFetch(
        `distributions-shallow?page=${page + 1}`
      )
    );
  };

  useEffect(() => {
    if (pageNumber) {
      dispatch(
        contactActions.handleDistributionListsFetch(
          `distributions-shallow?page=${pageNumber}`
        )
      );
    }
  }, [dispatch, pageNumber]);

  // const onDeleteDistrtribution = (d) => {
  //   setSelectedDistribution(d);
  //   setShowDeletePopUp(true);
  // };

  const handleFormSubmitCallback = () => {
    dispatch(
      contactActions.handleDistributionListsFetch(
        `distributions-shallow?page=${pageNumber}`
      )
    );
    navigate("/distributions");
  };

  return (
    <div className="relative" style={{ height: "55vh" }}>
      <div className="bg-transparent p-2 h-full w-full flex justify-start items-center flex-col overflow-y-scroll">
        {isAdd || isEdit ? (
          <DistributionForm
            isAdd={isAdd}
            handleFormSubmitCallback={handleFormSubmitCallback}
          />
        ) : (
          <>
            {distributions && distributions.length ? (
              distributions.map((distribution) => (
                <Distribution
                  distribution={distribution}
                  onDeleteDistribution={() => {}}
                  handleFormSubmitCallback={handleFormSubmitCallback}
                  key={distribution.id}
                />
              ))
            ) : (
              <div className="w-full">
                <div className="flex flex-wrap mt-8">
                  <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10">
                    <div className="w-full flex justify-center">
                      <img
                        src={no_content}
                        alt="nothing to show illustration"
                        style={{ height: "60px" }}
                        loading="lazy"
                      />
                    </div>
                    <div className="text-center relative p-5 text-[#757575] text-base flex items-center">
                      Nothing to show.{" "}
                      <FiInfo className="text-uberark-orange ml-1" />
                    </div>
                    <Link
                      className="flex items-center justify-center py-1 px-5 cursor-pointer opacity-80 hover:scale-105 ease-in-out duration-300 border rounded"
                      alt={`/distributions/add`}
                      to={`/distributions/add`}
                    >
                      <AiOutlinePlus className="text-[#FE4502] mr-1" /> Create
                      Distribution
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {totalDistributionPages && totalDistributionPages > 1 ? (
        <div className="p-4">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={totalDistributionPages}
            onPageChange={changePage}
            forcePage={0}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      ) : null}

      {/* <DeleteConversation
        dltTitle={"Distribution"}
        onClose={handleOnClose}
        deleteDetails={selectedDistribution}
        visible={showDeletePopUp}
      /> */}
    </div>
  );
};

export default Distributions;
