import React from "react";

const Image = ({ asset, size, className }) => {
  return (
    <img
      src={size === "asset__large" ? asset.file : asset.thumbnail || asset.file}
      alt={asset.name}
      className={`${size || ""} ${className || ""} ${
        size === "asset__large"
          ? ""
          : "hover:scale-105 ease-in-out duration-300"
      }`}
      loading="lazy"
    />
  );
};

export default Image;
