import * as types from './actionTypes'
import {
  request,
  success,
  failure
} from '../../../app/utilities/helpers/'
import { authService } from './_service'
import { toast } from 'react-toastify'

const handleConversationsListsFetch = (queryUrl) => {
  return (dispatch) => {
    dispatch(request(null, types.FETCH_CONVERSATIONS_LISTS_REQUEST))
    authService.handleConversationsListsFetch(queryUrl).then(
      (res) => {
        dispatch(success(res, types.FETCH_CONVERSATIONS_LISTS_SUCCESS))
      },
      (error) => {
        dispatch(failure(error, types.FETCH_CONVERSATIONS_LISTS_FAILED))
        if (error?.response?.status === 403 || error?.response?.status === 401) {
          return true
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message)
        } else {
          toast.error("Something went wrong. It's us not you")
        }
      }
    )
  }
}

const handleConversationsDelete = (id) => {
  return (dispatch) => {
    dispatch(request(null, types.DELETE_CONVERSATIONS_REQUEST))
    authService.handleConversationsDelete(id).then(
      (res) => {
        toast.success("Conversation deleted Successfuly")
        dispatch(success(res, types.DELETE_CONVERSATIONS_SUCCESS))
        dispatch(conversationsActions.handleConversationsListsFetch(`compose/send-shallow?page=1`));
      },
      (error) => {
        dispatch(failure(error, types.DELETE_CONVERSATIONS_FAILED))
        if (error?.response?.status === 403 || error?.response?.status === 401) {
          return true
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message)
        } else {
          toast.error("Something went wrong. It's us not you")
        }
      }
    )
  }
}

const handleConversationsAccess = (payload) => {
  return (dispatch) => {
    dispatch(request(null, types.UPDATE_CONVERSATIONS_REQUEST))
    authService.handleConversationsAccess(payload).then(
      (res) => {
        toast.success('User Access updated')
        dispatch(success(res, types.UPDATE_CONVERSATIONS_SUCCESS))
        dispatch(handleConversationsPreviewFetch(`compose/send/${payload.email_id}`));
      },
      (error) => {
        dispatch(failure(error, types.UPDATE_CONVERSATIONS_FAILED))
        if (error?.response?.status === 403 || error?.response?.status === 401) {
          return true
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message)
        } else {
          toast.error("Something went wrong. It's us not you")
        }
      }
    )
  }
}

const handleConversationsPreviewFetch = (queryUrl) => {
  return (dispatch) => {
    dispatch(request(null, types.PREVIEW_CONVERSATIONS_REQUEST))
    authService.handleConversationsListsFetch(queryUrl).then(
      (res) => {
        dispatch(success(res, types.PREVIEW_CONVERSATIONS_SUCCESS))
      },
      (error) => {
        dispatch(failure(error, types.PREVIEW_CONVERSATIONS_FAILED))
        if (error?.response?.status === 403 || error?.response?.status === 401) {
          return true
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message)
        } else {
          toast.error("Something went wrong. It's us not you")
        }
      }
    )
  }
}

export const conversationsActions = {
  handleConversationsListsFetch,
  handleConversationsDelete,
  handleConversationsAccess,
  handleConversationsPreviewFetch
}
