export const GET_ASSET_REQUEST = "GET_ASSET_REQUEST";
export const GET_ASSET_SUCCESS = "GET_ASSET_SUCCESS";
export const GET_ASSET_FAILED = "GET_ASSET_FAILED";

export const SAVE_EVENT_REQUEST = "SAVE_EVENT_REQUEST";
export const SAVE_EVENT_SUCCESS = "SAVE_EVENT_SUCCESS";
export const SAVE_EVENT_FAILED = "SAVE_EVENT_FAILED";

export const CREATE_SESSION_REQUEST = "CREATE_SESSION_REQUEST";
export const CREATE_SESSION_SUCCESS = "CREATE_SESSION_SUCCESS";
export const CREATE_SESSION_FAILED = "CREATE_SESSION_FAILED";
