import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import useCollapse from "react-collapsed";
import { AiOutlinePlus } from "react-icons/ai";
import { BsChevronDown, BsTrash } from "react-icons/bs";
import { FiInfo } from "react-icons/fi";
import { RiEditCircleFill } from "react-icons/ri";
import DistributionContacts from "./Contacts";
import { PopUpDelete } from "../../../../components";

import { contactActions } from "../../actions";
import no_content from "../../../../assets/images/no_content.svg";
import { dateCalculation } from "../../../../app/utilities/helpers";

const Distribution = ({
  distribution,
  onDeleteDistribution,
  handleFormSubmitCallback,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isExpanded, setExpanded] = useState(false);
  const [addContactPopup, setAddContactPopup] = useState(false);
  const [deleteDistributionModal, setDeleteDistributionModal] = useState(false);

  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const handleOnClose = () => {
    setAddContactPopup(false);
    handleFormSubmitCallback();
  };

  const toggleDeleteDistributionModal = () =>
    setDeleteDistributionModal(!deleteDistributionModal);

  const handleDeleteContact = (contact) => {
    dispatch(contactActions.handleContactDistributionDelete(contact.id));
    handleFormSubmitCallback();
  };

  return (
    <>
      <div className="flex flex-row items-center gap-1 px-2 bg-[whitesmoke] rounded mb-1 w-full">
        <div className="flex">
          <button>
            <div className="m-1 w-10 h-10 relative flex justify-center items-center rounded-full bg-gradient-to-b from-[#000080] to-[#6363ff] text-xl text-white uppercase">
              {distribution?.name.charAt(0)}
            </div>
          </button>
        </div>
        <div className="flex w-full justify-between h-full">
          <div className="flex flex-col justify-center">
            {" "}
            <span className="font-bold text-uberark-blue">
              {distribution?.name}
            </span>
            <div className="text-xs divide-x divide-[#0a0a0a] flex gap-2">
              <span>Updated</span>
              {dateCalculation(new Date(distribution?.updated_at), new Date())}
            </div>
          </div>

          <div className="text-[10px] text-[#757575] font-normal mt-2">
            <div className="flex flex-row h-inherit align-items-center">
              <button
                className="pr-2 text-center text-uberark-orange text-sm font-semibold"
                {...getToggleProps({
                  onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                })}
              >
                <span
                  className="rounded-[10px] px-2 text-sm"
                  style={{ border: "1px solid #000080" }}
                >
                  Contacts ({distribution.contacts.length})
                </span>
              </button>

              <button
                className="grid grid-flow-col items-center pr-2 pl-2 text-uberark-blue text-sm"
                onClick={() => {
                  navigate(`./edit/${distribution.id}`, {
                    state: { distributionData: distribution },
                  });
                }}
              >
                <RiEditCircleFill />
                <span className="hidden md:block">Update</span>
              </button>
              <button
                className="grid grid-flow-col items-center pr-2 pl-2 text-uberark-orange text-sm"
                onClick={() => toggleDeleteDistributionModal()}
              >
                <BsTrash />
                <span className="hidden md:block">Delete</span>
              </button>

              <button
                className="pr-2 pl-4 text-uberark-blue font-semibold animate-pulse"
                {...getToggleProps({
                  onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                })}
              >
                <span className="px-2 text-lg">
                  <BsChevronDown />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* distribution contacts accordion */}
      <section
        {...getCollapseProps()}
        className="p-4 gap-4 text-gray-600 mt-0 bg-transparent rounded-b border mb-2 w-full"
      >
        <div className="flex justify-between w-full">
          <div className="text-[#FE4502] font-semibold">Contacts</div>
          <button
            className="flex items-center justify-center px-5 cursor-pointer hover:scale-105 ease-in-out duration-300 border rounded"
            onClick={() => setAddContactPopup(true)}
          >
            <AiOutlinePlus className="text-[#FE4502] mr-1" /> Add Contact
          </button>
        </div>
        <div className="h-auto overflow-y-scroll scroll scroll-smooth scrollbar-hide flex flex-col">
          {distribution.contacts && distribution.contacts.length ? (
            distribution.contacts.map((contact) => {
              return (
                <div key={contact?.id}>
                  <div className="rounded-[30px] flex flex-row  items-center  gap-2 p-2 ">
                    <div className="flex">
                      <div className="m-1 mr-2 w-12 h-12 relative flex justify-center items-center rounded-full bg-gradient-to-b from-[#ff4501] to-[#ffbd14] text-xl text-white uppercase">
                        {contact.name.charAt(0)}
                      </div>
                    </div>
                    <div className="flex flex-col justify-between md:p-3 h-full">
                      <div className="flex flex-col">
                        {" "}
                        <span className="text-black font-normal">
                          {contact?.name}
                        </span>
                        <span className="text-xs text-black font-semibold">
                          {contact?.email}
                        </span>
                      </div>

                      <div className="text-xs">
                        <button
                          className="grid text-uberark-orange"
                          onClick={() => handleDeleteContact(contact)}
                        >
                          <span>Delete</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="w-full">
              <div className="flex flex-wrap mt-8">
                <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10">
                  <div className="w-full flex justify-center">
                    <img
                      src={no_content}
                      alt="nothing to show illustration"
                      style={{ height: "60px" }}
                      loading="lazy"
                    />
                  </div>
                  <div className="text-center relative p-5 text-[#757575] text-base flex items-center">
                    Nothing to show.{" "}
                    <FiInfo className="text-uberark-orange ml-1" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <DistributionContacts
        visible={addContactPopup}
        onClose={handleOnClose}
        distribution={distribution}
      />

      <PopUpDelete
        visible={deleteDistributionModal}
        onClose={toggleDeleteDistributionModal}
        itemName={distribution?.name || ""}
        deleteFn={onDeleteDistribution}
        actionName={"Delete"}
      />
    </>
  );
};

export default Distribution;
