import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "../../../../components/commons/LoadingSpinner";

import { contactActions } from "../../actions";

const DistributionForm = ({ isAdd, handleFormSubmitCallback }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading } = useSelector((state) => state.contacts);

  const initialValues = {
    name:
      location &&
      location.state &&
      location.state.distributionData &&
      location.state.distributionData.name
        ? location.state.distributionData.name
        : "",
  };

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Please enter distribution name"),
  });

  const handleSubmit = (data) => {
    const trimmedData = {};
    for (const key in data) {
      let value = data[key];
      value = value.trim();
      trimmedData[key] = value;
    }
    let payload = { ...trimmedData };
    return location &&
      location.state &&
      location.state.distributionData &&
      location.state.distributionData.id
      ? dispatch(
          contactActions.handleDistributionUpdate(
            payload,
            location.state.distributionData.id,
            handleFormSubmitCallback
          )
        )
      : dispatch(
          contactActions.handleDistributionCreation(
            payload,
            handleFormSubmitCallback
          )
        );
  };

  return (
    <div className="w-full p-5">
      <div className="text-[#000080] font-extrabold text-lg">
        {isAdd ? "Add" : "Edit"} Distribution
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={FormSchema}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form className="w-full">
            <div className="flex flex-col justify-between">
              <div className="flex flex-col items-start py-3">
                <div className="mb-1 w-full">
                  <label className="font-medium text-uberark-blue">Name:</label>{" "}
                  <Field
                    type="text"
                    value={values.name}
                    placeholder={
                      values.name.length
                        ? values.name
                        : "Please enter Distribution name"
                    }
                    change={(event) => {
                      setFieldValue("name", event.value);
                    }}
                    className="floating-input pl-2 text-lg text-uberark-blue pr-5 w-1/2"
                    id="name"
                  />
                  {errors.name && touched.name && (
                    <div
                      style={{ bottom: -25 }}
                      className="text-red-600 absolute"
                    >
                      {errors.name}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex gap-4">
                <button
                  className="bg-[#000080] hover:bg-white  text-white hover:text-[#000080] drop-shadow-lg font-bold py-2 px-4 rounded-3xl"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <LoadingSpinner /> : "Submit"}
                </button>
                <button
                  onClick={handleFormSubmitCallback}
                  type="button"
                  className="bg-uberark-orange hover:bg-white text-white hover:text-[#FE4502]  hover:drop-shadow-lg font-bold py-2 px-4 rounded-3xl"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DistributionForm;
