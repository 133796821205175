import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import ReactPaginate from "react-paginate";
import { BiSearch } from "react-icons/bi";
import { FiUserPlus, FiXCircle, FiChevronRight } from "react-icons/fi";
import { FiInfo } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import InviteUser from "./inviteUser";
import LoadingSpinner from "../../components/commons/LoadingSpinner";

import avatarPlaceholder from "../../assets/images/avatar-placeholder.png";
import { settingsActions } from "./actions";
import { authActions } from "../Auth/actions";

const Users = () => {
  const dispatch = useDispatch();
  const { organization, loading, users, userListCount, deleteConfirmation } =
    useSelector((state) => state.settings);
  const {
    user,
    user: { organization: userOrganization },
  } = useSelector((state) => state.auth);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(userListCount);
  const [inviteUserShow, setInviteUserShow] = useState(false);
  const [organizationData, setOrganizationData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (organization) {
      setOrganizationData(organization);
    }
  }, [organization]);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    setCurrentPage(selected);
  };

  const onInviteuser = () => {
    setInviteUserShow(!inviteUserShow);
    fetchAllUsers();
  };

  const fetchAllUsers = () => {
    if (
      (organization && organization.id) ||
      (userOrganization && userOrganization !== "None")
    ) {
      let orgId =
        organization && organization.id ? organization.id : userOrganization;
      let validEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      let queryUrl = `${orgId}`;
      if (searchTerm.length) {
        if (searchTerm.match(validEmail)) {
          queryUrl = `${queryUrl}?email=${searchTerm}`;
        } else {
          queryUrl = `${queryUrl}?username=${searchTerm}`;
        }
      }
      dispatch(settingsActions.handleUserListsFetch(queryUrl));
    }
  };

  useEffect(() => {
    if (organization.id === undefined) {
      if (userOrganization && userOrganization !== "None") {
        dispatch(
          settingsActions.handleAccountManagementFetch(userOrganization)
        );
      }
    }
    dispatch(authActions.handleGetLoggedInUser());
  }, []); // eslint-disable-line

  useEffect(() => {
    fetchAllUsers();
  }, [organization]); // eslint-disable-line

  useEffect(() => {
    if (deleteConfirmation) {
      fetchAllUsers();
    }
  }, [deleteConfirmation]); // eslint-disable-line

  const onDeleteUser = (user) => {
    dispatch(settingsActions.handleDeleteUser(user.id));
  };

  const onSearch = () => {
    fetchAllUsers();
  };

  return (
    <div className="bg-white rounded-xl md:m-4 md:p-14 p-2 w-full flex  flex-col gap-14">
      <div className="font-bold text-uberark-orange text-xl space-between-flex">
        <span>{inviteUserShow ? "Invite User" : "User Management"}</span>
        <TooltipComponent content={"Invite User"} position="BottomCenter">
          <button
            type="button"
            onClick={() => onInviteuser()}
            style={{ color: "#008" }}
            className="relative text-xl rounded-full p-3 hover:bg-light-gray"
          >
            {inviteUserShow ? (
              <FiXCircle />
            ) : (
              <div className="flex items-center">
                <FiUserPlus />
              </div>
            )}
          </button>
        </TooltipComponent>
      </div>

      {inviteUserShow ? (
        <InviteUser onCloseInviteForm={() => onInviteuser()} />
      ) : (
        <React.Fragment>
          <div>
            <label className="relative flex w-full">
              <span className="sr-only">Search</span>
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <BiSearch className="w-5 h-5" />
              </span>
              <input
                className="placeholder:text-slate-400 flex bg-white w-full h-12 rounded-2xl py-2 pl-9 pr-3 border focus:outline-none sm:text-sm "
                placeholder="Search"
                type="text"
                name="search"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                <FiChevronRight
                  style={{ cursor: "pointer" }}
                  className="w-5 h-5"
                  onClick={() => onSearch()}
                />
              </span>
            </label>
          </div>

          <div
            className="felx w-full items-center justify-center"
            style={{ overflow: window.innerWidth < 768 ? "scroll" : "none" }}
          >
            <table className="border-collapse w-full text-uberark-blue">
              <thead>
                <tr>
                  <th className="">Name</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {users && users.length ? (
                  users.map((u) => {
                    return (
                      <tr
                        key={u.id}
                        className="border-[0.25px] border-y-uberark-blue"
                      >
                        <td className="flex border-0">
                          <div className="bg-white flex justify-center items-center w-12 h-12">
                            <img
                              className="rounded-full w-8 h-8 hover:bg-light-gray"
                              src={u?.avatar ? u.avatar : avatarPlaceholder}
                              alt="user-profile"
                            />
                          </div>
                          <div className="flex flex-col justify-center">
                            <div className="text-sm font-semi-bold">
                              {u.username}
                            </div>
                            <div className="text-xs">{u.email}</div>
                          </div>
                        </td>
                        <td className="text-center border-0">
                          {u.is_active ? "Active" : "Inactive"}
                        </td>
                        <td className="border-0">
                          {organizationData?.name &&
                          user?.id &&
                          organizationData?.created_by ===
                            user?.id.toString() ? (
                            <button
                              className="font-bold flex items-center"
                              type="button"
                              onClick={() => onDeleteUser(u)}
                              disabled={loading}
                            >
                              {loading ? (
                                <LoadingSpinner />
                              ) : (
                                <>
                                  <BsTrash className="mr-1" /> Delete
                                </>
                              )}
                            </button>
                          ) : (
                            <button
                              className="font-bold flex items-center cursor-not-allowed"
                              type="button"
                              disabled={true}
                            >
                              <BsTrash className="mr-1" /> Delete
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <td
                    colspan="3"
                    className="text-center w-full"
                    style={{ border: "none" }}
                  >
                    <div className="text-center flex items-center justify-center w-full">
                      No Users found
                      <FiInfo className="text-uberark-orange ml-1" />
                    </div>
                  </td>
                )}
              </tbody>
            </table>
          </div>

          {users && users.length ? (
            <div className="py-4">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageNumber}
                onPageChange={changePage}
                marginPagesDisplayed={0}
                forcePage={currentPage}
                pageRangeDisplayed={5}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          ) : null}
        </React.Fragment>
      )}
    </div>
  );
};

export default Users;
