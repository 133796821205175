import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  HtmlEditor,
  Inject,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  Link,
} from "@syncfusion/ej2-react-richtexteditor";

import { BiSearch } from "react-icons/bi";
import { FiXCircle } from "react-icons/fi";
import { FiInfo } from "react-icons/fi";
import {
  BsReverseLayoutTextWindowReverse,
  BsCardHeading,
  BsTrash,
  BsFillFileEarmarkPlusFill,
} from "react-icons/bs";
import { RiEditCircleFill } from "react-icons/ri";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "../../components/commons/LoadingSpinner";
import {
  onDateDDMMMYYYY,
  RichTextEditorToolbar,
} from "../../app/utilities/helpers";
import { settingsActions } from "./actions";
import "./settings.css";

const NDA = () => {
  const dispatch = useDispatch();
  const { nda, loading, ndaCreation, ndaDeleteConfirmation } = useSelector(
    (state) => state.settings
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [showNDAForm, setShowNDAForm] = useState(false);
  const [formEdit, setFormEdit] = useState(null);
  const [initialValues, setInitialValues] = useState({
    name: "",
    title: "",
    description: "",
    public: "true",
  });
  const [noData, setNoData] = useState(true);

  const profilesPerPage = 4;
  const pagesVisited = pageNumber * profilesPerPage;

  const displayProfiles = nda
    ?.filter(
      (val) =>
        searchTerm === "" ||
        val.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        val.title.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        val.public.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    )
    .slice(pagesVisited, pagesVisited + profilesPerPage)
    .map((profile) => {
      return (
        <tr key={profile.id}>
          <td className="text-left">{profile.name}</td>
          <td className="text-center">{profile.title} </td>
          <td className="text-right">{onDateDDMMMYYYY(profile.created_at)}</td>
          <td className="text-center text-uberark-orange text-xs font-semibold">
            <span
              className="rounded-[10px] px-2"
              style={{ border: "1px solid #000080" }}
            >
              {profile.public ? "Public" : "Private"}
            </span>
          </td>
          <td>
            <div className="flex justify-center">
              {loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <div
                    className="flex items-center mx-1"
                    onMouseDown={() => onEdit(profile)}
                  >
                    <RiEditCircleFill /> Edit
                  </div>
                  <div
                    className="flex items-center mx-1"
                    onMouseDown={() => onDelete(profile)}
                  >
                    <BsTrash /> Delete
                  </div>
                </>
              )}
            </div>
          </td>
        </tr>
      );
    });

  const pageCount =
    nda && nda.length > 0 ? Math.ceil(nda.length / profilesPerPage) : 0;

  const changePage = ({ selected }) => setPageNumber(selected);

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Please enter template"),
    title: Yup.string().required("Please enter title"),
    description: Yup.string().required("Please enter description"),
    public: Yup.string(),
  });

  useEffect(() => {
    if (
      ((!nda || nda.length === 0) && noData) ||
      ndaCreation ||
      ndaDeleteConfirmation
    ) {
      dispatch(settingsActions.handleNDAListsFetch("ndas"));
      setShowNDAForm(false);
      setNoData(false);
    }
  }, [dispatch, nda, ndaCreation, ndaDeleteConfirmation, noData]);

  const onOpenCloseNDAForm = () => {
    setFormEdit(null);
    setInitialValues({
      name: "",
      title: "",
      description: "",
      public: "true",
    });
    setShowNDAForm(!showNDAForm);
  };

  const handleCreateNDA = (data) => {
    const trimmedData = {};
    for (const key in data) {
      let value = data[key];
      value = value.trim();
      trimmedData[key] = value;
    }
    const payload = {
      ...trimmedData,
    };
    return formEdit
      ? dispatch(settingsActions.handleNDAUpdate(payload, formEdit))
      : dispatch(settingsActions.handleNDACreation(payload));
  };

  const onEdit = (ndaItem) => {
    setInitialValues({
      name: ndaItem.name,
      title: ndaItem.title,
      description: ndaItem.description,
      public: String(ndaItem.public),
    });
    setFormEdit(ndaItem.id);
    setShowNDAForm(true);
  };

  const onDelete = (ndaItem) => {
    return dispatch(settingsActions.handleNDADelete(ndaItem.id));
  };

  return (
    <div className="bg-white rounded-xl md:m-4 md:p-14 p-2 w-full flex flex-col">
      <div className="font-bold text-uberark-orange text-xl my-4 space-between-flex">
        <span>
          {showNDAForm
            ? formEdit
              ? "Edit NDA"
              : "Create NDA"
            : "NDA Templates"}
        </span>
        <TooltipComponent
          content={formEdit ? "Edit NDA" : "Create NDA"}
          position="BottomCenter"
        >
          <button
            type="button"
            onClick={() => onOpenCloseNDAForm()}
            style={{ color: "#008" }}
            className="relative text-base rounded-full p-3 hover:bg-light-gray flex items-center"
          >
            {showNDAForm ? (
              <FiXCircle />
            ) : (
              <>
                <BsFillFileEarmarkPlusFill />
              </>
            )}
          </button>
        </TooltipComponent>
      </div>
      {!showNDAForm ? (
        <React.Fragment>
          <div className="my-4">
            <label className="relative flex w-full">
              <span className="sr-only">Search</span>
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <BiSearch className="w-5 h-5" />
              </span>
              <input
                className="placeholder:text-slate-400 flex bg-white w-full h-12 rounded-2xl py-2 pl-9 pr-3 border focus:outline-none  sm:text-sm "
                placeholder="Search"
                type="text"
                name="search"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </label>
          </div>
          <div
            style={{ overflow: window.innerWidth < 768 ? "scroll" : "none" }}
          >
            <table className="table-auto border-collapse w-full text-uberark-blue mb-10 my-4">
              <thead>
                <tr>
                  <th className="text-left">Name</th>
                  <th className="text-center">Title</th>
                  <th className="text-right">Date Created</th>
                  <th className="text-right">Status</th>
                  <th className="text-right">Action</th>
                </tr>
              </thead>

              {nda.length ? (
                <>{!loading ? <tbody>{displayProfiles}</tbody> : null}</>
              ) : (
                <tbody>
                  <td
                    colspan="5"
                    className="text-center w-full"
                    style={{ border: "none" }}
                  >
                    <div className="text-center flex items-center justify-center w-full">
                      No Templates found
                      <FiInfo className="text-uberark-orange ml-1" />
                    </div>
                  </td>
                </tbody>
              )}
            </table>
            {pageCount > 1 ? (
              <div className="py-4">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  marginPagesDisplayed={0}
                  pageRangeDisplayed={1}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            ) : null}
          </div>
        </React.Fragment>
      ) : (
        <div className="flex flex-col gap-8 mt-6">
          <div className="flex flex-col gap-8">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleCreateNDA}
              validationSchema={FormSchema}
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form>
                  <div className="mb-1">
                    <label className="font-medium text-uberark-blue">
                      Template Name <span className="text-red-500">*</span>:
                    </label>
                  </div>
                  <div className="flex lg:flex-row flex-col justify-between items-center gap-2 flex">
                    <div className="mb-1">
                      <Field
                        placeholder="Enter NDA template"
                        className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                        name="name"
                        id="name"
                      />
                      {errors.name && touched.name && (
                        <div className="text-red-600 pb-2">{errors.name}</div>
                      )}
                      <span className="absolute top-[10px] right-2">
                        <BsReverseLayoutTextWindowReverse
                          size={20}
                          className="text-[#666666]"
                        />
                      </span>
                    </div>

                    <div className="flex justify-between items-center w-3/5 p-8">
                      <label className="text-[12px] text-[#404040] flex items-center gap-2">
                        <Field type="radio" name="public" value="true" />
                        <span> Set As Public</span>
                      </label>
                      <label className="text-[12px] text-[#404040] flex items-center gap-2">
                        <Field type="radio" name="public" value="false" />
                        <span>Set As Private</span>
                      </label>
                    </div>
                  </div>

                  <div className="mb-1">
                    <label className="font-medium text-uberark-blue">
                      NDA Title <span className="text-red-500">*</span>:
                    </label>
                  </div>
                  <div className="mb-1">
                    <Field
                      placeholder="Enter NDA title"
                      className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                      name="title"
                      id="title"
                    />
                    {errors.title && touched.title && (
                      <div className="text-red-600 pb-2">{errors.title}</div>
                    )}
                    <span className="absolute top-[10px] right-2">
                      <BsCardHeading size={20} className="text-[#666666]" />
                    </span>
                  </div>

                  <div className="pb-2">
                    <div className="mb-1">
                      <label className="font-medium text-uberark-blue">
                        Description <span className="text-red-500">*</span>:
                      </label>
                    </div>
                    <RichTextEditorComponent
                      change={(event) => {
                        setFieldValue("description", event.value);
                      }}
                      value={values.description}
                      toolbarSettings={RichTextEditorToolbar}
                      className="rounded"
                    >
                      <Inject
                        services={[HtmlEditor, Link, Toolbar, QuickToolbar]}
                      />
                    </RichTextEditorComponent>
                    {errors.description && touched.description && (
                      <div className="text-red-600 pb-2">
                        {errors.description}
                      </div>
                    )}
                  </div>
                  <div className="flex lg:justify-end flex-row text-uberark-blue gap-4 text-lg mb-3 mt-3">
                    <button
                      className=":px-2 bg-[#000080] text-white hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl "
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? <LoadingSpinner /> : "Save"}
                    </button>
                    <button
                      className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
                      style={{ border: "0.24px solid #000080" }}
                      type="button"
                      onClick={() => onOpenCloseNDAForm()}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default NDA;
