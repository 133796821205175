import * as types from "../actions/actionTypes";

const initialState = {
  loading: false,
  asset: {},
  session: {},
  errors: {},
};

const assetReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ASSET_REQUEST:
      return {
        ...state,
        loading: true,
        session: {},
        errors: {},
      };
    case types.GET_ASSET_SUCCESS:
      return {
        ...state,
        asset: action.data,
        loading: false,
      };
    case types.GET_ASSET_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };

    case types.CREATE_SESSION_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        session: {},
        message: "",
      };
    case types.CREATE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        session: action.data,
      };
    case types.CREATE_SESSION_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default assetReducer;
