import { BsArrowUpCircleFill } from "react-icons/bs";
import { onDateDDMMMYYYYHHMM } from "../../../app/utilities/helpers";

// comment: this is a component that is used in the Conversations page
const DigitalPrint = ({ sessionDatas, hideSession }) => {
  return (
    <div>
      {sessionDatas.length ? (
        <table className="table-auto border-collapse w-full text-[10px] w-3/4 rounded-lg bg-slate-100">
          <tbody>
            {sessionDatas.map((item) => (
              <tr key={item.id} className="border-none">
                <td className="border-none mt-5">
                  {item.browser ? item.browser : "-"}
                  {item.os ? " on " + item.os : "-"}
                </td>
                <td className="border-none mt-5">
                  {onDateDDMMMYYYYHHMM(item?.updated_at)}
                </td>
                <td className="border-none mt-5">{item.ip ? item.ip : "-"}</td>
                <td className="border-none mt-5">
                  {item.country_name ? item.country_name + "," : "-"}{" "}
                  {item.country_code ? item.country_code : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
      <div className="flex justify-end text-xs text-slate-600 pr-3 mt-2 w-full cursor-pointer">
        <div className="flex items-center" onClick={() => hideSession()}>
          <BsArrowUpCircleFill className="mr-1" />
          Close
        </div>
      </div>
    </div>
  );
};
export default DigitalPrint;
