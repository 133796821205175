import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// import { Switch } from '@headlessui/react';
import LoadingSpinner from "../../components/commons/LoadingSpinner";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FiEdit } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import { toast } from "react-toastify";
import nothing from "../../assets/images/nothing.svg";
import { BsFillBuildingsFill } from "react-icons/bs";
import { CgWebsite } from "react-icons/cg";

import { settingsActions } from "./actions";
import { authActions } from "../Auth/actions";
import CustomFields from "./components/CustomFields";
import Share from "./components/Share";
import "./settings.css";

const Account = ({ slide }) => {
  const dispatch = useDispatch();
  const { organization, loading } = useSelector((state) => state.settings);
  const {
    user,
    user: { organization: userOrganization },
  } = useSelector((state) => state.auth);

  // const [enabled, setEnabled] = useState(false);
  const [organizationData, setOrganizationData] = useState({});
  const supportedFileExt = ["image/jpeg", "image/jpg", "image/png"];

  useEffect(() => {
    if (organization || userOrganization) {
      setOrganizationData(organization ? organization : null);
    }
  }, [organization, userOrganization]);

  useEffect(() => {
    if (userOrganization && userOrganization !== "None") {
      dispatch(settingsActions.handleAccountManagementFetch(userOrganization));
    }
  }, []); // eslint-disable-line

  const initialValues = {
    name: organizationData?.name ? organizationData.name : "",
    url: organizationData?.url ? organizationData.url : "",
    logo: organizationData?.logo ? organizationData.logo : "",
  };

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your company name"),
    url: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .required("Please enter you company url"),
    logo: Yup.lazy((value) => {
      switch (typeof value) {
        case "string":
          return Yup.string().url("Unsupported File Format");
        default:
          return Yup.mixed()
            .required("Logo File is required")
            .test("fileType", "Unsupportted file format ", (value) =>
              supportedFileExt.includes(value?.type)
            );
      }
    }),
  });

  const handleSubmit = (data) => {
    const payload = new FormData();
    payload.append("name", data.name.trim());
    payload.append("url", data.url.trim());

    if (typeof data.logo !== "string") {
      payload.append("logo", data.logo);
    }

    if (organizationData.id) {
      return dispatch(
        settingsActions.handleAccountManagementUpdate(
          payload,
          organizationData.id
        )
      );
    } else {
      return dispatch(settingsActions.handleAccountManagementCreation(payload));
    }
  };

  const handleLogout = () => dispatch(authActions.handleLogout());

  return (
    <div className={`w-full flex flex-col`}>
      <div>
        <div className={`flex flex-col gap-4 acemailemailemailemailc-mng-form`}>
          <div
            className={`${
              !slide ? "md:m-4 md:p-14" : "md:m-2 md:p-5"
            } bg-white rounded-xl p-2`}
          >
            <div className="font-bold text-uberark-orange text-xl">
              {" "}
              Organization
            </div>
            {user?.id &&
              organizationData?.name &&
              organizationData?.created_by &&
              organizationData.created_by.toString() !== user.id.toString() && (
                <div
                  className="relative py-2 pl-4 pr-5 text-sm leading-normal text-red-700 bg-red-100 rounded my-2"
                  role="alert"
                >
                  <p>You Don't have permision to edit organization</p>
                </div>
              )}
            {(!organization ||
              organization === "None" ||
              !userOrganization) && (
              <div
                className="relative py-2 pl-4 pr-5 text-sm leading-normal text-blue-700 bg-blue-100 rounded my-2"
                role="alert"
              >
                <p>Please create an organization to proceed</p>
              </div>
            )}
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={FormSchema}
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form>
                  <div className="mb-4 flex justify-center">
                    <label
                      className={`w-[166px] h-[166px] relative bg-transparent flex flex-row items-center justify-center rounded hover:bg-white drop-shadow-xl`}
                      style={{ border: "0.24px solid #000080" }}
                    >
                      {values.logo ? (
                        <img
                          className="w-full h-full cursor-pointer opacity-80 hover:scale-10 ease-in-out duration-300 rounded object-contain"
                          style={{
                            filter: "brightness(0.5)",
                          }}
                          src={
                            !values.logo
                              ? nothing
                              : typeof values.logo !== "string"
                              ? URL.createObjectURL(values.logo)
                              : values.logo
                          }
                          alt="cover"
                        />
                      ) : null}

                      <span
                        className="rounded bg-white text-sm flex items-center text-slate-600 p-2 bottom-[65px]"
                        style={{ position: "absolute" }}
                      >
                        {values.logo ? (
                          <>
                            <FiEdit className="hover:scale-105 ease-in-out duration-300 mr-1" />{" "}
                            Edit Logo
                          </>
                        ) : (
                          <>
                            <AiOutlinePlus className="hover:scale-105 ease-in-out duration-300 mr-1" />{" "}
                            Add Logo
                          </>
                        )}
                      </span>

                      <input
                        className="floating-input h-14 pl-2 text-lg text-uberark-blue hidden"
                        name="logo"
                        id="logo"
                        type="file"
                        onChange={(event) => {
                          if (
                            supportedFileExt.includes(
                              event.currentTarget.files[0]["type"]
                            )
                          ) {
                            setFieldValue("logo", event.currentTarget.files[0]);
                          } else {
                            const selectedFile = event.currentTarget.files[0];
                            const extension = selectedFile.name.split(".")[1];
                            toast.error(
                              `.${extension} file format is not supported!`
                            );
                          }
                        }}
                        disabled={
                          user?.id &&
                          organizationData?.name &&
                          organizationData?.created_by &&
                          organizationData?.created_by.toString() !==
                            user.id.toString()
                        }
                      />
                    </label>
                    {errors.logo && touched.logo && (
                      <div className="text-red-600 pb-2">{errors.logo}</div>
                    )}
                  </div>

                  <div className="mb-1">
                    <label className="font-medium text-uberark-blue">
                      Company Name <span className="text-red-500">*</span>:
                    </label>
                  </div>
                  <div className="acc-mng-form-input">
                    <Field
                      className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                      name="name"
                      id="name"
                      type="text"
                      placeholder="Company Name"
                      disabled={
                        user?.id &&
                        organizationData?.name &&
                        organizationData?.created_by &&
                        organizationData?.created_by.toString() !==
                          user.id.toString()
                      }
                    />
                    {errors.name && touched.name && (
                      <div className="text-red-600">{errors.name}</div>
                    )}
                    <span className="absolute top-[10px] right-2">
                      <BsFillBuildingsFill
                        size={20}
                        className="text-[#666666]"
                      />
                    </span>
                  </div>

                  <div className="mb-1">
                    <label className="font-medium text-uberark-blue">
                      Company URL <span className="text-red-500">*</span>:
                    </label>
                  </div>
                  <div className="acc-mng-form-input">
                    <Field
                      className="floating-input pl-2 text-lg text-uberark-blue  pr-30"
                      name="url"
                      id="url"
                      type="url"
                      placeholder="Company url"
                      disabled={
                        user?.id &&
                        organizationData?.name &&
                        organizationData?.created_by &&
                        organizationData?.created_by.toString() !==
                          user.id.toString()
                      }
                    />
                    {errors.url && touched.url && (
                      <div className="text-red-600">{errors.url}</div>
                    )}
                    <span className="absolute top-[10px] right-2">
                      <CgWebsite size={20} className="text-[#666666]" />
                    </span>
                  </div>

                  <div className="flex flex-row lg:justify-end justify-center text-uberark-blue gap-4  text-lg">
                    <button
                      className=":px-2 bg-[#000080] text-white  hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl"
                      type="submit"
                      disabled={
                        loading ||
                        (user?.id &&
                          organizationData?.name &&
                          organizationData?.created_by &&
                          organizationData?.created_by.toString() !==
                            user.id.toString())
                      }
                    >
                      {loading ? <LoadingSpinner /> : "Save"}
                    </button>
                    {slide ? (
                      <button
                        className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
                        to="/"
                        onClick={() => handleLogout()}
                        type="button"
                        style={{ border: "0.24px solid #000080" }}
                      >
                        Cancel
                      </button>
                    ) : null}
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          {!slide ? (
            <>
              <div
                className={`${
                  !slide ? "md:m-4 md:p-14" : "md:m-2 md:p-5"
                } bg-white rounded-xl p-2`}
              >
                {organization && Object.keys(organization).length ? (
                  <>
                    <div className="font-bold text-uberark-orange text-2xl">
                      {" "}
                      Custom Fields
                    </div>
                    <CustomFields />
                  </>
                ) : null}
              </div>
              <div
                className={`${
                  !slide ? "md:m-1" : "md:m-2 md:p-5"
                } bg-white rounded-xl p-2`}
              >
                <Share />
              </div>
            </>
          ) : null}
        </div>
      </div>

      {/*
        <div className="flex flex-col gap-8 p-8">
          <div className="flex flex-col">
            <span className="text-uberark-blue"> My Hub</span>
            <span className="text-[#757575]">
              {' '}
              Enable content curation and distribution to your team
            </span>
          </div>
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className={`${enabled ? ' bg-[#DBDBDB]' : ' bg-[#DBDBDB]'}
            relative inline-flex items-center h-5 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span
              aria-hidden="true"
              className={`${enabled ? 'translate-x-5' : 'translate-x-0'}
              pointer-events-none inline-flex h-6 w-6 transform rounded-2xl bg-uberark-blue shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div>
      */}
    </div>
  );
};

export default Account;
