import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Menu, Transition } from "@headlessui/react";
import { Switch } from "@headlessui/react";

import AssetAdd from "../commons/Asset/components/AssetAdd";
import AddSection from "./components/AddSection";
import EditSection from "./components/EditSection";
import { PopUpDelete, Slider } from "../../components";
import FileCard from "./components/FileCard";
import CollectionEdit from "./CollectionEdit";
import ComposeChild from "../Compose/components/ComposeChild";
import { toast } from "react-toastify";

import {
  BsChevronDown,
  BsChevronLeft,
  BsTrash,
  BsLock,
  BsThreeDots,
  BsFillBookmarkStarFill,
} from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { FiShare2, FiInfo } from "react-icons/fi";
import { RiAddCircleFill, RiEditCircleFill } from "react-icons/ri";
import { BiLink } from "react-icons/bi";
import { TbLivePhoto, TbTemplate } from "react-icons/tb";
import { HiUserGroup } from "react-icons/hi";
import { FaCut } from "react-icons/fa";

import "./collection.css";
import { collectionActions } from "./actions";
import { streamActions } from "../Streams/actions";
import no_content from "../../assets/images/no_content.svg";
import avatarPlaceholder from "../../assets/images/avatar-placeholder.png";

const Collection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id: collectionId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { previewCollection, dltSection, collectionUpdate, showCollection } =
    useSelector((state) => state.collection);

  const [templateEnabled, setTemplateEnabled] = useState(false);
  const [liveEnabled, setLiveEnabled] = useState(false);
  const [internaluseEnabled, setInternalUseEnabled] = useState(false);
  const [promoteEnabled, setPromoteEnabled] = useState(false);
  const [addAssetToggle, setAddAssetToggle] = useState(false);
  const [addSectionToggle, setAddSectionToggle] = useState(false);
  const [editSectionToggle, setEditSectionToggle] = useState(false);
  const [deleteAssetToggle, setDeleteAssetToggle] = useState(false);
  const [deleteCollectionModal, setDeleteCollectionModal] = useState(false);
  const [collectionData, setCollectionData] = useState({});
  const [shareCollectionShow, setShareCollectionShow] = useState(false);
  const [openSection, setOpenSection] = useState();
  const [fileType, setFileType] = useState("");
  const [dltSectionShow, setDltSectionShow] = useState(false);
  const [selectedDltSection, setSelectedDltSection] = useState(null);
  const [isCollectionCollaborator, setIsCollectionCollaborator] =
    useState(false);
  const [collaboratorPermission, setCollaboratorPermission] = useState(
    user && user.organization_admin ? user.organization_admin : false
  );
  const [disabled, setDisabled] = useState(true);
  const [moveAsset, setMoveAsset] = useState(false);
  const [editCollection, setEditCollection] = useState(false);

  useEffect(() => {
    if (user.organization) {
      // get stream and collections
      dispatch(collectionActions.handleGetCollectioPreview(collectionId));
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (collectionUpdate) {
      dispatch(collectionActions.handleGetCollectioPreview(collectionId));
    }
  }, [collectionUpdate, collectionId, dispatch]);

  useEffect(() => {
    // filter collection by id
    if (Object.keys(previewCollection).length && showCollection) {
      const collectionFiltered = previewCollection;
      if (collectionFiltered && collectionFiltered.id) {
        // remove deleted item
        const section_data = [];
        const asset_data = [];
        collectionFiltered.section.map((section_item) => {
          if (!section_item.deleted) {
            const section_item_data = section_item;
            const asset_item_data = [];
            section_item.asset.map((asset_item, index) => {
              if (!asset_item.deleted) {
                asset_item_data.push(asset_item);
                asset_data.push(asset_item);
              }
              return true;
            });

            section_item_data.asset = asset_item_data;
            section_data.push(section_item_data);
          }

          return true;
        });

        collectionFiltered.section = section_data;
        collectionFiltered.asset = asset_data;
        setCollectionData(collectionFiltered);
        if (
          collectionFiltered?.section &&
          collectionFiltered?.section.length > 0
        ) {
          const selectedSection = collectionFiltered?.section.filter(
            (section) => section.id === previewCollection.sectionId
          );
          setOpenSection(
            selectedSection && selectedSection.length
              ? selectedSection[0]
              : collectionFiltered?.section[0]
          );
        }

        if (collectionFiltered?.created_by.id === user.id) {
          setDisabled(false);
          if (!user.organization_admin) {
            setCollaboratorPermission(true);
          }
        } else {
          if (
            collectionFiltered.collaborators &&
            collectionFiltered.collaborators.length > 0
          ) {
            let d = collectionFiltered.collaborators?.filter(
              (item) => item.user === user.id
            );
            setDisabled(d.length ? false : true);
            if (!user.organization_admin) {
              setCollaboratorPermission(d.length ? true : false);
            }
          } else {
            setDisabled(true);
            if (!user.organization_admin) {
              setCollaboratorPermission(false);
            }
          }
        }

        if (
          collectionFiltered.collaborators &&
          collectionFiltered.collaborators.length > 0
        ) {
          collectionFiltered.collaborators.map((item) => {
            if (item.user === user.id) {
              setIsCollectionCollaborator(true);
            }

            return true;
          });
        }
      }

      setFileType(
        collectionFiltered?.banner
          ? collectionFiltered.banner.includes(".mp4")
            ? "video/mp4"
            : ""
          : ""
      );
      setLiveEnabled(collectionFiltered?.live_status || false);
      setTemplateEnabled(collectionFiltered?.template_status || false);
      setInternalUseEnabled(
        collectionFiltered?.internal_use_only_status || false
      );
      setPromoteEnabled(collectionFiltered?.promote || false);
    } else {
      setCollectionData({});
    }
  }, [previewCollection, showCollection]); // eslint-disable-line

  const statusToggle = (key) => {
    if (collectionId) {
      const payload = {
        id: collectionId,
      };

      if (key === "template_status") {
        payload[key] = !templateEnabled;
        setTemplateEnabled(!templateEnabled);
      }
      if (key === "live_status") {
        payload[key] = !liveEnabled;
        setLiveEnabled(!liveEnabled);
      }
      if (key === "internal_use_only_status") {
        payload[key] = !internaluseEnabled;
        setInternalUseEnabled(!internaluseEnabled);
      }
      if (key === "promote") {
        payload[key] = !promoteEnabled;
        setPromoteEnabled(!promoteEnabled);
      }
      return dispatch(
        collectionActions.handleEditCollection(payload, () => {})
      );
    }
  };

  const handleDeleteCollection = () => {
    const onSubmitDeleteCallback = () => {
      navigate(`/collections`);
    };

    return dispatch(
      collectionActions.handleDeleteCollection(
        collectionId,
        onSubmitDeleteCallback
      )
    );
  };

  const handleAddAssetToggle = () => {
    setAddAssetToggle(!addAssetToggle);
  };

  const handleAddSectionToggle = () => {
    setAddSectionToggle(!addSectionToggle);
  };

  const handleEditSectionToggle = () => {
    setEditSectionToggle(!editSectionToggle);
  };

  const handleAssetCopySubmit = (selectedStream, selectedCollection) => {
    const payload = [];
    if (selectedStream && selectedStream.length) {
      selectedStream.map((item) => {
        payload.push({
          id: item.id,
          collection: collectionId,
          section: openSection.id,
        });
        return true;
      });
    }

    if (selectedCollection && selectedCollection.length) {
      selectedCollection.map((item) => {
        payload.push({
          id: item.id,
          collection: collectionId,
          section: openSection.id,
        });
        return true;
      });
    }

    dispatch(
      streamActions.handleCopyAsset(payload, handleAddAssetToggle, true)
    );
  };

  const getPDFPagesCount = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onloadend = () =>
        resolve(reader.result.match(/\/Type[\s]*\/Page[^s]/g).length);
      reader.onerror = (error) => reject(null);
    });

  const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  const handleCreateAsset = async (data) => {
    const payload = {
      ...data,
      file_size: data.file.size,
      collection: collectionId,
      section: openSection.id,
    };

    if (data.file.type.split("/").at(-1) === "mp4") {
      // video duration
      const videoDuration = await getVideoDuration(data.file);
      payload.video_duration = videoDuration;
    }

    if (data.file.type.split("/").at(-1) === "pdf") {
      // get pdf file page count
      const pageCount = await getPDFPagesCount(data.file);
      payload.page_count = pageCount;
    }

    setTimeout(function () {
      dispatch(
        streamActions.handleCreateAsset(payload, handleAddAssetToggle, true)
      );
    }, 50);
  };

  const handleCreateSection = (data) => {
    const payload = {
      ...data,
      collection: collectionId,
    };
    dispatch(
      collectionActions.handleCreateSection(payload, handleAddSectionToggle)
    );
  };

  const handleEditSection = (data) => {
    const payload = {
      ...openSection,
      name: data.name,
    };
    dispatch(
      collectionActions.handleEditSection(payload, handleEditSectionToggle)
    );
  };

  const handleDeleteSection = () => {
    setSelectedDltSection(openSection);
    setDltSectionShow(true);
  };

  const onConfirmDlt = () => {
    return dispatch(
      collectionActions.handleDeleteSection(openSection?.id, collectionId)
    );
  };

  const handleOnAssetClick = (asset) => navigate(`/asset/${asset.id}`);

  const onShareCollectionClose = () => {
    setShareCollectionShow(!shareCollectionShow);
  };

  const handleCollaborate = () => {
    navigate(`/collaborators`, {
      state: {
        collectionId: collectionData?.id,
        collaborators: collectionData?.collaborators,
      },
    });
  };

  const handleDltPopupClose = () => {
    setDltSectionShow(false);
  };

  useEffect(() => {
    if (dltSection) {
      setDltSectionShow(false);
    }
  }, [dltSection]);

  const handleMoveAssetToggle = () => {
    setMoveAsset(!moveAsset);
  };

  const handleMoveAssetSubmit = (selectedStream, selectedCollection) => {
    const payload = [];
    if (selectedStream && selectedStream.length) {
      selectedStream.map((item) => {
        payload.push({
          id: item.id,
          collection: collectionId,
          section: openSection.id,
        });
        return true;
      });
    }

    if (selectedCollection && selectedCollection.length) {
      selectedCollection.map((item) => {
        payload.push({
          id: item.id,
          collection: collectionId,
          section: openSection.id,
        });
        return true;
      });
    }

    dispatch(
      streamActions.handleMoveAsset(
        payload,
        handleMoveAssetToggle,
        false,
        collectionId
      )
    );
  };

  return (
    <div className="relative">
      <div className="absolute sm:top-0 top-20 w-full h-full mb-5">
        {/* head */}
        <div className="md:mt-4 md:mx-20 mx-4 mt-4 grid grid-cols-1 md:grid-cols-2 justify-between bg-transparent">
          <div className="grid grid-flow-col justify-between w-full">
            <div className="grid grid-flow-col">
              <div className="grid grid-flow-col justify-items-end items-center justify-center gap-4">
                <button
                  onClick={() => navigate("/collections")}
                  className="inline-block font-medium text-xl leading-tight hover:bg-white hover:shadow-lg focus:bg-white bg-[#ecf2ff] focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg hover:scale-105 ease-in-out duration-300 rounded-full p-3 "
                  style={{ border: "0.24px solid #000080" }}
                >
                  <BsChevronLeft className="text-[#FE4502] drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] " />
                </button>

                <button
                  className="mr-4 text-gray-600  hover:text-[#000080] "
                  onClick={() => navigate("/collections")}
                >
                  Back
                </button>
              </div>
            </div>
          </div>

          {collaboratorPermission ? (
            <div>
              <div className="md:grid grid-flow-col justify-end items-center divide-x text-14 text-uberark-blue divide-slate-500 gap-1 hidden">
                {internaluseEnabled ? (
                  <span className="text-uberark-orange text-lg font-extrabold">
                    <BsLock />
                  </span>
                ) : null}
                {!internaluseEnabled && (
                  <button
                    onClick={() => {
                      if (
                        collectionData?.asset &&
                        collectionData?.asset.length
                      ) {
                        navigate(`/collection/links/${collectionId}`);
                      } else {
                        toast.info("Please add an asset to share collection");
                      }
                    }}
                    className="grid grid-flow-col items-center gap-1 hover:text-[#000080] px-1"
                  >
                    <BiLink /> Create Link
                  </button>
                )}

                <button
                  onClick={() => setEditCollection(!editCollection)}
                  className={`${disabled ? "cursor-not-allowed" : ""} ${
                    editCollection ? "text-uberark-orange" : ""
                  } grid grid-flow-col items-center gap-2 justify-center px-1`}
                  disabled={disabled}
                >
                  <RiEditCircleFill /> Edit Collection
                </button>

                <button
                  className={`${
                    disabled ? "cursor-not-allowed" : ""
                  } grid grid-flow-col items-center gap-1 hover:text-[#000080] px-1`}
                  onClick={() =>
                    setDeleteCollectionModal(!deleteCollectionModal)
                  }
                  disabled={disabled || isCollectionCollaborator}
                >
                  <BsTrash className="hover:scale-105 ease-in-out duration-300" />
                  <span>Delete Collection</span>
                </button>

                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex justify-center rounded-full border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 ml-2">
                      <BsThreeDots />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className="absolute right-0 mt-2 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      style={{ zIndex: "101" }}
                    >
                      <div className="py-3">
                        <Menu.Item>
                          <div
                            className="grid grid-flow-col gap-2 items-center justify-between px-5 py-2"
                            onMouseDown={() => handleCollaborate()}
                          >
                            <div className="flex items-center">
                              <HiUserGroup
                                className="mx-1 text-base"
                                style={{
                                  background: "#FE4502",
                                  borderRadius: "5px",
                                  padding: "3px",
                                  color: "white",
                                }}
                              />
                              <span className="">Collaborate</span>
                            </div>
                            <span className="relative right-4">
                              {collectionData && collectionData.collaborators
                                ? collectionData.collaborators.length
                                : 0}
                            </span>
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="grid grid-flow-col gap-2 items-center justify-between px-5 py-2">
                            <div className="flex items-center">
                              <TbTemplate
                                className="mx-1 text-base"
                                style={{
                                  background: "#FE4502",
                                  borderRadius: "5px",
                                  padding: "3px",
                                  color: "white",
                                }}
                              />
                              <span className="">Template</span>{" "}
                            </div>
                            <Switch
                              checked={templateEnabled}
                              onChange={() => statusToggle("template_status")}
                              className={`${
                                templateEnabled
                                  ? " bg-uberark-blue"
                                  : " bg-uberark-orange"
                              } ${disabled ? "cursor-not-allowed" : ""}
                           relative right-0 inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                              disabled={disabled}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={`${
                                  templateEnabled
                                    ? "translate-x-5"
                                    : "translate-x-0"
                                } pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                              />
                            </Switch>{" "}
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="grid grid-flow-col gap-2 justify-between items-center px-5 py-2">
                            <div className="flex items-center">
                              <TbLivePhoto
                                className="mx-1 text-base"
                                style={{
                                  background: "#FE4502",
                                  borderRadius: "5px",
                                  padding: "3px",
                                  color: "white",
                                }}
                              />
                              <span className="">Live Collection</span>
                            </div>
                            <Switch
                              checked={liveEnabled}
                              onChange={() => statusToggle("live_status")}
                              className={`${
                                liveEnabled
                                  ? " bg-uberark-blue"
                                  : " bg-uberark-orange"
                              }
                              ${disabled ? "cursor-not-allowed" : ""}
                           relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                              disabled={disabled}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={`${
                                  liveEnabled
                                    ? "translate-x-5"
                                    : "translate-x-0"
                                } pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                              />
                            </Switch>
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="grid grid-flow-col gap-2 items-center justify-between px-5 py-2">
                            <div className="flex items-center">
                              <BsLock
                                className="mx-1 text-base"
                                style={{
                                  background: "#FE4502",
                                  borderRadius: "5px",
                                  padding: "3px",
                                  color: "white",
                                }}
                              />
                              <span className="">Internal Use Only</span>{" "}
                            </div>
                            <Switch
                              checked={internaluseEnabled}
                              onChange={() =>
                                statusToggle("internal_use_only_status")
                              }
                              className={`${
                                internaluseEnabled
                                  ? " bg-uberark-blue"
                                  : " bg-uberark-orange"
                              }
                              ${disabled ? "cursor-not-allowed" : ""}
                             relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                              disabled={disabled}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={`${
                                  internaluseEnabled
                                    ? "translate-x-5"
                                    : "translate-x-0"
                                } pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                              />
                            </Switch>{" "}
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="grid grid-flow-col gap-2 items-center justify-between px-5 py-2">
                            <div className="flex items-center">
                              <BsFillBookmarkStarFill
                                className="mx-1 text-base"
                                style={{
                                  background: "#FE4502",
                                  borderRadius: "5px",
                                  padding: "3px",
                                  color: "white",
                                }}
                              />
                              <span className="">Promote</span>{" "}
                            </div>
                            <Switch
                              checked={promoteEnabled}
                              onChange={() => statusToggle("promote")}
                              className={`${
                                promoteEnabled
                                  ? " bg-uberark-blue"
                                  : " bg-uberark-orange"
                              }
                              ${disabled ? "cursor-not-allowed" : ""}
                             relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                              disabled={disabled}
                            >
                              <span className="sr-only">Promote</span>
                              <span
                                aria-hidden="true"
                                className={`${
                                  promoteEnabled
                                    ? "translate-x-5"
                                    : "translate-x-0"
                                } pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                              />
                            </Switch>{" "}
                          </div>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>

              <div className="md:hidden md:grid grid-flow-col justify-end mt-2">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-500 hover:text-[#000080] hover:bg-gray-50 focus:outline-none">
                      Options
                      <BsChevronDown
                        className="-mr-1 ml-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-3">
                        <Menu.Item>
                          <div className="grid grid-flow-col gap-2 items-center justify-between px-5 py-2">
                            <button
                              onClick={() => handleAddSectionToggle()}
                              className={`${
                                disabled ? "cursor-not-allowed" : ""
                              } grid justify-center`}
                              disabled={disabled}
                            >
                              {" "}
                              + Add Section
                            </button>
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="grid grid-flow-col gap-2 items-center justify-between px-5 py-2">
                            {!internaluseEnabled && (
                              <button
                                onClick={() => {
                                  if (
                                    collectionData?.asset &&
                                    collectionData?.asset.length
                                  ) {
                                    navigate(
                                      `/collection/links/${collectionId}`
                                    );
                                  } else {
                                    toast.info(
                                      "Please add an asset to share collection"
                                    );
                                  }
                                }}
                                className="grid grid-flow-col items-center gap-1 hover:text-[#000080]"
                              >
                                <BiLink /> Create Link
                              </button>
                            )}
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="grid grid-flow-col gap-2 items-center justify-between px-5 py-2">
                            <button
                              onClick={() =>
                                navigate("/collection/edit/" + collectionId)
                              }
                              className={`${
                                disabled ? "cursor-not-allowed" : ""
                              } grid grid-flow-col items-center gap-2 justify-center`}
                              disabled={disabled}
                            >
                              <RiEditCircleFill /> Edit Collection
                            </button>
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="grid grid-flow-col gap-2 items-center justify-between px-5 py-2">
                            <button
                              className={`${
                                disabled ? "cursor-not-allowed" : ""
                              } grid grid-flow-col items-center gap-1 hover:text-[#000080]`}
                              onClick={() =>
                                setDeleteCollectionModal(!deleteCollectionModal)
                              }
                              disabled={disabled}
                            >
                              <BsTrash className="hover:scale-105 ease-in-out duration-300" />
                              <span>Delete Collection</span>
                            </button>
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="grid grid-flow-col gap-2 items-center justify-between px-5 py-2">
                            <button
                              className="relative flex items-center justify-end gap-1 hover:text-[#000080]"
                              onClick={() => handleCollaborate()}
                            >
                              {/* <RiTeamFill className="hover:scale-105 ease-in-out duration-300" /> */}
                              <span>Collaborate</span>
                            </button>
                            <span className="relative right-4">
                              {collectionData && collectionData.collaborators
                                ? collectionData.collaborators.length
                                : 0}
                            </span>
                            {/* <span className='relative right-4'>{collectionData?.collaborators !== undefined ? isCollectionCollaborator ? collectionData?.collaborators.length-1 : collectionData?.collaborators.length : 0}</span> */}
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="grid grid-flow-col gap-2 items-center justify-between px-5 py-2">
                            <span>Template</span>{" "}
                            <Switch
                              checked={templateEnabled}
                              onChange={() => statusToggle("template_status")}
                              className={`${
                                templateEnabled
                                  ? " bg-uberark-blue"
                                  : " bg-uberark-orange"
                              }, ${
                                disabled
                                  ? "bg-uberark-orange cursor-not-allowed"
                                  : " bg-uberark-orange"
                              }
                           relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                              disabled={disabled}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={`${
                                  templateEnabled
                                    ? "translate-x-5"
                                    : "translate-x-0"
                                } pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                              />
                            </Switch>{" "}
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="grid grid-flow-col gap-2 justify-between items-center px-5 py-2">
                            <span>Live Collection</span>
                            <Switch
                              checked={liveEnabled}
                              onChange={() => statusToggle("live_status")}
                              className={`${
                                liveEnabled
                                  ? " bg-uberark-blue"
                                  : " bg-uberark-orange"
                              }, ${
                                disabled
                                  ? "bg-uberark-orange cursor-not-allowed"
                                  : " bg-uberark-orange"
                              }
                           relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                              disabled={disabled}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={`${
                                  liveEnabled
                                    ? "translate-x-5"
                                    : "translate-x-0"
                                } pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                              />
                            </Switch>
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="grid grid-flow-col gap-2 items-center justify-between px-5 py-2">
                            <span>Internal Use Only</span>{" "}
                            <Switch
                              checked={internaluseEnabled}
                              onChange={() =>
                                statusToggle("internal_use_only_status")
                              }
                              className={`${
                                internaluseEnabled
                                  ? " bg-uberark-blue"
                                  : " bg-uberark-orange"
                              }, ${
                                disabled
                                  ? "bg-uberark-orange cursor-not-allowed"
                                  : " bg-uberark-orange"
                              }
                             relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                              disabled={disabled}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={`${
                                  internaluseEnabled
                                    ? "translate-x-5"
                                    : "translate-x-0"
                                } pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                              />
                            </Switch>{" "}
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="grid grid-flow-col gap-2 items-center justify-between px-5 py-2">
                            <span>Promote</span>{" "}
                            <Switch
                              checked={promoteEnabled}
                              onChange={() => statusToggle("promote")}
                              className={`${
                                promoteEnabled
                                  ? " bg-uberark-blue"
                                  : " bg-uberark-orange"
                              }, ${
                                disabled
                                  ? "bg-uberark-orange cursor-not-allowed"
                                  : " bg-uberark-orange"
                              }
                             relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                              disabled={disabled}
                            >
                              <span className="sr-only">Promote</span>
                              <span
                                aria-hidden="true"
                                className={`${
                                  promoteEnabled
                                    ? "translate-x-5"
                                    : "translate-x-0"
                                } pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                              />
                            </Switch>{" "}
                          </div>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          ) : null}
        </div>

        {/* collection head --------------------------------------------------------------------- */}
        {editCollection ? (
          <CollectionEdit
            imported={true}
            handleClose={() => {
              dispatch(
                collectionActions.handleGetCollectioPreview(collectionId)
              );
              setEditCollection(false);
            }}
          />
        ) : (
          <div
            className="relative rounded-3xl min-h-[370px] bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] m-4 shadow object-contain flex flex-wrap text-gray-600"
            style={{
              backgroundImage: collectionData?.banner_thumbnail
                ? `url(${collectionData.banner_thumbnail})`
                : collectionData?.banner
                ? `url(${collectionData.banner})`
                : "",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            {fileType.includes("video/mp4") ? (
              <video
                className="absolute w-full h-full rounded-3xl"
                style={{ objectFit: "fill" }}
                controls={false}
                loop
                muted
                autoPlay
              >
                <source src={collectionData?.banner} type="video/mp4" />
                <source src={collectionData?.banner} type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            ) : null}

            <div
              className="relative p-8 pt-2 justify-between w-full relative bg-[#fafafd4f] rounded-3xl"
              style={{
                background:
                  "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QgdBy0F1sAe4QAAACFJREFUCNdj/P//vy8DBNgzMDAwMDGgARaYDAMDw0GsKgD/0gSTo/gcFwAAAABJRU5ErkJggg==) repeat hsla(0,0%,100%,.4)",
              }}
            >
              <div className="w-full">
                <div className="flex flex-col h-[40px] w-[80px]">
                  {collectionData?.logo ? (
                    <img
                      src={collectionData?.logo}
                      alt="logo"
                      className="object-contain h-[40px] w-[80px] rounded bg-transparent"
                      loading="lazy"
                    />
                  ) : null}
                </div>
              </div>

              <div className="flex flex-col md:flex-row">
                <div className="flex sm:flex-row flex-col pt-2 gap-4 min-h-[166px] md:w-3/4 w-full pr-2">
                  <div className="flex flex-col gap-2">
                    <span className="text-[#FE4502] font-bold text-2xl text-left mb-2">
                      {collectionData?.name}
                    </span>
                    <span
                      className="e-content"
                      dangerouslySetInnerHTML={{
                        __html: collectionData?.description,
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-col flex-wrap h-full md:w-1/4 w-full mt-2">
                  <div className="flex items-center md:w-[160px] xl:w-[220px]">
                    {!internaluseEnabled ? (
                      <button
                        className={`hover:scale-105 ease-in-out duration-200 flex flex-row items-center gap-4`}
                        onClick={() => {
                          if (
                            collectionData?.asset &&
                            collectionData?.asset.length
                          ) {
                            onShareCollectionClose();
                          } else {
                            toast.info(
                              "Please add an asset to share collection"
                            );
                          }
                        }}
                      >
                        <FiShare2 className="flex" />{" "}
                        <span
                          className={`text-uberark-blue flex text-base font-semibold`}
                        >
                          Share Collection
                        </span>
                      </button>
                    ) : null}
                  </div>
                  <div className="flex items-center mt-2 md:w-[160px] xl:w-[220px]">
                    <img
                      src={
                        collectionData?.created_by?.avatar
                          ? collectionData.created_by.avatar
                          : avatarPlaceholder
                      }
                      alt="avatar"
                      className="rounded-full w-[30px] h-[30px] hover:bg-light-gray object-cover mr-1"
                      style={{ border: "0.24px solid #000080" }}
                    />

                    <div className="md:w-[130px] xl:w-[190px]">
                      <div className="truncate text-sm">
                        {collectionData?.created_by?.username}
                      </div>
                      <div className="truncate text-sm">
                        {collectionData?.created_by?.email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Collections assets/sections----------------------------------------------------------------------------------------- */}
        <div className="rounded-3xl bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] mt-4 ml-4 mr-4 mb-10 p-6 shadow">
          <>
            <div className="grid grid-flow-col md:grid-flow-col justify-center mb-5 text-sm divide-x text-slate-600 divide-slate-500">
              {collectionData?.section &&
                collectionData?.section.length > 1 &&
                collectionData?.section.map((item, index) => (
                  <div
                    key={index}
                    className={`px-2  font-bold ${
                      openSection.id === item.id
                        ? "text-uberark-orange"
                        : "text-uberark-blue"
                    }`}
                    onMouseDown={() => setOpenSection(item)}
                  >
                    {item.name}
                  </div>
                ))}
            </div>

            {collaboratorPermission ? (
              <div className="w-full relative">
                <div className="flex justify-between items-center text-slate-600 w-full">
                  <div className="grid justify-center hover:text-[#000080] drop-shadow-lg">
                    {collectionData?.asset && collectionData?.asset.length}{" "}
                    Asset(s){" "}
                  </div>

                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex justify-center items-center rounded border border-gray-300 bg-white px-2 py-1 text-sm text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 ml-2">
                        <BsThreeDots className="mr-1" />
                        Actions
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-[100]">
                        <div className="py-3">
                          <Menu.Item>
                            <button
                              onClick={() => handleAddSectionToggle()}
                              className={`hover:text-[#000080] text-slate-600 drop-shadow-lg flex items-center px-5 py-2 ${
                                disabled ? "cursor-not-allowed" : ""
                              }`}
                              disabled={disabled}
                            >
                              <RiAddCircleFill
                                className="ml-2 mr-2 text-base"
                                style={{
                                  background: "#FE4502",
                                  borderRadius: "5px",
                                  padding: "3px",
                                  color: "white",
                                }}
                              />{" "}
                              Add Section
                            </button>
                          </Menu.Item>
                          {collectionData &&
                            collectionData.section &&
                            collectionData.section.length > 1 && (
                              <>
                                <Menu.Item>
                                  <button
                                    className={`hover:text-[#000080] text-slate-600 drop-shadow-lg flex items-center px-5 py-2`}
                                    disabled={
                                      !collectionData ||
                                      (previewCollection &&
                                        previewCollection.section &&
                                        previewCollection.section.length === 1)
                                    }
                                    onClick={() => {
                                      setEditSectionToggle(!editSectionToggle);
                                    }}
                                  >
                                    <RiEditCircleFill
                                      className="ml-2 mr-2 text-base"
                                      style={{
                                        background: "#FE4502",
                                        borderRadius: "5px",
                                        padding: "3px",
                                        color: "white",
                                      }}
                                    />
                                    Edit Section{" "}
                                  </button>
                                </Menu.Item>
                                <Menu.Item>
                                  <button
                                    className={`hover:text-[#000080] text-slate-600 drop-shadow-lg flex items-center px-5 py-2`}
                                    onClick={() => handleDeleteSection()}
                                  >
                                    <BsTrash
                                      className="ml-2 mr-2 text-base"
                                      style={{
                                        background: "#FE4502",
                                        borderRadius: "5px",
                                        padding: "3px",
                                        color: "white",
                                      }}
                                    />
                                    Delete Section{" "}
                                  </button>
                                </Menu.Item>
                              </>
                            )}

                          <hr className="my-2" />

                          {collectionData?.asset &&
                          collectionData?.asset.length ? (
                            <>
                              <Menu.Item>
                                <button
                                  className={`hover:text-[#000080] drop-shadow-lg flex items-center px-5 py-2 text-slate-600`}
                                  onClick={() => {
                                    setMoveAsset(!moveAsset);
                                  }}
                                  type="button"
                                >
                                  <FaCut
                                    className="ml-2 mr-2 text-base"
                                    style={{
                                      background: "#FE4502",
                                      borderRadius: "5px",
                                      padding: "3px",
                                      color: "white",
                                    }}
                                  />
                                  Move Asset{" "}
                                </button>
                              </Menu.Item>
                              <Menu.Item>
                                <button
                                  className={`hover:text-[#000080] drop-shadow-lg flex items-center px-5 py-2 ${
                                    deleteAssetToggle
                                      ? "text-uberark-orange"
                                      : "text-slate-600"
                                  }`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    if (e.target !== e.currentTarget) {
                                      return;
                                    }
                                    setDeleteAssetToggle(!deleteAssetToggle);
                                  }}
                                  type="button"
                                >
                                  <BsTrash
                                    className="ml-2 mr-2 text-base"
                                    style={{
                                      background: "#FE4502",
                                      borderRadius: "5px",
                                      padding: "3px",
                                      color: "white",
                                    }}
                                  />
                                  Delete Asset{" "}
                                </button>
                              </Menu.Item>
                            </>
                          ) : null}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            ) : null}

            <div className="w-full">
              <div className="flex flex-wrap mt-5">
                {!openSection?.asset ||
                (openSection?.asset && openSection?.asset.length === 0) ? (
                  <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10">
                    <div className="w-full flex justify-center">
                      <img
                        src={no_content}
                        alt="nothing to show illustration"
                        style={{ height: "60px" }}
                      />
                    </div>
                    <div className="text-center relative p-5 text-[#757575] text-14 flex items-center">
                      Nothing to show.{" "}
                      <FiInfo className="text-uberark-orange ml-1" />
                    </div>
                    {collaboratorPermission ? (
                      <button
                        className="flex items-center justify-center py-1 px-5 cursor-pointer opacity-80 hover:scale-105 ease-in-out duration-300 border rounded"
                        onClick={() => handleAddAssetToggle()}
                      >
                        <AiOutlinePlus className="text-[#FE4502] mr-1" /> Add
                        Asset
                      </button>
                    ) : null}
                  </div>
                ) : collaboratorPermission ? (
                  <div
                    className="mt-2 w-155px h-[155px] md:h-[155px] md:w-[155px]
                inline-grid items-center justify-center p-2 cursor-pointer
                opacity-80 hover:scale-105 ease-in-out duration-300
                rounded-[20px]"
                    style={{ border: "0.25px solid #000080" }}
                    onMouseDown={() => handleAddAssetToggle()}
                  >
                    <button onClick={() => handleAddAssetToggle()}>
                      <AiOutlinePlus size={70} className="text-[#FE4502]" />
                    </button>
                  </div>
                ) : null}
                <FileCard
                  assetsData={
                    openSection?.asset && openSection?.asset.length > 0
                      ? openSection.asset
                      : []
                  }
                  deleteAssetToggle={deleteAssetToggle}
                  setDeleteAssetToggle={setDeleteAssetToggle}
                  onAssetClick={handleOnAssetClick}
                  fileHover={true}
                  collectionId={collectionId}
                  sectionId={openSection?.id}
                  cardLoader={
                    openSection && openSection.asset.length ? true : false
                  }
                />
              </div>
            </div>
          </>
        </div>

        {/* modals */}
        <Slider
          float="left"
          title=""
          visible={addAssetToggle}
          onClose={handleAddAssetToggle}
          className="w-[50%]"
        >
          <AssetAdd
            handleModalClose={handleAddAssetToggle}
            handleAssetCopySubmit={handleAssetCopySubmit}
            handleAssetCreateSubmit={handleCreateAsset}
            collection={{
              id: collectionId,
              sectionId: openSection?.id || null,
            }}
          />
        </Slider>
        <Slider
          float="left"
          title=""
          visible={moveAsset}
          onClose={handleMoveAssetToggle}
          className="w-[50%]"
        >
          <AssetAdd
            handleModalClose={handleMoveAssetToggle}
            handleMoveAssetSubmit={handleMoveAssetSubmit}
            handleMoveAssetClick={handleMoveAssetToggle}
          />
        </Slider>

        <AddSection
          visible={addSectionToggle}
          handleModalClose={handleAddSectionToggle}
          handleCreateSectionSubmit={handleCreateSection}
        />

        <EditSection
          visible={editSectionToggle}
          handleModalClose={handleEditSectionToggle}
          handleEditSectionSubmit={handleEditSection}
          section={openSection || {}}
        />

        <Slider
          float="left"
          title=""
          visible={shareCollectionShow}
          onClose={onShareCollectionClose}
          className="w-[80%]"
        >
          <ComposeChild
            onShareCollectionClose={() => onShareCollectionClose()}
            collection={collectionData}
          />
        </Slider>

        <PopUpDelete
          onClose={setDeleteCollectionModal}
          visible={deleteCollectionModal}
          itemName={collectionData?.name}
          deleteFn={handleDeleteCollection}
        />

        <PopUpDelete
          onClose={handleDltPopupClose}
          visible={dltSectionShow}
          itemName={selectedDltSection?.name || ""}
          deleteFn={onConfirmDlt}
        />
      </div>
    </div>
  );
};

export default Collection;
