import React from "react";

import { useSelector } from "react-redux";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { LoadingSpinner, Slider } from "../../../components";

const EditSection = ({
  visible,
  handleModalClose,
  handleEditSectionSubmit,
  section,
}) => {
  const { loading } = useSelector((state) => state.stream);

  const initialValues = {
    name: section.name,
  };

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
  });

  return (
    <Slider
      direction="right"
      title="Edit Section"
      visible={visible}
      onClose={() => handleModalClose()}
    >
      <div className="w-full">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleEditSectionSubmit}
          validationSchema={FormSchema}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form>
              <div>
                <p className="font-medium text-uberark-blue text-left">
                  <span>Name</span>{" "}
                  <span className="text-base text-red-500 ml-1">*</span>:
                </p>
                <Field
                  placeholder="Enter section name"
                  className="text-base floating-input pl-2 text-uberark-blue"
                  name="name"
                  id="name"
                />
                {errors.name && touched.name && (
                  <div className="text-red-600 pb-2">{errors.name}</div>
                )}
              </div>
              <div className="flex flex-row items-center text-uberark-blue gap-4 text-lg mt-2">
                <button
                  className=":px-2 bg-[#000080] text-white  hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <LoadingSpinner /> : "Save"}
                </button>
                <button
                  className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
                  onClick={() => handleModalClose(false)}
                  style={{ border: "0.24px solid #000080" }}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Slider>
  );
};

export default EditSection;
