import React, { useState, useEffect } from "react";

import { FiInfo } from "react-icons/fi";
import AssetCard from "../../commons/Asset/components/AssetCard";

import { onDateDDMMMYYYYHHMM } from "../../../app/utilities/helpers";
import no_content from "../../../assets/images/no_content.svg";
import avatarPlaceholder from "../../../assets/images/avatar-placeholder.png";
import "../Conversation.css";

const Activity = ({ activityContext }) => {
  const [noActivity, setNoActivity] = useState(false);
  const [events, setEvents] = useState([]);

  const eventPhrases = {
    share: "Shared",
    download: "Downloaded",
    email_open: "Opened Email",
    // link_open: "Opened Link",
    // link_close: "Closed Link",
    view_start: "Started Viewing",
    view_stop: "Stopped Viewing",
    video_play: "Played Video",
    video_pause: "Paused Video",
    video_stop: "Stopped Video",
    document_page_change: "Changed Document Page",
  };

  useEffect(() => {
    if (activityContext.data.activity && activityContext.data.activity.length) {
      let eventsRef = [];
      activityContext.data.activity.map((activityItem) => {
        if (activityItem.events && activityItem.events.length === 0) {
          setNoActivity(true);
        } else {
          eventsRef.push(...activityItem.events);
        }

        return true;
      });
      eventsRef.sort(function (a, b) {
        var dateA = new Date(a.created_at),
          dateB = new Date(b.created_at);
        return dateA - dateB;
      });
      eventsRef = eventsRef.filter(
        (event) =>
          event.event_name !== "link_open" && event.event_name !== "link_close"
      );
      setEvents(eventsRef);
    } else {
      setNoActivity(true);
    }
  }, [activityContext]);

  const handleActivityDescription = (e) => {
    let phrase = `<b>${e?.user?.name || e?.contact?.name}</b> ${
      eventPhrases[e.event_name]
    } `;
    if (activityContext.context === "recipient") {
      phrase = `${eventPhrases[e.event_name]}  <b>${
        e?.asset && e?.asset?.name ? e.asset.name : ""
      }</b>`;
    }
    phrase = `${phrase} on ${onDateDDMMMYYYYHHMM(e.updated_at)}`;

    return phrase;
  };

  return (
    <>
      <div className="flex flex-col p-5 pt-0">
        <div className="flex">
          <div className="flex items-center">
            {activityContext.context === "asset" ? (
              <AssetCard
                asset={activityContext.data}
                size="asset__small"
                className="md:w-20 md:h-20 w-11 h-11 conversation-asset"
              />
            ) : (
              <img
                src={avatarPlaceholder}
                alt="avatar"
                className="w-10 h-10 rounded-full shadow-xl"
                loading="lazy"
              />
            )}
          </div>
          <div className="flex flex-col justify-center pl-2">
            <div className="text-[#000000] font-normal pl-2 truncate max-w-[270px]">
              {activityContext.data?.name}
            </div>
            {/* <div className="flex pl-2 items-center text-[10px] font-normal">
              Last Viewed{" "}
              <div className="text-[#757575] ml-1 italic">
                {activityContext.data?.statistics?.last_viewed || "Never"}
              </div>
            </div> */}

            <div className="flex items-center px-2 text-[10px] text-[#757575] font-normal italic">
              Last Viewed
              <div className="ml-1">
                {activityContext.data?.statistics?.last_viewed || "Never"}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className={`${noActivity ? "" : "activity-show-cont"}`}>
          {events && events.length ? (
            <div className="h-[485px] overflow-x-hidden overflow-y-scroll">
              {events.reverse().map((event, index) => (
                <div
                  className="activity-lists-cont"
                  key={`act-${event.id}-${index}`}
                >
                  <div className="activity-list">
                    <div
                      className="activity-name"
                      style={{ display: index === 0 ? "block" : "none" }}
                    >
                      <div>
                        {activityContext.context === "asset"
                          ? "A"
                          : activityContext.data?.name.charAt(0).toUpperCase()}
                      </div>
                    </div>
                    <span
                      className="activity-dot"
                      style={{ display: index !== 0 ? "block" : "none" }}
                    ></span>
                  </div>
                  <span
                    className="activity-time"
                    dangerouslySetInnerHTML={{
                      __html: handleActivityDescription(event),
                    }}
                  ></span>
                </div>
              ))}
            </div>
          ) : (
            <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10 mt-20">
              <div className="w-full flex justify-center">
                <img
                  src={no_content}
                  alt="nothing to show illustration"
                  style={{ height: "60px" }}
                  loading="lazy"
                />
              </div>
              <div className="text-center flex items-center relative p-5 text-[#757575] text-14">
                Nothing to show. <FiInfo className="text-uberark-orange ml-1" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Activity;
