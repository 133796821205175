export const FETCH_CONVERSATIONS_LISTS_REQUEST = 'FETCH_CONVERSATIONS_LISTS_REQUEST'
export const FETCH_CONVERSATIONS_LISTS_SUCCESS = 'FETCH_CONVERSATIONS_LISTS_SUCCESS'
export const FETCH_CONVERSATIONS_LISTS_FAILED = 'FETCH_CONVERSATIONS_LISTS_FAILED'

export const DELETE_CONVERSATIONS_REQUEST = 'DELETE_CONVERSATIONS_REQUEST'
export const DELETE_CONVERSATIONS_SUCCESS = 'DELETE_CONVERSATIONS_SUCCESS'
export const DELETE_CONVERSATIONS_FAILED = 'DELETE_CONVERSATIONS_FAILED'

export const UPDATE_CONVERSATIONS_REQUEST = 'UPDATE_CONVERSATIONS_REQUEST'
export const UPDATE_CONVERSATIONS_SUCCESS = 'UPDATE_CONVERSATIONS_SUCCESS'
export const UPDATE_CONVERSATIONS_FAILED = 'UPDATE_CONVERSATIONS_FAILED'

export const PREVIEW_CONVERSATIONS_REQUEST = 'PREVIEW_CONVERSATIONS_REQUEST'
export const PREVIEW_CONVERSATIONS_SUCCESS = 'PREVIEW_CONVERSATIONS_SUCCESS'
export const PREVIEW_CONVERSATIONS_FAILED = 'PREVIEW_CONVERSATIONS_FAILED'
