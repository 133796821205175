import React from 'react';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { BsThreeDotsVertical } from 'react-icons/bs';
import { TbDiscount2 } from 'react-icons/tb';


const Parameter = ({ data, theme }) => {
  const themes = {
    orange: {
      iconBg: 'bg-[#FF890126]',
      iconClassName: 'text-uberark-orange',
      progressbarPathColor: '#ff4101'
    },
    blue: {
      iconBg: 'bg-[#00008026]',
      iconClassName: 'text-uberark-blue',
      progressbarPathColor: '#008'
    }
  }
  const { per, label, value } = data

  return (
    <div>
      <div className="flex justify-end">
        <BsThreeDotsVertical />
      </div>
      <div className="flex justify-between items-center">
        <div className="flex flex-wrap flex-row space-x-4 justify-center items-center w-full">
          <div className={`w-14 h-14 ${themes[theme].iconBg} rounded-2xl h-30 flex flex-row-reverse ... justify-center items-center`}>
            <TbDiscount2 size={25} className={themes[theme].iconClassName} />
          </div>
          <div className="flex flex-col space-x-4">
            <p>{label}</p>
            <p>{value}</p>
          </div>
          <div className="w-20 h-20">
            {' '}
            <CircularProgressbar
              value={per}
              text={`${per}%`}
              className="text-uberark-blue"
              styles={buildStyles({
                textColor: '#008',
                pathColor: themes[theme].progressbarPathColor,
                trailColor: '#FF890126'
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parameter;
