import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReactPaginate from "react-paginate";

import { RiArrowUpDownFill } from "react-icons/ri";
import { BsTrash, BsEyeFill } from "react-icons/bs";
import { RiEditCircleFill } from "react-icons/ri";

import { streamActions } from "../actions";
import DeleteConversation from "../../Conversations/components/DeleteConversation";
import "./tableCss.css";

const FileTable = ({
  assetsAndGroupData,
  deleteToggle,
  setDeleteToggle,
  editGroup,
  setEditGroup,
  setSelectedGroup,
  handleOpenGroup,
  onAssetClick,
  collaboratorPermission,
  handleGetAssetsAndGroups,
}) => {
  const dispatch = useDispatch();
  const { dltConfirm } = useSelector((state) => state.stream);
  const [assetsAndGroupTableData, setAssetsAndGroupTableData] = useState([]);
  const [order, setOrder] = useState("ASC");
  const [pageNumber, setPageNumber] = useState(0);
  const [displayChild, setDisplayChild] = useState({});
  const [dltType, setDltType] = useState(null);
  const [dltId, setDltId] = useState(null);
  const [selectedDltItem, setSelectedDltItem] = useState(null);
  const [deleteTableStream, setDeleteTableStream] = useState(false);
  // const [viewClicked, setViewClicked] = useState(false);

  const assetsAndGroupTableDataPerPage = 6;
  const pagesVisited = pageNumber * assetsAndGroupTableDataPerPage;
  const pageCount = Math.ceil(
    assetsAndGroupTableData.length / assetsAndGroupTableDataPerPage
  );

  useEffect(() => {
    if (assetsAndGroupData && assetsAndGroupData.length > 0) {
      // remove group_tree_index 0
      const filteredAssetsAndGroupData = assetsAndGroupData.filter(
        (item) => item.group_tree_index !== "0"
      );
      setAssetsAndGroupTableData(filteredAssetsAndGroupData);
    } else {
      setAssetsAndGroupTableData([]);
    }
  }, [assetsAndGroupData]);

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...assetsAndGroupTableData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setAssetsAndGroupTableData(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...assetsAndGroupTableData].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setAssetsAndGroupTableData(sorted);
      setOrder("ASC");
    }
  };

  const handleDisplayChild = (groupId) => {
    setDisplayChild((prevState) => ({
      ...prevState,
      [groupId]: !prevState[groupId],
    }));
  };

  const displayGrandChildren = (assetsAndGroupTableData) => {
    const assetsAndGroupTableDataParam = assetsAndGroupTableData;
    return assetsAndGroupTableDataParam
      ?.slice(pagesVisited, pagesVisited + assetsAndGroupTableDataPerPage)
      ?.map((grandChild) => {
        const groupId = grandChild?.group;
        return (
          <table key={grandChild.id} className="w-full table-fixed">
            <tr key={grandChild?.id} className="border-b-0">
              <td className="text-left">{grandChild?.name}</td>
              <td className="text-center">{grandChild?.views_count || 0}</td>
              <td className="text-center">{grandChild?.shares_count || 0}</td>
              <td className="text-center">
                {grandChild?.downloads_count || 0}
              </td>
              <td className="text-center">
                <div className="flex items-center justify-center">
                  {collaboratorPermission ? (
                    <div
                      className="file-table__delete"
                      onMouseDown={() =>
                        handleAssetsAndGroupSelect(
                          grandChild.id,
                          grandChild.file ? "asset" : "group",
                          grandChild
                        )
                      }
                    >
                      <BsTrash /> Delete
                    </div>
                  ) : null}
                  <div
                    className="file-table__delete"
                    onMouseDown={() => {
                      if (grandChild?.group_tree_index) {
                        handleDisplayChild(groupId);
                      } else {
                        onAssetClick(grandChild);
                      }
                    }}
                  >
                    <BsEyeFill /> View
                  </div>
                </div>
              </td>
            </tr>
          </table>
        );
      });
  };

  const displayChildren = (assetsAndGroupTableData) => {
    const assetsAndGroupTableDataParam = assetsAndGroupTableData;
    return assetsAndGroupTableDataParam
      ?.slice(pagesVisited, pagesVisited + assetsAndGroupTableDataPerPage)
      ?.map((assetsAndGroupTableDataParam) => {
        const groupId = assetsAndGroupTableDataParam?.id;
        const grandChildAssetsAndGroupData =
          assetsAndGroupTableDataParam?.group_tree_index
            ? handleGetAssetsAndGroups(assetsAndGroupTableDataParam)
            : [];

        return (
          <table className="w-full table-fixed">
            <tr key={assetsAndGroupTableDataParam?.id} className="border-b-0">
              <td className="text-left">
                {assetsAndGroupTableDataParam?.name}
              </td>
              <td className="text-center">
                {assetsAndGroupTableDataParam?.views_count || 0}
              </td>
              <td className="text-center">
                {assetsAndGroupTableDataParam?.shares_count || 0}
              </td>
              <td className="text-center">
                {assetsAndGroupTableDataParam?.downloads_count || 0}
              </td>
              <td className="text-center">
                <div className="flex items-center justify-center">
                  {collaboratorPermission ? (
                    <div
                      className="file-table__delete"
                      onMouseDown={() =>
                        handleAssetsAndGroupSelect(
                          assetsAndGroupTableDataParam.id,
                          assetsAndGroupTableDataParam.file ? "asset" : "group",
                          assetsAndGroupTableDataParam
                        )
                      }
                    >
                      <BsTrash /> Delete
                    </div>
                  ) : null}
                  {assetsAndGroupTableDataParam?.group_tree_index &&
                    collaboratorPermission && (
                      <>
                        <div
                          className="file-table__delete"
                          onMouseDown={() => {
                            setSelectedGroup(
                              () => assetsAndGroupTableDataParam.id
                            );
                            setEditGroup(!editGroup);
                          }}
                        >
                          <RiEditCircleFill /> Edit
                        </div>
                      </>
                    )}
                  <div
                    className="file-table__delete"
                    onMouseDown={() => {
                      if (assetsAndGroupTableDataParam?.group_tree_index) {
                        handleDisplayChild(groupId);
                      } else onAssetClick(assetsAndGroupTableDataParam);
                    }}
                  >
                    <BsEyeFill /> View
                  </div>
                </div>
              </td>
            </tr>
            {displayChild[groupId] &&
            assetsAndGroupTableDataParam?.group_tree_index ? (
              <tr>
                <td colSpan={5} className="p-2 border-b-0 bg-gray-100">
                  {grandChildAssetsAndGroupData.length > 0 ? (
                    displayGrandChildren(grandChildAssetsAndGroupData)
                  ) : (
                    <p className="w-full text-gray-500 text-center">
                      This group is empty.
                    </p>
                  )}
                </td>
              </tr>
            ) : null}
          </table>
        );
      });
  };

  const displayTableRows = assetsAndGroupTableData
    ?.slice(pagesVisited, pagesVisited + assetsAndGroupTableDataPerPage)
    ?.map((assetsAndGroupTableData) => {
      const groupId = assetsAndGroupTableData?.id;
      const childAssetsAndGroupData = assetsAndGroupTableData?.group_tree_index
        ? handleGetAssetsAndGroups(assetsAndGroupTableData)
        : [];
      return (
        <React.Fragment key={assetsAndGroupTableData?.id}>
          <tr
            className={`${displayChild[groupId] ? "border-b-0" : "border-b"}`}
          >
            <td className="text-left truncate">
              {assetsAndGroupTableData?.name}
            </td>
            <td className="text-center">
              {assetsAndGroupTableData?.views_count || 0}
            </td>
            <td className="text-center">
              {assetsAndGroupTableData?.shares_count || 0}
            </td>
            <td className="text-center">
              {assetsAndGroupTableData?.downloads_count || 0}
            </td>
            <td className="text-center">
              <div className="flex items-center justify-center">
                {collaboratorPermission ? (
                  <div
                    className="file-table__delete"
                    onMouseDown={() =>
                      handleAssetsAndGroupSelect(
                        assetsAndGroupTableData.id,
                        assetsAndGroupTableData.file ? "asset" : "group",
                        assetsAndGroupTableData
                      )
                    }
                  >
                    <BsTrash /> Delete
                  </div>
                ) : null}
                {assetsAndGroupTableData?.group_tree_index &&
                  collaboratorPermission && (
                    <>
                      <div
                        className="file-table__delete"
                        onMouseDown={() => {
                          setSelectedGroup(() => assetsAndGroupTableData.id);
                          setEditGroup(!editGroup);
                        }}
                      >
                        <RiEditCircleFill /> Edit
                      </div>
                    </>
                  )}
                <div
                  className="file-table__delete"
                  onMouseDown={() => {
                    if (assetsAndGroupTableData?.group_tree_index) {
                      handleDisplayChild(groupId);
                      // setViewClicked(!viewClicked);
                    } else {
                      onAssetClick(assetsAndGroupTableData);
                    }
                  }}
                >
                  <BsEyeFill /> View
                </div>
              </div>
            </td>
          </tr>
          {displayChild[groupId] &&
          assetsAndGroupTableData?.group_tree_index ? (
            <tr>
              <td colSpan={5} className="p-2 border-b-0 bg-gray-100">
                {childAssetsAndGroupData.length > 0 ? (
                  displayChildren(childAssetsAndGroupData)
                ) : (
                  <p className="w-full text-gray-500 text-center">
                    This group is empty.
                  </p>
                )}
              </td>
            </tr>
          ) : null}
        </React.Fragment>
      );
    });

  const displayTableSm = assetsAndGroupTableData
    ?.slice(pagesVisited, pagesVisited + assetsAndGroupTableDataPerPage)
    ?.map((assetsAndGroupTableData) => {
      return (
        <div
          key={assetsAndGroupTableData?.id}
          className="space-y-3 p-4 sm:grid-cols-2"
        >
          <div className="grid grid-flow-col justify-between">
            <div className="font-bold">Name:</div>
            <div className="truncate">{assetsAndGroupTableData?.name}</div>
          </div>
          <div className="grid grid-flow-col justify-between">
            <div className="font-bold">View:</div>
            {assetsAndGroupTableData?.views_count || 0}
          </div>
          <div className="grid grid-flow-col justify-between">
            <div className="font-bold">Shares:</div>
            {assetsAndGroupTableData?.shares_count || 0}
          </div>
          <div className="grid grid-flow-col justify-between">
            <div className="font-bold">Downloads:</div>
            {assetsAndGroupTableData?.downloads_count || 0}
          </div>
          <div className="flex items-center">
            <div
              className="file-table__delete"
              onMouseDown={() =>
                handleAssetsAndGroupSelect(
                  assetsAndGroupTableData.id,
                  assetsAndGroupTableData.file ? "asset" : "group",
                  assetsAndGroupTableData
                )
              }
            >
              <BsTrash /> Delete
            </div>
            {assetsAndGroupTableData?.group_tree_index && (
              <>
                <div
                  className="file-table__delete"
                  onMouseDown={() => {
                    setSelectedGroup(() => assetsAndGroupTableData.id);
                    setEditGroup(!editGroup);
                  }}
                >
                  <RiEditCircleFill /> Edit
                </div>
                <div
                  className="file-table__delete"
                  onMouseDown={() =>
                    handleOpenGroup(assetsAndGroupTableData.id)
                  }
                >
                  <BsEyeFill /> View
                </div>
              </>
            )}
          </div>
        </div>
      );
    });

  const changePage = ({ selected }) => setPageNumber(selected);

  const handleAssetsAndGroupSelect = (id, type, item) => {
    setSelectedDltItem(item);
    setDltType(type);
    setDltId(id);
    setDeleteTableStream(true);
  };

  const onConfirmDlt = (id, type) => {
    if (dltType === "group") {
      // delete group
      setDeleteTableStream(false);
      return dispatch(streamActions.handleDeleteGroup(dltId));
    }
    return dispatch(streamActions.handleDeleteAsset(dltId));
  };

  useEffect(() => {
    if (dltConfirm) {
      setDeleteTableStream(false);
    }
  }, [dltConfirm]);

  return (
    <div>
      <div className="overflow-auto hidden md:block min-h-[200px]">
        <table
          className="table-fixed border-collapse w-full text-uberark-blue mb-10"
          key={Math.floor(Math.random() * 1000)}
        >
          <thead>
            <tr className="border-b">
              <th className="border-r p-2">
                <div className="flex flex-row justify-between">
                  <span>Name</span>
                  <RiArrowUpDownFill
                    onClick={() => sorting("name")}
                    className="cursor-pointer"
                  />
                </div>
              </th>
              <th
                className="text-center border-r p-2"
                onClick={() => sorting("views_count")}
              >
                Views
              </th>
              <th
                className="text-center border-r p-2"
                onClick={() => sorting("shares_count")}
              >
                Shares
              </th>
              <th
                className="text-center border-r p-2"
                onClick={() => sorting("downloads_count")}
              >
                Downloads
              </th>
              <th
                className="text-center border-r p-2"
                onClick={() => sorting("downloads_count")}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>{displayTableRows}</tbody>
        </table>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:hidden m-4 p-4 mt-10 text-uberark-blue">
        {displayTableSm}
      </div>
      {pageCount && pageCount > 1 ? (
        <div className="">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            marginPagesDisplayed={0}
            pageRangeDisplayed={1}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      ) : null}
      <DeleteConversation
        visible={deleteTableStream}
        dltTitle={dltType}
        onClose={() => {
          setDeleteTableStream(false);
        }}
        onDeleteEvent={onConfirmDlt}
        deleteDetails={selectedDltItem}
        dltLoader={deleteTableStream}
      />
    </div>
  );
};

export default FileTable;
