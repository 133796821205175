import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FiXCircle } from "react-icons/fi";
import { BsArrowRightCircleFill } from "react-icons/bs";
import LoadingSpinner from "../../../components/commons/LoadingSpinner";
import { Switch } from "@headlessui/react";

import { settingsActions } from "../actions";

const AddEditCustomField = (props) => {
  const dispatch = useDispatch();
  const { loadingCustomFields: loading } = useSelector(
    (state) => state.settings
  );
  const [isMultipleSelect, setIsMultipleSelect] = useState(
    props && props.initialValues && props.initialValues.is_multi_select
      ? props.initialValues.is_multi_select
      : false
  );
  const [isEnableSearch, setIsEnableSearch] = useState(
    props && props.initialValues && props.initialValues.enable_in_search
      ? props.initialValues.enable_in_search
      : false
  );
  const [listValue, setListValue] = useState("");
  const [listValues, setListValues] = useState(
    props && props.initialValues && props.initialValues.list_value
      ? props.initialValues.list_value
      : []
  );
  const newCF =
    props &&
    props.initialValues &&
    props.initialValues.name !== undefined &&
    props.initialValues.name.length
      ? false
      : true;
  const addFormData = {
    name:
      props &&
      props.initialValues &&
      props.initialValues.name !== undefined &&
      props.initialValues.name
        ? props.initialValues.name
        : "",
    help_text:
      props && props.initialValues && props.initialValues.help_text
        ? props.initialValues.help_text
        : "",
    is_multi_select:
      props && props.initialValues && props.initialValues.is_multi_select
        ? props.initialValues.is_multi_select
        : "",
    enable_in_search:
      props && props.initialValues && props.initialValues.enable_in_search
        ? props.initialValues.enable_in_search
        : "",
  };
  const showCFForm = true;
  const [listValueValid, setListValueValid] = useState(false);

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Please enter first name"),
    help_text: Yup.string(),
  });

  const handleCreateCF = (data, e) => {
    let list_values = [];
    if (listValues.length) {
      listValues.forEach((item) => {
        list_values.push(item.name ? item.name : item);
      });

      let payload = {
        name: data.name,
        help_text: data.help_text,
        list_value: list_values,
        is_multi_select: isMultipleSelect ? "True" : "False",
        enable_in_search: isEnableSearch ? "True" : "False",
      };
      if (
        props &&
        props.initialValues &&
        props.initialValues.name !== undefined &&
        props.initialValues.name.length &&
        !newCF
      ) {
        payload["list_value"] = listValues;
        props?.onUpdateCF(payload);
      } else {
        return dispatch(
          settingsActions.handleCustomFieldCreate(payload, onCFClose)
        );
      }
    } else {
      setListValueValid(true);
    }
  };

  const onCFClose = () => {
    if (props && props.initialValues) {
      props?.onCloseCF();
    }
  };

  const onLVChange = () => {
    let lv = listValues;
    if (listValue) {
      const lvExists = lv.filter((item) => item.name === listValue.name);
      if (lvExists.length === 0) {
        lv.push({ name: listValue });
        setListValues([]);
        setListValue("");
        setTimeout(() => {
          setListValues(lv);
        }, 500);
      }
    }
  };

  const onRemoveLV = (value) => {
    const listValuesRef = [...listValues];
    listValues.map((listValue, index) => {
      if (value.id && listValue.name === value.name) {
        listValuesRef[index]["deleted"] = true;
      } else if (!value.id && listValue.name === value.name) {
        listValuesRef.splice(index, 1);
      }
      return true;
    });
    setListValues([]);
    setListValues(() => listValuesRef);
  };

  return (
    <div
      className={`${
        props && props.initialValues ? `` : `rounded-2xl`
      } bg-gradient-to-r w-96 p-4 shadow grid relative`}
      style={{ marginTop: window.innerWidth < 768 ? "2rem" : "0" }}
    >
      <div className="grid px-2 py-2">
        <span className="md:block font-bold text-xl text-[#000080]">
          {`${
            props &&
            props.initialValues &&
            props.initialValues.name !== undefined &&
            String(props.initialValues.name).length
              ? "Edit"
              : "Add"
          } Custom Field`}
        </span>
      </div>
      {showCFForm ? (
        <Formik
          enableReinitialize
          initialValues={addFormData}
          onSubmit={handleCreateCF}
          validationSchema={FormSchema}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form>
              <div>
                <div className="grid lg:gap-4 gap-6 lg:mb-4 mb-6">
                  <div className="mb-1">
                    <label className="font-medium text-uberark-blue">
                      <span>Name</span>
                      <span className="text-red-500 mr-0">*</span>:
                    </label>
                    <div className="relative">
                      <Field
                        type="text"
                        placeholder="Name"
                        name="name"
                        className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                      />
                      {errors.name && touched.name && (
                        <div
                          style={{ bottom: -25 }}
                          className="text-red-600 absolute"
                        >
                          {errors.name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mb-1">
                    <label className="font-medium text-uberark-blue">
                      <span>Help Text:</span>
                    </label>
                    <div className="relative">
                      <Field
                        type="text"
                        placeholder="Help Text"
                        name="help_text"
                        className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                      />
                    </div>
                  </div>
                  <div className="mb-1">
                    <label className="font-medium text-uberark-blue">
                      <span>List Value:</span>
                      <span className="text-red-500 mr-0">*</span>:
                    </label>
                    <div className="relative">
                      {listValues && listValues.length ? (
                        <div className="email-chip-wrap">
                          {listValues
                            .filter((value) => !value.deleted)
                            .map((t, i) => (
                              <p key={t.id || Math.floor(Math.random() * 101)}>
                                {t.name ? t.name : t}{" "}
                                <FiXCircle onClick={() => onRemoveLV(t)} />
                              </p>
                            ))}
                        </div>
                      ) : null}
                      <div className="relative">
                        <Field
                          type="text"
                          placeholder="List Value"
                          name="list_value"
                          className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                          onChange={(e) => {
                            e.preventDefault();
                            setListValue(e.target.value);
                            setListValueValid(false);
                          }}
                          value={listValue}
                        />
                        {listValueValid && (
                          <div
                            style={{ bottom: -25 }}
                            className="text-red-600 absolute"
                          >
                            Please add one list value
                          </div>
                        )}
                        <span className="absolute top-[10px] right-2 cursor-pointer">
                          <BsArrowRightCircleFill
                            size={20}
                            className="text-[#666666]"
                            onClick={() => onLVChange()}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="flex justify-between items-center w-1/2 pl-2 py-2">
                      <div>Is multiple Select</div>{" "}
                      <Switch
                        checked={isMultipleSelect}
                        onChange={() => setIsMultipleSelect(!isMultipleSelect)}
                        className={`${
                          isMultipleSelect
                            ? " bg-uberark-blue"
                            : " bg-uberark-orange"
                        }
              relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                      >
                        <span
                          aria-hidden="true"
                          className={`${
                            isMultipleSelect ? "translate-x-5" : "translate-x-0"
                          }
                pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                        />
                      </Switch>{" "}
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="flex justify-between items-center w-1/2 pl-2 py-2">
                      <div>Enable in Search</div>{" "}
                      <Switch
                        checked={isEnableSearch}
                        onChange={() => setIsEnableSearch(!isEnableSearch)}
                        className={`${
                          isEnableSearch
                            ? " bg-uberark-blue"
                            : " bg-uberark-orange"
                        }
              relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                      >
                        <span
                          aria-hidden="true"
                          className={`${
                            isEnableSearch ? "translate-x-5" : "translate-x-0"
                          }
                pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                        />
                      </Switch>{" "}
                    </div>
                  </div>
                </div>

                <div className="flex flex-row justify-center text-uberark-blue gap-4 text-lg lg:justify-end">
                  <button
                    type="submit"
                    disabled={loading}
                    className="leading-tight bg-[#000080] hover:bg-white text-white hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 focus:shadow-xl focus:outline-none focus:ring-0 active:shadow-lg hover:scale-105 ease-in-out duration-300 rounded-2xl"
                  >
                    {loading ? <LoadingSpinner /> : "Save"}
                  </button>

                  <button
                    className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
                    style={{ border: "0.24px solid #000080" }}
                    type="button"
                    onClick={() => onCFClose()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : null}
    </div>
  );
};

export default AddEditCustomField;
