import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ReactPaginate from "react-paginate";
import {
  HtmlEditor,
  Inject,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { BiSearch, BiLink } from "react-icons/bi";
import { FiXCircle, FiCheck } from "react-icons/fi";
import { FiInfo } from "react-icons/fi";
import {
  BsReverseLayoutTextWindowReverse,
  BsCardHeading,
  BsTrash,
  BsFillFileEarmarkPlusFill,
} from "react-icons/bs";
import { RiEditCircleFill } from "react-icons/ri";
import { settingsActions } from "./actions";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "../../components/commons/LoadingSpinner";
import {
  onDateDDMMMYYYY,
  RichTextEditorToolbar,
} from "../../app/utilities/helpers";
import "./settings.css";

const EmailTemplates = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { eTemplateList, getTemplate } = props;
  const eTemplateTableOnly = eTemplateList ? eTemplateList : false;
  const { organization } = useSelector((state) => state.auth.user);
  const { eTemplate, loading, eTemplateCreation, eTemplateDeleteConfirmation } =
    useSelector((state) => state.settings);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [showETemplate, setShowETemplate] = useState(false);
  const [formEdit, setFormEdit] = useState(null);
  const [initialValues, setInitialValues] = useState({
    name: "",
    public: "true",
    subject: "",
    description: "",
  });
  const [noData, setNoData] = useState(true);
  let [selectTemplate, setSelectTemplate] = useState("");

  const templatePerPage = 5;
  const pagesVisited = pageNumber * templatePerPage;

  const displayTemplate =
    eTemplate &&
    eTemplate.length &&
    eTemplate
      .filter(
        (val) =>
          searchTerm === "" ||
          val.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
          val.subject.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
          val.public.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      )
      .slice(pagesVisited, pagesVisited + templatePerPage)
      .map((template) => {
        return (
          <tr key={template.id}>
            <td
              onClick={() => onTableRow(template)}
              className={eTemplateTableOnly ? "relative cursor-pointer" : ""}
            >
              {template.name}{" "}
              {template.id === selectTemplate ? (
                <span style={{ left: -5 }} className="absolute float-left p-1">
                  <FiCheck />
                </span>
              ) : null}
            </td>
            {!eTemplateTableOnly ? (
              <td className="text-center">{template.subject}</td>
            ) : null}
            {!eTemplateTableOnly ? (
              <td className="text-center">
                {onDateDDMMMYYYY(template.updated_at)}{" "}
              </td>
            ) : null}
            {!eTemplateTableOnly ? (
              <td className="text-center text-uberark-orange text-xs font-semibold">
                <span
                  className="rounded-[10px] px-2"
                  style={{ border: "1px solid #000080" }}
                >
                  {template.public ? "Public" : "Private"}
                </span>
              </td>
            ) : null}

            {!eTemplateTableOnly ? (
              <td>
                <div className="flex justify-center">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      <div
                        className="flex items-center mx-1"
                        onMouseDown={() => onEdit(template)}
                      >
                        <RiEditCircleFill /> Edit
                      </div>
                      <div
                        className="flex items-center mx-1"
                        onMouseDown={() => onDelete(template)}
                      >
                        <BsTrash /> Delete
                      </div>
                    </>
                  )}
                </div>
              </td>
            ) : null}
          </tr>
        );
      });

  const pageCount =
    eTemplate && eTemplate.length > 0
      ? Math.ceil(eTemplate.length / templatePerPage)
      : 0;

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Please enter template"),
    public: Yup.string(),
    subject: Yup.string().required("Please enter description"),
    description: Yup.string().required("Please enter description"),
  });

  useEffect(() => {
    if (
      ((!eTemplate || eTemplate.length === 0) && noData) ||
      eTemplateCreation ||
      eTemplateDeleteConfirmation
    ) {
      if (organization && organization !== "None") {
        dispatch(
          settingsActions.handleETemplateListsFetch(
            `email/templates?organization=${organization}`
          )
        );
        setShowETemplate(false);
        setNoData(false);
      }
    }
  }, [
    dispatch,
    eTemplate,
    eTemplateCreation,
    eTemplateDeleteConfirmation,
    noData,
    organization,
  ]);

  const onOpenCloseETemplateForm = () => {
    setFormEdit(null);
    setInitialValues({
      name: "",
      public: "true",
      subject: "",
      description: "",
    });
    setShowETemplate(!showETemplate);
  };

  const handleCreateETemplate = (data) => {
    const payload = {
      name: data.name.trim(),
      subject: data.subject.trim(),
      message: data.description.trim(),
      public: JSON.parse(data.public.trim()),
    };
    return formEdit
      ? dispatch(settingsActions.handleETemplateUpdate(payload, formEdit))
      : dispatch(settingsActions.handleETemplateCreation(payload));
  };

  const onEdit = (eTemplateItem) => {
    setInitialValues({
      name: eTemplateItem.name,
      public: String(eTemplateItem.public),
      subject: eTemplateItem.subject,
      description: eTemplateItem.message,
    });
    setFormEdit(eTemplateItem.id);
    setShowETemplate(true);
  };

  const onDelete = (eTemplateItem) => {
    return dispatch(settingsActions.handleETemplateDelete(eTemplateItem.id));
  };

  const onTableRow = (t) => {
    if (getTemplate) {
      getTemplate(t);
      setSelectTemplate(t.id);
    }
  };

  return (
    <div
      className={`${
        eTemplateTableOnly
          ? "bg-slate-50 p-6"
          : "md:p-14 bg-white rounded-xl md:m-4 p-2"
      } w-full flex flex-col`}
    >
      {!eTemplateTableOnly ? (
        <div className="font-bold text-uberark-orange text-xl my-4 space-between-flex">
          <span>
            {showETemplate
              ? formEdit
                ? "Edit Email Template"
                : "Create Email Template"
              : "Email Templates"}
          </span>
          <TooltipComponent
            content={
              formEdit ? "Edit Email Templates" : "Create Email Templates"
            }
            position="BottomCenter"
          >
            <button
              type="button"
              onClick={() => onOpenCloseETemplateForm()}
              style={{ color: "#008" }}
              className="relative text-base rounded-full p-3 hover:bg-light-gray flex items-center"
            >
              {showETemplate ? (
                <FiXCircle />
              ) : (
                <>
                  <BsFillFileEarmarkPlusFill />
                </>
              )}
            </button>
          </TooltipComponent>
        </div>
      ) : (
        <div className="font-bold text-uberark-blue mt-3 space-between-flex">
          <span>Email Templates</span>
        </div>
      )}
      {!showETemplate ? (
        <React.Fragment>
          <div className="my-4">
            <label className="relative flex w-full">
              <span className="sr-only">Search</span>
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <BiSearch className="w-5 h-5" />
              </span>
              <input
                className="placeholder:text-slate-400 flex bg-white w-full h-12 rounded-2xl py-2 pl-9 pr-3 border focus:outline-none sm:text-sm "
                placeholder="Search"
                type="text"
                name="search"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </label>
          </div>
          <div className="table-auto border-collapse w-full text-uberark-blue mb-10 my-4">
            <div
              className="md:block"
              style={{ overflow: window.innerWidth < 768 ? "scroll" : "none" }}
            >
              <table className="table-auto border-collapse w-full text-uberark-blue">
                {!eTemplateTableOnly ? (
                  <thead>
                    <tr>
                      <th className="text-left">Name</th>
                      <th className="text-center">Subject</th>
                      <th className="text-center">Modified</th>
                      <th className="text-center">Public</th>
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                ) : null}

                {!loading && displayTemplate ? (
                  <tbody>{displayTemplate}</tbody>
                ) : (
                  <>
                    {eTemplateTableOnly ? (
                      <div className="flex flex-col items-center w-full">
                        <div>No templates found</div>
                        <button
                          onClick={() => navigate(`/UserProfile/Etemplates`)}
                          className="flex text-[#FE4502] items-center hover:text-[#000080] px-2"
                        >
                          <BiLink /> Create Template
                        </button>
                      </div>
                    ) : (
                      <tbody>
                        <td
                          colspan="5"
                          className="text-center w-full"
                          style={{ border: "none" }}
                        >
                          <div className="text-center flex items-center justify-center w-full">
                            No Templates found
                            <FiInfo className="text-uberark-orange ml-1" />
                          </div>
                        </td>
                      </tbody>
                    )}
                  </>
                )}
              </table>
            </div>
            {pageCount > 1 ? (
              <div className="py-4">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  marginPagesDisplayed={0}
                  pageRangeDisplayed={1}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </React.Fragment>
      ) : (
        <div className="flex flex-col gap-10">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleCreateETemplate}
            validationSchema={FormSchema}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <div className="flex lg:flex-row flex-col justify-between items-center gap-2 mb-1">
                  <div className="mb-1">
                    <label className="font-medium text-uberark-blue">
                      Template Name <span className="text-red-500">*</span>:{" "}
                    </label>
                    <div className="mb-3 relative w-full">
                      <Field
                        className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                        placeholder="Enter Template Name"
                        name="name"
                        id="name"
                      />

                      <span className="absolute top-[10px] right-2">
                        <BsReverseLayoutTextWindowReverse
                          size={20}
                          className="text-[#666666]"
                        />
                      </span>
                    </div>
                  </div>

                  <div className="flex justify-between items-center w-3/5 p-8">
                    <label className="text-[12px] text-[#404040] flex items-center gap-2">
                      <Field type="radio" name="public" value="true" />
                      <span> Set As Public</span>
                    </label>
                    <label className="text-[12px] text-[#404040] flex items-center gap-2">
                      <Field type="radio" name="public" value="false" />
                      <span>Set As Private</span>
                    </label>
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col justify-between items-center gap-2">
                  {errors.name && touched.name && (
                    <div className="text-red-600 pb-2">{errors.name}</div>
                  )}
                </div>

                <div className="mb-1">
                  <label className="font-medium text-uberark-blue">
                    Subject <span className="text-red-500">*</span>:{" "}
                  </label>
                  <div className="mb-3 relative w-full">
                    <Field
                      className="floating-input pl-2 text-lg text-uberark-blue pr-30"
                      placeholder="Enter Subject"
                      name="subject"
                      id="subject"
                    />
                    {errors.subject && touched.subject && (
                      <div className="text-red-600 pb-2">{errors.subject}</div>
                    )}

                    <span className="absolute top-[10px] right-2">
                      <BsCardHeading size={20} className="text-[#666666]" />
                    </span>
                  </div>
                </div>
                <div className="pb-2">
                  <div className="mb-1">
                    <label className="font-medium">
                      Description <span className="text-red-500">*</span>:
                    </label>
                  </div>
                  <RichTextEditorComponent
                    change={(event) => {
                      setFieldValue("description", event.value);
                    }}
                    value={values.description}
                    toolbarSettings={RichTextEditorToolbar}
                  >
                    <Inject services={[HtmlEditor, Toolbar, QuickToolbar]} />
                  </RichTextEditorComponent>
                  {errors.description && touched.description && (
                    <div className="text-red-600 pb-2">
                      {errors.description}
                    </div>
                  )}
                </div>
                <div className="flex lg:justify-end flex-row text-uberark-blue gap-4 text-lg mb-3 mt-3">
                  <button
                    className=":px-2 bg-[#000080] text-white hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl "
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <LoadingSpinner /> : "Save"}
                  </button>
                  <button
                    className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
                    style={{ border: "0.24px solid #000080" }}
                    type="button"
                    onClick={() => onOpenCloseETemplateForm()}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default EmailTemplates;
