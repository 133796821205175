import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "../../../../../components/commons/LoadingSpinner";
import { FiUploadCloud } from "react-icons/fi";
import { BsFileEarmark } from "react-icons/bs";
import FileViewer from "react-file-viewer";

const AssetAddUpload = ({ handleClose, handleCreateAsset }) => {
  const { loadingAssets, fileLoading } = useSelector((state) => state.stream);
  const [progress, setProgress] = useState(0);
  const [fileValid, setFileValid] = useState(false);
  const supported = ["jpeg", "jpg", "png", "mp4"];

  const initialValues = {
    file: "",
  };

  const FormSchema = Yup.object().shape({
    file: Yup.mixed().required("File is required"),
  });

  const onProgress = () => {
    if (fileValid) {
      setProgress(5);
    }
  };

  useEffect(() => {
    if (fileLoading === "success" && progress !== 0 && !loadingAssets) {
      setProgress(100);
    } else if (fileLoading === "progress") {
      let uploadProgress = progress + 5;
      if (uploadProgress !== 100) {
        setProgress(uploadProgress);
      }
    } else if (fileLoading === "failure") {
      if (progress !== 0) {
        setProgress(0);
      }
    }
  }, [fileLoading, loadingAssets]); //eslint-disable-line

  return (
    <div className="mt-1">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleCreateAsset}
        validationSchema={FormSchema}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form>
            <div className="flex flex-col justify-center items-center">
              <div className="w-3/4 bg-transparent flex flex-col items-center justify-center p-4">
                <label className="bg-transparent flex items-center justify-center text-[#000080] hover:drop-shadow-lg w-36 h-12 hover:scale-105 ease-in-out duration-200">
                  {values.file ? (
                    <div
                      key={Math.random()}
                      className="flex items-center flex-col"
                      style={{ width: "100%" }}
                    >
                      {supported.includes(
                        values.file.type.split("/").at(-1)
                      ) ? (
                        <FileViewer
                          fileType={values.file.type.split("/").at(-1)}
                          filePath={URL.createObjectURL(values.file)}
                          errorComponent={<FiUploadCloud size={70} />}
                          onError={() => {
                            console.log("error");
                          }}
                        />
                      ) : (
                        <div className="">
                          <BsFileEarmark className="text-[#000080] h-[70px] drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]" />
                        </div>
                      )}
                      <br />
                    </div>
                  ) : (
                    <FiUploadCloud size={70} />
                  )}
                </label>

                <label
                  className="bg-transparent flex flex-row items-center justify-center text-[#000080] hover:drop-shadow-lg w-36 h-10 rounded-3xl hover:scale-105 ease-in-out duration-200 mt-3"
                  style={{
                    border: "0.24px solid #000080",
                    pointerEvents: loadingAssets ? "none" : "auto",
                  }}
                  type="button"
                >
                  {values.file ? <span>Change File</span> : <span>Browse</span>}
                  <input
                    className="floating-input h-14 pl-2 text-lg text-uberark-blue hidden"
                    name="file"
                    id="file"
                    type="file"
                    onChange={(event) => {
                      setFieldValue("file", event.currentTarget.files[0]);
                      setFileValid(true);
                    }}
                  />
                </label>
                {errors.file && touched.file && (
                  <div className="text-red-600 pb-2">{errors.file}</div>
                )}
              </div>
            </div>
            <div className="flex flex-row items-center text-uberark-blue gap-4 text-lg mt-4">
              <button
                className=":px-2 bg-[#000080] text-white  hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl"
                type="submit"
                disabled={loadingAssets || !fileValid}
                onClick={() => onProgress()}
              >
                {loadingAssets ? <LoadingSpinner /> : "Save"}
              </button>
              <button
                className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
                onClick={() => handleClose(false)}
                style={{ border: "0.24px solid #000080" }}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AssetAddUpload;
