import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { MdOutlineEmail } from "react-icons/md";
import { FiFilter } from "react-icons/fi";

import { settingsActions } from "./actions";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const Integrations = () => {
  const dispatch = useDispatch();
  const {
    user: { gmail_refresh_token },
  } = useSelector((state) => state.auth);

  const handleGmailAuth = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        handleGenerateRefreshToken(response.code);
      } catch (err) {
        console.log(err);
      }
    },
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/gmail.send",
  });

  const handleGenerateRefreshToken = (code) => {
    let payload = {
      grant_type: "authorization_code",
      code: code,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
    };

    axios
      .post(`https://oauth2.googleapis.com/token`, payload, {
        headers: {
          "Content-Type": "application/json;",
        },
      })
      .then((res) => {
        return res.data;
      })
      .then((response) => {
        dispatch(
          settingsActions.handleGmailAuth({
            refresh_token: response.refresh_token,
            name: "gmail",
          })
        );
      })
      .catch((err) => console.log("err: ", err));
  };

  return (
    <div className="bg-white rounded-xl md:m-4 md:p-14 p-2 w-full flex flex-col">
      <div className="flex justify-between">
        <span className="font-bold text-uberark-orange text-xl">
          {" "}
          Integration Settings
        </span>
        <div className="flex">
          <button className="flex items-center gap-2 text-[#757575]">
            <span className=""> Filter</span>
            <FiFilter />
          </button>
        </div>
      </div>
      <div className="flex flex-row flex-wrap gap-1 pt-5 justify-between">
        <div
          className="flex flex-col items-center justify-center rounded-2xl w-[48%] h-45v p-5"
          style={{
            border: gmail_refresh_token
              ? "0.24px solid #000080"
              : "0.24px solid whitesmoke",
          }}
          onMouseDown={() =>
            gmail_refresh_token
              ? dispatch(settingsActions.handleGmailAuth({ name: "gmail" }))
              : handleGmailAuth()
          }
        >
          <MdOutlineEmail className="text-uberark-orange" size={50} />
          <span>Gmail</span>
          <span className="text-[#757575] text-[9px] text-center">
            Integrate your gmail account. Share from your Uberark account using
            your gmail profile. This integration helps keep your conversations
            in sync with your mailbox.{" "}
          </span>
          <span>{gmail_refresh_token ? "Deactivate" : "Activate"}</span>
        </div>

        {/* <div
          className="flex flex-col items-center justify-center rounded-2xl w-[48%] h-45v p-5"
          style={{ border: '0.24px solid #000080' }}
        >
          <MdOutlineEmail className="text-uberark-orange" size={50} />
          <span>Outlook</span>
          <span className="text-[#757575] text-[7px] text-center">
            Integrate your gmail account. Share from your Ubermark account using
            your gmail profile. This integration helps keep your onversationsin
            sync wiht your mailbox.{' '}
          </span>
        </div>
        <div
          className="flex flex-col items-center justify-center rounded-2xl w-[48%] h-45v p-5"
          style={{ border: '0.24px solid #000080' }}
        >
          <MdOutlineEmail className="text-uberark-orange" size={50} />
          <span>STMP</span>
          <span className="text-[#757575] text-[7px] text-center">
            Integrate with your STMP to share content from your STMP service
            provider.
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default Integrations;
