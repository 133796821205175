import React, { useState } from 'react';

import Chart from 'react-apexcharts';

const Line = () => {
  const [state] = useState({
    options: {
      // grid: {
      //   padding: {
      //     left: 30, // or whatever value that works
      //     right: 30, // or whatever value that works
      //   },
      // },
      colors: ['#FF4101', '#000080'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'month',
      },
      // legend: {
      //   position: 'top',
      //   horizontalAlign: 'left',
      // },
    },
    series: [
      {
        name: 'Views',
        data: [40, 35, 50, 49, 60, 70, 91, 125, 10, 11, 12, 12],
      },
      {
        name: 'Downloads',
        data: [30, 45, 55, 29, 30, 90, 101, 95, 50, 49, 60, 12],
      },
    ],
  });
  return (
    <div>
      <Chart
        series={state.series}
        options={state.options}
        type="area"
        width="100%"
        height={370}
        stacked="true"
        // overflow-x-scroll=" overflow-x: scroll"
      />
    </div>
  );
};

export default Line;
