import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Switch } from "@headlessui/react";
import LoadingSpinner from "../../components/commons/LoadingSpinner";
import { settingsActions } from "./actions";
import { BsTrash } from "react-icons/bs";

import "./settings.css";

const PrivacyControl = (props) => {
  const dispatch = useDispatch();
  const {
    privacyPolicy,
    privacyPolicyCreation,
    privacyPolicyDeleteConfirmation,
  } = useSelector((state) => state.settings);
  const [enabled, setEnabled] = useState(false);
  const [enablePrivacyPolicy, setEnablePrivacyPolicy] = useState(
    "use_ubermark_privacy_policy"
  );
  const [enableConsent, setEnableConsent] = useState("none_consent");
  const [customPolicyURL, setCustomPolicyURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [privacyUpdate, setPrivacyUpdate] = useState(
    privacyPolicy && privacyPolicy.length ? true : false
  );
  const [noData, setNoData] = useState(false);

  const onPrivacyPolicySubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let payload = {
      uberark_policy_url: "",
      custom_policy_url: customPolicyURL,
      custom_policy_content: "",
      constent_mandatory: enableConsent === "mandatory_consent" ? true : false,
      constent_optional: enableConsent === "optional_consent" ? true : false,
      constent_none: enableConsent === "none_consent" ? true : false,
      constent_mandatory_eu_only: enabled,
    };
    return privacyUpdate && privacyPolicy && privacyPolicy.length
      ? dispatch(
          settingsActions.handlePrivacyPolicyUpdate(
            payload,
            privacyPolicy[0]["id"]
          )
        )
      : dispatch(settingsActions.handlePrivacyPolicyCreate(payload));
  };

  const onPPCDelete = () => {
    return privacyUpdate && privacyPolicy && privacyPolicy.length
      ? dispatch(
          settingsActions.handlePrivacyPolicyDelete(privacyPolicy[0]["id"])
        )
      : true;
  };

  useEffect(() => {
    if (
      ((!privacyPolicy || privacyPolicy.length === 0) && !noData) ||
      privacyPolicyCreation ||
      privacyPolicyDeleteConfirmation
    ) {
      setLoading(false);
      setNoData(true);
      setEnabled(false);
      setEnablePrivacyPolicy("use_ubermark_privacy_policy");
      setEnableConsent("none_consent");
      setCustomPolicyURL("");
      setPrivacyUpdate(false);
      return dispatch(settingsActions.handlePrivacyPolicyListsFetch("privacy"));
    }
    if (privacyPolicyDeleteConfirmation) {
      setPrivacyUpdate(false);
    }
    if (privacyPolicy && privacyPolicy.length) {
      let privacyPolicyData = privacyPolicy[0];
      setEnabled(privacyPolicyData["constent_mandatory_eu_only"]);
      setEnableConsent(
        privacyPolicyData["constent_mandatory"]
          ? "mandatory_consent"
          : privacyPolicyData["constent_optional"]
          ? "optional_consent"
          : "none_consent"
      );
      setCustomPolicyURL(privacyPolicyData["custom_policy_url"]);
      setEnablePrivacyPolicy(
        privacyPolicyData["custom_policy_url"].length
          ? "use_custom_privacy_policy"
          : "use_ubermark_privacy_policy"
      );
      setPrivacyUpdate(true);
    }
  }, [
    privacyPolicy,
    privacyPolicyCreation,
    privacyPolicyDeleteConfirmation,
    noData,
    dispatch,
  ]);

  return (
    <div
      className={
        props && props.removeClass
          ? ""
          : "bg-white rounded-xl md:m-4 md:p-14 p-2 w-full flex flex-col"
      }
    >
      <form onSubmit={onPrivacyPolicySubmit}>
        <div className="flex justify-between mb-4">
          <div className="flex flex-col text-">
            <div className="border-b-2 font-bold text-uberark-orange text-xl space-between-flex">
              Privacy Policy Control
              <div className="flex">
                {privacyUpdate ? (
                  <button
                    type="button"
                    onClick={() => onPPCDelete()}
                    style={{ color: "#008" }}
                    className="relative text-base rounded-full p-3 hover:bg-light-gray flex items-center"
                  >
                    <BsTrash />
                    &nbsp;Delete
                  </button>
                ) : null}
              </div>
            </div>
            <span className="text-[10px] pt-4">
              Links to privacy policy appear for your recipients when any
              content is shared. Please choose from Ubermark standard privacy
              policy or set your own custom privacy policy.
            </span>
          </div>
        </div>
        {/* ----------------------------------------------------------------------------------------------- */}

        <div className="flex flex-row gap-8 mb-4">
          <div className="flex flex-row  gap-2">
            <label className="text-[10px] text-[#404040] flex gap-2 cursor-pointer items-center">
              <input
                type="radio"
                name="privacy_policy"
                id="sPublic"
                onChange={(e) => setEnablePrivacyPolicy(e.target.value)}
                value={"use_ubermark_privacy_policy"}
                checked={
                  enablePrivacyPolicy === "use_ubermark_privacy_policy"
                    ? true
                    : false
                }
              />{" "}
              Use Ubermark privacy policy
            </label>
          </div>
          <div className="flex flex-row  gap-2 relative">
            <label className="text-[10px] text-[#404040] flex gap-2 cursor-pointer items-center">
              <input
                type="radio"
                name="privacy_policy"
                id="sPublic"
                onChange={(e) => setEnablePrivacyPolicy(e.target.value)}
                value={"use_custom_privacy_policy"}
                checked={
                  enablePrivacyPolicy === "use_custom_privacy_policy"
                    ? true
                    : false
                }
              />{" "}
              Set custom privacy policy
            </label>
            {enablePrivacyPolicy === "use_custom_privacy_policy" ? (
              <>
                <div
                  className="flex items-center"
                  style={{ position: "absolute", bottom: -40 }}
                >
                  <input
                    placeholder="Enter Custom Policy URL"
                    className="p-2 outline-none"
                    name="custom_policy_url"
                    id="custom_policy_url"
                    value={customPolicyURL}
                    onChange={(e) => setCustomPolicyURL(e.target.value)}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div>
          <div className="border-b-2 font-bold text-uberark-orange text-xl">
            Consent Type
          </div>
          <div className="flex flex-col border-b-2 my-4">
            <div className="flex flex-row items-center gap-2">
              <label className="text-uberark-blue flex gap-2 cursor-pointer">
                <input
                  type="radio"
                  name="consent"
                  onChange={(e) => setEnableConsent(e.target.value)}
                  id="sDefult"
                  value={"mandatory_consent"}
                  checked={enableConsent === "mandatory_consent" ? true : false}
                />
                Mandatory Consent
              </label>
            </div>
            <span className="text-[10px] p-2 px-6">
              The recipient has to accept the privacy policy and express consent
              that will be recorded. If not, they will not be able to view your
              content.
            </span>
          </div>
          <div className="flex flex-col border-b-2 my-4">
            <div className="flex flex-row items-center gap-2">
              <label className="text-uberark-blue flex gap-2 cursor-pointer">
                <input
                  type="radio"
                  name="consent"
                  onChange={(e) => setEnableConsent(e.target.value)}
                  id="sDefult"
                  value={"optional_consent"}
                  checked={enableConsent === "optional_consent" ? true : false}
                />
                Optional Consent
              </label>
            </div>
            <span className="text-[10px] p-2 px-6">
              The recipient has to accept the privacy policy and express consent
              that will be recorded. If not, they will not be able to view your
              content.
            </span>
          </div>
          <div className="flex flex-col border-b-2 my-4">
            <div className="flex flex-row items-center gap-2">
              <label className="text-uberark-blue flex gap-2 cursor-pointer">
                <input
                  type="radio"
                  name="consent"
                  onChange={(e) => setEnableConsent(e.target.value)}
                  id="sDefult"
                  value={"none_consent"}
                  checked={enableConsent === "none_consent" ? true : false}
                />
                <span> No Consent</span>
              </label>
            </div>
            <span className="text-[10px] p-2 px-6">
              The recipient has to accept the privacy policy and express consent
              that will be recorded. If not, they will not be able to view your
              content.
            </span>
          </div>
          <div>
            <p className="text-[10px] pb-4 text-uberark-blue font-semibold">
              Request consent for recipients ONLY from the EU region. Turning
              this OFF will request consent from all recipients across the globe
              per settings above.
            </p>
            <Switch
              checked={enabled}
              onChange={setEnabled}
              className={`${enabled ? " bg-[#DBDBDB]" : " bg-[#DBDBDB]"}
          relative inline-flex items-center h-5 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              {/* <span className="sr-only">Use setting</span> */}
              <span
                aria-hidden="true"
                className={`${enabled ? "translate-x-5" : "translate-x-0"}
            pointer-events-none inline-flex h-6 w-6 transform rounded-2xl bg-uberark-blue shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
        </div>

        <div className="flex flex-row justify-center lg:justify-end text-uberark-blue gap-4 p-2 text-lg">
          <button
            className=":px-2 bg-[#000080] hover:bg-white text-white hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl  "
            type="submit"
            disabled={loading}
          >
            {loading ? <LoadingSpinner /> : "Save"}
          </button>
          <button
            className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
            style={{ border: "0.24px solid #000080" }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default PrivacyControl;
