export const ACCOUNT_MANAGEMENT_REQUEST = "ACCOUNT_MANAGEMENT_REQUEST";
export const ACCOUNT_MANAGEMENT_SUCCESS = "ACCOUNT_MANAGEMENT_SUCCESS";
export const ACCOUNT_MANAGEMENT_FAILED = "ACCOUNT_MANAGEMENT_FAILED";

export const USER_INVITE_REQUEST = "USER_INVITE_REQUEST";
export const USER_INVITE_SUCCESS = "USER_INVITE_SUCCESS";
export const USER_INVITE_FAILED = "USER_INVITE_FAILED";

export const FETCH_USER_LISTS_REQUEST = "FETCH_USER_LISTS_REQUEST";
export const FETCH_USER_LISTS_SUCCESS = "FETCH_USER_LISTS_SUCCESS";
export const FETCH_USER_LISTS_FAILED = "FETCH_USER_LISTS_FAILED";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILED = "USER_DELETE_FAILED";

export const FETCH_NDA_LISTS_REQUEST = "FETCH_NDA_LISTS_REQUEST";
export const FETCH_NDA_LISTS_SUCCESS = "FETCH_NDA_LISTS_SUCCESS";
export const FETCH_NDA_LISTS_FAILED = "FETCH_NDA_LISTS_FAILED";

export const CREATE_NDA_REQUEST = "CREATE_NDA_REQUEST";
export const CREATE_NDA_SUCCESS = "CREATE_NDA_SUCCESS";
export const CREATE_NDA_FAILED = "CREATE_NDA_FAILED";

export const UPDATE_NDA_REQUEST = "UPDATE_NDA_REQUEST";
export const UPDATE_NDA_SUCCESS = "UPDATE_NDA_SUCCESS";
export const UPDATE_NDA_FAILED = "UPDATE_NDA_FAILED";

export const DELETE_NDA_REQUEST = "DELETE_NDA_REQUEST";
export const DELETE_NDA_SUCCESS = "DELETE_NDA_SUCCESS";
export const DELETE_NDA_FAILED = "DELETE_NDA_FAILED";

export const FETCH_ETEMPLATE_LISTS_REQUEST = "FETCH_ETEMPLATE_LISTS_REQUEST";
export const FETCH_ETEMPLATE_LISTS_SUCCESS = "FETCH_ETEMPLATE_LISTS_SUCCESS";
export const FETCH_ETEMPLATE_LISTS_FAILED = "FETCH_ETEMPLATE_LISTS_FAILED";

export const CREATE_ETEMPLATE_REQUEST = "CREATE_ETEMPLATE_REQUEST";
export const CREATE_ETEMPLATE_SUCCESS = "CREATE_ETEMPLATE_SUCCESS";
export const CREATE_ETEMPLATE_FAILED = "CREATE_ETEMPLATE_FAILED";

export const UPDATE_ETEMPLATE_REQUEST = "UPDATE_ETEMPLATE_REQUEST";
export const UPDATE_ETEMPLATE_SUCCESS = "UPDATE_ETEMPLATE_SUCCESS";
export const UPDATE_ETEMPLATE_FAILED = "UPDATE_ETEMPLATE_FAILED";

export const DELETE_ETEMPLATE_REQUEST = "DELETE_ETEMPLATE_REQUEST";
export const DELETE_ETEMPLATE_SUCCESS = "DELETE_ETEMPLATE_SUCCESS";
export const DELETE_ETEMPLATE_FAILED = "DELETE_ETEMPLATE_FAILED";

export const FETCH_TAC_LISTS_REQUEST = "FETCH_TAC_LISTS_REQUEST";
export const FETCH_TAC_LISTS_SUCCESS = "FETCH_TAC_LISTS_SUCCESS";
export const FETCH_TAC_LISTS_FAILED = "FETCH_TAC_LISTS_FAILED";

export const CREATE_TAC_REQUEST = "CREATE_TAC_REQUEST";
export const CREATE_TAC_SUCCESS = "CREATE_TAC_SUCCESS";
export const CREATE_TAC_FAILED = "CREATE_TAC_FAILED";

export const UPDATE_TAC_REQUEST = "UPDATE_TAC_REQUEST";
export const DELETE_TAC_REQUEST = "DELETE_TAC_REQUEST";
export const DELETE_TAC_SUCCESS = "DELETE_TAC_SUCCESS";
export const DELETE_TAC_FAILED = "DELETE_TAC_FAILED";

export const FETCH_ESIGN_LISTS_REQUEST = "FETCH_ESIGN_LISTS_REQUEST";
export const FETCH_ESIGN_LISTS_SUCCESS = "FETCH_ESIGN_LISTS_SUCCESS";
export const FETCH_ESIGN_LISTS_FAILED = "FETCH_ESIGN_LISTS_FAILED";

export const CREATE_ESIGN_REQUEST = "CREATE_ESIGN_REQUEST";
export const CREATE_ESIGN_SUCCESS = "CREATE_ESIGN_SUCCESS";
export const CREATE_ESIGN_FAILED = "CREATE_ESIGN_FAILED";

export const UPDATE_ESIGN_REQUEST = "UPDATE_ESIGN_REQUEST";
export const UPDATE_ESIGN_SUCCESS = "UPDATE_ESIGN_SUCCESS";
export const UPDATE_ESIGN_FAILED = "UPDATE_ESIGN_FAILED";

export const DELETE_ESIGN_REQUEST = "DELETE_ESIGN_REQUEST";
export const DELETE_ESIGN_SUCCESS = "DELETE_ESIGN_SUCCESS";
export const DELETE_ESIGN_FAILED = "DELETE_ESIGN_FAILED";

export const FETCH_NOTIFICATION_LISTS_REQUEST =
  "FETCH_NOTIFICATION_LISTS_REQUEST";
export const FETCH_NOTIFICATION_LISTS_SUCCESS =
  "FETCH_NOTIFICATION_LISTS_SUCCESS";
export const FETCH_NOTIFICATION_LISTS_FAILED =
  "FETCH_NOTIFICATION_LISTS_FAILED";

export const UPDATE_NOTIFICATION_REQUEST = "UPDATE_NOTIFICATION_REQUEST";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAILED = "UPDATE_NOTIFICATION_FAILED";

export const GET_NOTIFICATION_SETTINGS_REQUEST =
  "GET_NOTIFICATION_SETTINGS_REQUEST";
export const GET_NOTIFICATION_SETTINGS_SUCCESS =
  "GET_NOTIFICATION_SETTINGS_SUCCESS";
export const GET_NOTIFICATION_SETTINGS_FAILURE =
  "GET_NOTIFICATION_SETTINGS_FAILURE";

export const EDIT_NOTIFICATION_SETTINGS_REQUEST =
  "EDIT_NOTIFICATION_SETTINGS_REQUEST";
export const EDIT_NOTIFICATION_SETTINGS_SUCCESS =
  "EDIT_NOTIFICATION_SETTINGS_SUCCCESS";
export const EDIT_NOTIFICATION_SETTINGS_FAILURE =
  "EDIT_NOTIFICATION_SETTINGS_FAILURE";

export const FETCH_CF_LISTS_REQUEST = "FETCH_CF_LISTS_REQUEST";
export const FETCH_CF_LISTS_SUCCESS = "FETCH_CF_LISTS_SUCCESS";
export const FETCH_CF_LISTS_FAILED = "FETCH_CF_LISTS_FAILED";

export const CREATE_CF_REQUEST = "CREATE_CF_REQUEST";
export const CREATE_CF_SUCCESS = "CREATE_CF_SUCCESS";
export const CREATE_CF_FAILED = "CREATE_CF_FAILED";

export const UPDATE_CF_REQUEST = "UPDATE_CF_REQUEST";
export const UPDATE_CF_SUCCESS = "UPDATE_CF_SUCCESS";
export const UPDATE_CF_FAILED = "UPDATE_CF_FAILED";

export const DELETE_CF_REQUEST = "DELETE_CF_REQUEST";
export const DELETE_CF_SUCCESS = "DELETE_CF_SUCCESS";
export const DELETE_CF_FAILED = "DELETE_CF_FAILED";

export const FETCH_PRIVACY_POLICY_LISTS_REQUEST =
  "FETCH_PRIVACY_POLICY_LISTS_REQUEST";
export const FETCH_PRIVACY_POLICY_LISTS_SUCCESS =
  "FETCH_PRIVACY_POLICY_LISTS_SUCCESS";
export const FETCH_PRIVACY_POLICY_LISTS_FAILED =
  "FETCH_PRIVACY_POLICY_LISTS_FAILED";

export const CREATE_PRIVACY_POLICY_REQUEST = "CREATE_PRIVACY_POLICY_REQUEST";
export const CREATE_PRIVACY_POLICY_SUCCESS = "CREATE_PRIVACY_POLICY_SUCCESS";
export const CREATE_PRIVACY_POLICY_FAILED = "CREATE_PRIVACY_POLICY_FAILED";

export const DELETE_PRIVACY_POLICY_REQUEST = "DELETE_PRIVACY_POLICY_REQUEST";
export const DELETE_PRIVACY_POLICY_SUCCESS = "DELETE_PRIVACY_POLICY_SUCCESS";
export const DELETE_PRIVACY_POLICY_FAILED = "DELETE_PRIVACY_POLICY_FAILED";

export const GET_SHARE_REQUEST = "GET_SHARE_REQUEST";
export const GET_SHARE_SUCCESS = "GET_SHARE_SUCCESS";
export const GET_SHARE_FAILED = "GET_SHARE_FAILED";

export const CREATE_SHARE_REQUEST = "CREATE_SHARE_REQUEST";
export const CREATE_SHARE_SUCCESS = "CREATE_SHARE_SUCCESS";
export const CREATE_SHARE_FAILED = "CREATE_SHARE_FAILED";

export const UPDATE_SHARE_REQUEST = "UPDATE_SHARE_REQUEST";
export const UPDATE_SHARE_SUCCESS = "UPDATE_SHARE_SUCCESS";
export const UPDATE_SHARE_FAILED = "UPDATE_SHARE_FAILED";

export const POST_GMAIL_AUTH_REQUEST = "POST_GMAIL_AUTH_REQUEST";
export const POST_GMAIL_AUTH_SUCCESS = "POST_GMAIL_AUTH_SUCCESS";
export const POST_GMAIL_AUTH_FAILED = "POST_GMAIL_AUTH_FAILED";
