
export const FETCH_CONTACT_LISTS_REQUEST = 'FETCH_CONTACT_LISTS_REQUEST'
export const FETCH_CONTACT_LISTS_SUCCESS = 'FETCH_CONTACT_LISTS_SUCCESS'
export const FETCH_CONTACT_LISTS_FAILED = 'FETCH_CONTACT_LISTS_FAILED'
export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST'
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS'
export const CREATE_CONTACT_FAILED = 'CREATE_CONTACT_FAILED'
export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST'
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS'
export const DELETE_CONTACT_FAILED = 'DELETE_CONTACT_FAILED'
export const FETCH_DISTRIBUTION_LISTS_REQUEST = 'FETCH_DISTRIBUTION_LISTS_REQUEST'
export const FETCH_DISTRIBUTION_LISTS_SUCCESS = 'FETCH_DISTRIBUTION_LISTS_SUCCESS'
export const FETCH_DISTRIBUTION_LISTS_FAILED = 'FETCH_DISTRIBUTION_LISTS_FAILED'
export const CREATE_DISTRIBUTION_REQUEST = 'CREATE_DISTRIBUTION_REQUEST'
export const CREATE_DISTRIBUTION_SUCCESS = 'CREATE_DISTRIBUTION_SUCCESS'
export const CREATE_DISTRIBUTION_FAILED = 'CREATE_DISTRIBUTION_FAILED'
export const DELETE_DISTRIBUTION_REQUEST = 'DELETE_DISTRIBUTION_REQUEST'
export const DELETE_DISTRIBUTION_SUCCESS = 'DELETE_DISTRIBUTION_SUCCESS'
export const DELETE_DISTRIBUTION_FAILED = 'DELETE_DISTRIBUTION_FAILED'
export const ADD_CONTACT_DISTRIBUTION_REQUEST = 'ADD_CONTACT_DISTRIBUTION_REQUEST'
export const ADD_CONTACT_DISTRIBUTION_SUCCESS = 'ADD_CONTACT_DISTRIBUTION_SUCCESS'
export const ADD_CONTACT_DISTRIBUTION_FAILED = 'ADD_CONTACT_DISTRIBUTION_FAILED'
export const DELETE_CONTACT_DISTRIBUTION_REQUEST = 'DELETE_CONTACT_DISTRIBUTION_REQUEST'
export const DELETE_CONTACT_DISTRIBUTION_SUCCESS = 'DELETE_CONTACT_DISTRIBUTION_SUCCESS'
export const DELETE_CONTACT_DISTRIBUTION_FAILED = 'DELETE_CONTACT_DISTRIBUTION_FAILED'