import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Switch } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "../../../components/commons/LoadingSpinner";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { collectionActions } from "../actions";
import { format } from "date-fns";

const EditLink = ({ link, collectionId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.collection);
  const { nda: ndas } = useSelector((state) => state.settings);
  const [statusData, setStatusData] = useState({
    read_only: true,
    email_gate: false,
    share: true,
    download: true,
  });
  const [advancedSettingToggle, setAdvancedSettingToggle] = useState(false);
  const [linkTypeAnonymous, setLinkTypeAnonymous] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [expiryRequired, setExpiryRequired] = useState(false);
  const [ndaRequired, setNdaRequired] = useState(false);

  useEffect(() => {
    if (link) {
      setStatusData({
        read_only: link.read_only,
        email_gate: link.email_gate,
        share: link.share,
        download: link.download,
      });

      if (link.contact_data && link.contact_data.length === 0) {
        setLinkTypeAnonymous(true);
      }
      if (link.password) {
        setPasswordRequired(true);
      }
      if (link.expiry_date) {
        setExpiryRequired(true);
      }
      if (link.nda) {
        setNdaRequired(true);
      }
    }
  }, []); // eslint-disable-line

  const doe = link?.expiry_date ? new Date(link.expiry_date) : null;
  const timezoneOffset = doe ? doe?.getTimezoneOffset() * 60000 : null;

  const initialValues = {
    email:
      link.contact_data && link.contact_data.length
        ? link.contact_data[0].email
        : "",
    password: "",
    expiry_date: link.expiry_date
      ? format(
          new Date(new Date(doe.getTime() + timezoneOffset)),
          "yyyy-MM-dd'T'HH:mm:ss"
        )
      : "",
    nda: link.nda ? link.nda : "",
  };

  const FormSchema = Yup.object().shape({
    email: linkTypeAnonymous
      ? null
      : Yup.string().email("Invalid email").required("Please enter you Email"),
    password:
      passwordRequired && !link.password
        ? Yup.string()
            .required("Please enter password")
            .min(8, "Password is too short - should be 8 chars minimum.")
            .max(16, "Use max of 16 characters")
            .matches(
              /[^a-zA-Z0-9]/,
              "Password should have atleast 1 special character"
            )
            .matches(
              /[A-Z]/,
              "Password should have atleast 1 Upper case character"
            )
            .matches(
              /[a-z]/,
              "Password should have atleast 1 Lower case character"
            )
            .matches(/[0-9]/, "Password should have atleast 1 Number character")
        : null,
    expiry_date: expiryRequired
      ? Yup.date().required("Please enter expiry date")
      : null,
    nda: ndaRequired ? Yup.string().required("Please select NDA") : null,
  });

  const handleEditLink = (data) => {
    const payload = statusData;
    if (data.email !== link.contact_data[0].email) {
      payload.contact = data.email;
    }
    if (linkTypeAnonymous) {
      payload.contact = null;
    }
    if (data.password) {
      payload.password = data.password;
    }
    if (data.expiry_date) {
      payload.expiry_date = data.expiry_date;
    }
    if (data.nda) {
      payload.nda = data.nda;
    }

    if (linkTypeAnonymous) {
      payload.email = null;
    }
    if (!passwordRequired) {
      payload.password = null;
    }
    if (!expiryRequired) {
      payload.expiry_date = null;
    }
    if (!ndaRequired) {
      payload.nda = null;
    }

    payload.id = link.id;
    dispatch(collectionActions.handleEditLink(payload, collectionId));
  };

  const statusToggle = (status) => {
    setStatusData((prev) => ({ ...prev, [status]: !statusData[status] }));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleEditLink}
      validationSchema={FormSchema}
    >
      {({
        errors,
        touched,
        setFieldValue,
        values,
        handleBlur,
        handleChange,
      }) => (
        <Form autoComplete="off" role="presentation">
          <div className="p-4 gap-4 text-gray-600 ml-4 mt-2 border border-slate-300 rounded-sm">
            <div className="flex justify-between items-center w-1/2 pl-2 py-2">
              <div>Anonymous Link</div>{" "}
              <Switch
                checked={linkTypeAnonymous}
                onChange={() => setLinkTypeAnonymous(!linkTypeAnonymous)}
                className={`${
                  linkTypeAnonymous ? " bg-uberark-blue" : " bg-uberark-orange"
                }
              relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`${
                    linkTypeAnonymous ? "translate-x-5" : "translate-x-0"
                  }
                pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>{" "}
            </div>
            {!linkTypeAnonymous && (
              <>
                <div className="flex items-center">
                  <Field
                    placeholder="Enter recipient email here"
                    className="p-2 outline-none"
                    name="email"
                    id="email"
                  />
                </div>
                {errors.email && touched.email && (
                  <div className="text-red-600 pb-2">{errors.email}</div>
                )}
              </>
            )}

            <div
              className="pl-2 pt-6 flex items-center font-semibold"
              onMouseDown={() =>
                setAdvancedSettingToggle(!advancedSettingToggle)
              }
            >
              Advanced Settings{" "}
              {advancedSettingToggle ? (
                <BsChevronDown className="pl-1 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]" />
              ) : (
                <BsChevronUp className="pl-1 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]" />
              )}
            </div>
            {advancedSettingToggle && (
              <div className="pl-2">
                <div className="flex justify-between items-center w-1/2 py-2">
                  <div>
                    Read Only
                    <div className="text-xs italic">
                      *This applies to PPTs, Docs, Google Slides and Google
                      Docs.
                    </div>
                  </div>{" "}
                  <Switch
                    checked={statusData["read_only"]}
                    onChange={() => statusToggle("read_only")}
                    className={`${
                      statusData["read_only"]
                        ? " bg-uberark-blue"
                        : " bg-uberark-orange"
                    }
                relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        statusData["read_only"]
                          ? "translate-x-5"
                          : "translate-x-0"
                      }
                pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>{" "}
                </div>

                <div className="flex justify-between items-center w-1/2 py-2">
                  <div>Enable Email Gating</div>{" "}
                  <Switch
                    checked={statusData["email_gate"]}
                    onChange={() => statusToggle("email_gate")}
                    className={`${
                      statusData["email_gate"]
                        ? " bg-uberark-blue"
                        : " bg-uberark-orange"
                    }
                relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        statusData["email_gate"]
                          ? "translate-x-5"
                          : "translate-x-0"
                      }
                pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>{" "}
                </div>

                <div className="flex justify-between items-center w-1/2 py-2">
                  <div>Allow recipient to Reshare</div>{" "}
                  <Switch
                    checked={statusData["share"]}
                    onChange={() => statusToggle("share")}
                    className={`${
                      statusData["share"]
                        ? " bg-uberark-blue"
                        : " bg-uberark-orange"
                    }
                relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        statusData["share"] ? "translate-x-5" : "translate-x-0"
                      }
                pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>{" "}
                </div>

                <div className="flex justify-between items-center w-1/2 py-2">
                  <div>Allow recipient to Download</div>{" "}
                  <Switch
                    checked={statusData["download"]}
                    onChange={() => statusToggle("download")}
                    className={`${
                      statusData["download"]
                        ? " bg-uberark-blue"
                        : " bg-uberark-orange"
                    }
                relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        statusData["download"]
                          ? "translate-x-5"
                          : "translate-x-0"
                      }
                pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>{" "}
                </div>

                <div className="flex justify-between items-center w-1/2 py-2">
                  <div>Set a Password</div>{" "}
                  <Switch
                    checked={passwordRequired}
                    onChange={() => setPasswordRequired(!passwordRequired)}
                    className={`${
                      passwordRequired
                        ? " bg-uberark-blue"
                        : " bg-uberark-orange"
                    }
                relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        passwordRequired ? "translate-x-5" : "translate-x-0"
                      }
                  pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>{" "}
                </div>
                {passwordRequired && (
                  <>
                    <div className="flex items-center">
                      <Field
                        placeholder="***********"
                        className="p-2 outline-none"
                        name="password"
                        id="password"
                        type="password"
                        autoComplete="new-password"
                      />
                    </div>
                    {errors.password && touched.password && (
                      <div className="text-red-600 pb-2">{errors.password}</div>
                    )}
                  </>
                )}

                <div className="flex justify-between items-center w-1/2 py-2">
                  <div>Set expiry date & time</div>{" "}
                  <Switch
                    checked={expiryRequired}
                    onChange={() => setExpiryRequired(!expiryRequired)}
                    className={`${
                      expiryRequired ? " bg-uberark-blue" : " bg-uberark-orange"
                    }
                relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        expiryRequired ? "translate-x-5" : "translate-x-0"
                      }
                  pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>{" "}
                </div>
                {expiryRequired && (
                  <>
                    <div className="flex items-center">
                      <Field
                        className="p-2 outline-none"
                        name="expiry_date"
                        id="expiry_date"
                        type="datetime-local"
                      />
                    </div>
                    {errors.expiry_date && touched.expiry_date && (
                      <div className="text-red-600 pb-2">
                        {errors.expiry_date}
                      </div>
                    )}
                  </>
                )}

                <div className="flex justify-between items-center w-1/2 py-2">
                  <div>
                    Enable NDA
                    {(!ndas || ndas.length === 0) && (
                      <div className="text-xs text-[#ff0000] italic">
                        *You need to create an NDAs to enable NDA.{" "}
                        <div
                          className="underline font-semibold"
                          onMouseDown={() => navigate(`/UserProfile/NDA`)}
                        >
                          Create NDA
                        </div>
                      </div>
                    )}
                  </div>{" "}
                  <Switch
                    checked={ndaRequired}
                    onChange={() =>
                      ndas && ndas.length > 0 && setNdaRequired(!ndaRequired)
                    }
                    className={`${
                      ndaRequired ? " bg-uberark-blue" : " bg-uberark-orange"
                    }
                relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        ndaRequired ? "translate-x-5" : "translate-x-0"
                      }
                  pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>{" "}
                </div>
                {ndaRequired && (
                  <>
                    <div className="flex items-center">
                      <select
                        name="nda"
                        value={values.nda}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="Select an NDA">
                          Select an NDA{" "}
                        </option>
                        {ndas &&
                          ndas.map((nda, index) => (
                            <option value={nda.id} label={nda.title}>
                              {" "}
                              {nda.title}
                            </option>
                          ))}
                      </select>
                    </div>
                    {errors.nda && touched.nda && (
                      <div className="text-red-600 pb-2">{errors.nda}</div>
                    )}
                  </>
                )}
              </div>
            )}

            <div className="flex flex-row items-center text-uberark-blue gap-4 pt-5 text-lg">
              <button
                className=":px-2 bg-[#000080] text-white hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl"
                type="submit"
                disabled={loading}
              >
                {loading ? <LoadingSpinner /> : "Submit"}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditLink;
