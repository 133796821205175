import * as types from "./actionTypes";
import { request, success, failure } from "../../../app/utilities/helpers";
import { collectionService } from "./_service";

import { toast } from "react-toastify";

const handleGetCollection = (filter, resetSearch, pageNumber) => {
  return (dispatch) => {
    dispatch(request(null, types.GET_COLLECTION_REQUEST));
    let queryUrl = `collections-shallow?page=${pageNumber ? pageNumber : 1}`;
    if (filter && filter.value) {
      queryUrl = queryUrl + `&${filter.name}=${filter.value}`;
    }
    collectionService.handleGetCollection(queryUrl).then(
      (res) => {
        dispatch(success(res, types.GET_COLLECTION_SUCCESS));
        if (resetSearch) {
          setTimeout(function () {
            resetSearch();
          }, 300);
        }
      },
      (error) => {
        dispatch(failure(error, types.GET_COLLECTION_FAILED));
        // if (error?.response?.status === 403 || error?.response?.status === 401) {
        //   dispatch(failure(error, types.GET_COLLECTION_FAILED))
        // } else if (error?.response?.data?.message) {
        //   toast.error(error?.response?.data?.message)
        // } else {
        //   toast.error("Something went wrong. It's us not you")
        // }
      }
    );
  };
};

const handleGetAllCollection = (
  organizationId,
  order_by,
  filter,
  resetSearch
) => {
  return (dispatch) => {
    dispatch(request(null, types.GET_ALL_COLLECTION_REQUEST));
    let queryUrl = `collections?organization=${organizationId}`;
    if (order_by) {
      queryUrl = queryUrl + `&order_by=${order_by}`;
    }
    if (filter && filter.value) {
      queryUrl = queryUrl + `&${filter.name}=${filter.value}`;
    }
    collectionService.handleGetCollection(queryUrl).then(
      (res) => {
        dispatch(success(res, types.GET_ALL_COLLECTION_SUCCESS));
        if (resetSearch) {
          setTimeout(function () {
            resetSearch();
          }, 300);
        }
      },
      (error) => {
        dispatch(failure(error, types.GET_ALL_COLLECTION_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.GET_ALL_COLLECTION_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleCreateCollection = (data, onSubmitCallback, templateId) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_COLLECTION_REQUEST));
    collectionService.handleCreateCollection(data, templateId).then(
      (res) => {
        toast.success("Collection created successfully");
        dispatch(success(true, types.CREATE_COLLECTION_SUCCESS));

        setTimeout(function () {
          onSubmitCallback(res.id);
        }, 300);
      },
      (error) => {
        dispatch(failure(error, types.CREATE_COLLECTION_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.CREATE_COLLECTION_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleEditCollection = (data, onSubmitCallback) => {
  return (dispatch) => {
    dispatch(request(null, types.EDIT_COLLECTION_REQUEST));
    collectionService.handleEditCollection(data).then(
      (res) => {
        toast.success("Collection edited successfully");
        dispatch(success(res, types.EDIT_COLLECTION_SUCCESS));
        setTimeout(function () {
          onSubmitCallback();
        }, 300);
      },
      (error) => {
        dispatch(failure(error, types.EDIT_COLLECTION_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.EDIT_COLLECTION_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleDeleteCollection = (collectionId, onSubmitCallback) => {
  return (dispatch) => {
    dispatch(request(null, types.DELETE_COLLECTION_REQUEST));
    collectionService.handleDeleteCollection(collectionId).then(
      (res) => {
        toast.success("Collection deleted successfully");
        dispatch(success(collectionId, types.DELETE_COLLECTION_SUCCESS));
        setTimeout(function () {
          onSubmitCallback && onSubmitCallback();
        }, 1000);
      },
      (error) => {
        dispatch(failure(error, types.DELETE_COLLECTION_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.DELETE_COLLECTION_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleCreateSection = (data, handleClose) => {
  return (dispatch, getState) => {
    // const organization = getState().auth.user.organization
    dispatch(request(null, types.CREATE_SECTION_REQUEST));
    collectionService.handleCreateSection(data).then(
      (res) => {
        toast.success("Section created successfully");
        dispatch(success(res, types.CREATE_SECTION_SUCCESS));
        setTimeout(function () {
          dispatch(
            collectionActions.handleGetCollectioPreview(data.collection, null)
          );
          handleClose && handleClose();
        }, 300);
      },
      (error) => {
        dispatch(failure(error, types.CREATE_SECTION_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.CREATE_SECTION_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleEditSection = (payload, onSubmitCallback) => {
  return (dispatch, getState) => {
    dispatch(request(null, types.EDIT_SECTION_REQUEST));
    collectionService.handleEditSection(payload).then(
      (res) => {
        toast.success("Section edited successfully");
        dispatch(success(res, types.EDIT_SECTION_SUCCESS));
        setTimeout(function () {
          dispatch(
            collectionActions.handleGetCollectioPreview(
              payload.collection,
              payload.id
            )
          );
          onSubmitCallback();
        }, 300);
      },
      (error) => {
        dispatch(failure(error, types.EDIT_SECTION_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.EDIT_SECTION_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleDeleteSection = (sectionId, collectionId) => {
  return (dispatch, getState) => {
    dispatch(request(null, types.DELETE_SECTION_REQUEST));
    collectionService.handleDeleteSection(sectionId).then(
      (res) => {
        toast.success("Section deleted successfully");
        dispatch(success(sectionId, types.DELETE_SECTION_SUCCESS));
        setTimeout(function () {
          dispatch(
            collectionActions.handleGetCollectioPreview(collectionId, null)
          );
        }, 1000);
      },
      (error) => {
        dispatch(failure(error, types.DELETE_SECTION_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.DELETE_SECTION_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleCreateLink = (data) => {
  return (dispatch, getState) => {
    dispatch(request(null, types.CREATE_LINK_REQUEST));
    collectionService.handleCreateLink(data).then(
      (res) => {
        toast.success("Link created successfully");
        dispatch(success(res, types.CREATE_LINK_SUCCESS));
        setTimeout(function () {
          dispatch(
            collectionActions.handleGetCollectioPreview(data.collection, null)
          );
        }, 500);
      },
      (error) => {
        dispatch(failure(error, types.CREATE_LINK_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.CREATE_LINK_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleClearLink = (data) => {
  return (dispatch) => {
    dispatch(success("", types.CREATE_LINK_SUCCESS));
  };
};

const handleAddCollaborators = (data) => {
  return (dispatch, getState) => {
    dispatch(request(null, types.ADD_COLLABORATOR_REQUEST));
    collectionService.handleAddCollaborators(data).then(
      (res) => {
        toast.success("Collaborator added successfully");
        dispatch(success(true, types.ADD_COLLABORATOR_SUCCESS));
        setTimeout(() => {
          dispatch(handleGetCollectioPreview(data.collection));
        }, 200);
      },
      (error) => {
        dispatch(failure(error, types.ADD_COLLABORATOR_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.ADD_COLLABORATOR_FAILED));
        } else if (error?.response?.status === 409) {
          toast.warning(error?.response?.data?.message);
          setTimeout(() => {
            dispatch(handleGetCollectioPreview(data.collection));
          }, 200);
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleDeleteCollaborator = (collaboratorId, id) => {
  return (dispatch, getState) => {
    dispatch(request(null, types.DELETE_COLLABORATOR_REQUEST));
    collectionService.handleDeleteCollaborator(collaboratorId).then(
      (res) => {
        toast.success("Collaborator deleted successfully");
        dispatch(success(collaboratorId, types.DELETE_COLLABORATOR_SUCCESS));
        setTimeout(() => {
          dispatch(handleGetCollectioPreview(id));
        }, 200);
      },
      (error) => {
        dispatch(failure(error, types.DELETE_COLLABORATOR_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.DELETE_COLLABORATOR_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleDeleteLink = (linkId, collectionId) => {
  return (dispatch, getState) => {
    dispatch(request(null, types.DELETE_LINK_REQUEST));
    collectionService.handleDeleteLink(linkId).then(
      (res) => {
        toast.success("Link deleted successfully");
        dispatch(success(res, types.DELETE_LINK_SUCCESS));
        setTimeout(function () {
          dispatch(
            collectionActions.handleGetCollectioPreview(collectionId, null)
          );
        }, 500);
      },
      (error) => {
        dispatch(failure(error, types.DELETE_LINK_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.DELETE_LINK_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleEditLink = (data, collectionId) => {
  return (dispatch, getState) => {
    dispatch(request(null, types.EDIT_LINK_REQUEST));
    collectionService.handleEditLink(data).then(
      (res) => {
        toast.success("Link edited successfully");
        dispatch(success(res, types.EDIT_LINK_SUCCESS));
        setTimeout(function () {
          dispatch(
            collectionActions.handleGetCollectioPreview(collectionId, null)
          );
        }, 300);
      },
      (error) => {
        dispatch(failure(error, types.EDIT_LINK_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.EDIT_LINK_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleCreateFolder = (data, callback) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_FOLDER_REQUEST));
    collectionService.handleCreateFolder(data).then(
      (res) => {
        toast.success("Folder created successfully");
        dispatch(success(res, types.CREATE_FOLDER_SUCCESS));
        callback && callback();
        setTimeout(function () {
          dispatch(collectionActions.handleGetFolder());
        }, 300);
      },
      (error) => {
        dispatch(failure(error, types.CREATE_FOLDER_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.CREATE_FOLDER_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleGetFolder = () => {
  return (dispatch) => {
    dispatch(request(null, types.GET_FOLDER_REQUEST));
    collectionService.handleGetFolder().then(
      (res) => {
        dispatch(success(res, types.GET_FOLDER_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.GET_FOLDER_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.GET_FOLDER_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleDeleteFolder = (folderId) => {
  return (dispatch) => {
    dispatch(request(null, types.DELETE_FOLDER_REQUEST));
    collectionService.handleDeleteFolder(folderId).then(
      (res) => {
        toast.success("Folder deleted successfully");
        dispatch(success(res, types.DELETE_FOLDER_SUCCESS));
        setTimeout(function () {
          dispatch(collectionActions.handleGetFolder());
          dispatch(collectionActions.handleGetCollection());
        }, 500);
      },
      (error) => {
        dispatch(failure(error, types.DELETE_FOLDER_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.DELETE_FOLDER_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleEditFolder = (data, callback) => {
  return (dispatch) => {
    dispatch(request(null, types.EDIT_FOLDER_REQUEST));
    collectionService.handleEditFolder(data).then(
      (res) => {
        toast.success("Folder edited successfully");
        dispatch(success(res, types.EDIT_FOLDER_SUCCESS));
        callback && callback();
        setTimeout(function () {
          dispatch(collectionActions.handleGetFolder());
        }, 300);
      },
      (error) => {
        dispatch(failure(error, types.EDIT_FOLDER_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.EDIT_FOLDER_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleCloneCollection = (collectionId) => {
  return (dispatch) => {
    dispatch(request(null, types.CLONE_COLLECTION_REQUEST));
    collectionService.handleCloneCollection(collectionId).then(
      (res) => {
        toast.success("Collection Cloned successfully");
        dispatch(success(collectionId, types.CLONE_COLLECTION_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.CLONE_COLLECTION_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.CLONE_COLLECTION_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleGetCollectioPreview = (collectionID, sectionId) => {
  return (dispatch) => {
    dispatch(request(null, types.PREVIEW_COLLECTION_REQUEST));
    let queryUrl = `collections/${collectionID}`;
    collectionService.handleGetCollection(queryUrl).then(
      (res) => {
        dispatch(
          success(
            { ...res, sectionId: sectionId },
            types.PREVIEW_COLLECTION_SUCCESS
          )
        );
      },
      (error) => {
        dispatch(failure(error, types.PREVIEW_COLLECTION_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.PREVIEW_COLLECTION_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

const handleGetCollectionTemplate = (pageNumber, templateOn) => {
  return (dispatch) => {
    dispatch(request(null, types.GET_COLLECTION_TEMPLATE_REQUEST));
    let queryUrl = `collections-shallow?page=${pageNumber}&template=${templateOn}`;
    collectionService.handleGetCollection(queryUrl).then(
      (res) => {
        dispatch(success(res, types.GET_COLLECTION_TEMPLATE_SUCCESS));
      },
      (error) => {
        dispatch(failure(error, types.GET_COLLECTION_TEMPLATE_FAILED));
        if (
          error?.response?.status === 403 ||
          error?.response?.status === 401
        ) {
          dispatch(failure(error, types.GET_COLLECTION_TEMPLATE_FAILED));
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong. It's us not you");
        }
      }
    );
  };
};

export const collectionActions = {
  handleGetCollection,
  handleCreateCollection,
  handleEditCollection,
  handleDeleteCollection,
  handleCreateSection,
  handleEditSection,
  handleDeleteSection,
  handleCreateLink,
  handleDeleteLink,
  handleEditLink,
  handleCreateFolder,
  handleGetFolder,
  handleDeleteFolder,
  handleEditFolder,
  handleAddCollaborators,
  handleDeleteCollaborator,
  handleClearLink,
  handleCloneCollection,
  handleGetAllCollection,
  handleGetCollectioPreview,
  handleGetCollectionTemplate,
};
